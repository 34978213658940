import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from './Icon';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'absolute',
      top: theme.spacing(2),
      left: -48, // width of button
    },
  }),
  { name: 'ButtonBack' }
);

type Props = {
  to: string;
  onClick?: () => void;
};

export const ButtonBack = (props: Props): JSX.Element => (
  <IconButton
    className={useStyles({}).root}
    component={!props.onClick ? Link : undefined}
    {...props}
    children={<Icon name="chevronLeft" />}
  />
);
