// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AssignEmployeeRequest,
    CountPotentialEmployeeRequest,
    CountPotentialEmployees,
    OpportunityRequest,
    ProjectDTO,
    ProjectPositionDTO,
    ProjectPositionRequest,
    ProjectRequest,
    ProjectsDTO,
    SuggestedEmployeesDTO,
} from '../models';

export interface CountPotentialEmployeesRequest {
    countPotentialEmployeeRequest: CountPotentialEmployeeRequest;
}

export interface CreateOpportunityRequest {
    opportunityRequest: OpportunityRequest;
}

export interface CreateProjectRequest {
    projectRequest: ProjectRequest;
}

export interface CreateProjectPositionRequest {
    id: string;
    projectPositionRequest: ProjectPositionRequest;
}

export interface DeleteProjectRequest {
    id: string;
}

export interface DeleteProjectPositionRequest {
    projectId: string;
    positionId: string;
}

export interface GetProjectRequest {
    id: string;
}

export interface GetProjectsRequest {
    status?: GetProjectsStatusEnum;
    searchKeyword?: string;
    page?: number;
}

export interface GetSuggestedEmployeesRequest {
    projectId: string;
    positionId: string;
    pool?: GetSuggestedEmployeesPoolEnum;
    page?: number;
}

export interface PatchAssignEmployeeRequest {
    id: string;
    assignEmployeeRequest: AssignEmployeeRequest;
}

export interface UpdateProjectRequest {
    id: string;
    projectRequest: ProjectRequest;
}

export interface UpdateProjectPositionRequest {
    projectId: string;
    positionId: string;
    projectPositionRequest: ProjectPositionRequest;
}

/**
 * no description
 */
export class ProjectControllerApi extends BaseAPI {

    /**
     */
    countPotentialEmployees({ countPotentialEmployeeRequest }: CountPotentialEmployeesRequest): Observable<CountPotentialEmployees>
    countPotentialEmployees({ countPotentialEmployeeRequest }: CountPotentialEmployeesRequest, opts?: OperationOpts): Observable<AjaxResponse<CountPotentialEmployees>>
    countPotentialEmployees({ countPotentialEmployeeRequest }: CountPotentialEmployeesRequest, opts?: OperationOpts): Observable<CountPotentialEmployees | AjaxResponse<CountPotentialEmployees>> {
        throwIfNullOrUndefined(countPotentialEmployeeRequest, 'countPotentialEmployeeRequest', 'countPotentialEmployees');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        let result = this.request<CountPotentialEmployees>({
            url: '/api/projects/positions/countPotentialEmployees',
            method: 'POST',
            headers,
            body: countPotentialEmployeeRequest,
        }, opts?.responseOpts);
        return result;
    };

    /**
     */
    createOpportunity({ opportunityRequest }: CreateOpportunityRequest): Observable<ProjectDTO>
    createOpportunity({ opportunityRequest }: CreateOpportunityRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectDTO>>
    createOpportunity({ opportunityRequest }: CreateOpportunityRequest, opts?: OperationOpts): Observable<ProjectDTO | AjaxResponse<ProjectDTO>> {
        throwIfNullOrUndefined(opportunityRequest, 'opportunityRequest', 'createOpportunity');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ProjectDTO>({
            url: '/api/projects/opportunity',
            method: 'POST',
            headers,
            body: opportunityRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    createProject({ projectRequest }: CreateProjectRequest): Observable<ProjectDTO>
    createProject({ projectRequest }: CreateProjectRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectDTO>>
    createProject({ projectRequest }: CreateProjectRequest, opts?: OperationOpts): Observable<ProjectDTO | AjaxResponse<ProjectDTO>> {
        throwIfNullOrUndefined(projectRequest, 'projectRequest', 'createProject');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ProjectDTO>({
            url: '/api/projects',
            method: 'POST',
            headers,
            body: projectRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    createProjectPosition({ id, projectPositionRequest }: CreateProjectPositionRequest): Observable<ProjectPositionDTO>
    createProjectPosition({ id, projectPositionRequest }: CreateProjectPositionRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectPositionDTO>>
    createProjectPosition({ id, projectPositionRequest }: CreateProjectPositionRequest, opts?: OperationOpts): Observable<ProjectPositionDTO | AjaxResponse<ProjectPositionDTO>> {
        throwIfNullOrUndefined(id, 'id', 'createProjectPosition');
        throwIfNullOrUndefined(projectPositionRequest, 'projectPositionRequest', 'createProjectPosition');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ProjectPositionDTO>({
            url: '/api/projects/{id}/positions'.replace('{id}', encodeURI(id)),
            method: 'POST',
            headers,
            body: projectPositionRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    deleteProject({ id }: DeleteProjectRequest): Observable<object>
    deleteProject({ id }: DeleteProjectRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    deleteProject({ id }: DeleteProjectRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(id, 'id', 'deleteProject');

        return this.request<object>({
            url: '/api/projects/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     */
    deleteProjectPosition({ projectId, positionId }: DeleteProjectPositionRequest): Observable<object>
    deleteProjectPosition({ projectId, positionId }: DeleteProjectPositionRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    deleteProjectPosition({ projectId, positionId }: DeleteProjectPositionRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(projectId, 'projectId', 'deleteProjectPosition');
        throwIfNullOrUndefined(positionId, 'positionId', 'deleteProjectPosition');

        return this.request<object>({
            url: '/api/projects/{projectId}/positions/{positionId}'.replace('{projectId}', encodeURI(projectId)).replace('{positionId}', encodeURI(positionId)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     */
    getProject({ id }: GetProjectRequest): Observable<ProjectDTO>
    getProject({ id }: GetProjectRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectDTO>>
    getProject({ id }: GetProjectRequest, opts?: OperationOpts): Observable<ProjectDTO | AjaxResponse<ProjectDTO>> {
        throwIfNullOrUndefined(id, 'id', 'getProject');

        return this.request<ProjectDTO>({
            url: '/api/projects/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    getProjects({ status, searchKeyword, page }: GetProjectsRequest): Observable<ProjectsDTO>
    getProjects({ status, searchKeyword, page }: GetProjectsRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectsDTO>>
    getProjects({ status, searchKeyword, page }: GetProjectsRequest, opts?: OperationOpts): Observable<ProjectsDTO | AjaxResponse<ProjectsDTO>> {

        const query: HttpQuery = {};

        if (status != null) { query['status'] = status; }
        if (searchKeyword != null) { query['searchKeyword'] = searchKeyword; }
        if (page != null) { query['page'] = page; }

        return this.request<ProjectsDTO>({
            url: '/api/projects',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getSuggestedEmployees({ projectId, positionId, pool, page }: GetSuggestedEmployeesRequest): Observable<SuggestedEmployeesDTO>
    getSuggestedEmployees({ projectId, positionId, pool, page }: GetSuggestedEmployeesRequest, opts?: OperationOpts): Observable<AjaxResponse<SuggestedEmployeesDTO>>
    getSuggestedEmployees({ projectId, positionId, pool, page }: GetSuggestedEmployeesRequest, opts?: OperationOpts): Observable<SuggestedEmployeesDTO | AjaxResponse<SuggestedEmployeesDTO>> {
        throwIfNullOrUndefined(projectId, 'projectId', 'getSuggestedEmployees');
        throwIfNullOrUndefined(positionId, 'positionId', 'getSuggestedEmployees');

        const query: HttpQuery = {};

        if (pool != null) { query['pool'] = pool; }
        if (page != null) { query['page'] = page; }

        return this.request<SuggestedEmployeesDTO>({
            url: '/api/projects/{projectId}/positions/{positionId}/employees'.replace('{projectId}', encodeURI(projectId)).replace('{positionId}', encodeURI(positionId)),
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    patchAssignEmployee({ id, assignEmployeeRequest }: PatchAssignEmployeeRequest): Observable<ProjectDTO>
    patchAssignEmployee({ id, assignEmployeeRequest }: PatchAssignEmployeeRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectDTO>>
    patchAssignEmployee({ id, assignEmployeeRequest }: PatchAssignEmployeeRequest, opts?: OperationOpts): Observable<ProjectDTO | AjaxResponse<ProjectDTO>> {
        throwIfNullOrUndefined(id, 'id', 'patchAssignEmployee');
        throwIfNullOrUndefined(assignEmployeeRequest, 'assignEmployeeRequest', 'patchAssignEmployee');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ProjectDTO>({
            url: '/api/projects/{id}/assignments'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: assignEmployeeRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    updateProject({ id, projectRequest }: UpdateProjectRequest): Observable<ProjectDTO>
    updateProject({ id, projectRequest }: UpdateProjectRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectDTO>>
    updateProject({ id, projectRequest }: UpdateProjectRequest, opts?: OperationOpts): Observable<ProjectDTO | AjaxResponse<ProjectDTO>> {
        throwIfNullOrUndefined(id, 'id', 'updateProject');
        throwIfNullOrUndefined(projectRequest, 'projectRequest', 'updateProject');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ProjectDTO>({
            url: '/api/projects/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: projectRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    updateProjectPosition({ projectId, positionId, projectPositionRequest }: UpdateProjectPositionRequest): Observable<ProjectPositionDTO>
    updateProjectPosition({ projectId, positionId, projectPositionRequest }: UpdateProjectPositionRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectPositionDTO>>
    updateProjectPosition({ projectId, positionId, projectPositionRequest }: UpdateProjectPositionRequest, opts?: OperationOpts): Observable<ProjectPositionDTO | AjaxResponse<ProjectPositionDTO>> {
        throwIfNullOrUndefined(projectId, 'projectId', 'updateProjectPosition');
        throwIfNullOrUndefined(positionId, 'positionId', 'updateProjectPosition');
        throwIfNullOrUndefined(projectPositionRequest, 'projectPositionRequest', 'updateProjectPosition');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ProjectPositionDTO>({
            url: '/api/projects/{projectId}/positions/{positionId}'.replace('{projectId}', encodeURI(projectId)).replace('{positionId}', encodeURI(positionId)),
            method: 'PUT',
            headers,
            body: projectPositionRequest,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetProjectsStatusEnum {
    Done = 'DONE',
    Running = 'RUNNING',
    Opportunity = 'OPPORTUNITY'
}
/**
 * @export
 * @enum {string}
 */
export enum GetSuggestedEmployeesPoolEnum {
    Ba = 'BA',
    Su = 'SU',
    Cluster = 'CLUSTER',
    MhpLocal = 'MHP_LOCAL',
    MhpInternational = 'MHP_INTERNATIONAL',
    Freelancer = 'FREELANCER'
}
