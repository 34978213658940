// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    ClientDTO,
    EmployeeDTO,
    HyperlinkDTO,
    ProjectPositionDTO,
} from './';

/**
 * @export
 * @interface ProjectDTO
 */
export interface ProjectDTO {
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    id: string;
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    name: string;
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    description?: string;
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    startDate: string;
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    endDate?: string;
    /**
     * @type {ClientDTO}
     * @memberof ProjectDTO
     */
    client?: ClientDTO;
    /**
     * @type {number}
     * @memberof ProjectDTO
     */
    budget?: number;
    /**
     * @type {number}
     * @memberof ProjectDTO
     */
    manDays?: number;
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    location?: string;
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    projectNumber: string;
    /**
     * @type {EmployeeDTO}
     * @memberof ProjectDTO
     */
    projectLead?: EmployeeDTO;
    /**
     * @type {EmployeeDTO}
     * @memberof ProjectDTO
     */
    projectSubLead?: EmployeeDTO;
    /**
     * @type {number}
     * @memberof ProjectDTO
     */
    projectLeadWorkingHours: number;
    /**
     * @type {number}
     * @memberof ProjectDTO
     */
    projectSubLeadWorkingHours?: number;
    /**
     * @type {Array<ProjectPositionDTO>}
     * @memberof ProjectDTO
     */
    positions: Array<ProjectPositionDTO>;
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    label: ProjectDTOLabelEnum;
    /**
     * @type {Array<HyperlinkDTO>}
     * @memberof ProjectDTO
     */
    hyperlinks: Array<HyperlinkDTO>;
    /**
     * @type {number}
     * @memberof ProjectDTO
     */
    staffedPositions?: number;
    /**
     * @type {number}
     * @memberof ProjectDTO
     */
    totalPositions?: number;
    /**
     * @type {Array<string>}
     * @memberof ProjectDTO
     */
    permissions: Array<ProjectDTOPermissionsEnum>;
    /**
     * @type {string}
     * @memberof ProjectDTO
     */
    status: ProjectDTOStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ProjectDTOLabelEnum {
    Critical = 'CRITICAL',
    Normal = 'NORMAL'
}
/**
 * @export
 * @enum {string}
 */
export enum ProjectDTOPermissionsEnum {
    Create = 'CREATE',
    Read = 'READ',
    Update = 'UPDATE',
    Delete = 'DELETE'
}
/**
 * @export
 * @enum {string}
 */
export enum ProjectDTOStatusEnum {
    Opportunity = 'OPPORTUNITY',
    Running = 'RUNNING',
    Done = 'DONE'
}

