import { makeStyles, createStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnterpriseSearchField } from '..';
import { Position, UpdateStatus, SearchType } from '../../models';
import { autoCompleteSuggestions, clearSuggestion, getCriteriaOptions } from '../../store/search';
import { getFormStyles } from '../../utils';
import { PositionFormModel } from '../StepperModal';
import * as Yup from 'yup';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getFormStyles(theme),
      checkBox: {
        margin: 0,
      },
      skills: {
        height: 400,
        overflowY: 'scroll',
      },
      divider: {
        margin: '14px 0px',
      },
      caption: {
        width: '100%',
      },
    }),
  { name: 'PositionForm' }
);

const validateName = (key: FormPosition) => key;

type Props = {
  position?: Position;
  updateStatus?: UpdateStatus;
  type?: Extract<SearchType, 'Certificate' | 'Language'>;
};

type FormPosition = keyof PositionFormModel | string;

export const validationSchemaCertificatesLanguages = Yup.object().shape({
  certificatesLanguages: Yup.array().nullable().notRequired(),
});

export const CertificatesLanguagesStep = ({ position, updateStatus, type }: Props) => {
  const { values, setFieldValue } = useFormikContext<PositionFormModel>();
  const [rerender, setRerender] = useState(true);
  const dispatch = useDispatch();
  const c = useStyles({});
  const criteriaOptions = useSelector(getCriteriaOptions);

  const formPosition = type.toLowerCase() as FormPosition;

  return (
    <div className={c.section}>
      {rerender && (
        <div>
          <EnterpriseSearchField
            useLogic={false}
            criteriaOptions={criteriaOptions}
            onInputChange={(keyword) =>
              keyword
                ? dispatch(
                    autoCompleteSuggestions.request({
                      keyword,
                      types: [type],
                    })
                  )
                : dispatch(clearSuggestion())
            }
            onSubmit={(field) => {
              setFieldValue(validateName(formPosition), [...values[formPosition], field]);
              setRerender(false);
              setRerender(true);
            }}
            placeholder={`Add ${type} (optional)`}
          />
        </div>
      )}
      <div className={c.skills}>
        {(values[formPosition] ?? []).map((field, index) => (
          <EnterpriseSearchField
            useLogic={false}
            searchField={field}
            onDelete={() => {
              setFieldValue(validateName(formPosition), [
                ...values[formPosition].filter((certLang) => certLang.criteria.value !== field.criteria.value),
              ]);
            }}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};
