import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBlock, List, PositionSortBy, Tabs } from '../components';
import { getSubNavStyles, TabValue } from '../components/Tabs';
import { getWrapStyles, Main } from '../layout';
import { Position, PositionStatus } from '../models';
import { sitemap } from '../routes';
import {
  fetchMyPositions,
  fetchOpenPositions,
  getMyPositionPagination,
  getMyPositions,
  getOpenPositionPagination,
  getOpenPositions,
  getSortBy,
  getStatus,
  setPositionsStatus,
  updatePositionLike,
} from '../store/positions';
import { getConfig, getUser } from '../store/app';
import { Pagination } from '../components/Pagination';
import { useToasts } from 'react-toast-notifications';
import { isUserProjectLead } from '../utils';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
      main: {
        top: theme.header.height,
      },
      head: {
        ...getSubNavStyles(theme).root,
        height: theme.subnav.height,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center',
      },
      headInner: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      group: {
        background: '#e3e4e4', // TODO: move into theme?
      },
      groupHead: {
        background: theme.palette.common.white,
        height: 40,
        lineHeight: '40px',
      },
      groupTitle: {
        fontSize: 14,
        color: theme.palette.primary.main,
      },
    }),
  { name: 'Positions' }
);

export const PositionsPage = (): JSX.Element => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const openPositions = useSelector(getOpenPositions);
  const myPositions = useSelector(getMyPositions);
  const status = useSelector(getStatus);
  const openPositionPagination = useSelector(getOpenPositionPagination);
  const myPositionPagination = useSelector(getMyPositionPagination);
  const sortBy = useSelector(getSortBy);
  const { activeFeatures } = useSelector(getConfig);
  const { data, error } = openPositions;
  const { addToast } = useToasts();

  useEffect(() => {
    if (!data.length) {
      dispatch(fetchOpenPositions.request({ page: 1, sortBy }));
      activeFeatures.includes('CREATE_POSITIONS_WIZARD') &&
        dispatch(fetchMyPositions.request({ page: 1, sortBy: 'match' }));
    }
    return () => {
      dispatch(fetchOpenPositions.cancel());
      activeFeatures.includes('CREATE_POSITIONS_WIZARD') && dispatch(fetchMyPositions.cancel());
    };
  }, [activeFeatures]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLikeButtonClick = (position?: Position) => {
    const likeRequestValue = !position.like;
    dispatch(updatePositionLike.request({ ...position, like: likeRequestValue }));
    addToast(likeRequestValue ? `Position: ${position.role} is liked` : `Position: ${position.role} is disliked`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onPaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    loadData(value);
  };

  const tabItems: TabValue<PositionStatus>[] = [
    { label: `Open Positions (${openPositionPagination.totalElements})`, value: 'open-position' },
    ...(isUserProjectLead(user) && activeFeatures.includes('CREATE_POSITIONS_WIZARD')
      ? [{ label: `My Positions (${myPositionPagination.totalElements})`, value: 'my-position' as PositionStatus }]
      : []),
  ];

  const onTabChange = (status: PositionStatus) => {
    dispatch(setPositionsStatus(status));
    loadData();
  };

  const loadData = (page = 1) => {
    if (status === 'my-position') {
      return dispatch(fetchMyPositions.request({ page, sortBy: 'latest' }));
    }
    return dispatch(fetchOpenPositions.request({ page, sortBy }));
  };

  const positions = status === 'open-position' ? openPositions : myPositions;
  const pagination = status === 'open-position' ? openPositionPagination : myPositionPagination;
  return (
    <Main className={c.main}>
      <Helmet title={'Positions'} />

      <header className={c.head}>
        <div className={cx(c.wrap, c.headInner)}>
          <Tabs value={status} values={tabItems} onChange={onTabChange} />
          {status === 'open-position' && (
            <PositionSortBy
              sortBy={sortBy}
              onChange={(data) => dispatch(fetchOpenPositions.request({ page: pagination.currentPage, sortBy: data }))}
            />
          )}
        </div>
      </header>
      <div className={c.wrap}>
        {error && <div className={c.wrap} children={<ErrorBlock {...error} />} />}
        <Pagination
          currentPage={pagination.currentPage}
          totalPage={pagination.totalPages}
          totalElements={pagination.totalElements}
          onChange={onPaginationChange}
        />
        <List
          type={status}
          data={positions}
          baseUrl={sitemap.positions.detail.path}
          projectUrl={sitemap.projects.detail.path}
          onEdit={onLikeButtonClick}
        />
        <Pagination
          currentPage={pagination.currentPage}
          totalPage={pagination.totalPages}
          onChange={onPaginationChange}
        />
      </div>
    </Main>
  );
};
