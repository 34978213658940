import { Grid, IconButton, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { ButtonBack, ButtonFloating, FormattedName, Icon, PositionCounter, PositionStaffing, TitleValueItem } from '.';
import { getWrapStyles, Main } from '../layout';
import { getLinkTypes, Position, Project } from '../models';
import {
  canCreate,
  canUpdate,
  capitalizeFirstLetter,
  formatCurrency,
  formatDate,
  getWorkDays,
  getWorkDaysWithHours,
  sumArray,
} from '../utils';
import { getSectionStyles } from './EmployeeDetail';
import { useSelector } from 'react-redux';
import { getConfig } from '../store/app';

const useStyles = makeStyles(
  (theme) => ({
    ...getWrapStyles(theme),
    ...getSectionStyles(theme),
    counter: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
  { name: 'ProjectDetail' }
);

type Props = {
  project: Project;
  onEdit: () => void;
  onDelete: () => void;
  onAddOrEditPosition: (position?: Position) => void;
  onDeletePosition: (position: Position) => void;
  onAssignEmployee: (position: Position) => void;
  backButtonProps?: {
    to: string;
    onClick?: () => void;
  };
};

export const ProjectDetail = ({ project, onEdit, onDelete, backButtonProps, ...props }: Props): JSX.Element => {
  const c = useStyles({});
  const {
    name,
    projectNumber,
    client,
    location,
    projectLead,
    startDate,
    endDate,
    budget,
    manDays,
    description,
    positions,
    status,
    links,
    permissions,
  } = project;

  const { activeFeatures } = useSelector(getConfig);

  const amountWorkDays = getWorkDays(startDate, endDate);
  const positionsWorkDaysSum = Math.ceil(
    sumArray(positions.map((item) => getWorkDaysWithHours(item.startDate, item.endDate, item.workingHoursPerWeek)))
  );

  const titleValueProps = {
    className: c.titleValue,
    isDetail: true,
  };

  const isOppProject = status === 'opportunity';
  const isValidProject = status !== 'opportunity';

  return (
    <Main className={c.root}>
      <header className={cx(c.section, c.head)}>
        <div className={c.wrap}>
          <div className={c.headTitle}>
            <Grid container>
              <Grid className={cx(c.headCol, c.headColTitle)} item xs={4}>
                <h1 className={c.h1}>{name}</h1>
                <div>{client && client.name}</div>
              </Grid>

              <Grid className={c.headCol} item xs={2}>
                {formatDate(startDate)} - {formatDate(endDate)}
              </Grid>
              {isValidProject && (
                <Grid className={c.headCol} item xs={2}>
                  {projectNumber ? `#${projectNumber}` : '–'}
                </Grid>
              )}

              <Grid className={c.headCol} item xs={2}>
                <PositionCounter className={c.counter} positions={positions} />
              </Grid>

              <Grid className={c.headCol} item xs={2}>
                {status && capitalizeFirstLetter(status)}
                {canCreate(permissions) && (
                  <div className={c.headActions}>
                    <IconButton onClick={onEdit} children={<Icon name="edit" />} />
                    {isOppProject && <IconButton onClick={onDelete} children={<Icon name="delete" />} />}
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
          {backButtonProps && <ButtonBack {...backButtonProps} />}
        </div>
      </header>

      <section className={cx(c.section, c.sectionRelative)}>
        <div className={cx(c.sectionContent, c.wrap)}>
          <Grid container>
            <Grid item xs={2}>
              {!isOppProject && <TitleValueItem title={'Client'} value={client && client.name} {...titleValueProps} />}
              <TitleValueItem title={'Project Location'} value={location} {...titleValueProps} />
            </Grid>
            <Grid item xs={2}>
              <TitleValueItem title={'Project Lead'} value={<FormattedName {...projectLead} />} {...titleValueProps} />
              {isValidProject && (
                <TitleValueItem title={'Budget'} value={budget && formatCurrency(budget)} {...titleValueProps} />
              )}
            </Grid>
            <Grid item xs={2}>
              <TitleValueItem title={'Man Days'} value={manDays} {...titleValueProps} />
              <TitleValueItem
                title={'Working Days'}
                value={`${positionsWorkDaysSum} / ${amountWorkDays}`}
                {...titleValueProps}
              />
            </Grid>
            {isValidProject && (
              <Grid item xs={2}>
                <TitleValueItem
                  title={'Links'}
                  value={
                    links &&
                    links.length &&
                    links.map(({ value, type: t }) => (
                      <a key={t} href={value} target="_blank" rel="noopener noreferrer" children={getLinkTypes()[t]} />
                    ))
                  }
                  {...titleValueProps}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <TitleValueItem title={'Description'} value={description} {...titleValueProps} hasLineBreaks />
            </Grid>
          </Grid>
        </div>
      </section>

      <PositionStaffing positions={positions} startDate={startDate} endDate={endDate} {...props} />

      {!activeFeatures.includes('CREATE_POSITIONS_WIZARD') && canUpdate(permissions) && (
        <ButtonFloating onClick={() => props.onAddOrEditPosition()} icon="add" />
      )}
    </Main>
  );
};
