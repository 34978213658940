import { Button, createStyles, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import cx from 'clsx';
import React, { useRef, useState } from 'react';
import { Icon } from '.';
import { Model, Sorting } from '../models';
import { withId } from '../utils';

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    }),
  { name: 'CriteriaSorting' }
);

type Props = {
  sorting: Sorting<Model>;
  onChange: (sorting: Partial<Sorting<Model>>) => void;
  className?: string;
};

export const CriteriaSorting = ({ sorting, onChange, className }: Props): JSX.Element => {
  const c = useStyles({});

  const sortableFields: { id: keyof Model; label: string }[] = [
    { id: 'name', label: 'Name' },
    { id: 'updatedAt', label: 'Evaluated At' },
  ];
  const { orderBy, orderAsc } = sorting;

  const menuRef = useRef<HTMLButtonElement>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  const onSetSorting = (sorting: Partial<Sorting<Model>>) => () => {
    onChange(sorting);
    closeMenu();
  };

  return (
    <div className={cx(c.root, className)}>
      Sorted by:
      <Button
        ref={(ref) => (menuRef.current = ref)}
        variant="text"
        size="small"
        onClick={openMenu}
        children={sortableFields.find(withId(orderBy)).label}
      />
      <IconButton
        size="small"
        onClick={onSetSorting({ orderAsc: !orderAsc })}
        children={<Icon name={orderAsc ? 'chevronUp' : 'chevronDown'} />}
      />
      <Menu
        anchorEl={menuRef.current}
        getContentAnchorEl={undefined}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {sortableFields.map(({ id, label }) => (
          <MenuItem key={id} disabled={orderBy === id} onClick={onSetSorting({ orderBy: id })} children={label} />
        ))}
      </Menu>
    </div>
  );
};
