import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeeContainer } from '../components';
import { getConfig, getUser } from '../store/app';
import {
  createOrUpdateEmployee,
  fetchEmployee,
  fetchEmployeeProjects,
  getEmployeeProjects,
  getEmployeeUpdateStatus,
  resetEmployee,
  updateCertificates, updateProjectsHistory,
  updateSkills,
  uploadProfileImage,
} from '../store/employee';
import { fetchOrganizationTreeRoot } from '../store/mixed';

export const UserProfilePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { activeFeatures } = useSelector(getConfig);
  const { employee } = user;
  const skillRequestToggle = activeFeatures.includes('SKILL_REQUEST');

  useEffect(() => {
    dispatch(fetchEmployeeProjects.request(employee.id));
    dispatch(fetchOrganizationTreeRoot.request());
    return () => {
      dispatch(fetchEmployee.cancel());
      employee && dispatch(resetEmployee());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet title={'My Profile'} />
      <EmployeeContainer
        employee={{ data: employee }}
        user={user}
        updateStatus={useSelector(getEmployeeUpdateStatus)}
        projects={useSelector(getEmployeeProjects)}
        createOrUpdateEmployee={(data) => dispatch(createOrUpdateEmployee.request(data))}
        updateCertificates={(data) => dispatch(updateCertificates.request(data))}
        updateSkills={(data) => dispatch(updateSkills.request(data))}
        uploadProfileImage={(data) => dispatch(uploadProfileImage.request(data))}
        skillRequestToggle={skillRequestToggle}
        updateProjectsHistory={(data) => dispatch(updateProjectsHistory.request(data))}
        showWarning
      />
    </>
  );
};
