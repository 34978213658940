// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface EmailRequestBody
 */
export interface EmailRequestBody {
    /**
     * @type {string}
     * @memberof EmailRequestBody
     */
    message: string;
    /**
     * @type {string}
     * @memberof EmailRequestBody
     */
    to: string;
    /**
     * @type {string}
     * @memberof EmailRequestBody
     */
    emailTemplate: EmailRequestBodyEmailTemplateEnum;
    /**
     * @type {{ [key: string]: string; }}
     * @memberof EmailRequestBody
     */
    templateProps: { [key: string]: string; };
}

/**
 * @export
 * @enum {string}
 */
export enum EmailRequestBodyEmailTemplateEnum {
    DeclineCriteria = 'DECLINE_CRITERIA'
}

