import { createStyles, Grid, IconButton, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Icon } from '.';
import { getTruncateStyles } from '../layout';
import { Certificate, Skill } from '../models';
import { getFormSectionStyles } from '../utils';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getTruncateStyles(),
      root: {
        fontSize: 14,
        marginTop: -theme.spacing(2),
        '& $item': {
          marginTop: theme.spacing(2),
        },
      },
      rootReadOnly: {
        '& $item': {
          marginTop: 10,
        },
      },
      item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          '& $btnRemove': { display: 'block' },
        },
      },
      certificateWrap: {
        display: 'flex',
        alignItems: 'center',
        width: '80%',
        minWidth: 0,
      },
      certificateName: {
        display: 'inline-block',
        position: 'relative',
      },
      btnRemove: {
        display: 'none',
        position: 'absolute',
        top: -14, // 48px button, 20px item
        right: 15,
      },
      ...getFormSectionStyles(theme),
    }),
  { name: 'CertificateList' }
);

type Props = {
  certificates: Certificate[];
  sectionTitle?: string;
  onChange?: (params: Skill) => void;
  onRemove?: (id: string) => void;
  className?: string;
};

export const CertificateList = ({ certificates, sectionTitle, onChange, onRemove, className }: Props): JSX.Element => {
  const c = useStyles({});
  return (
    <>
      {sectionTitle && (
        <div className={c.section}>
          <h2 className={c.sectionTitle}>{sectionTitle}</h2>
        </div>
      )}
      <Grid container className={cx(c.root, { [c.rootReadOnly]: !onChange }, className)}>
        {certificates.map((item) => (
          <Grid item xs={6} key={item.name} className={c.item}>
            <div className={c.certificateWrap} title={item.name}>
              <Icon name={'certificate'} />
              <div className={cx(c.certificateName, c.truncate)} children={item.name} />
            </div>
            {onRemove && (
              <IconButton
                className={cx(c.btnRemove)}
                onClick={() => onRemove(item.id)}
                children={<Icon name="delete" />}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
