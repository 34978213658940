import { Epic } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';
import { autoCompleteSuggestions, enterpriseSearchEmployees } from './actions';

const enterpriseSearchEmployeesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { searchService }
) =>
  action$.pipe(
    filterAction(enterpriseSearchEmployees.request),
    switchMap(({ payload }) =>
      searchService
        .enterpriseSearchEmployees(payload)
        .pipe(
          map(enterpriseSearchEmployees.success),
          catchErrorAndHandleWithAction(enterpriseSearchEmployees.failure),
          takeUntilAction(action$, enterpriseSearchEmployees.cancel)
        )
    )
  );

const autoCompleteSuggestionsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { searchService }
) =>
  action$.pipe(
    filterAction(autoCompleteSuggestions.request),
    switchMap(({ payload }) =>
      searchService
        .autoCompleteSuggestions(payload)
        .pipe(
          map(autoCompleteSuggestions.success),
          catchErrorAndHandleWithAction(autoCompleteSuggestions.failure),
          takeUntilAction(action$, autoCompleteSuggestions.cancel)
        )
    )
  );

export const searchEpics = [enterpriseSearchEmployeesEpic, autoCompleteSuggestionsEpic];
