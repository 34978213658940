import { Epic } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  downloadSearchEmployees,
  fetchInternalEmployees,
  fetchProjectLeads,
  getActiveMonth,
  getEmployeesSorting,
  searchEmployees,
} from '.';
import { EmployeeSearchRequest } from '../../models';
import { Services } from '../../services';
import {
  catchErrorAndHandleWithAction,
  filterAction,
  getFirstDateInMonth,
  getLastDateInMonth,
  takeUntilAction,
} from '../../utils';
import { RootAction, RootState } from '../rootReducer';

const searchEmployeesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { employeeService }) =>
  action$.pipe(
    filterAction(searchEmployees.request),
    withLatestFrom(
      state$,
      (filter, state): EmployeeSearchRequest => {
        const { orderBy, orderAsc } = getEmployeesSorting(state);
        return {
          ...filter.payload,
          startDate: getFirstDateInMonth(getActiveMonth(state)),
          endDate: getLastDateInMonth(getActiveMonth(state)),
          orderBy,
          orderAsc,
        };
      }
    ),
    switchMap((param) =>
      employeeService
        .searchEmployees(param)
        .pipe(
          map(searchEmployees.success),
          catchErrorAndHandleWithAction(searchEmployees.failure),
          takeUntilAction(action$, searchEmployees.cancel)
        )
    )
  );

const downloadSearchEmployeesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(downloadSearchEmployees.request),
    withLatestFrom(
      state$,
      (filter, state): EmployeeSearchRequest => {
        return {
          ...filter.payload,
          startDate: getFirstDateInMonth(getActiveMonth(state)),
          endDate: getLastDateInMonth(getActiveMonth(state)),
        };
      }
    ),
    switchMap((param) =>
      employeeService
        .downloadSearchEmployees(param)
        .pipe(map(downloadSearchEmployees.success), catchErrorAndHandleWithAction(downloadSearchEmployees.failure))
    )
  );

const fetchProjectLeadsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(fetchProjectLeads.request),
    switchMap(() =>
      employeeService
        .getProjectLeads()
        .pipe(map(fetchProjectLeads.success), catchErrorAndHandleWithAction(fetchProjectLeads.failure))
    )
  );

const fetchInternalEmployeesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(fetchInternalEmployees.request),
    switchMap(() =>
      employeeService
        .getInternalEmployees()
        .pipe(map(fetchInternalEmployees.success), catchErrorAndHandleWithAction(fetchInternalEmployees.failure))
    )
  );

export const employeesEpics = [
  searchEmployeesEpic,
  downloadSearchEmployeesEpic,
  fetchProjectLeadsEpic,
  fetchInternalEmployeesEpic,
];
