import { createStyles, Divider, FormControl, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Field, useFormikContext } from 'formik';
import { Icon } from '../Icon';
import { PositionFormModel } from '../StepperModal';
import { EnterpriseSearchField } from '../EnterpriseSearchField';
import { useDispatch, useSelector } from 'react-redux';
import { autoCompleteSuggestions, clearSuggestion, getCriteriaOptions } from '../../store/search';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { getFieldProps } from '../../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardGroup: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyItems: 'center',
      gap: 24,
      '& button:nth-child(1)': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        backgroundColor: 'white',
      },
      '& button:nth-child(2)': {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: 'white',
      },
    },
    subTitle: {
      marginTop: 8,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 24,
      '& p': {
        width: 250,
        padding: 5,
      },
    },
    inputCriteria: {
      width: '100%',
      '& fieldset': {
        border: 0,
      },
    },
    outputCriteria: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      justifyContent: 'space-between',
      '&:first-child': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    card: {
      width: '250px',
      height: '250px',
      borderRadius: 4,
    },
    icon: {
      width: '64px',
      height: '64px',
    },
    iconActive: {
      '& svg path': {
        fill: '#0C8FFE',
      },
    },
    radioText: {
      position: 'absolute',
      bottom: 5,
      left: 35,
      fontSize: 12,
    },
    radioCircle: {
      position: 'absolute',
      bottom: 7,
      left: 10,
      width: 15,
      height: 15,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '50%',
    },
    description: {
      color: theme.palette.grey[500],
    },
    searchSection: {
      margin: '1rem 0',
    },
      helperMessage: {
          color: theme.palette.grey[500],
          margin: '1rem 0',
      },
  })
);

export const validationSchemaProject = Yup.object().shape(
  {
    contextSelection: Yup.string().required('Please select an option'),
    selectedProject: Yup.object().when('contextSelection', {
      is: 'existing',
      then: Yup.object().required('Please choose a project to continue'),
    }),
    projectName: Yup.string().when('contextSelection', {
      is: 'planned',
      then: Yup.string().required('Please enter project name'),
    }),
  },
  [['contextSelection', 'selectedProject']]
);

export const ProjectContextStep = () => {
  const { values, setFieldValue, errors } = useFormikContext<PositionFormModel>();
  const validateName = (key: keyof PositionFormModel) => key;
  const c = useStyles(values);
  const dispatch = useDispatch();
  const [showSelection, setShowSelection] = useState(false);
  const criteriaOptions = useSelector(getCriteriaOptions);
  const fieldProps = getFieldProps(false, undefined, true);

  useEffect(() => {
    if (values.selectedProject === undefined) {
      setShowSelection(false);
    } else {
      setShowSelection(true);
    }
  }, [values.selectedProject]);

  return (
    <FormControl component="fieldset">
      <ToggleButtonGroup
        className={c.cardGroup}
        value={values.contextSelection}
        exclusive
        aria-label="selection"
        onChange={(event, value) => {
          setFieldValue(validateName('contextSelection'), value);
          setFieldValue(validateName('selectedProject'), undefined);
        }}
      >
        <ToggleButton className={c.card} value={'existing'}>
          <Icon
            name="searchProject"
            className={`${c.icon} ${values.contextSelection === 'existing' ? c.iconActive : ''}`}
          />
          <span
            className={c.radioCircle}
            style={{
              backgroundColor: values.contextSelection === 'existing' ? '#0C8FFE' : '',
              borderColor: values.contextSelection === 'existing' ? '#0C8FFE' : '',
            }}
          />
          <Typography
            className={c.radioText}
            style={{ color: values.contextSelection === 'existing' ? '#0C8FFE' : '' }}
          >
            Existing project
          </Typography>
        </ToggleButton>
        <ToggleButton className={c.card} value={'planned'}>
          <Icon
            name="potentialProject"
            className={`${c.icon} ${values.contextSelection === 'planned' ? c.iconActive : ''}`}
          />
          <span
            className={c.radioCircle}
            style={{
              backgroundColor: values.contextSelection === 'planned' ? '#0C8FFE' : '',
              borderColor: values.contextSelection === 'planned' ? '#0C8FFE' : '',
            }}
          />
          <Typography className={c.radioText} style={{ color: values.contextSelection === 'planned' ? '#0C8FFE' : '' }}>
            Planned Initiative
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={c.subTitle}>
        <Typography className={c.description} variant="body2">
          The Project is part of an existing project in "Skill Matching"
        </Typography>
        <Typography className={c.description} variant="body2">
          Planned Initiative does not exist yet in "Skill Matching"
        </Typography>
      </div>
      {values.contextSelection === 'existing' ? (
        <section className={c.searchSection}>
          <Divider variant="middle" />
            <Typography className={c.helperMessage} variant="body2">
                You can only search for your own projects
            </Typography>
          {showSelection ? (
                <EnterpriseSearchField
              searchField={values.selectedProject}
              useLogic={false}
              onDelete={() => {
                setFieldValue(validateName('selectedProject'), undefined);
              }}
              className={c.outputCriteria}
            />
          ) : (
            <EnterpriseSearchField
              criteriaOptions={criteriaOptions}
              onInputChange={(keyword) =>
                keyword
                  ? dispatch(autoCompleteSuggestions.request({ keyword, types: ['Project'] }))
                  : dispatch(clearSuggestion())
              }
              onSubmit={(field) => {
                setFieldValue(validateName('selectedProject'), field);
              }}
              placeholder="Search for existing Project"
              useLogic={false}
              className={c.inputCriteria}
            />
          )}
          {errors.selectedProject && <FormHelperText error>{errors.selectedProject}</FormHelperText>}
        </section>
      ) : (
        <section className={c.searchSection}>
          <Divider variant="middle" />
          <Field {...fieldProps.text} name={validateName('projectName')} label={'Initiative Project'} />
        </section>
      )}
    </FormControl>
  );
};
