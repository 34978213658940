import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeeSearchRadius, Position, Project, ProjectPositionEmployee } from '../models';
import { fetchInternalEmployees, getInternalEmployees } from '../store/employees';
import { fetchSuggestions } from '../store/project';
import { AssignEmployeeForm } from './AssignEmployeeForm';
import { getConfig } from '../store/app';

type Props = {
  project: Project;
  position: Position;
  onSubmit: (params: ProjectPositionEmployee) => void;
  onCancel: () => void;
};

export const AssignEmployeeFormContainer = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const employees = useSelector(getInternalEmployees).data;
  const { activeFeatures } = useSelector(getConfig);
  const [searchRadius, setSearchRadius] = useState(EmployeeSearchRadius.MHP);
  const [page, setPage] = useState(1);
  const {
    project: { id: projectId },
    position: { id: positionId, skills, isLoadingSuggestions, totalPages },
  } = props;

  const handleScroll = () => {
    const scrollTop = document.getElementById('EmployeeSuggestionList')?.scrollTop;
    const offsetHeight = document.getElementById('EmployeeSuggestionList')?.offsetHeight;
    const scrollHeight = document.getElementById('EmployeeSuggestionList')?.scrollHeight;
    if (scrollTop + offsetHeight > scrollHeight * 0.7 && !isLoadingSuggestions && page <= totalPages) {
      loadData(page);
    }
  };

  const loadData = (page: number) => {
    dispatch(fetchSuggestions.request({ projectId, positionId, searchRadius, page }));
    setPage(page + 1);
  };

  useEffect(() => {
    // without skills on the position we can't get suggestions
    if (skills.length) {
      loadData(1);
    }

    if (!employees.length) {
      dispatch(fetchInternalEmployees.request());
    }
  }, [searchRadius]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AssignEmployeeForm
      {...props}
      employees={employees}
      searchRadius={searchRadius}
      setSearchRadius={setSearchRadius}
      handleScroll={handleScroll}
      withAvailability={activeFeatures.includes('SHOW_SUGGESTION_AVAILABILITY')}
    />
  );
};
