import { Epic, StateObservable } from 'redux-observable';
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {createOrUpdateEmployee, deleteEmployee, fetchEmployee, updateProjectsHistory, uploadProfileImage} from '.';
import { Employee } from '../../models';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction, takeUntilAction, withId, withName } from '../../utils';
import { getUser, updateUserEmployee } from '../app';
import { removeEmployee, updateEmployee } from '../employees';
import { updateFreelancer } from '../freelancers';
import { addModel } from '../mixed';
import { RootAction, RootState } from '../rootReducer';
import { fetchEmployeeProjects, updateCertificates, updateSkills } from './actions';
import { getEmployee } from './selectors';
import { push } from "connected-react-router";
import { sitemap } from '../../routes';

const fetchEmployeeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { employeeService }) =>
  action$.pipe(
    filterAction(fetchEmployee.request),
    switchMap(({ payload }) =>
      employeeService
        .getEmployee(payload)
        .pipe(
          map(fetchEmployee.success),
          catchErrorAndHandleWithAction(fetchEmployee.failure),
          takeUntilAction(action$, fetchEmployee.cancel)
        )
    )
  );

const fetchEmployeeProjectsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(fetchEmployeeProjects.request),
    switchMap(({ payload }) =>
      employeeService.getProjects(payload).pipe(
        map(fetchEmployeeProjects.success),
        catchErrorAndHandleWithAction(fetchEmployeeProjects.failure),
        takeUntilAction(action$, fetchEmployee.cancel) // use cancel action from fetchEmployee
      )
    )
  );

const withUpdateUser = (state$: StateObservable<RootState>) =>
  withLatestFrom(state$, (payload: Employee, state: RootState) => {
    const userEmployee = getUser(state).employee;
    const updateUser = payload.id === userEmployee.id;
    return {
      // when editing own profile there is nothing in the employee store, so the employee will just have an id
      employee: updateUser ? { ...userEmployee, ...payload } : payload,
      updateUser,
    };
  });

const createOrUpdateEmployeeEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(createOrUpdateEmployee.request),
    switchMap(({ payload }) =>
      employeeService.createOrUpdateEmployee(payload).pipe(
        withUpdateUser(state$),
        concatMap(({ employee, updateUser }) => [
          createOrUpdateEmployee.success(employee),
          ...(updateUser ? [updateUserEmployee(employee)] : []),
          employee.isExternal ? updateFreelancer(employee) : updateEmployee(employee), // freelancers or employees state
        ]),
        catchErrorAndHandleWithAction(createOrUpdateEmployee.failure)
      )
    )
  );

const deleteEmployeeEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(deleteEmployee.request),
    switchMap(({ payload }) =>
      employeeService.deleteEmployee(payload).pipe(
        concatMap(() => [
          deleteEmployee.success(),
          removeEmployee(payload),
          push(sitemap.teamLead.employees.root.path),
        ]),
        catchErrorAndHandleWithAction(deleteEmployee.failure)
      )
    )
  );

const updateCertificatesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(updateCertificates.request),
    switchMap(({ payload }) =>
      employeeService.updateCertificates(payload).pipe(
        withUpdateUser(state$),
        concatMap(({ employee, updateUser }) => [
          // no need to update employees state
          updateCertificates.success(employee),
          ...(updateUser ? [updateUserEmployee(employee)] : []),
        ]),
        catchErrorAndHandleWithAction(updateCertificates.failure)
      )
    )
  );

const updateSkillsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { employeeService }) =>
  action$.pipe(
    filterAction(updateSkills.request),
    switchMap(({ payload }) =>
      employeeService.updateSkills(payload).pipe(
        withUpdateUser(state$),
        concatMap(({ employee, updateUser }) => [
          // no need to update employees state
          updateSkills.success(employee),
          ...(updateUser ? [updateUserEmployee(employee)] : []),
          // if a new skill was created, let's add add it to he list of available skills
          ...(payload.skills.some(withId(null))
            ? [
                addModel({
                  type: 'skills',
                  data: employee.skills.find(withName(payload.skills.find(withId(null)).name)),
                }),
              ]
            : []),
        ]),
        catchErrorAndHandleWithAction(updateSkills.failure)
      )
    )
  );

const uploadProfileImageEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(uploadProfileImage.request),
    switchMap(({ payload }) =>
      employeeService.uploadProfileImage(payload).pipe(
        withLatestFrom(state$, (profileImage, state) => ({
          ...(getEmployee(state).data ?? { id: payload.id }), // crucial fallback for user employee
          profileImage,
        })),
        withUpdateUser(state$),
        concatMap(({ employee, updateUser }) => [
          uploadProfileImage.success(employee.profileImage),
          updateEmployee(employee), // employees state
          ...(updateUser ? [updateUserEmployee(employee)] : []),
        ]),
        catchErrorAndHandleWithAction(uploadProfileImage.failure)
      )
    )
  );


const updateProjectsHistoryEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    { employeeService }
) =>
    action$.pipe(
        filterAction(updateProjectsHistory.request),
        switchMap(({ payload }) => {
            return employeeService.updateProjectsHistory(payload).pipe(
                map(response => {
                    return updateProjectsHistory.success(response);
                }),
                catchErrorAndHandleWithAction(updateProjectsHistory.failure),
            );
        })
    );
export const employeeEpics = [
  fetchEmployeeEpic,
  fetchEmployeeProjectsEpic,
  deleteEmployeeEpic,
  createOrUpdateEmployeeEpic,
  updateCertificatesEpic,
  updateSkillsEpic,
  uploadProfileImageEpic,
  updateProjectsHistoryEpic,
];
