// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    AppRatingFeedbackContent,
    DashboardItemDTO,
    DeactivatePositionFeedbackContent,
    DeactivatePositionFeedbackDTO,
    DeletePositionFeedbackContent,
} from '../models';

export interface DisableDeactivatePositionFeedbackRequest {
    positionId: string;
}

export interface GetDeactivatePositionFeedbacksRequest {
    positionIds?: Array<string>;
}

export interface SubmitAppRatingRequest {
    appRatingFeedbackContent: AppRatingFeedbackContent;
}

export interface SubmitDeactivatePositionFeedbackRequest {
    deactivatePositionFeedbackContent: DeactivatePositionFeedbackContent;
}

export interface SubmitDeletePositionFeedbackRequest {
    deletePositionFeedbackContent: DeletePositionFeedbackContent;
}

/**
 * no description
 */
export class FeedbackControllerApi extends BaseAPI {

    /**
     */
    disableAppRatingFeedback(): Observable<object>
    disableAppRatingFeedback(opts?: OperationOpts): Observable<AjaxResponse<object>>
    disableAppRatingFeedback(opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        return this.request<object>({
            url: '/api/feedback/app-rating/disable',
            method: 'PUT',
        }, opts?.responseOpts);
    };

    /**
     */
    disableDeactivatePositionFeedback({ positionId }: DisableDeactivatePositionFeedbackRequest): Observable<object>
    disableDeactivatePositionFeedback({ positionId }: DisableDeactivatePositionFeedbackRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    disableDeactivatePositionFeedback({ positionId }: DisableDeactivatePositionFeedbackRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(positionId, 'positionId', 'disableDeactivatePositionFeedback');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'positionId': positionId,
        };

        return this.request<object>({
            url: '/api/feedback/deactivate-position/disable',
            method: 'PUT',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getDeactivatePositionFeedbacks({ positionIds }: GetDeactivatePositionFeedbacksRequest): Observable<Array<DeactivatePositionFeedbackDTO>>
    getDeactivatePositionFeedbacks({ positionIds }: GetDeactivatePositionFeedbacksRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<DeactivatePositionFeedbackDTO>>>
    getDeactivatePositionFeedbacks({ positionIds }: GetDeactivatePositionFeedbacksRequest, opts?: OperationOpts): Observable<Array<DeactivatePositionFeedbackDTO> | AjaxResponse<Array<DeactivatePositionFeedbackDTO>>> {

        const query: HttpQuery = {};

        if (positionIds != null) { query['positionIds'] = positionIds; }

        return this.request<Array<DeactivatePositionFeedbackDTO>>({
            url: '/api/feedback/deactivate-position',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getFeedbackOverview(): Observable<Array<DashboardItemDTO>>
    getFeedbackOverview(opts?: OperationOpts): Observable<AjaxResponse<Array<DashboardItemDTO>>>
    getFeedbackOverview(opts?: OperationOpts): Observable<Array<DashboardItemDTO> | AjaxResponse<Array<DashboardItemDTO>>> {
        return this.request<Array<DashboardItemDTO>>({
            url: '/api/feedback/overview',
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    submitAppRating({ appRatingFeedbackContent }: SubmitAppRatingRequest): Observable<object>
    submitAppRating({ appRatingFeedbackContent }: SubmitAppRatingRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    submitAppRating({ appRatingFeedbackContent }: SubmitAppRatingRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(appRatingFeedbackContent, 'appRatingFeedbackContent', 'submitAppRating');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<object>({
            url: '/api/feedback/app-rating',
            method: 'POST',
            headers,
            body: appRatingFeedbackContent,
        }, opts?.responseOpts);
    };

    /**
     */
    submitDeactivatePositionFeedback({ deactivatePositionFeedbackContent }: SubmitDeactivatePositionFeedbackRequest): Observable<object>
    submitDeactivatePositionFeedback({ deactivatePositionFeedbackContent }: SubmitDeactivatePositionFeedbackRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    submitDeactivatePositionFeedback({ deactivatePositionFeedbackContent }: SubmitDeactivatePositionFeedbackRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(deactivatePositionFeedbackContent, 'deactivatePositionFeedbackContent', 'submitDeactivatePositionFeedback');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<object>({
            url: '/api/feedback/deactivate-position',
            method: 'POST',
            headers,
            body: deactivatePositionFeedbackContent,
        }, opts?.responseOpts);
    };

    /**
     */
    submitDeletePositionFeedback({ deletePositionFeedbackContent }: SubmitDeletePositionFeedbackRequest): Observable<object>
    submitDeletePositionFeedback({ deletePositionFeedbackContent }: SubmitDeletePositionFeedbackRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    submitDeletePositionFeedback({ deletePositionFeedbackContent }: SubmitDeletePositionFeedbackRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(deletePositionFeedbackContent, 'deletePositionFeedbackContent', 'submitDeletePositionFeedback');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<object>({
            url: '/api/feedback/delete-position',
            method: 'POST',
            headers,
            body: deletePositionFeedbackContent,
        }, opts?.responseOpts);
    };

}
