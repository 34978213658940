import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { HttpError } from '../models';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: '25px 0',
        textAlign: 'center',
      },
      rootInModal: {
        height: 36, // button height
        textAlign: 'left',
        padding: 0,
        margin: `${-theme.spacing(1)}px auto 0 0`,
      },
      title: {},
    }),
  { name: 'ErrorBlock' }
);

type Props = HttpError & {
  inModal?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

export const ErrorBlock = ({ status, error, message, inModal, style, className }: Props): JSX.Element => {
  const c = useStyles({});
  return (
    <div className={cx(c.root, { [c.rootInModal]: inModal }, className)} style={style}>
      <h3 className={c.title}>
        {status !== undefined && `${status} - `}
        {error || 'Ein Fehler ist aufgetreten'}
      </h3>
      {message}
    </div>
  );
};
