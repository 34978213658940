import React, { useEffect } from 'react';
import { CriteriaList } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModels, getSkills } from '../store/mixed';

export const SkillsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const skills = useSelector(getSkills);

  useEffect(() => {
    if (!skills.data.length) {
      dispatch(fetchModels.request('skills'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <CriteriaList criteria={skills} type={'skills'} />;
};
