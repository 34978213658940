import { Sorting } from '../models';

export const toLowerIfString = (val) => (typeof val === 'string' ? val.toLowerCase() : val);

export const sortArrayBy = <T>(target: T[], { orderBy, orderAsc }: Sorting<T>) =>
  [...target].sort((a, b) =>
    orderAsc
      ? toLowerIfString(a[orderBy]) <= toLowerIfString(b[orderBy])
        ? -1
        : 1
      : toLowerIfString(a[orderBy]) > toLowerIfString(b[orderBy])
      ? -1
      : 1
  );

export const sortArrayDeepBy = <T, K extends keyof T>(target: T[], { orderBy, orderDeepBy, orderAsc }: Sorting<T, K>) =>
  [...target].sort((a, b) =>
    orderAsc
      ? toLowerIfString(a[orderBy][orderDeepBy]) <= toLowerIfString(b[orderBy][orderDeepBy])
        ? -1
        : 1
      : toLowerIfString(a[orderBy][orderDeepBy]) > toLowerIfString(b[orderBy][orderDeepBy])
      ? -1
      : 1
  );
