import { push } from 'connected-react-router';
import { Epic } from 'redux-observable';
import { concatMap, map, switchMap } from 'rxjs/operators';
import {
  assignEmployeeToPosition,
  createOrUpdatePosition,
  createOrUpdateProject,
  deletePosition,
  deleteProject,
  fetchPositionFeedback,
  fetchPotentialEmployees,
  fetchProject,
  fetchSuggestions,
} from '.';
import { sitemap } from '../../routes';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction, takeUntilAction } from '../../utils';
import { resetOpenPositions } from '../positions';
import { addOrUpdateProjectPosition, removeProject, removeProjectPosition, updateProject } from '../projects';
import { RootAction, RootState } from '../rootReducer';

const fetchProjectEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { projectService }) =>
  action$.pipe(
    filterAction(fetchProject.request),
    switchMap(({ payload }) =>
      projectService
        .getProject(payload)
        .pipe(
          map(fetchProject.success),
          catchErrorAndHandleWithAction(fetchProject.failure),
          takeUntilAction(action$, fetchProject.cancel)
        )
    )
  );

const fetchDeactivatePositionFeedbackEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { feedbackService }
) =>
  action$.pipe(
    filterAction(fetchPositionFeedback.request),
    switchMap(({ payload }) =>
      feedbackService
        .fetchDeactivatePositionFeedback(payload)
        .pipe(
          map(fetchPositionFeedback.success),
          catchErrorAndHandleWithAction(fetchPositionFeedback.failure),
          takeUntilAction(action$, fetchPositionFeedback.cancel)
        )
    )
  );

const createOrUpdateProjectEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { projectService }
) =>
  action$.pipe(
    filterAction(createOrUpdateProject.request),
    switchMap(({ payload }) =>
      projectService.createOrUpdateProject(payload).pipe(
        concatMap((data) => [createOrUpdateProject.success(data), updateProject(data)]),
        catchErrorAndHandleWithAction(createOrUpdateProject.failure)
      )
    )
  );

const createOrUpdatePositionEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { projectService }
) =>
  action$.pipe(
    filterAction(createOrUpdatePosition.request),
    switchMap(({ payload, payload: { id } }) =>
      projectService.createOrUpdatePosition(payload).pipe(
        concatMap((position) => [
          createOrUpdatePosition.success(position),
          addOrUpdateProjectPosition({ id, position }),
          resetOpenPositions(),
        ]),
        catchErrorAndHandleWithAction(createOrUpdatePosition.failure)
      )
    )
  );

const fetchPotentialEmployeesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { projectService }
) =>
  action$.pipe(
    filterAction(fetchPotentialEmployees.request),
    switchMap(({ payload }) =>
      projectService
        .fetchPotentialEmployees(payload)
        .pipe(
          map(fetchPotentialEmployees.success),
          catchErrorAndHandleWithAction(fetchPotentialEmployees.failure)
        )
    )
  );

const deletePositionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { projectService }) =>
  action$.pipe(
    filterAction(deletePosition.request),
    switchMap(({ payload, payload: { id: projectId, position: { id: positionId } } }) =>
      projectService.deletePosition(payload).pipe(
        concatMap(() => [deletePosition.success(positionId), removeProjectPosition({ projectId, positionId })]),
        catchErrorAndHandleWithAction(deletePosition.failure)
      )
    )
  );

const fetchSuggestionsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { projectService }) =>
  action$.pipe(
    filterAction(fetchSuggestions.request),
    switchMap(({ payload }) =>
      projectService
        .getEmployeeSuggestions(payload)
        .pipe(map(fetchSuggestions.success), catchErrorAndHandleWithAction(fetchSuggestions.failure))
    )
  );

const assignEmployeeToPositionEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { projectService }
) =>
  action$.pipe(
    filterAction(assignEmployeeToPosition.request),
    switchMap(({ payload }) =>
      projectService.assignEmployeeToPosition(payload).pipe(
        concatMap((data) => [assignEmployeeToPosition.success(data), updateProject(data), resetOpenPositions()]),
        catchErrorAndHandleWithAction(assignEmployeeToPosition.failure)
      )
    )
  );

const deleteProjectEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { projectService }) =>
  action$.pipe(
    filterAction(deleteProject.request),
    switchMap(({ payload }) =>
      projectService.deleteProject(payload).pipe(
        concatMap(() => [
          deleteProject.success(),
          removeProject(payload),
          resetOpenPositions(),
          push(sitemap.projects.root.path),
        ]),
        catchErrorAndHandleWithAction(deleteProject.failure)
      )
    )
  );

export const projectEpics = [
  fetchProjectEpic,
  fetchDeactivatePositionFeedbackEpic,
  createOrUpdateProjectEpic,
  createOrUpdatePositionEpic,
  deletePositionEpic,
  fetchPotentialEmployeesEpic,
  fetchSuggestionsEpic,
  assignEmployeeToPositionEpic,
  deleteProjectEpic,
];
