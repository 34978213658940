import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FAQ } from '../models';
import { FaqControllerApi } from '../proxy';
import { mapArray } from '../utils';
import { AuthInterceptor } from './auth.interceptor';
import { FAQDTO } from '../proxy/models';

export class FAQService {
  private api = new FaqControllerApi(AuthInterceptor.Instance);

  static toFAQ = (data: FAQDTO): FAQ => ({
    question: data.question,
    answer: data.answer,
  });

  getFAQ = (): Observable<FAQ[]> => this.api.getFAQs().pipe(map(mapArray(FAQService.toFAQ)));
}
