import { Button, createStyles, makeStyles, Menu, MenuItem } from '@material-ui/core';
import cx from 'clsx';
import React, { useRef, useState } from 'react';
import { SortedBy } from '../models';
import { withId } from '../utils';

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    }),
  { name: 'PositionSortBy' }
);

type Props = {
  sortBy: SortedBy;
  onChange: (sortBy: SortedBy) => void;
  className?: string;
};

export const PositionSortBy = ({ sortBy, onChange, className }: Props): JSX.Element => {
  const c = useStyles({});

  const sortableFields: { id: SortedBy; label: string }[] = [
    { id: 'match', label: 'Match' },
    { id: 'latest', label: 'Latest' },
  ];

  const menuRef = useRef<HTMLButtonElement>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  const onSetSortBy = (sortBy: SortedBy) => () => {
    onChange(sortBy);
    closeMenu();
  };

  return (
    <div className={cx(c.root, className)}>
      Sorted by:
      <Button
        ref={(ref) => (menuRef.current = ref)}
        variant="text"
        size="small"
        onClick={openMenu}
        children={sortableFields.find(withId(sortBy)).label}
      />
      <Menu
        anchorEl={menuRef.current}
        getContentAnchorEl={undefined}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {sortableFields.map(({ id, label }) => (
          <MenuItem key={id} disabled={sortBy === id} onClick={onSetSortBy(id)} children={label} />
        ))}
      </Menu>
    </div>
  );
};
