// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    CriteriaDTO,
    CriteriaRequestDTO,
} from '../models';

export interface CreateCriteriaRequest {
    criteriaRequestDTO: CriteriaRequestDTO;
}

export interface EditCriteriaRequest {
    id: string;
    criteriaRequestDTO: CriteriaRequestDTO;
}

export interface GetCriteriaRequest {
    type: GetCriteriaTypeEnum;
}

export interface GetCriteriaStatisticRequest {
    id: string;
}

export interface RemoveCriteriaRequest {
    id: string;
}

/**
 * no description
 */
export class CriteriaControllerApi extends BaseAPI {

    /**
     */
    createCriteria({ criteriaRequestDTO }: CreateCriteriaRequest): Observable<CriteriaDTO>
    createCriteria({ criteriaRequestDTO }: CreateCriteriaRequest, opts?: OperationOpts): Observable<AjaxResponse<CriteriaDTO>>
    createCriteria({ criteriaRequestDTO }: CreateCriteriaRequest, opts?: OperationOpts): Observable<CriteriaDTO | AjaxResponse<CriteriaDTO>> {
        throwIfNullOrUndefined(criteriaRequestDTO, 'criteriaRequestDTO', 'createCriteria');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<CriteriaDTO>({
            url: '/api/criteria',
            method: 'POST',
            headers,
            body: criteriaRequestDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    editCriteria({ id, criteriaRequestDTO }: EditCriteriaRequest): Observable<CriteriaDTO>
    editCriteria({ id, criteriaRequestDTO }: EditCriteriaRequest, opts?: OperationOpts): Observable<AjaxResponse<CriteriaDTO>>
    editCriteria({ id, criteriaRequestDTO }: EditCriteriaRequest, opts?: OperationOpts): Observable<CriteriaDTO | AjaxResponse<CriteriaDTO>> {
        throwIfNullOrUndefined(id, 'id', 'editCriteria');
        throwIfNullOrUndefined(criteriaRequestDTO, 'criteriaRequestDTO', 'editCriteria');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<CriteriaDTO>({
            url: '/api/criteria/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: criteriaRequestDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    getCriteria({ type }: GetCriteriaRequest): Observable<Array<CriteriaDTO>>
    getCriteria({ type }: GetCriteriaRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<CriteriaDTO>>>
    getCriteria({ type }: GetCriteriaRequest, opts?: OperationOpts): Observable<Array<CriteriaDTO> | AjaxResponse<Array<CriteriaDTO>>> {
        throwIfNullOrUndefined(type, 'type', 'getCriteria');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'type': type,
        };

        return this.request<Array<CriteriaDTO>>({
            url: '/api/criteria',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getCriteriaStatistic({ id }: GetCriteriaStatisticRequest): Observable<CriteriaDTO>
    getCriteriaStatistic({ id }: GetCriteriaStatisticRequest, opts?: OperationOpts): Observable<AjaxResponse<CriteriaDTO>>
    getCriteriaStatistic({ id }: GetCriteriaStatisticRequest, opts?: OperationOpts): Observable<CriteriaDTO | AjaxResponse<CriteriaDTO>> {
        throwIfNullOrUndefined(id, 'id', 'getCriteriaStatistic');

        return this.request<CriteriaDTO>({
            url: '/api/criteria/{id}/statistic'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    removeCriteria({ id }: RemoveCriteriaRequest): Observable<object>
    removeCriteria({ id }: RemoveCriteriaRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    removeCriteria({ id }: RemoveCriteriaRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(id, 'id', 'removeCriteria');

        return this.request<object>({
            url: '/api/criteria/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetCriteriaTypeEnum {
    Skill = 'SKILL',
    Language = 'LANGUAGE',
    OfficeLocation = 'OFFICE_LOCATION',
    Certificate = 'CERTIFICATE'
}
