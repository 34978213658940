import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
  Employee,
  FileUploadRequest,
  HttpError,
  ProjectHistory,
  UpdateCertificatesRequest, UpdateProjectsHistoryRequest,
  UpdateSkillsRequest,
} from '../../models';

export const fetchEmployee = createAsyncAction(
  '@employee/FETCH_EMPLOYEE',
  '@employee/FETCH_EMPLOYEE_SUCCESS',
  '@employee/FETCH_EMPLOYEE_FAILURE',
  '@employee/FETCH_EMPLOYEE_CANCEL'
)<string, Employee, HttpError, void>();

export const fetchEmployeeProjects = createAsyncAction(
  '@employee/FETCH_EMPLOYEE_PROJECTS',
  '@employee/FETCH_EMPLOYEE_PROJECTS_SUCCESS',
  '@employee/FETCH_EMPLOYEE_PROJECTS_FAILURE'
)<string, ProjectHistory[], HttpError>();

export const createOrUpdateEmployee = createAsyncAction(
  '@employee/CREATE_OR_UPDATE_EMPLOYEE',
  '@employee/CREATE_OR_UPDATE_EMPLOYEE_SUCCESS',
  '@employee/CREATE_OR_UPDATE_EMPLOYEE_FAILURE'
)<Employee, Employee, HttpError>();

export const deleteEmployee = createAsyncAction(
  '@employee/DELETE_EMPLOYEE',
  '@employee/DELETE_EMPLOYEE_SUCCESS',
  '@employee/DELETE_EMPLOYEE_FAILURE'
)<string, void, HttpError>();

export const updateCertificates = createAsyncAction(
  '@employee/UPDATE_CERTIFICATES',
  '@employee/UPDATE_CERTIFICATES_SUCCESS',
  '@employee/UPDATE_CERTIFICATES_FAILURE'
)<UpdateCertificatesRequest, Employee, HttpError>();

export const updateSkills = createAsyncAction(
  '@employee/UPDATE_SKILLS',
  '@employee/UPDATE_SKILLS_SUCCESS',
  '@employee/UPDATE_SKILLS_FAILURE'
)<UpdateSkillsRequest, Employee, HttpError>();

export const uploadProfileImage = createAsyncAction(
  '@employee/UPLOAD_PROFILE_IMAGE',
  '@employee/UPLOAD_PROFILE_IMAGE_SUCCESS',
  '@employee/UPLOAD_PROFILE_IMAGE_FAILURE'
)<FileUploadRequest, string, HttpError>();

export const updateProjectsHistory = createAsyncAction(
    '@employee/UPDATE_PROJECTS_HISTORY',
    '@employee/FETCH_EMPLOYEE_PROJECTS_SUCCESS',
    '@employee/UPDATE_PROJECTS_HISTORY_FAILURE'
)<UpdateProjectsHistoryRequest, Employee, HttpError>();

export const resetSuccess = createAction('@employee/RESET_SUCCESS')();
export const resetEmployee = createAction('@employee/RESET_EMPLOYEE')();

export type EmployeeAction =
  | ActionType<typeof fetchEmployee>
  | ActionType<typeof fetchEmployeeProjects>
  | ActionType<typeof deleteEmployee>
  | ActionType<typeof createOrUpdateEmployee>
  | ActionType<typeof updateCertificates>
  | ActionType<typeof updateSkills>
  | ActionType<typeof uploadProfileImage>
  | ActionType<typeof updateProjectsHistory>
  | ActionType<typeof resetSuccess>
  | ActionType<typeof resetEmployee>;
