import { Field, Formik } from 'formik';
import { getFieldProps } from '../utils';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import { fromOptionType, OptionType, toOptionType } from './AutoCompleteField';
import { Model } from '../models';

type SearchFormModel = { searchCriteria?: OptionType };

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: theme.palette.common.white,
      boxShadow: theme.customShadows.fixed,
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      width: 400,
      margin: 0,
    },
  }),
  { name: 'CriteriaSearch' }
);

type Props = {
  onSubmit: (searchCriteria: Model) => void;
  criteriaList: Model[];
  title: string;
  className?: string;
};

export const CriteriaSearch = ({ criteriaList, title, onSubmit, className }: Props): JSX.Element => {
  const c = useStyles({});

  const fieldProps = getFieldProps(false);
  const initialValues: SearchFormModel = {
    searchCriteria: undefined,
  };

  const validateName = (key: keyof SearchFormModel) => key;
  return (
    <div className={cx(c.root, className)}>
      <Formik
        onSubmit={({ searchCriteria }, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit(searchCriteria ? fromOptionType(searchCriteria) : undefined);
        }}
        initialValues={initialValues}
      >
        {({ submitForm, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={cx(fieldProps.select.className, c.input)}>
              <Field
                {...fieldProps.select}
                name={validateName('searchCriteria')}
                placeholder={`Search for ${title}`}
                options={criteriaList.map(toOptionType)}
                onChange={(tokens) => !!(tokens || values.searchCriteria) && setTimeout(submitForm, 0)}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
