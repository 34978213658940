import { Epic } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { fetchProjects, getFilter, getPagination } from '.';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';

const fetchProjectsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { projectService }) =>
  action$.pipe(
    filterAction(fetchProjects.request),
    withLatestFrom(state$, (_, state) => ({ filter: getFilter(state), page: getPagination(state).currentPage })),
    switchMap(({ filter, page }) => {
      return projectService
        .getProjects(filter.status, page, filter.searchKeyword)
        .pipe(
          map(fetchProjects.success),
          catchErrorAndHandleWithAction(fetchProjects.failure),
          takeUntilAction(action$, fetchProjects.cancel)
        );
    })
  );

export const projectsEpics = [fetchProjectsEpic];
