import     { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

const positionState = (state: RootState) => state.position;

export const getPosition = createSelector([positionState], ({ position , isDeleted}) => ({
    ...position,
    data: position.data,
    isDeleted: isDeleted
}));
