import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
  HttpError,
  Project,
  ProjectFilter,
  ProjectPosition,
  ProjectPositionEmployee,
  ProjectsMeta,
  ProjectStatus,
} from '../../models';

export const fetchProjects = createAsyncAction(
  '@projects/FETCH_PROJECTS',
  '@projects/FETCH_PROJECTS_SUCCESS',
  '@projects/FETCH_PROJECTS_FAILURE',
  '@projects/FETCH_PROJECTS_CANCEL'
)<ProjectFilter, ProjectsMeta, HttpError, void>();

export const updateProject = createAction('@projects/UPDATE_PROJECT')<Project>();
export const removeProject = createAction('@projects/REMOVE_PROJECT')<string>();

export const addOrUpdateProjectPosition = createAction('@projects/ADD_OR_UPDATE_PROJECT_POSITION')<ProjectPosition>();
export const removeProjectPosition = createAction('@projects/REMOVE_PROJECT_POSITION')<ProjectPositionEmployee>();

export const setProjectsStatus = createAction('@projects/SET_PROJECTS_STATUS')<ProjectStatus>();

export const setProjectsKeyword = createAction('@projects/SET_PROJECTS_KEYWORD')<string>();

export const setProjectsPage = createAction('@projects/SET_PROJECTS_PAGE')<number>();

export type ProjectsAction =
  | ActionType<typeof fetchProjects>
  | ActionType<typeof updateProject>
  | ActionType<typeof removeProject>
  | ActionType<typeof addOrUpdateProjectPosition>
  | ActionType<typeof removeProjectPosition>
  | ActionType<typeof setProjectsStatus>
  | ActionType<typeof setProjectsKeyword>
  | ActionType<typeof setProjectsPage>;
