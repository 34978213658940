import { Epic } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { getPagination, searchFreelancers } from '.';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';
import { EmployeeFilter } from '../../models';

const searchFreelancersEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(searchFreelancers.request),
    withLatestFrom(
      state$,
      (filter, state): EmployeeFilter => ({
        ...filter.payload,
        page: getPagination(state).currentPage,
      })
    ),
    switchMap((payload) =>
      employeeService
        .searchFreelancers(payload)
        .pipe(
          map(searchFreelancers.success),
          catchErrorAndHandleWithAction(searchFreelancers.failure),
          takeUntilAction(action$, searchFreelancers.cancel)
        )
    )
  );

export const freelancersEpics = [searchFreelancersEpic];
