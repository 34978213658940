import { createStyles, Divider, FormControl, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { EnterpriseSearchField } from '..';
import { Position } from '../../models';
import { fetchPosition } from '../../store/positions';
import { autoCompleteSuggestions, clearSuggestion, getCriteriaOptions } from '../../store/search';
import { Icon } from '../Icon';
import { initialValuesPositionForm, PositionFormModel } from '../StepperModal';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardGroup: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyItems: 'center',
      gap: 24,
      '& button:nth-child(1)': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        backgroundColor: 'white',
      },
      '& button:nth-child(2)': {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: 'white',
      },
    },
    subTitle: {
      marginTop: 8,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 24,
      '& p': {
        width: 250,
        padding: 5,
      },
    },
    card: {
      width: '250px',
      height: '250px',
      borderRadius: 4,
    },
    icon: {
      width: '64px',
      height: '64px',
    },
    iconActive: {
      '& svg path': {
        fill: '#0C8FFE',
      },
    },
    radioText: {
      position: 'absolute',
      bottom: 5,
      left: 35,
      fontSize: 12,
    },
    radioCircle: {
      position: 'absolute',
      bottom: 7,
      left: 10,
      width: 15,
      height: 15,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '50%',
    },
    description: {
      color: theme.palette.grey[500],
    },
    searchSection: {
      margin: '1rem 0',
    },
    inputCriteria: {
      width: '100%',
      '& fieldset': {
        border: 0,
      },
    },
    outputCriteria: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      justifyContent: 'space-between',
      '&:first-child': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
  })
);

const HIGHLIGHTED_COLOR = '#0C8FFE';

export type StartForm = {
  startSelection: 'new' | 'existing';
  selectedPosition: Position;
};

export const validationSchemaStart = Yup.object().shape(
  {
    startSelection: Yup.string().required('Please select an option'),
    selectedPosition: Yup.object().when('startSelection', {
      is: 'existing',
      then: Yup.object().required('Please choose a positon to continue'),
    }),
  },
  [['startSelection', 'selectedPosition']]
);

export const StartStep = () => {
  const [showSelection, setShowSelection] = useState(false);
  const { values, setFieldValue, errors, resetForm } = useFormikContext<PositionFormModel>();
  const validateName = (key: keyof PositionFormModel) => key;
  const c = useStyles(values);
  const dispatch = useDispatch();
  const criteriaOptions = useSelector(getCriteriaOptions);

  useEffect(() => {
    if (values.selectedPosition === undefined) {
      setShowSelection(false);
    } else {
      setShowSelection(true);
    }
  }, [values.selectedPosition]);

  return (
    <FormControl component="fieldset">
      <ToggleButtonGroup
        className={c.cardGroup}
        value={values.startSelection}
        exclusive
        aria-label="selection"
        onChange={(event, value) => {
          if (value !== null) {
            resetForm({ values: initialValuesPositionForm });
            setFieldValue(validateName('startSelection'), value);
            setFieldValue(validateName('selectedPosition'), undefined);
          }
        }}
      >
        <ToggleButton className={c.card} value={'new'}>
          <Icon name="createPosition" className={`${c.icon} ${values.startSelection === 'new' ? c.iconActive : ''}`} />
          <span
            className={c.radioCircle}
            style={{
              backgroundColor: values.startSelection === 'new' ? HIGHLIGHTED_COLOR : '',
              borderColor: values.startSelection === 'new' ? HIGHLIGHTED_COLOR : '',
            }}
          />
          <Typography
            className={c.radioText}
            style={{ color: values.startSelection === 'new' ? HIGHLIGHTED_COLOR : '' }}
          >
            Create new positon
          </Typography>
        </ToggleButton>
        <ToggleButton className={c.card} value={'existing'}>
          <Icon
            name="copyPosition"
            className={`${c.icon} ${values.startSelection === 'existing' ? c.iconActive : ''}`}
          />
          <span
            className={c.radioCircle}
            style={{
              backgroundColor: values.startSelection === 'existing' ? HIGHLIGHTED_COLOR : '',
              borderColor: values.startSelection === 'existing' ? HIGHLIGHTED_COLOR : '',
            }}
          />
          <Typography
            className={c.radioText}
            style={{ color: values.startSelection === 'existing' ? HIGHLIGHTED_COLOR : '' }}
          >
            Create from existing position
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={c.subTitle}>
        <Typography className={c.description} variant="body2">
          Start from scratch
        </Typography>
        <Typography className={c.description} variant="body2">
          Use existing positions as templates and make adjustments if necessary. You can only use your own positions
        </Typography>
      </div>
      {values.startSelection === 'existing' && (
        <section className={c.searchSection}>
          <Divider variant="middle" />
          {showSelection ? (
            <EnterpriseSearchField
              searchField={values.selectedPosition}
              useLogic={false}
              onDelete={() => {
                setFieldValue(validateName('selectedPosition'), undefined);
              }}
              className={c.outputCriteria}
            />
          ) : (
            <EnterpriseSearchField
              criteriaOptions={criteriaOptions}
              onInputChange={(keyword) =>
                keyword
                  ? dispatch(autoCompleteSuggestions.request({ keyword, types: ['Position'] }))
                  : dispatch(clearSuggestion())
              }
              onSubmit={(field) => {
                setFieldValue(validateName('selectedPosition'), field);
                dispatch(fetchPosition.request(field.criteria.value));
              }}
              placeholder="Search for existing Position"
              useLogic={false}
              className={c.inputCriteria}
            />
          )}
          {errors.selectedPosition && <FormHelperText error>{errors.selectedPosition}</FormHelperText>}
        </section>
      )}
    </FormControl>
  );
};
