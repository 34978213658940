import { makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { array, object, string } from 'yup';
import { Certificate, Employee, UpdateStatus } from '../models';
import { getFormStyles } from '../utils';
import { OptionType } from './AutoCompleteField';
import { FormActions } from './ProjectForm';
import { CertificateManager } from './';

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    requestButton: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(2),
      whiteSpace: 'nowrap',
      minWidth: 'auto',
    },
    ...getFormStyles(theme),
  }),
  { name: 'EmployeeCertificatesForm' }
);

type Props = {
  employee: Employee;
  updateStatus: UpdateStatus;
  onSubmit: (params: Certificate[]) => void;
  onCancel?: () => void;
  certificates: Certificate[];
  showRequestButton?: boolean;
};

type EmployeeCertificatesFormModel = {
  certificates: Certificate[];
  certificateAutoComplete: OptionType;
};
const validateName = (key: keyof EmployeeCertificatesFormModel) => key;

export const EmployeeCertificatesForm = ({
  employee,
  onSubmit,
  certificates,
  showRequestButton,
  ...props
}: Props): JSX.Element => {
  const c = useStyles({});

  const initialValues: EmployeeCertificatesFormModel = {
    certificates: employee.certificates,
    certificateAutoComplete: undefined,
  };

  const validationSchema = object({
    certificates: array().of(
      object({
        name: string(),
        requestMessage: string().when('id', {
          is: (val) => val,
          then: string(),
          otherwise: string(), //TODO fix this
        }),
      })
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit(values.certificates || []);
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <CertificateManager
            updateStatus={props.updateStatus}
            name={validateName('certificateAutoComplete')}
            targetName={validateName('certificates')}
            certificates={certificates}
            form={formikProps}
            showRequestButton={showRequestButton}
          />

          <FormActions {...props} ctaLabel={'Update Certificates'} c={c} />
        </form>
      )}
    </Formik>
  );
};
