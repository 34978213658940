// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts } from '../runtime';
import type {
    DepartmentDTO,
} from '../models';

/**
 * no description
 */
export class DepartmentControllerApi extends BaseAPI {

    /**
     */
    getOrganizationTreeRoot(): Observable<DepartmentDTO>
    getOrganizationTreeRoot(opts?: OperationOpts): Observable<AjaxResponse<DepartmentDTO>>
    getOrganizationTreeRoot(opts?: OperationOpts): Observable<DepartmentDTO | AjaxResponse<DepartmentDTO>> {
        return this.request<DepartmentDTO>({
            url: '/api/departments/root',
            method: 'GET',
        }, opts?.responseOpts);
    };

}
