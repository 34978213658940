import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  CriteriaStatus,
  EmailModel,
  EmailTemplateModel,
  ModelType,
  ModelUnion,
  OrganizationTree,
  Skill,
} from '../models';
import {
  BusinessTitleControllerApi,
  CriteriaControllerApi,
  CriteriaDTO,
  CriteriaRequestDTOStatusEnum,
  DepartmentControllerApi,
  DepartmentDTO,
  EmailControllerApi,
  SkillRequest,
  VwTaskForceControllerApi,
} from '../proxy';
import { mapArray, throwIfNotInMap } from '../utils';
import { AuthInterceptor } from './auth.interceptor';
import {
  CriteriaDTOStatusEnum,
  CriteriaRequestDTOTypeEnum,
  EmailRequestBodyEmailTemplateEnum,
  GetCriteriaTypeEnum,
} from '../proxy';
import { CriteriaFormModel } from '../components/CriteriaList';
import { parseJSON } from 'date-fns';

export class MixedService {
  private criteriaApi = new CriteriaControllerApi(AuthInterceptor.Instance);
  private titleApi = new BusinessTitleControllerApi(AuthInterceptor.Instance);
  private departmentApi = new DepartmentControllerApi(AuthInterceptor.Instance);
  private vwTaskForceApi = new VwTaskForceControllerApi(AuthInterceptor.Instance);
  private emailApi = new EmailControllerApi(AuthInterceptor.Instance);

  static toModel = <T = ModelUnion>(data: Partial<CriteriaDTO>): T =>
    ({
      id: data.id,
      name: data.name,
      requestMessage: data.requestMessage ?? undefined,
      ...(data.createdBy && { createdBy: data.createdBy }),
      ...(data.createdAt && { createdAt: parseJSON(data.createdAt) }),
      ...(data.status && { status: data.status ? MixedService.toCriteriaStatus(data.status) : 'confirm' }),
      ...(data.level && { level: data.level > 0 ? data.level : undefined }),
      ...(data.updatedAt && { updatedAt: parseJSON(data.updatedAt) }),
      ...(data.updatedBy && { updatedBy: data.updatedBy }),
      ...(data.statistics && { stats: data.statistics?.map(({ count }) => count) ?? [] }),
    } as unknown as T);

  static toOrganization = (data: DepartmentDTO): OrganizationTree => ({
    id: data.id,
    name: data.name,
    ...(data.parent && { parent: MixedService.toOrganization(data.parent) }),
    ...(data.children && { children: data.children.map(MixedService.toOrganization) }),
  });

  static toCriteriaStatus = (status: CriteriaDTOStatusEnum): CriteriaStatus => {
    const enumToTypeMap: { [key in CriteriaDTOStatusEnum]: CriteriaStatus } = {
      [CriteriaDTOStatusEnum.Confirm]: 'confirm',
      [CriteriaDTOStatusEnum.Decline]: 'decline',
      [CriteriaDTOStatusEnum.Pending]: 'pending',
    };
    throwIfNotInMap(enumToTypeMap, status, 'CriteriaDTOStatusEnum');
    return enumToTypeMap[status];
  };

  static toGetCriteriaUsingGETTypeEnum = (type: ModelType): GetCriteriaTypeEnum => {
    switch (type) {
      case 'certificates':
        return GetCriteriaTypeEnum.Certificate;
      case 'languages':
        return GetCriteriaTypeEnum.Language;
      case 'locations':
        return GetCriteriaTypeEnum.OfficeLocation;
      case 'skills':
        return GetCriteriaTypeEnum.Skill;
      default:
        throw new Error(`unsupported value for ModelType: ${type}`);
    }
  };

  static toCriteriaRequestDTOTypeEnum = (type: ModelType): CriteriaRequestDTOTypeEnum => {
    switch (type) {
      case 'certificates':
        return CriteriaRequestDTOTypeEnum.Certificate;
      case 'languages':
        return CriteriaRequestDTOTypeEnum.Language;
      case 'locations':
        return CriteriaRequestDTOTypeEnum.OfficeLocation;
      case 'skills':
        return CriteriaRequestDTOTypeEnum.Skill;
      default:
        throw new Error(`unsupported value for ModelType: ${type}`);
    }
  };

  static toCriteriaRequestDTOStatusEnum = (status: CriteriaStatus): CriteriaRequestDTOStatusEnum => {
    switch (status) {
      case 'confirm':
        return CriteriaRequestDTOStatusEnum.Confirm;
      case 'pending':
        return CriteriaRequestDTOStatusEnum.Pending;
      case 'decline':
        return CriteriaRequestDTOStatusEnum.Decline;
      default:
        throw new Error(`unsupported value for ModelType: ${status}`);
    }
  };

  static toEmailRequestBodyEmailTemplateEnum = (template: EmailTemplateModel): EmailRequestBodyEmailTemplateEnum => {
    switch (template) {
      case 'declineCriteria':
        return EmailRequestBodyEmailTemplateEnum.DeclineCriteria;
      default:
        throw new Error(`unsupported value for EmailModelType: ${template}`);
    }
  };

  static toSkillRequest = (data: Skill): SkillRequest => ({
    id: data.id,
    level: data.level,
    name: data.name,
    requestMessage: data.requestMessage,
  });

  getModels = <T = ModelUnion>(param: ModelType): Observable<T[]> =>
    of(param).pipe(
      switchMap((type) =>
        type === 'jobTitles'
          ? (this.titleApi.getTitles() as Observable<CriteriaDTO[]>)
          : type === 'vwSkills'
          ? (this.vwTaskForceApi.getVWSkills() as Observable<CriteriaDTO[]>)
          : this.criteriaApi.getCriteria({ type: MixedService.toGetCriteriaUsingGETTypeEnum(param) })
      ),
      map(mapArray<CriteriaDTO, T>(MixedService.toModel))
    );

  getModel = <T = ModelUnion>(id: string): Observable<T> =>
    this.criteriaApi.getCriteriaStatistic({ id }).pipe(map<CriteriaDTO, T>(MixedService.toModel));

  editModel = <T = ModelUnion>({ id, name, status, type, requestMessage = undefined }: CriteriaFormModel): Observable<T> =>
    this.criteriaApi
      .editCriteria({
        id,
        criteriaRequestDTO: {
          name,
          type: MixedService.toCriteriaRequestDTOTypeEnum(type),
          status: MixedService.toCriteriaRequestDTOStatusEnum(status),
          requestMessage
        },
      })
      .pipe(map<CriteriaDTO, T>(MixedService.toModel));

  getOrganizationTreeRoot = (): Observable<OrganizationTree> =>
    this.departmentApi.getOrganizationTreeRoot().pipe(map((item) => MixedService.toOrganization(item)));

  removeModel = (id: string): Observable<void> => this.criteriaApi.removeCriteria({ id }).pipe(map(() => undefined));

  createModel = <T = ModelUnion>({ name, status, type, requestMessage = undefined }: CriteriaFormModel): Observable<T> =>
    this.criteriaApi
      .createCriteria({
        criteriaRequestDTO: {
          name,
          status: MixedService.toCriteriaRequestDTOStatusEnum(status),
          type: MixedService.toCriteriaRequestDTOTypeEnum(type),
          requestMessage
        },
      })
      .pipe(map<CriteriaDTO, T>(MixedService.toModel));

  sendEmail = ({ message, to, template, templateProps }: EmailModel<'declineCriteria'>): Observable<any> =>
    this.emailApi.sendEmail({
      emailRequestBody: {
        message,
        to,
        emailTemplate: MixedService.toEmailRequestBodyEmailTemplateEnum(template),
        templateProps,
      },
    });
}
