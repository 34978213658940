import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { Avatar, FormattedName } from '.';
import { getTruncateStyles } from '../layout';
import { Employee } from '../models';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1.4,
      width: '100%', // to not overflow in ListItem
    },
    avatar: {
      marginRight: 10,
    },
    ...getTruncateStyles(),
    nameMargin: {
      marginRight: 5,
    },
    jobTitle: {
      marginTop: 5,
    },
  }),
  { name: 'AvatarListItem' }
);

type Props = Pick<Employee, 'firstName' | 'lastName' | 'profileImage' | 'jobTitle'> & {
  size?: 'small' | 'medium' | 'large';
  className?: string;
  isInTextField?: boolean;
  isShort?: boolean;
  withJobTitle?: boolean;
  withAvatar?: boolean;
  isEmployeePage?: boolean
};

export const AvatarListItem = ({
  className,
  isInTextField,
  isShort,
  children,
  withJobTitle,
  withAvatar,
  ...employee
}: PropsWithChildren<Props>): JSX.Element => {
  const c = useStyles({});
  return (
    <div className={cx(c.root, className)}>
      {withAvatar && <Avatar className={c.avatar} {...employee} />}
      <div
        className={cx(c.truncate, { [c.nameMargin]: !!children })}
        children={
          <>
            <FormattedName isInTextField={isInTextField} isShort={isShort} {...employee} isEmployeePage />
            {withJobTitle && employee.jobTitle && <div className={c.jobTitle}>{employee.jobTitle.name}</div>}
          </>
        }
      />

      {/* children are used for scores in suggestions */}
      {children}
    </div>
  );
};
