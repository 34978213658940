import { createStyles, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import cx from 'clsx';
import React from 'react';
import { Icon } from '.';
import { Employee } from '../models';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'relative',
        display: 'flex',
        height: 34,
        width: 34,
        minWidth: 34,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        color: theme.palette.common.black,
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        boxSizing: 'border-box',
        fontSize: 16,
        fontWeight: theme.typography.fontWeightRegular,
        userSelect: 'none',
      },
      rootMedium: {
        height: 40,
        width: 40,
        minWidth: 40,
      },
      rootLarge: {
        height: 60,
        width: 60,
        minWidth: 60,
        fontSize: 24,
      },
      rootExtraLarge: {
        height: 100,
        width: 100,
        minWidth: 100,
        fontSize: 40,
      },
      rootClickable: {
        cursor: 'pointer',
        '&:hover': {
          '& $overlay': { display: 'flex' },
        },
      },
      rootDefaultBackground: {
        background: theme.palette.background.default,
      },
      img: {
        height: '100%',
        width: '100%',
      },
      imgPreview: {
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
      },
      overlay: {
        display: 'none',
        position: 'absolute',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: alpha(theme.palette.common.black, 0.5),
        color: theme.palette.common.white,
      },
    }),
  { name: 'Avatar' }
);

type Props = Partial<Pick<Employee, 'firstName' | 'lastName' | 'profileImage'>> & {
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  onClick?: () => void;
  isPreview?: boolean;
  className?: string;
};

export const Avatar = ({
  profileImage,
  firstName,
  lastName,
  size = 'small',
  isPreview,
  onClick,
  className,
}: Props): JSX.Element => {
  const c = useStyles({});
  const isDefaultIcon = !(profileImage || (firstName && lastName));
  return (
    <div
      className={cx(
        c.root,
        {
          [c.rootMedium]: size === 'medium',
          [c.rootLarge]: size === 'large',
          [c.rootExtraLarge]: size === 'extraLarge',
          [c.rootClickable]: onClick,
          [c.rootDefaultBackground]: isDefaultIcon,
        },
        className
      )}
      onClick={onClick}
    >
      {isPreview ? (
        <div className={cx(c.img, c.imgPreview)} style={{ backgroundImage: `url(${profileImage})` }} />
      ) : profileImage ? (
        <img className={c.img} src={profileImage} alt="" />
      ) : firstName && lastName ? (
        `${firstName.charAt(0)}${lastName.charAt(0)}`
      ) : (
        <Icon name="person" />
      )}
      {onClick && <div className={c.overlay} children={<Icon name="edit" />} />}
    </div>
  );
};
