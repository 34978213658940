import { createStyles, Fab, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Icon, IconType } from './Icon';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'fixed',
        right: theme.spacing(4),
        bottom: theme.spacing(4),
      },
    }),
  { name: 'ButtonFloating' }
);

type Props = {
  icon: IconType;
  onClick: () => void;
  className?: string;
};

export const ButtonFloating = ({ icon, onClick, className }: Props): JSX.Element => (
  <Fab
    className={cx(useStyles({}).root, className)}
    color="primary"
    onClick={onClick}
    children={<Icon name={icon} fontSize="large" />}
  />
);
