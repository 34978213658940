// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface HyperlinkDTO
 */
export interface HyperlinkDTO {
    /**
     * @type {string}
     * @memberof HyperlinkDTO
     */
    url: string;
    /**
     * @type {string}
     * @memberof HyperlinkDTO
     */
    hyperlinkType: HyperlinkDTOHyperlinkTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum HyperlinkDTOHyperlinkTypeEnum {
    Jira = 'JIRA',
    Confluence = 'CONFLUENCE',
    Sharepoint = 'SHAREPOINT'
}

