import { createSelector } from 'reselect';
import {Certificate, JobTitle, Language, Location, ModelArray, Skill} from '../../models';
import { RootState } from '../rootReducer';
import { State } from './reducer';

const mixedState = (state: RootState) => state.mixed;

const getEntity = <T>(key: keyof State) =>
  createSelector([mixedState], (mixed) => (mixed[key] as unknown) as ModelArray<T>);

export const getCertificates = getEntity<Certificate>('certificates');

export const getOrganizationTreeRoot = createSelector([mixedState], (mixed) => mixed.organizationTreeRoot);

export const getFAQs = createSelector([mixedState], (mixed) => mixed.faqs);
export const getJobTitles = getEntity<JobTitle>('jobTitles');
export const getLanguages = getEntity<Language>('languages');
export const getLocations = getEntity<Location>('locations');
export const getSkills = getEntity<Skill>('skills');
// export const getProjectsHistory = getEntity<ProjectHistory>("vwSkills");

export const getVWSkills = getEntity<Skill>('vwSkills');
