import { createStyles, makeStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { mixed, object } from 'yup';
import { Avatar } from '.';
import { Employee, UpdateStatus } from '../models';
import { getFieldProps, getFormStyles } from '../utils';
import { FormActions } from './ProjectForm';

const MAX_FILE_SIZE = 1000 * 1024;
const SUPPORTED_FILE_FORMATS = ['image/jpg', 'image/jpeg'];

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getFormStyles(theme),
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      avatar: {
        marginBottom: theme.spacing(2),
      },
    }),
  { name: 'ProfileImageForm' }
);

type Props = {
  employee: Employee;
  updateStatus: UpdateStatus;
  onSubmit: (params: File) => void;
  onCancel?: () => void;
};

type ProfileImageFormModel = {
  image: File;
};
const validateName = (key: keyof ProfileImageFormModel) => key;

export const ProfileImageForm = ({ employee, onSubmit, ...props }: Props): JSX.Element => {
  const c = useStyles({});
  const [image, setImage] = useState<string>(employee && employee.profileImage);

  const initialValues: ProfileImageFormModel = {
    image: undefined,
  };

  const validationSchema = object({
    image: mixed()
      .required('Image is required')
      .test('fileSize', "File can't be bigger than 1 MB", (value) => value && value.size <= MAX_FILE_SIZE)
      .test('fileFormat', 'Unsupported Format', (value) => value && SUPPORTED_FILE_FORMATS.includes(value.type)),
  });

  const fieldProps = {
    ...getFieldProps(props.updateStatus.isPending).file,
    accept: SUPPORTED_FILE_FORMATS,
    onChange: (file: File) => {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => setImage(reader.result as string);
        reader.readAsDataURL(file);
      } else {
        setImage(undefined);
      }
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ image }, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit(image);
      }}
    >
      {({ handleSubmit }) => (
        <form className={c.root} onSubmit={handleSubmit}>
          <Avatar className={c.avatar} {...employee} profileImage={image} isPreview={!!image} size="extraLarge" />
          <Field {...fieldProps} name={validateName('image')} label={'Choose Image'} />

          <FormActions {...props} ctaLabel={'Upload Image'} c={c} />
        </form>
      )}
    </Formik>
  );
};
