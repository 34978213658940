import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import cx from 'clsx';
import React, { useState } from 'react';
import { MonthSelector, PositionCounter, PositionItem, PositionItemCalendar } from '.';
import { Position } from '../models';
import { getMonths } from '../utils';
import { isSameMonth } from 'date-fns';

export const getPositionBaseStyles = (theme: Theme) =>
  createStyles({
    position: {
      display: 'flex',
      height: 95,
      overflow: 'hidden', // to cut shadows
    },
    positionNav: {
      height: 40,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    positionCalendar: {
      height: 60,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    positionHead: {
      position: 'relative',
      width: 230,
      boxShadow: '1px 0 4px 0 rgba(0, 0, 0, 0.1)',
      padding: `2.5px ${theme.spacing(1)}px`,
      zIndex: 1,
    },
    positionHeadFlex: {
      display: 'flex',
      alignItems: 'center',
    },
    positionHeadTitle: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    monthSelector: {
      flex: 1,
      padding: `2.5px ${theme.spacing(1)}px`,
    },
    showEmployeeButton: {
      height: 26,
      paddingTop: 0,
      paddingBottom: 0,
      boxShadow: 'none',
      fontSize: 13,
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  });

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    ...getPositionBaseStyles(theme),
    counter: {
      marginLeft: 'auto',
    },
  }),
  { name: 'PositionStaffing' }
);

type Props = {
  positions: Position[];
  startDate: Date;
  endDate: Date;
  onAddOrEditPosition: (position: Position) => void;
  onDeletePosition: (position: Position) => void;
  onAssignEmployee: (position: Position) => void;
  className?: string;
};

export const PositionStaffing = ({
  positions,
  startDate,
  endDate,
  onAddOrEditPosition,
  onDeletePosition,
  onAssignEmployee,
  className,
}: Props): JSX.Element => {
  const c = useStyles({});

  const months = getMonths(startDate, endDate);
  const [activeMonth, setActiveMonth] = useState(months.find((item) => item.isCurrent).date);
  const [showAllEmployees, setShowAllEmployees] = useState(false);

  const positionProps = { activeMonth: activeMonth };

  const currentPositions = showAllEmployees
    ? positions
    : positions.filter(
        (p) =>
          p.isReadOnly ||
          p.capacities.length === 0 ||
          p.capacities.some((capacity) => isSameMonth(capacity.entryDate, activeMonth))
      );

  const renderPositionItem = (item: Position) => (
    <PositionItem
      key={item.id}
      position={item}
      {...positionProps}
      onEdit={() => onAddOrEditPosition(item)}
      onDelete={() => onDeletePosition(item)}
      onAssign={() => onAssignEmployee(item)}
    />
  );

  const showEmployeesText = showAllEmployees ? 'Show only in current Month' : 'Show all Employees';

  return (
    <section className={cx(c.root, className)}>
      <div className={cx(c.position, c.positionNav)}>
        <div className={cx(c.positionHead, c.positionHeadFlex)}>
          <div className={c.positionHeadTitle} children={'Positions'} />
          <PositionCounter
            className={c.counter}
            staffedPosition={currentPositions.length}
            totalPosition={positions.length}
            hideIcon
          />
        </div>
        <MonthSelector
          className={c.monthSelector}
          months={months}
          activeMonth={activeMonth}
          onChange={setActiveMonth}
          showToday
        />
      </div>
      <div className={cx(c.position, c.positionCalendar)}>
        <div className={c.positionHead}>
          <Button
            variant="outlined"
            className={c.showEmployeeButton}
            children={showEmployeesText}
            onClick={() => setShowAllEmployees(!showAllEmployees)}
          />
        </div>

        <PositionItemCalendar {...positionProps} />
      </div>

      {currentPositions.filter((item) => !item.employee).map(renderPositionItem)}

      {currentPositions
        .filter((item) => item.isReadOnly)
        .map((item) => (
          <PositionItem key={item.id} position={item} {...positionProps} />
        ))}

      {currentPositions
        .filter((item) => !item.isReadOnly && item.employee)
        // 2nd sorting criteria is id in order to be idempotent when the same role occurs multiple times
        .sort((a, b) => (a.role === b.role ? (a.id > b.id ? 1 : -1) : a.role > b.role ? 1 : -1))
        .map(renderPositionItem)}
    </section>
  );
};
