import { Icon } from '../Icon';
import {
    createStyles,
    makeStyles,
    Grid,
    Typography,
    Divider,
    FormControl,
    FormHelperText, IconButton,
} from '@material-ui/core';
import { Loading } from '../../components';
import React, {useEffect, useState} from 'react';
import {getFormStyles} from "../../utils";
import {clearUpload, getFileUploadStatus, getUploadResponse, uploadFile} from "../../store/file";
import { useDispatch, useSelector } from 'react-redux';
import {useFormikContext} from "formik";
import {PositionFormModel} from "../StepperModal";
import cx from "clsx";

const useStyles =  makeStyles((theme) =>
    createStyles({
        ...getFormStyles(theme),
        division: {
            display: 'grid',
            gridTemplateRows: '1fr 0.5fr 1fr',
        },
        card: {
            display: 'grid',
            justifyItems: 'center',
            alignItems:'center',
            border: `2px dashed ${theme.palette.grey[300]}`,
            borderRadius: 6,
            margin: theme.spacing(0.5),
            padding:'30px 110px 10px 110px',
        },
        iconAlignment: {
            display: 'grid',
            justifyItems: 'center',
            alignItems: 'center',
        },
       icon: {
                width: '80px',
                height: '80px',
            },
        description: {
            color: theme.palette.grey[500],
            margin: '1rem 0',
        },
        content: {
            display: 'grid',
            justifyItems: 'center',
            alignItems: 'center',
            margin: theme.spacing(7),
            color: theme.palette.grey[500],
        },
        errorContent:{
            display: 'grid',
            justifyItems: 'center',
            alignItems: 'center',
            margin: theme.spacing(1),
            padding: 4,
            backgroundColor: '#ffefef',
        },
        fileContent: {
            display: 'grid',
            alignItems: 'center',
            margin: theme.spacing(1.5),
            fontWeight: theme.typography.fontWeightSemiBold,
        },
        deleteButton: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: theme.spacing(1),
            color: 'red',
        },
        addColor : {
            color: '#1a79e0',
        },
        uploadedFileSection: {
            display: 'grid',
            gridTemplateColumns: '3fr auto 0.2fr',
            fontSize: 14,
            marginTop: theme.spacing(2),
            border: '1px solid',
            borderColor: `${theme.palette.divider}`,
            borderRadius: 8,
        },
        addErrorBorder:{
            borderColor: 'red',
        },
        errorFile: {
            display: 'grid',
            alignItems: 'center',
            color: 'red',
        },
    })
);

const MAX_FILE_SIZE = 10000 * 1024;
const SUPPORTED_FILE_FORMATS = ['.pdf','.PDF','.doc','.docx','.ppt','.pptx'];

export const UploadStep = (): JSX.Element  => {
    const updateStatus=useSelector(getFileUploadStatus);
    const uploadResponse=useSelector(getUploadResponse);
    const dispatch = useDispatch();
    const { values, setFieldValue, errors } = useFormikContext<PositionFormModel>();
    const validateName = (key: keyof PositionFormModel) => key;
    const [file, setFile] = useState<File>(undefined);
    const [fileErrorMessage, setFileErrorMessage] = useState<string>(undefined);
    const [errorFileName, setErrorFileName] = useState<string>(undefined);
    let oldResponseId = undefined;
    const [uploading, setUploading] = useState<boolean>(false);
    const c = useStyles({});

    useEffect(() => {
       if((uploadResponse !== null && uploadResponse !== undefined) && (oldResponseId == null || uploadResponse.id !== oldResponseId)){
           oldResponseId = uploadResponse.id;
            setUploading(false);
            setFieldValue(validateName("fileIds"), new Array(uploadResponse.id));
            dispatch(clearUpload());
        }
    }, [uploadResponse]);

    useEffect(() => {
        const currentFileId = values.fileIds.values().next().value;
        if(values.attachments !== null && values.attachments !== undefined && currentFileId !== null && currentFileId !== undefined){
            const attachmentObj = values.attachments.filter(attachment => attachment.id === currentFileId);
            if(attachmentObj.values().next()?.value?.fileName){
                setFile(new File([""], attachmentObj.values().next()?.value?.fileName));
            }
        }
    }, [values.attachments, values.fileIds]);

    const filePathValidator = (fileName:string) => {
        return SUPPORTED_FILE_FORMATS.some(el => fileName.endsWith(el));
    }

    const clearUploads = () => {
        setFieldValue(validateName("fileIds"), []);
        setFileErrorMessage(undefined);
        setErrorFileName(undefined);
        setFile(undefined);
        setUploading(false);
        // cleaning the state in redux
        dispatch(clearUpload());
    }

    const changeHandler = (file: File) => {
        setFileErrorMessage(undefined);
        if (file) {
            if(file.size > MAX_FILE_SIZE){
                clearUploads();
                setFileErrorMessage(" File can't be bigger than 10 MB. ");
                setErrorFileName(file.name);
                return;
            }else if (!filePathValidator(file.name)){
                clearUploads();
                setFileErrorMessage( " Unsupported Format.");
                setErrorFileName(file.name);
                return;
            }
            dispatch(uploadFile.request({file}))
            setFile(file);
        } else {
            setFile(undefined);
        }
    };

    return(
        <FormControl component="fieldset">
            {errors.fileIds &&
                <div className={c.errorContent}> {errors.fileIds &&
                    <FormHelperText error>{errors.fileIds}</FormHelperText>}
                </div>
            }

            <Grid className={`${c.division}`}>
                <Grid className={`${c.card}`}>
                    <div className={c.iconAlignment}>
                        <Icon
                            name="uploadFile"
                            className={`${c.icon}`}
                        />
                        <Typography className={c.description}>
                            Please click on
                            <label className={c.addColor}> browse
                                {updateStatus &&
                                    <input type='file' id="fileIds" hidden
                                           onChange={(event) => {
                                               setUploading(true);
                                               changeHandler(event.currentTarget.files[0]);
                                               event.target.value = null;
                                           }}/>
                                }
                            </label>
                             &nbsp;to upload your file. (Optional)
                        </Typography>

                    </div>
                </Grid>
                <div>
                    <Typography className={c.description} variant="body2">
                        Files allowed: .pdf, .pptx, .ppt, .doc, .docx | Max. 1 File (10MB)
                    </Typography>
                    <Divider/>
                </div>
                <div>
                    {(file || fileErrorMessage) ?
                        <>
                            <div className={c.description}>Uploaded File</div>
                            {uploading ?
                                <Loading withMargin/>
                                :
                                <div className={cx(c.uploadedFileSection,{ [c.addErrorBorder]: fileErrorMessage })}>
                                    <div className={c.fileContent}>
                                        {(file && file.name) || (fileErrorMessage && errorFileName)}
                                    </div>
                                    {fileErrorMessage ? <div className={c.errorFile}> {fileErrorMessage} </div> :
                                        <div></div>}
                                    <IconButton size="small" onClick={clearUploads}
                                                children={<Icon className={c.deleteButton} name={'close'}/>}/>
                                </div>
                            }
                        </>
                        :
                        <Typography className={c.content} variant="body2">
                            No file has been uploaded, yet.
                        </Typography>
                    }
                </div>
            </Grid>
        </FormControl>
    )
}