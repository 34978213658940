import { createStyles, makeStyles, Theme } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { getPlannedDaysPerWeekInMonth } from '../utils';

export const getTimeLineStyles = (theme: Theme) => {
  const DAY_IN_MONTH_COLOR = '#F1F5FB';
  const DAY_IN_WEEK_COLOR = '#E3EAF4';

  return createStyles({
    timeline: {
      display: 'flex',
      userSelect: 'none',
      height: '100%',
      fontSize: 10,
      padding: theme.spacing(0, 1),
    },
    timelinePosition: {
      background: theme.palette.common.white,
      fontSize: 11,
      fontWeight: theme.typography.fontWeightSemiBold,
      color: 'transparent',
      '&:hover': {
        color: theme.palette.common.white,
      },
      '& $week': {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    day: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-end',
      textAlign: 'center',
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
      marginLeft: 5,
      '&:first-child': { marginLeft: 0 },
    },
    dayInMonth: {
      background: DAY_IN_MONTH_COLOR,
    },
    dayNotInMonth: {
      opacity: 0.3,
    },
    dayInWeek: {
      background: DAY_IN_WEEK_COLOR,
    },
    dayToday: {
      background: '#D7E2F1', // not reused
    },
    dayInner: {
      flex: 1,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    },
    dayInnerPlanned: {
      background: '#e3e4e4', // not reused
    },
    dayInnerAssigned: {
      background: theme.palette.accent.green,
    },
    week: {
      position: 'relative',
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 1),
    },
    weekCurrent: {
      background: DAY_IN_WEEK_COLOR,
    },
    weekLabel: {
      position: 'absolute',
      top: 10,
      left: 10,
      zIndex: 1,
    },
    weekCalendar: {
      '& $day': {
        '&:nth-child(2)': {
          marginLeft: 0,
          '&::before': { display: 'none' },
        },
      },
      '& $dayInner': {
        background: theme.palette.common.white,
        height: 20,
        lineHeight: '20px',
        margin: theme.spacing(0, -1, 1),
        borderRadius: 0,
      },
      '& $dayToday': {
        fontWeight: theme.typography.fontWeightSemiBold,
        color: theme.palette.primary.main,
        '& $dayInner': {
          position: 'relative',
          background: DAY_IN_MONTH_COLOR,
          marginLeft: 0,
          marginRight: 0,
          zIndex: 1,
        },
      },
      '& $dayInMonth': {
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: -5,
          bottom: 0,
          background: DAY_IN_MONTH_COLOR,
          width: 5,
        },
      },
      '& $dayInWeek::before': { display: 'none' },
    },
  });
};

const useStyles = makeStyles(
  (theme) => ({
    ...getTimeLineStyles(theme),
    root: {
      flex: 1,
      padding: `2.5px 0`,
    },
  }),
  { name: 'PositionItemTimeline' }
);

type Props = {
  weeks: ReturnType<typeof getPlannedDaysPerWeekInMonth>;
  capacities: {
    currentCapacity: number;
    previousCapacity: number;
    nextCapacity: number;
  };
  isAssigned: boolean;
};

export const PositionItemTimeline = ({
  weeks,
  capacities: { previousCapacity, currentCapacity, nextCapacity },
  isAssigned,
}: Props): JSX.Element => {
  const c = useStyles({});
  const percentage = `${currentCapacity}%`;
  const previousPercentage = `${previousCapacity}%`;
  const nextPercentage = `${nextCapacity}%`;
  return (
    <div className={c.root}>
      <div className={cx(c.timeline, c.timelinePosition)}>
        {weeks.map(({ week, days }) => (
          <div key={week} className={c.week}>
            {days.map(({ isInMonth, isPlanned, isToday }, index) => (
              <div
                key={index}
                className={cx(c.day, {
                  [c.dayInMonth]: isInMonth === 'current',
                  [c.dayNotInMonth]: isInMonth !== 'current',
                  [c.dayToday]: isInMonth === 'current' && isToday,
                })}
                children={
                  <div
                    className={cx(c.dayInner, {
                      [c.dayInnerPlanned]: isPlanned && !isAssigned,
                      [c.dayInnerAssigned]: isPlanned && isAssigned,
                    })}
                    style={
                      isInMonth === 'current'
                        ? { height: percentage }
                        : isInMonth === 'previous'
                        ? { height: previousPercentage }
                        : { height: nextPercentage }
                    }
                    children={isInMonth === 'current' && isPlanned && isAssigned && percentage}
                  />
                }
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
