// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    EmployeeChangePasswordRequest,
    ResetPasswordRequest,
    UserLoginRequest,
    UserLoginResponse,
} from '../models';

export interface ChangePasswordRequest {
    employeeChangePasswordRequest: EmployeeChangePasswordRequest;
}

export interface LoginRequest {
    userLoginRequest: UserLoginRequest;
}

export interface PostResetPasswordRequest {
    resetPasswordRequest: ResetPasswordRequest;
}

/**
 * no description
 */
export class AuthControllerApi extends BaseAPI {

    /**
     */
    changePassword({ employeeChangePasswordRequest }: ChangePasswordRequest): Observable<UserLoginResponse>
    changePassword({ employeeChangePasswordRequest }: ChangePasswordRequest, opts?: OperationOpts): Observable<AjaxResponse<UserLoginResponse>>
    changePassword({ employeeChangePasswordRequest }: ChangePasswordRequest, opts?: OperationOpts): Observable<UserLoginResponse | AjaxResponse<UserLoginResponse>> {
        throwIfNullOrUndefined(employeeChangePasswordRequest, 'employeeChangePasswordRequest', 'changePassword');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<UserLoginResponse>({
            url: '/auth/change-password',
            method: 'PATCH',
            headers,
            body: employeeChangePasswordRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    login({ userLoginRequest }: LoginRequest): Observable<UserLoginResponse>
    login({ userLoginRequest }: LoginRequest, opts?: OperationOpts): Observable<AjaxResponse<UserLoginResponse>>
    login({ userLoginRequest }: LoginRequest, opts?: OperationOpts): Observable<UserLoginResponse | AjaxResponse<UserLoginResponse>> {
        throwIfNullOrUndefined(userLoginRequest, 'userLoginRequest', 'login');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<UserLoginResponse>({
            url: '/auth/login',
            method: 'POST',
            headers,
            body: userLoginRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    logout(): Observable<object>
    logout(opts?: OperationOpts): Observable<AjaxResponse<object>>
    logout(opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        return this.request<object>({
            url: '/auth/logout',
            method: 'POST',
        }, opts?.responseOpts);
    };

    /**
     */
    postResetPassword({ resetPasswordRequest }: PostResetPasswordRequest): Observable<object>
    postResetPassword({ resetPasswordRequest }: PostResetPasswordRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    postResetPassword({ resetPasswordRequest }: PostResetPasswordRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(resetPasswordRequest, 'resetPasswordRequest', 'postResetPassword');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<object>({
            url: '/auth/reset-password',
            method: 'POST',
            headers,
            body: resetPasswordRequest,
        }, opts?.responseOpts);
    };

}
