import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PrivacyPolicyText } from '../components/PrivacyPolicyText';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '40px 140px',
    },
  }),
  { name: 'PrivacyPolicyPage' }
);

export const PrivacyPolicyPage = (): JSX.Element => {
  const c = useStyles({});
  return (
    <>
      <Helmet title={'Privacy Policy'} />
      <PrivacyPolicyText className={cx(c.root)} />
    </>
  );
};
