import React, { useState } from 'react';
import { ConfirmationDialog } from '.';
import Rating from '@material-ui/lab/Rating/Rating';
import Box from '@material-ui/core/Box';

const labels: { [index: string]: string } = {
  1: 'Bad',
  2: 'Not so good',
  3: 'Moderate',
  4: 'Good',
  5: 'Excellent',
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (rate: number, anonymous: boolean) => void;
};

export const AppRatingDialog = ({ isOpen, onClose, onConfirm }: Props): JSX.Element => {
  const [rate, setRate] = useState<number>(3);
  // const [anonymous, setAnonymous] = useState<boolean>(true);
  const [hover, setHover] = useState(-1);

  return (
    <ConfirmationDialog
      onConfirm={() => onConfirm(rate, false)}
      isOpen={isOpen}
      children={
        <>
          <div>
            <Rating
              name="simple-controlled"
              value={rate}
              onChange={(event, newValue) => {
                setRate(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
            />
            {rate !== null && <Box ml={2}>{labels[hover !== -1 ? hover : rate]}</Box>}
          </div>
          {/*<div>*/}
          {/*  <FormControlLabel*/}
          {/*    control={*/}
          {/*      <Checkbox*/}
          {/*        checked={anonymous}*/}
          {/*        onChange={(event, checked) => setAnonymous(checked)}*/}
          {/*        name="anonymous"*/}
          {/*        color="primary"*/}
          {/*      />*/}
          {/*    }*/}
          {/*    label="Please anonymize my Feedback"*/}
          {/*  />*/}
          {/*</div>*/}
        </>
      }
      onClose={onClose}
      confirmLabel={'Submit'}
      cancelLabel={'Not Now'}
      headline={'Please give us feedback'}
    />
  );
};
