import {
    Grid,
    Card,
    CardContent,
    Button,
    makeStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from '@material-ui/core';
import { Field, FieldArray, Formik } from 'formik';
import React, { useState } from 'react';
import { array, boolean, date, number, object, string } from 'yup';
import { getFieldProps, getFormStyles } from '../utils';
import { FormActions } from './ProjectForm';
import { Employee, EntityArray, ProjectHistory, UpdateStatus } from '../models';
import {parseJSON} from "date-fns";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    ...getFormStyles(theme),
    card: {
        marginBottom: theme.spacing(2),
    },
    fieldContainer: {
        marginBottom: theme.spacing(2),
    },
    infobox: {
        marginBottom: theme.spacing(2),
    },
}));

type ProjectsHistoryFormProps = {
    employee?: Employee;
    projectsHistory?: EntityArray<ProjectHistory>;
    updateStatus: UpdateStatus;
    onSubmit: (projectsHistory: ProjectHistory[]) => void;
    onCancel?: () => void;
};

const MAX_TASK_DESCRIPTION_LENGTH = 150;

export const ProjectsHistoryForm = ({
                                        employee,
                                        projectsHistory,
                                        onSubmit,
                                        ...props
                                    }: ProjectsHistoryFormProps): JSX.Element => {
    const c = useStyles({});
    const [removeIndex, setRemoveIndex] = useState<number | null>(null);
    const [removeAction, setRemoveAction] = useState<(() => void) | null>(null);

    const initialValues = {
        projectsHistory: projectsHistory?.data.map((project) => ({
            ...project,
            projectLead: project.projectLead || '',
            client: project.client || '',
            location: project.location || '',
            manDays: project.manDays || 0,
            projectHistoryDescription: project.projectHistoryDescription || '',

        })) || [],
    };

    const validationSchema = object({
        projectsHistory: array(
            object({
                name: string().required('Project Name is required'),
                startDate: date().required('Project Start Date is required').nullable(false),
                endDate: date().required('Project End Date is required').nullable(false),
                role: string(),
                projectLead: string(),
                client: string(),
                location: string(),
                manDays: number(),  // Ensure manDays is validated as a number
                projectHistoryDescription: string()
                    .max(MAX_TASK_DESCRIPTION_LENGTH, `Project History Description cannot exceed ${MAX_TASK_DESCRIPTION_LENGTH} characters`),
                isFromRealProject: boolean().required(),
            })
        ),
    });

    const fieldProps = getFieldProps(props.updateStatus.isPending);

    const handleRemove = (index: number, remove: (index: number) => void, values: any) => {
        const project = values.projectsHistory[index];
        if (!project.name && !project.role && !project.projectHistoryDescription) {
            remove(index);
        } else {
            setRemoveIndex(index);
            setRemoveAction(() => () => remove(index));
        }
    };

    const confirmRemove = () => {
        if (removeAction) {
            removeAction();
            setRemoveIndex(null);
            setRemoveAction(null);
        }
    };

    const cancelRemove = () => {
        setRemoveIndex(null);
        setRemoveAction(null);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                console.log(projectsHistory)

                const convertedValues = values.projectsHistory.map((project) => ({
                    ...project,
                    manDays: Number(project.manDays),
                    startDate: project.startDate ? parseJSON(project.startDate) : undefined,
                    endDate: project.endDate ? parseJSON(project.endDate) : undefined,
                }));
                setSubmitting(false);
                console.log(projectsHistory)
                onSubmit(convertedValues);
            }}
        >
            {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    {/* Hint Text */}
                    <div className={c.infobox}>
                        <Alert>
                            Fields with actual project values will revert to their original state. For changes, please contact your Project Lead.
                        </Alert>
                    </div>
                    <FieldArray name="projectsHistory">
                        {({ remove, push }) => (
                            <Grid container spacing={3}>
                                {values.projectsHistory.map((_, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Card className={c.card}>
                                            <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.text}
                                                            name={`projectsHistory.${index}.name`}
                                                            label={'Project Name'}
                                                            fullWidth
                                                            disabled={values.projectsHistory[index].isFromRealProject}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.text}
                                                            name={`projectsHistory.${index}.role`}
                                                            label={'Role'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.text}
                                                            name={`projectsHistory.${index}.projectLead`}
                                                            label={'Project Lead'}
                                                            fullWidth
                                                            disabled={values.projectsHistory[index].isFromRealProject}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.text}
                                                            name={`projectsHistory.${index}.client`}
                                                            label={'Client'}
                                                            fullWidth
                                                            disabled={values.projectsHistory[index].isFromRealProject}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.text}
                                                            name={`projectsHistory.${index}.location`}
                                                            label={'Location'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.text}
                                                            name={`projectsHistory.${index}.manDays`}
                                                            label={'Man Days'}
                                                            fullWidth
                                                            type="number"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.date}
                                                            name={`projectsHistory.${index}.startDate`}
                                                            label={'Start Date'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.date}
                                                            name={`projectsHistory.${index}.endDate`}
                                                            label={'End Date'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={c.fieldContainer}>
                                                        <Field
                                                            {...fieldProps.text}
                                                            name={`projectsHistory.${index}.projectHistoryDescription`}
                                                            label={'Project History Description'}
                                                            multiline
                                                            rows={4}
                                                            inputProps={{ maxLength: MAX_TASK_DESCRIPTION_LENGTH }}
                                                            helperText={`${values.projectsHistory[index].projectHistoryDescription?.length}/${MAX_TASK_DESCRIPTION_LENGTH}`}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    type="button"
                                                    disabled={values.projectsHistory[index].isFromRealProject}
                                                    onClick={() => handleRemove(index, remove, values)}
                                                    color="secondary"
                                                >
                                                    Remove
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <Button
                                        type="button"
                                        onClick={() => push({
                                            id: '',
                                            name: '',
                                            role: '',
                                            projectLead: '',
                                            client: '',
                                            location: '',
                                            manDays: 0,
                                            startDate: new Date().toISOString(),
                                            endDate: new Date().toISOString(),
                                            projectHistoryDescription: '',
                                            isFromRealProject: false,
                                            employeeId: employee.id
                                        })}
                                        color="primary"
                                    >
                                        Add Project
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </FieldArray>

                    <FormActions {...props} ctaLabel={'Save Projects History'} c={c} />

                    <Dialog
                        open={removeIndex !== null}
                        onClose={cancelRemove}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirm Remove Project History"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to remove this project? This will not affect any of your projects.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={cancelRemove} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={confirmRemove} color="primary" autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
};
