// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PositionMetadataDTO
 */
export interface PositionMetadataDTO {
    /**
     * @type {string}
     * @memberof PositionMetadataDTO
     */
    status: PositionMetadataDTOStatusEnum;
    /**
     * @type {number}
     * @memberof PositionMetadataDTO
     */
    count: number;
}

/**
 * @export
 * @enum {string}
 */
export enum PositionMetadataDTOStatusEnum {
    MyPositions = 'MY_POSITIONS',
    OpenPositions = 'OPEN_POSITIONS'
}

