import { createStyles, Grid, IconButton, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Icon } from '.';
import { getTruncateStyles } from '../layout';
import { Skill } from '../models';
import { getFieldProps, getFormSectionStyles, withId } from '../utils';
import { SkillLevel } from './SkillLevel';
import { MAX_SKILLS } from '../constants';
import { Field } from 'formik';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getTruncateStyles(),
      root: {
        fontSize: 14,
        marginTop: -theme.spacing(2),
        '& $item': {
          marginTop: theme.spacing(2),
        },
      },
      rootReadOnly: {
        '& $item': {
          marginTop: 10,
        },
      },
      rootFullWidth: {
        '& $skillWrap': {
          flex: 1,
        },
        '& $levels': {
          marginRight: -5,
        },
      },
      item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          '& $btnRemove': { display: 'block' },
        },
      },
      skillWrap: {
        display: 'flex',
        alignItems: 'center',
        width: 215,
        minWidth: 0,
      },
      skillName: {
        display: 'inline-block',
        position: 'relative',
      },
      skillNamePending: {
        display: 'inline-block',
        position: 'relative',
        color: 'orange',
      },
      skillCount: {
        display: 'flex',
        justifyContent: 'center',
        height: 15,
        minWidth: 15,
        fontSize: 10,
        borderRadius: '50%',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        margin: '-7px 0 0 3px',
      },
      skillsCountCurrent: {
        marginLeft: 30,
      },
      skillsCountMax: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
      },
      levels: {}, // for reference only
      btnRemove: {
        display: 'none',
        position: 'absolute',
        top: -14, // 48px button, 20px item
        right: 15,
      },
      btnRemoveDescription: {
        position: 'relative',
      },
      ...getFormSectionStyles(theme),
    }),
  { name: 'SkillList' }
);

type Props = {
  skills: Skill[];
  sectionTitle?: string;
  skillsOverlap?: Skill[];
  columns?: 1 | 2 | 3;
  onChange?: (params: Skill) => void;
  onRemove?: (id: string) => void;
  showStats?: boolean;
  isFullWidth?: boolean;
  className?: string;
};

export const SkillList = ({
  skills,
  sectionTitle,
  skillsOverlap,
  columns = 3,
  onChange,
  onRemove,
  showStats,
  isFullWidth,
  className,
}: Props): JSX.Element => {
  const c = useStyles({});

  const confirmedSkills = skills.filter((item) => item.id && item.status === 'confirm');

  const pendingSkills = skills.filter((item) => !(item.id && item.status === 'confirm'));

  const index = (name: string) => skills.findIndex((item) => item.name === name);
  const fieldProps = getFieldProps(false);

  const skillList = (shownSkills: Skill[], sectionTitle: string, withDescription?: boolean) => {
    const xs = withDescription ? 12 : columns === 3 ? 4 : columns === 2 ? 6 : 12;
    return shownSkills.length > 0 ? (
      <>
        {sectionTitle && (
          <div className={c.section}>
            <h2 className={c.sectionTitle}>
              {sectionTitle}{' '}
              {!withDescription && onChange && (
                <>
                  <span className={c.skillsCountCurrent}>{skills.length}</span>
                  <span className={c.skillsCountMax}>&nbsp;/&nbsp;{MAX_SKILLS}</span>
                </>
              )}
            </h2>
          </div>
        )}
        <Grid
          container
          className={cx(c.root, { [c.rootReadOnly]: !onChange, [c.rootFullWidth]: isFullWidth }, className)}
        >
          {shownSkills.map((item) => (
            <Grid item xs={xs} key={item.name} className={c.item}>
              <div className={c.skillWrap} title={item.name}>
                <div
                  className={cx(
                    item.status === 'confirm' || !item.status ? c.skillName : c.skillNamePending,
                    c.truncate
                  )}
                  children={item.name}
                />
                {showStats && item.stats && item.stats.length > 0 && (
                  <span className={c.skillCount} children={item.stats[item.level - 1]} />
                )}
              </div>

              <SkillLevel
                className={c.levels}
                skill={item}
                skillOverlap={(skillsOverlap || []).find(withId(item.id))}
                showOverlap={!!skillsOverlap}
                onLevelChange={onChange}
              />

              {fieldProps && withDescription && (
                <Field
                  {...fieldProps.text}
                  name={`skills[${index(item.name)}].requestMessage`}
                  label={'Description'}
                  placeholder={'Tell us why you need this skill'}
                  disabled={!!item.id}
                />
              )}

              {onRemove && (
                <IconButton
                  className={cx(withDescription ? c.btnRemoveDescription : c.btnRemove)}
                  onClick={() => onRemove(item.id)}
                  children={<Icon name="delete" />}
                  disabled={!!item.id && withDescription}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      {skillList(confirmedSkills, sectionTitle)}
      {skillList(pendingSkills, 'Requested Skills', !!onRemove)}
    </>
  );
};
