import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

const positionsState = (state: RootState) => state.positions;

export const getEditPosition = createSelector([positionsState], (positions) => positions.editPosition);

export const getOpenPositions = createSelector([positionsState], (positions) => positions.openPositions);

export const getOpenPositionPagination = createSelector(
  [positionsState],
  (positions) => positions.openPositionPagination
);

export const getMyPositionPagination = createSelector([positionsState], (positions) => positions.myPositionPagination);

export const getMyPositions = createSelector([positionsState], (positions) => positions.myPositions);

export const getSortBy = createSelector([positionsState], (positions) => positions.sortBy);

export const getStatus = createSelector([positionsState], (positions) => positions.status);
