import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

export const UserGuidePage = (): JSX.Element => {
  useEffect(() => {
    window.location.href = 'https://mhpcloud.sharepoint.com/sites/1003/SitePages/How-to--Skill-Matching.aspx';
    // window.open('https://mhpcloud.sharepoint.com/sites/1003/SitePages/How-to--Skill-Matching.aspx', '_blank');
  }, []);

  return (
    <>
    <Helmet title={'UserGuide'} />
    <div>
      <h1>User Guide</h1>
      <p>Please Wait</p>
    </div>
  </>
  );
};
