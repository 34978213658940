import { combineEpics } from 'redux-observable';
import { appEpics } from './app/epics';
import { clientsEpics } from './clients/epics';
import { employeeEpics } from './employee/epics';
import { employeesEpics } from './employees/epics';
import { freelancersEpics } from './freelancers/epics';
import { fileEpics } from './file/epics';
import { mixedEpics } from './mixed/epics';
import { overviewEpics } from './overview/epics';
import { positionEpics } from './position/epics';
import { positionsEpics } from './positions/epics';
import { projectEpics } from './project/epics';
import { projectsEpics } from './projects/epics';
import { searchEpics } from './search/epics';

export const rootEpic = combineEpics(
  ...appEpics,
  ...clientsEpics,
  ...employeeEpics,
  ...employeesEpics,
  ...fileEpics,
  ...freelancersEpics,
  ...mixedEpics,
  ...overviewEpics,
  ...positionEpics,
  ...positionsEpics,
  ...projectEpics,
  ...projectsEpics,
  ...searchEpics
);
