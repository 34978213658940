import { getType } from 'typesafe-actions';
import { Employee, EmployeeFilter, EntityArray, Pagination } from '../../models';
import { getInitialEntityArrayState, replaceIn, withId } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

type State = {
  readonly freelancers: EntityArray<Employee>;
  readonly filter: EmployeeFilter;
  readonly pagination: Pagination;
  readonly totalElements?: number;
};

const initialState: State = {
  freelancers: getInitialEntityArrayState(),
  filter: {
    searchNames: [],
    skillIds: [],
    isReplaceable: false,
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
  },
};

export const freelancersReducer = (
  state = initialState,
  action: fromActions.FreelancersAction | AppActions.AppAction
): State => {
  const { freelancers } = state;
  const { data } = freelancers;

  switch (action.type) {
    case getType(fromActions.searchFreelancers.request):
      return {
        ...state,
        freelancers: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };

    case getType(fromActions.searchFreelancers.success):
      return {
        ...state,
        freelancers: {
          ...freelancers,
          data: action.payload.data,
          isLoading: false,
        },
        pagination: {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
        },
      };

    case getType(fromActions.searchFreelancers.failure):
      return {
        ...state,
        freelancers: {
          ...freelancers,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.updateFreelancer):
      const newEmployee = action.payload;
      return {
        ...state,
        // replace on update, add on create
        freelancers: {
          ...freelancers,
          data: data.length
            ? data.filter(withId(newEmployee.id)).length
              ? replaceIn(data, withId(newEmployee.id), ({ capacityScore }) => ({
                  ...newEmployee,
                  capacityScore,
                }))
              : data.concat([newEmployee])
            : data,
        },
      };

    // -------------------------

    case getType(fromActions.setFreelancersPage):
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload,
        },
      };

    // -------------------------

    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
