// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    CriteriaSearch,
    EmployeesSearchDTO,
    SuggestionSearchDTO,
} from '../models';

export interface EnterpriseSearchRequest {
    page: number;
    criteriaSearch: Array<CriteriaSearch>;
}

export interface SearchSuggestionRequest {
    keyword: string;
    types: Array<SearchSuggestionTypesEnum>;
}

/**
 * no description
 */
export class SearchControllerApi extends BaseAPI {

    /**
     */
    enterpriseSearch({ page, criteriaSearch }: EnterpriseSearchRequest): Observable<EmployeesSearchDTO>
    enterpriseSearch({ page, criteriaSearch }: EnterpriseSearchRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeesSearchDTO>>
    enterpriseSearch({ page, criteriaSearch }: EnterpriseSearchRequest, opts?: OperationOpts): Observable<EmployeesSearchDTO | AjaxResponse<EmployeesSearchDTO>> {
        throwIfNullOrUndefined(page, 'page', 'enterpriseSearch');
        throwIfNullOrUndefined(criteriaSearch, 'criteriaSearch', 'enterpriseSearch');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'page': page,
        };

        return this.request<EmployeesSearchDTO>({
            url: '/api/search',
            method: 'POST',
            headers,
            query,
            body: criteriaSearch,
        }, opts?.responseOpts);
    };

    /**
     */
    searchSuggestion({ keyword, types }: SearchSuggestionRequest): Observable<Array<SuggestionSearchDTO>>
    searchSuggestion({ keyword, types }: SearchSuggestionRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<SuggestionSearchDTO>>>
    searchSuggestion({ keyword, types }: SearchSuggestionRequest, opts?: OperationOpts): Observable<Array<SuggestionSearchDTO> | AjaxResponse<Array<SuggestionSearchDTO>>> {
        throwIfNullOrUndefined(keyword, 'keyword', 'searchSuggestion');
        throwIfNullOrUndefined(types, 'types', 'searchSuggestion');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'keyword': keyword,
            'types': types,
        };

        return this.request<Array<SuggestionSearchDTO>>({
            url: '/api/search',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum SearchSuggestionTypesEnum {
    BusinessTitle = 'BUSINESS_TITLE',
    Skill = 'SKILL',
    Certificate = 'CERTIFICATE',
    OfficeLocation = 'OFFICE_LOCATION',
    Language = 'LANGUAGE',
    Employee = 'EMPLOYEE',
    Project = 'PROJECT',
    Position = 'POSITION',
    Capacity = 'CAPACITY',
}
