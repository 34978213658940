import { Button, createStyles, IconButton, makeStyles } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { DatePicker } from '@material-ui/pickers';
import cx from 'clsx';
import React, { useRef, useState } from 'react';
import { Icon } from '.';
import { addMonths, format, isAfter, isBefore, isSameMonth, startOfMonth, subMonths } from 'date-fns';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
      },
      nextPrevNav: {
        marginRight: theme.spacing(3),
        '&:last-child': { margin: 0 },
      },
      btn: {
        height: 26,
        paddingTop: 0,
        paddingBottom: 0,
        boxShadow: 'none',
      },
      btnDisabled: {
        pointerEvents: 'none',
      },
      btnCurrent: {
        width: 140, // needs to as wide as the longest name
        margin: theme.spacing(0, 1),
      },
      datePicker: { display: 'none' },
    }),
  { name: 'MonthSelector' }
);

type Props = {
  activeMonth: Date;
  months: { date: Date; isCurrent: boolean }[];
  onChange: (date: Date) => void;
  showToday?: boolean;
  className?: string;
};

export const MonthSelector = ({ activeMonth, months, onChange, showToday, className }: Props): JSX.Element => {
  const c = useStyles({});
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>();

  const amountMonths = months.length;
  const currentMonth = startOfMonth(new Date());
  const firstMonth = months[0].date;
  const lastMonth = months[amountMonths - 1].date;

  const navBtnProps: { [key: string]: IconButtonProps } = {
    prev: {
      size: 'small',
      children: <Icon name="chevronLeft" />,
      className: cx({ [c.btnDisabled]: amountMonths < 2 || isSameMonth(activeMonth, firstMonth) }),
      onClick: () => onChange(subMonths(activeMonth, 1)),
    },
    current: {
      children: format(activeMonth, 'MMMM yyyy'),
      className: cx(c.btn, c.btnCurrent),
      onClick: () => setIsDatePickerOpen(true),
      ref: (ref) => (btnRef.current = ref),
    },
    next: {
      size: 'small',
      children: <Icon name="chevronRight" />,
      className: cx({ [c.btnDisabled]: amountMonths < 2 || isSameMonth(activeMonth, lastMonth) }),
      onClick: () => onChange(addMonths(activeMonth, 1)),
    },
    today: {
      children: 'Today',
      className: cx(c.btn, {
        [c.btnDisabled]: isBefore(currentMonth, firstMonth) || isAfter(currentMonth, lastMonth),
      }),
      onClick: () => onChange(currentMonth),
    },
  };

  return (
    <nav className={cx(c.root, className)}>
      <div className={c.nextPrevNav}>
        <IconButton {...navBtnProps.prev} />
        <Button {...navBtnProps.current} />
        <IconButton {...navBtnProps.next} />

        <DatePicker
          className={c.datePicker} // hidden so it doesn't render an input
          open={isDatePickerOpen}
          autoOk
          variant="inline"
          value={activeMonth}
          openTo="month"
          views={['year', 'month']}
          PopoverProps={{
            anchorEl: btnRef.current,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
            transformOrigin: { horizontal: 'center', vertical: 'top' },
          }}
          onChange={(date) => {
            if (!isSameMonth(date, activeMonth)) {
              onChange(date);
            }
          }}
          onClose={() => setIsDatePickerOpen(false)}
          minDate={firstMonth}
          maxDate={lastMonth}
        />
      </div>

      {showToday && <Button variant="outlined" {...navBtnProps.today} />}
    </nav>
  );
};
