import { Button } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { object, ref, string } from 'yup';
import { UpdateStatus } from '../models';
import { sitemap } from '../routes';
import { e2e, getFieldProps } from '../utils';
import { Icon } from './Icon';
import { SimpleFormActions, useSimpleFormStyles } from './LoginForm';

type Props = {
  onSubmit: (params: { oldPassword: string; newPassword: string }) => void;
  updateStatus: UpdateStatus;
};

type ChangePasswordFormModel = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};
const validateName = (key: keyof ChangePasswordFormModel) => key;

export const ChangePasswordForm = ({ onSubmit, ...props }: Props): JSX.Element => {
  const c = useSimpleFormStyles({});
  const initialValues: ChangePasswordFormModel = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  };

  const validationSchema = object({
    oldPassword: string()
      .min(8, 'Password must have at least 8 characters')
      .max(30, "Can't have more than 30 characters")
      .required('Current password is required'),
    newPassword: string()
      .min(8, 'Password must have at least 8 characters')
      .max(30, "Can't have more than 30 characters")
      .required('Password is required'),
    newPasswordConfirmation: string()
      .oneOf([ref(validateName('newPassword')), null], 'Passwords must match')
      .required('Password confirmation is required'),
  });

  const {
    updateStatus: { isPending, isSuccess },
  } = props;

  const fieldProps = getFieldProps(isPending).password;

  return (
    <div className={c.root} {...e2e('change-password-form')}>
      <h2 className={c.title}>{'Change Password'}</h2>
      <p className={c.intro}>
        {'Please enter your current password and your new one. It needs to be at least 8 characters long.'}
      </p>

      {!isSuccess ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={({ oldPassword, newPassword }, { setSubmitting }) => {
            setSubmitting(false);
            onSubmit({ oldPassword, newPassword });
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                {...fieldProps}
                name={validateName('oldPassword')}
                label={'Old Password'}
                {...e2e('input-old-pass')}
              />
              <Field
                {...fieldProps}
                name={validateName('newPassword')}
                label={'New Password'}
                {...e2e('input-new-pass')}
              />
              <Field
                {...fieldProps}
                name={validateName('newPasswordConfirmation')}
                label={'Confirm new Password'}
                {...e2e('input-confirm-pass')}
              />

              <SimpleFormActions {...props} ctaLabel={'Change Password'} c={c} />
            </form>
          )}
        </Formik>
      ) : (
        <div className={c.success}>
          <Icon className={c.successIcon} name="star" />
          <Button component={Link} to={sitemap.home.path} children={"Let's go"} />
        </div>
      )}
    </div>
  );
};
