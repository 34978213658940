import React, { useEffect } from 'react';
import { CriteriaList } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModels, getCertificates } from '../store/mixed';

export const CertificatesPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const certificates = useSelector(getCertificates);

  useEffect(() => {
    if (!certificates.data.length) {
      dispatch(fetchModels.request('certificates'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <CriteriaList criteria={certificates} type={'certificates'} />;
};
