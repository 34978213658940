import { ActionType, createAsyncAction } from 'typesafe-actions';
import { HttpError, OverviewItem, ReleaseNote } from '../../models';

export const fetchOverview = createAsyncAction(
  '@overview/FETCH_OVERVIEW',
  '@overview/FETCH_OVERVIEW_SUCCESS',
  '@overview/FETCH_OVERVIEW_FAILURE'
)<void, OverviewItem[], HttpError>();

export const fetchAppRating = createAsyncAction(
  '@overview/FETCH_APP_RATING',
  '@overview/FETCH_APP_RATING_SUCCESS',
  '@overview/FETCH_APP_RATING_FAILURE'
)<void, OverviewItem[], HttpError>();

export const fetchReleaseNote = createAsyncAction(
  '@overview/FETCH_RELEASE_NOTE',
  '@overview/FETCH_RELEASE_NOTE_SUCCESS',
  '@overview/FETCH_RELEASE_NOTE_FAILURE'
)<void, ReleaseNote[], HttpError>();

export type OverviewAction =
  | ActionType<typeof fetchOverview>
  | ActionType<typeof fetchReleaseNote>
  | ActionType<typeof fetchAppRating>;
