import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonFloating, ErrorBlock, List, Modal, ProjectFormContainer, ProjectSearch, Tabs } from '../components';
import { getSubNavStyles, TabValue } from '../components/Tabs';
import { getWrapStyles, Main } from '../layout';
import { Project, ProjectStatus } from '../models';
import { sitemap } from '../routes';
import { getConfig, getUser } from '../store/app';
import { createOrUpdateProject, getProjectUpdateStatus } from '../store/project';
import {
  fetchProjects,
  getFilter,
  getPagination,
  getProjects,
  getStats,
  setProjectsKeyword,
  setProjectsPage,
  setProjectsStatus,
} from '../store/projects';
import { isUserOnlyEmployee } from '../utils';
import { Pagination } from '../components/Pagination';
import cx from 'clsx';

const useStyles = makeStyles(
  (theme) => ({
    ...getWrapStyles(theme),
    main: {
      top: theme.subnav.height,
    },
    head: {
      ...getSubNavStyles(theme).root,
      height: theme.subnav.height,
      padding: theme.spacing(0, 2),
      display: 'flex',
      alignItems: 'center',
    },
    headInner: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
  { name: 'ProjectsPage' }
);

export const ProjectsPage = (): JSX.Element => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const { activeFeatures } = useSelector(getConfig);
  const { currentPage, totalPages, totalElements } = useSelector(getPagination);
  const updateStatus = useSelector(getProjectUpdateStatus);
  const { status, searchKeyword } = useSelector(getFilter);
  const stats = useSelector(getStats);
  const user = useSelector(getUser);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [editProject, setEditProject] = useState<Project>(undefined);

  const { data, error } = projects;

  useEffect(() => {
    if (!data.length) {
      loadData();
    }
    return () => {
      dispatch(fetchProjects.cancel());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateStatus.isSuccess) {
      setIsProjectModalOpen(false);
    }
  }, [updateStatus]);

  const openProjectModal = (project?: Project) => {
    setIsProjectModalOpen(true);
    setEditProject(project);
  };

  const closeProjectModal = () => {
    setIsProjectModalOpen(false);
    setEditProject(undefined);
  };

  const tabItems: TabValue<ProjectStatus>[] = [
    {
      label: `${activeFeatures.includes('OPPORTUNITY') ? 'Opportunity' : 'Planned'} (${
        stats ? stats.opportunity : '?'
      })`,
      value: 'opportunity',
    },
    { label: `Running (${stats ? stats.running : '?'})`, value: 'running' },
    { label: `Done (${stats ? stats.done : '?'})`, value: 'done' },
  ];

  const onTabChange = (status: ProjectStatus) => {
    dispatch(setProjectsStatus(status));
    loadData();
  };

  const onPaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setProjectsPage(value));
    loadData();
  };

  const onSearchKeywordChange = (searchKeyword) => {
    dispatch(setProjectsKeyword(searchKeyword));
    loadData();
  };

  const loadData = () => dispatch(fetchProjects.request({ status, searchKeyword }));

  return (
    <Main className={c.main}>
      <Helmet title={'Projects'} />

      <header className={c.head}>
        <div className={cx(c.wrap, c.headInner)}>
          <Tabs value={status} values={tabItems} onChange={onTabChange} />
          <ProjectSearch searchKeyword={searchKeyword} onSubmit={onSearchKeywordChange} />
        </div>
      </header>

      <div className={c.wrap}>
        {error && <ErrorBlock {...error} />}
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          totalElements={totalElements}
          onChange={onPaginationChange}
        />
        <List
          type="project"
          data={projects}
          displayProjectLabel
          baseUrl={sitemap.projects.detail.path}
          onEdit={openProjectModal}
        />
        <Pagination currentPage={currentPage} totalPage={totalPages} onChange={onPaginationChange} />
      </div>

      {!isUserOnlyEmployee(user) && status === 'opportunity' && activeFeatures.includes('OPPORTUNITY') && (
        <>
          <ButtonFloating onClick={() => openProjectModal()} icon="add" />

          <Modal
            isOpen={isProjectModalOpen}
            onClose={closeProjectModal}
            headline={editProject ? 'Edit Project' : 'Add Opportunity'}
          >
            {isProjectModalOpen && (
              <ProjectFormContainer
                project={editProject}
                updateStatus={updateStatus}
                onSubmit={(data) => dispatch(createOrUpdateProject.request(data))}
                onCancel={closeProjectModal}
              />
            )}
          </Modal>
        </>
      )}
    </Main>
  );
};
