import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Employee, EntityArray, ModelType, ProjectHistory, UpdateStatus} from '../models';
import {fetchModels, getCertificates, getSkills} from '../store/mixed';
import { pick } from '../utils';
import { ProjectsHistoryForm } from './ProjectsHistoryForm';
import {getEmployeeProjects} from "../store/employee";

type Props = {
    employee?: Employee;
    projectsHistory?: EntityArray<ProjectHistory>;
    updateStatus: UpdateStatus;
    onSubmit: (data: ProjectHistory[]) => void;
    onCancel: () => void;
};


export const ProjectsHistoryFormContainer = ({...props}:Props): JSX.Element => {
    return (
    <ProjectsHistoryForm
        {...props}
        />
    );
};
