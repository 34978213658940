import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OverviewItem, OverviewItemEntry, OverviewItemType } from '../models';
import { DashboardControllerApi, DashboardItemDTO, DashboardItemDTOTypeEnum, DashboardItemEntryDTO } from '../proxy';
import { mapArray, throwIfNotInMap } from '../utils';
import { AuthInterceptor } from './auth.interceptor';
import { parseJSON } from 'date-fns';

export class DashboardService {
  private api = new DashboardControllerApi(AuthInterceptor.Instance);

  static toOverview = (data: DashboardItemDTO): OverviewItem => ({
    type: DashboardService.toOverviewItemType(data.type),
    amount: data.amount,
    entries: data.entries?.map(DashboardService.toOverviewItemEntry) ?? [],
  });

  static toOverviewItemEntry = (data: DashboardItemEntryDTO): OverviewItemEntry => ({
    name: data.name,
    amount: data.amount,
    total: data.total,
    department: data.department,
    position: data.position,
    date: data.date && parseJSON(data.date),
  });

  static toOverviewItemType = (data: DashboardItemDTOTypeEnum): OverviewItemType => {
    const enumToTypeMap: { [key in DashboardItemDTOTypeEnum]: OverviewItemType } = {
      [DashboardItemDTOTypeEnum.Project]: 'project',
      [DashboardItemDTOTypeEnum.Employee]: 'employee',
      [DashboardItemDTOTypeEnum.OpenPosition]: 'openPosition',
      [DashboardItemDTOTypeEnum.External]: 'freelancer',
      [DashboardItemDTOTypeEnum.DeleteOpenPosition]: 'deletePosition',
      [DashboardItemDTOTypeEnum.CreateOpenPosition]: 'createPosition',
      [DashboardItemDTOTypeEnum.DeactivateOpenPosition]: 'deactivatePosition',
      [DashboardItemDTOTypeEnum.Match]: 'match',
    };
    throwIfNotInMap(enumToTypeMap, data, 'DashboardItemDTOTypeEnum');
    return enumToTypeMap[data];
  };

  getOverview = (): Observable<OverviewItem[]> =>
    this.api.getDashboard().pipe(map(mapArray(DashboardService.toOverview)));
}
