import { getType } from 'typesafe-actions';
import { Employee, EmployeeFilter, EntityArray, Pagination, SearchEmployeeDownloadFile, Sorting } from '../../models';
import { getFirstDateInMonth } from '../../utils/formatting'; // separate import needed for tests
import { getInitialEntityArrayState, replaceIn, sortArrayBy, withId } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

type State = {
  readonly employees: EntityArray<Employee>;
  readonly projectLeads: EntityArray<Employee>;
  readonly internalEmployees: EntityArray<Employee>;
  readonly sorting: Sorting<Employee>;
  readonly activeMonth: Date;
  readonly filter: EmployeeFilter;
  readonly downloadUrl?: SearchEmployeeDownloadFile;
  readonly pagination: Pagination;
};

const initialState: State = {
  employees: getInitialEntityArrayState(),
  projectLeads: getInitialEntityArrayState(),
  internalEmployees: getInitialEntityArrayState(),
  sorting: {
    orderBy: 'lastName',
    orderAsc: true,
  },
  activeMonth: getFirstDateInMonth(new Date()),
  filter: {
    searchNames: [],
    skillIds: [],
  },
  pagination: { currentPage: 1, totalPages: 1 },
  downloadUrl: null,
};

export const employeesReducer = (
  state = initialState,
  action: fromActions.EmployeesAction | AppActions.AppAction
): State => {
  const { employees, projectLeads, internalEmployees, sorting } = state;
  const { data } = employees;

  switch (action.type) {
    case getType(fromActions.searchEmployees.request):
      return {
        ...state,
        employees: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
        filter: {
          ...state.filter,
          ...action.payload,
        },
        downloadUrl: null,
      };

    case getType(fromActions.searchEmployees.success):
      return {
        ...state,
        employees: {
          ...employees,
          data: action.payload.data,
          isLoading: false,
        },
        pagination: {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
        },
      };

    case getType(fromActions.downloadSearchEmployees.request):
      return {
        ...state,
        downloadUrl: null,
      };

    case getType(fromActions.downloadSearchEmployees.success):
      return {
        ...state,
        downloadUrl: action.payload,
      };

    case getType(fromActions.searchEmployees.failure):
      return {
        ...state,
        employees: {
          ...employees,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.fetchProjectLeads.request):
      return {
        ...state,
        projectLeads: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchProjectLeads.success):
      return {
        ...state,
        projectLeads: {
          ...projectLeads,
          data: sortArrayBy(action.payload, sorting),
          isLoading: false,
        },
      };

    case getType(fromActions.fetchProjectLeads.failure):
      return {
        ...state,
        projectLeads: {
          ...projectLeads,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.fetchInternalEmployees.request):
      return {
        ...state,
        internalEmployees: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchInternalEmployees.success):
      return {
        ...state,
        internalEmployees: {
          ...internalEmployees,
          data: sortArrayBy(action.payload, sorting),
          isLoading: false,
        },
      };

    case getType(fromActions.fetchInternalEmployees.failure):
      return {
        ...state,
        projectLeads: {
          ...internalEmployees,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.updateEmployee):
      const newEmployee = action.payload;
      return {
        ...state,
        // replace on update, add on create
        employees: {
          ...employees,
          data: data.length
            ? data.filter(withId(newEmployee.id)).length
              ? replaceIn(data, withId(newEmployee.id), ({ capacityScore }) => ({
                  ...newEmployee,
                  capacityScore,
                }))
              : sortArrayBy([newEmployee, ...data], sorting)
            : data,
        },
      };

    case getType(fromActions.removeEmployee):
      return {
        ...state,
        employees: {
          ...employees,
          data: data.filter((item) => item.id !== action.payload),
        },
      };

    // ---------------------------

    case getType(fromActions.setEmployeesSorting):
      const newSorting: Sorting<Employee> = {
        ...state.sorting,
        ...action.payload,
      };
      return {
        ...state,
        sorting: newSorting,
      };

    // -------------------------

    case getType(fromActions.setActiveMonth):
      return {
        ...state,
        activeMonth: action.payload,
      };
    // -------------------------

    case getType(fromActions.setEmployeesPage):
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload,
        },
      };

    // -------------------------

    // NOTE: reset is dispatched when updating freeCapacity
    case getType(fromActions.resetEmployees):
      return {
        ...state,
        employees: getInitialEntityArrayState(),
      };

    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
