// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AppConfigDTO
 */
export interface AppConfigDTO {
    /**
     * @type {string}
     * @memberof AppConfigDTO
     */
    key: AppConfigDTOKeyEnum;
    /**
     * @type {object}
     * @memberof AppConfigDTO
     */
    value: object;
}

/**
 * @export
 * @enum {string}
 */
export enum AppConfigDTOKeyEnum {
    MaxSkillLevel = 'MAX_SKILL_LEVEL',
    Version = 'VERSION',
    ActiveFeatures = 'ACTIVE_FEATURES',
    FeedbackType = 'FEEDBACK_TYPE'
}

