import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getWrapStyles } from '../layout';

const useStyles = makeStyles(getWrapStyles, { name: 'ImprintPage' });

export const ImprintPage = (): JSX.Element => {
  const c = useStyles({});
  return (
    <div className={cx(c.wrap, c.wrapTopAndBottom)}>
      <Helmet title={'Imprint'} />
      <h1>Imprint</h1>
      <p>
        <b>MHP Management- und IT-Beratung GmbH</b>
        <br />
        Film- und Medienzentrum | Königsallee 49 | 71638 Ludwigsburg, Germany
        <br />
        Tel. +49 (0)7141 7856-0 | Fax +49 (0)7141 7856-199
        <br />
        eMail: <b>info@mhp.com</b>
        <br />
        <br />
        <b>Represented by the management</b>
        <br />
        Dr.-Ing. Ralf Hofmann (Chairman)
        <br />
        Marc Zimmermann
        <br />
        <br />
        Stuttgart District Court, Commercial Register no. HRB 205571 VAT ID no. DE183227978
      </p>
    </div>
  );
};
