import { AppBar, createStyles, makeStyles, Menu, MenuItem } from '@material-ui/core';
import cx from 'clsx';
import React, { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AvatarListItem, Logo } from '.';
import { environment as env } from '../environments';
import { User } from '../models';
import { NavItemType, sitemap, toNavItemType } from '../routes';
import { e2e } from '../utils';
import { useSelector } from 'react-redux';
import { getConfig } from '../store/app';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        background: '#5c647b',
        height: theme.header.height,
        fontSize: 14,
      },
      navItem: {
        display: 'inline-block',
        height: theme.header.height,
        lineHeight: `${theme.header.height}px`,
        letterSpacing: '1.3px',
        padding: '0 15px',
        borderBottom: '3px solid transparent',
        color: theme.palette.common.white,
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightSemiBold,
        userSelect: 'none',
      },
      navItemActive: {
        borderColor: theme.palette.common.white,
      },
      navItemDisabled: {
        pointerEvents: 'none',
        color: theme.palette.grey[300],
      },
      navItemProfile: {
        display: 'flex',
        alignItems: 'center',
        letterSpacing: 0,
        fontWeight: theme.typography.fontWeightRegular,
      },
      profile: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
      },
      logo: {
        right: 'auto',
        left: 15,
        height: theme.header.height,
      },
      env: {
        position: 'absolute',
        bottom: 5,
        left: 0,
        right: 0,
        height: 16,
        lineHeight: '16px',
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        borderRadius: 5,
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '2px',
      },
    }),
  { name: 'Header' }
);

type Props = {
  user: User;
  onLogout: () => void;
};

export const AdminHeader = ({ user, onLogout }: Props): JSX.Element => {
  const c = useStyles({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>();

  const { activeFeatures } = useSelector(getConfig);

  const mainNav: NavItemType[] = [
    toNavItemType(sitemap.skills),
    toNavItemType(sitemap.certificates),
    toNavItemType(sitemap.locations),
    toNavItemType(sitemap.languages),
    ...(activeFeatures.includes('FEEDBACK_ADMIN_OVERVIEW') ? [toNavItemType(sitemap.feedbacks)] : []),
  ];

  const userMenuNav: NavItemType[] = [toNavItemType(sitemap.profile), toNavItemType({ path: '/', name: 'Exit Admin' })];

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <AppBar className={c.root} position="fixed" color="inherit" elevation={0}>
      <Link
        to={sitemap.skills.path}
        className={cx(c.profile, c.logo)}
        children={
          <>
            <Logo />
            <span className={c.env}>{env.environment !== 'prod' && env.environment} Admin</span>
          </>
        }
      />

      <nav>
        {mainNav.map((item, index) => (
          <NavLink
            key={index}
            className={cx(c.navItem, { [c.navItemDisabled]: item.disabled })}
            activeClassName={c.navItemActive}
            {...item}
            {...e2e(`nav-${item.children}`)}
          />
        ))}
      </nav>

      {user && (
        <div className={c.profile}>
          <div
            ref={(ref) => (menuRef.current = ref)}
            className={cx(c.navItem, c.navItemProfile)}
            onClick={openMenu}
            {...e2e('btn-menu')}
          >
            <AvatarListItem isShort {...user.employee} withAvatar />
          </div>

          <Menu
            anchorEl={menuRef.current}
            getContentAnchorEl={undefined}
            open={isMenuOpen}
            onClose={closeMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            {userMenuNav.map((item, index) => (
              <MenuItem
                key={index}
                component={Link}
                onClick={closeMenu}
                {...item}
                {...e2e(`btn-menu-${item.children}`)}
              />
            ))}
            <MenuItem onClick={onLogout} children={'Logout'} />
          </Menu>
        </div>
      )}
    </AppBar>
  );
};
