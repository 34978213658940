import { Epic } from 'redux-observable';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';
import {
  createUpdatePosition,
  fetchMyPositions,
  fetchOpenPositions,
  fetchPosition,
  updatePositionLike,
} from './actions';
import { push } from 'connected-react-router';
import { fetchProject } from '../project';

const fetchOpenPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { positionService }
) =>
  action$.pipe(
    filterAction(fetchOpenPositions.request),
    switchMap(({ payload }) =>
      positionService
        .getOpenPositions(payload)
        .pipe(
          map(fetchOpenPositions.success),
          catchErrorAndHandleWithAction(fetchOpenPositions.failure),
          takeUntilAction(action$, fetchOpenPositions.cancel)
        )
    )
  );

const fetchMyPositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { positionService }
) =>
  action$.pipe(
    filterAction(fetchMyPositions.request),
    switchMap(({ payload }) =>
      positionService
        .getMyPositions(payload)
        .pipe(
          map(fetchMyPositions.success),
          catchErrorAndHandleWithAction(fetchMyPositions.failure),
          takeUntilAction(action$, fetchMyPositions.cancel)
        )
    )
  );

const createUpdatePositionsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { positionService }
) =>
  action$.pipe(
    filterAction(createUpdatePosition.request),
    switchMap(({ payload }) =>
      positionService.createUpdatePosition(payload.request, payload.positionId).pipe(
        concatMap((data) => [
          createUpdatePosition.success(data),
          fetchOpenPositions.request({ page: 1, sortBy: 'latest' }),
          fetchMyPositions.request({ page: 1, sortBy: 'latest' }),
          ...(payload.request.selectedProject
            ? [fetchProject.request(payload.request.selectedProject.criteria.value)]
            : []),
          ...(payload.reroute ? [push(payload.reroute)] : []),
        ]),
        catchErrorAndHandleWithAction(createUpdatePosition.failure),
        takeUntilAction(action$, createUpdatePosition.cancel)
      )
    )
  );

const fetchPositionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { positionService }) =>
  action$.pipe(
    filterAction(fetchPosition.request),
    switchMap(({ payload }) =>
      positionService
        .getPosition(payload)
        .pipe(
          map(fetchPosition.success),
          catchErrorAndHandleWithAction(fetchPosition.failure),
          takeUntilAction(action$, fetchPosition.cancel)
        )
    )
  );

const updatePositionLikeEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { positionService }
) =>
  action$.pipe(
    filterAction(updatePositionLike.request),
    switchMap(({ payload }) =>
      positionService
        .updatePositionLike(payload)
        .pipe(map(updatePositionLike.success), catchErrorAndHandleWithAction(updatePositionLike.failure))
    )
  );

export const positionsEpics = [
  fetchPositionEpic,
  fetchOpenPositionsEpic,
  fetchMyPositionsEpic,
  updatePositionLikeEpic,
  createUpdatePositionsEpic,
];
