import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { ErrorBlock, Loading, OverviewCard } from '../components';
import { getSubNavStyles } from '../components/Tabs';
import { getWrapStyles, Main } from '../layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppRating, getAppRating } from '../store/overview';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
      main: {
        top: theme.subnav.height,
      },
      head: {
        ...getSubNavStyles(theme).root,
        height: theme.subnav.height,
      },
      headInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.palette.text.secondary,
      },
      cardContainer: {
        padding: theme.spacing(2, 0),
      },
      bold: {
        fontWeight: theme.typography.fontWeightBold,
      },
      title: {
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(2),
      },
    }),
  { name: 'Feedbacks' }
);

export const FeedbacksPage = (): JSX.Element => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector(getAppRating);

  useEffect(() => {
    dispatch(fetchAppRating.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Main className={c.main}>
      <Helmet title={'Feedbacks'} />
      <header className={c.head} />

      <div className={c.wrap}>
        {error && <ErrorBlock {...error} />}
        {isLoading && <Loading />}
        <Grid container className={c.cardContainer}>
          {data &&
            data.map((card) => (
              <Grid key={card.type} item md={4}>
                <OverviewCard type={card.type} amount={card.amount} entries={card.entries} />
              </Grid>
            ))}
        </Grid>
      </div>
    </Main>
  );
};
