// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    CreateFreelancerRequest,
    CreateInternalEmployeeRequest,
    CriteriaRequest,
    EmployeeDTO,
    EmployeesDTO,
    FileDTO,
    InlineObject1,
    ProjectDTO,
    SkillRequest,
    UpdateExternalEmployeeRequest,
    UpdateInternalEmployeeRequest,
} from '../models';
import {ProjectHistoryDTO} from "../models/ProjectHistoryDTO";
import {ProjectHistory} from "../../models";

export interface DeleteEmployeeRequest {
    id: string;
}

export interface DownloadEmployeesRequest {
    startDate: string;
    endDate: string;
    employeeNames?: Array<string>;
    skillIds?: Array<string>;
}

export interface GetEmployeeRequest {
    id: string;
}

export interface GetHistoryProjectsRequest {
    id: string;
}

export interface PostCreateFreelancerRequest {
    createFreelancerRequest: CreateFreelancerRequest;
}

export interface PostCreateInternalEmployeeRequest {
    createInternalEmployeeRequest: CreateInternalEmployeeRequest;
}

export interface PutUpdateExternalEmployeeRequest {
    id: string;
    updateExternalEmployeeRequest: UpdateExternalEmployeeRequest;
}

export interface PutUpdateInternalEmployeeRequest {
    id: string;
    updateInternalEmployeeRequest: UpdateInternalEmployeeRequest;
}

export interface SearchEmployeesRequest {
    startDate: string;
    endDate: string;
    page: number;
    sortBy: SearchEmployeesSortByEnum;
    employeeNames?: Array<string>;
    skillIds?: Array<string>;
    isVWSkill?: boolean;
}

export interface SearchFreelancersRequest {
    employeeNames?: Array<string>;
    skillIds?: Array<string>;
    replaceable?: boolean;
    page?: number;
}

export interface UpdateCertificatesRequest {
    id: string;
    criteriaRequest: Array<CriteriaRequest>;
}

export interface UpdateSkillsRequest {
    id: string;
    skillRequest: Array<SkillRequest>;
}

export interface UploadProfilePicRequest {
    id: string;
    inlineObject1?: InlineObject1;
}

export interface UpdateProjectsHistoryRequest {
    id: string;
    projectsHistory: Array<ProjectHistory>;
}

/**
 * no description
 */
export class EmployeeControllerApi extends BaseAPI {

    /**
     */
    acceptPrivacyPolicy(): Observable<void>
    acceptPrivacyPolicy(opts?: OperationOpts): Observable<void | AjaxResponse<void>>
    acceptPrivacyPolicy(opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
        return this.request<void>({
            url: '/api/employees/private-policy',
            method: 'PATCH',
        }, opts?.responseOpts);
    };

    /**
     */
    deleteEmployee({ id }: DeleteEmployeeRequest): Observable<void>
    deleteEmployee({ id }: DeleteEmployeeRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>
    deleteEmployee({ id }: DeleteEmployeeRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteEmployee');

        return this.request<void>({
            url: '/api/employees/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
        }, opts?.responseOpts);
    };

    /**
     */
    downloadEmployees({ startDate, endDate, employeeNames, skillIds }: DownloadEmployeesRequest): Observable<FileDTO>
    downloadEmployees({ startDate, endDate, employeeNames, skillIds }: DownloadEmployeesRequest, opts?: OperationOpts): Observable<AjaxResponse<FileDTO>>
    downloadEmployees({ startDate, endDate, employeeNames, skillIds }: DownloadEmployeesRequest, opts?: OperationOpts): Observable<FileDTO | AjaxResponse<FileDTO>> {
        throwIfNullOrUndefined(startDate, 'startDate', 'downloadEmployees');
        throwIfNullOrUndefined(endDate, 'endDate', 'downloadEmployees');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'startDate': startDate,
            'endDate': endDate,
        };

        if (employeeNames != null) { query['employeeNames'] = employeeNames; }
        if (skillIds != null) { query['skillIds'] = skillIds; }

        return this.request<FileDTO>({
            url: '/api/employees/download',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getEmployee({ id }: GetEmployeeRequest): Observable<EmployeeDTO>
    getEmployee({ id }: GetEmployeeRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeeDTO>>
    getEmployee({ id }: GetEmployeeRequest, opts?: OperationOpts): Observable<EmployeeDTO | AjaxResponse<EmployeeDTO>> {
        throwIfNullOrUndefined(id, 'id', 'getEmployee');

        return this.request<EmployeeDTO>({
            url: '/api/employees/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    getHistoryProjects({ id }: GetHistoryProjectsRequest): Observable<Array<ProjectHistoryDTO>>;
    getHistoryProjects({ id }: GetHistoryProjectsRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<ProjectHistoryDTO>>>;
    getHistoryProjects({ id }: GetHistoryProjectsRequest, opts?: OperationOpts): Observable<Array<ProjectHistoryDTO> | AjaxResponse<Array<ProjectHistoryDTO>>> {
        throwIfNullOrUndefined(id, 'id', 'getHistoryProjects');

        const response$ = this.request<Array<ProjectHistoryDTO>>({
            url: `/api/employees/${encodeURI(id)}/projects`,
            method: 'GET',
        }, opts?.responseOpts);

        if (opts) {
            return response$ as Observable<AjaxResponse<Array<ProjectHistoryDTO>>>;
        }

        return response$;
    }


    /**
     */
    getInternalEmployees(): Observable<Array<EmployeeDTO>>
    getInternalEmployees(opts?: OperationOpts): Observable<AjaxResponse<Array<EmployeeDTO>>>
    getInternalEmployees(opts?: OperationOpts): Observable<Array<EmployeeDTO> | AjaxResponse<Array<EmployeeDTO>>> {
        return this.request<Array<EmployeeDTO>>({
            url: '/api/employees/internal',
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    getProjectLeads(): Observable<Array<EmployeeDTO>>
    getProjectLeads(opts?: OperationOpts): Observable<AjaxResponse<Array<EmployeeDTO>>>
    getProjectLeads(opts?: OperationOpts): Observable<Array<EmployeeDTO> | AjaxResponse<Array<EmployeeDTO>>> {
        return this.request<Array<EmployeeDTO>>({
            url: '/api/employees/project-leads',
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    postCreateFreelancer({ createFreelancerRequest }: PostCreateFreelancerRequest): Observable<EmployeeDTO>
    postCreateFreelancer({ createFreelancerRequest }: PostCreateFreelancerRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeeDTO>>
    postCreateFreelancer({ createFreelancerRequest }: PostCreateFreelancerRequest, opts?: OperationOpts): Observable<EmployeeDTO | AjaxResponse<EmployeeDTO>> {
        throwIfNullOrUndefined(createFreelancerRequest, 'createFreelancerRequest', 'postCreateFreelancer');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EmployeeDTO>({
            url: '/api/employees/external',
            method: 'POST',
            headers,
            body: createFreelancerRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    postCreateInternalEmployee({ createInternalEmployeeRequest }: PostCreateInternalEmployeeRequest): Observable<EmployeeDTO>
    postCreateInternalEmployee({ createInternalEmployeeRequest }: PostCreateInternalEmployeeRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeeDTO>>
    postCreateInternalEmployee({ createInternalEmployeeRequest }: PostCreateInternalEmployeeRequest, opts?: OperationOpts): Observable<EmployeeDTO | AjaxResponse<EmployeeDTO>> {
        throwIfNullOrUndefined(createInternalEmployeeRequest, 'createInternalEmployeeRequest', 'postCreateInternalEmployee');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EmployeeDTO>({
            url: '/api/employees',
            method: 'POST',
            headers,
            body: createInternalEmployeeRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    putUpdateExternalEmployee({ id, updateExternalEmployeeRequest }: PutUpdateExternalEmployeeRequest): Observable<EmployeeDTO>
    putUpdateExternalEmployee({ id, updateExternalEmployeeRequest }: PutUpdateExternalEmployeeRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeeDTO>>
    putUpdateExternalEmployee({ id, updateExternalEmployeeRequest }: PutUpdateExternalEmployeeRequest, opts?: OperationOpts): Observable<EmployeeDTO | AjaxResponse<EmployeeDTO>> {
        throwIfNullOrUndefined(id, 'id', 'putUpdateExternalEmployee');
        throwIfNullOrUndefined(updateExternalEmployeeRequest, 'updateExternalEmployeeRequest', 'putUpdateExternalEmployee');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EmployeeDTO>({
            url: '/api/employees/external/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: updateExternalEmployeeRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    putUpdateInternalEmployee({ id, updateInternalEmployeeRequest }: PutUpdateInternalEmployeeRequest): Observable<EmployeeDTO>
    putUpdateInternalEmployee({ id, updateInternalEmployeeRequest }: PutUpdateInternalEmployeeRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeeDTO>>
    putUpdateInternalEmployee({ id, updateInternalEmployeeRequest }: PutUpdateInternalEmployeeRequest, opts?: OperationOpts): Observable<EmployeeDTO | AjaxResponse<EmployeeDTO>> {
        throwIfNullOrUndefined(id, 'id', 'putUpdateInternalEmployee');
        throwIfNullOrUndefined(updateInternalEmployeeRequest, 'updateInternalEmployeeRequest', 'putUpdateInternalEmployee');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EmployeeDTO>({
            url: '/api/employees/internal/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: updateInternalEmployeeRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    searchEmployees({ startDate, endDate, page, sortBy, employeeNames, skillIds, isVWSkill }: SearchEmployeesRequest): Observable<EmployeesDTO>
    searchEmployees({ startDate, endDate, page, sortBy, employeeNames, skillIds, isVWSkill }: SearchEmployeesRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeesDTO>>
    searchEmployees({ startDate, endDate, page, sortBy, employeeNames, skillIds, isVWSkill }: SearchEmployeesRequest, opts?: OperationOpts): Observable<EmployeesDTO | AjaxResponse<EmployeesDTO>> {
        throwIfNullOrUndefined(startDate, 'startDate', 'searchEmployees');
        throwIfNullOrUndefined(endDate, 'endDate', 'searchEmployees');
        throwIfNullOrUndefined(page, 'page', 'searchEmployees');
        throwIfNullOrUndefined(sortBy, 'sortBy', 'searchEmployees');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'startDate': startDate,
            'endDate': endDate,
            'page': page,
            'sortBy': sortBy,
        };

        if (employeeNames != null) { query['employeeNames'] = employeeNames; }
        if (skillIds != null) { query['skillIds'] = skillIds; }
        if (isVWSkill != null) { query['isVWSkill'] = isVWSkill; }

        return this.request<EmployeesDTO>({
            url: '/api/employees/search',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    searchFreelancers({ employeeNames, skillIds, replaceable, page }: SearchFreelancersRequest): Observable<EmployeesDTO>
    searchFreelancers({ employeeNames, skillIds, replaceable, page }: SearchFreelancersRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeesDTO>>
    searchFreelancers({ employeeNames, skillIds, replaceable, page }: SearchFreelancersRequest, opts?: OperationOpts): Observable<EmployeesDTO | AjaxResponse<EmployeesDTO>> {

        const query: HttpQuery = {};

        if (employeeNames != null) { query['employeeNames'] = employeeNames; }
        if (skillIds != null) { query['skillIds'] = skillIds; }
        if (replaceable != null) { query['replaceable'] = replaceable; }
        if (page != null) { query['page'] = page; }

        return this.request<EmployeesDTO>({
            url: '/api/employees/external',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    updateCertificates({ id, criteriaRequest }: UpdateCertificatesRequest): Observable<EmployeeDTO>
    updateCertificates({ id, criteriaRequest }: UpdateCertificatesRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeeDTO>>
    updateCertificates({ id, criteriaRequest }: UpdateCertificatesRequest, opts?: OperationOpts): Observable<EmployeeDTO | AjaxResponse<EmployeeDTO>> {
        throwIfNullOrUndefined(id, 'id', 'updateCertificates');
        throwIfNullOrUndefined(criteriaRequest, 'criteriaRequest', 'updateCertificates');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EmployeeDTO>({
            url: '/api/employees/{id}/certificates'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: criteriaRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    updateSkills({ id, skillRequest }: UpdateSkillsRequest): Observable<EmployeeDTO>
    updateSkills({ id, skillRequest }: UpdateSkillsRequest, opts?: OperationOpts): Observable<AjaxResponse<EmployeeDTO>>
    updateSkills({ id, skillRequest }: UpdateSkillsRequest, opts?: OperationOpts): Observable<EmployeeDTO | AjaxResponse<EmployeeDTO>> {
        throwIfNullOrUndefined(id, 'id', 'updateSkills');
        throwIfNullOrUndefined(skillRequest, 'skillRequest', 'updateSkills');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EmployeeDTO>({
            url: '/api/employees/{id}/skills'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: skillRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    uploadProfilePic({ id, inlineObject1 }: UploadProfilePicRequest): Observable<FileDTO>
    uploadProfilePic({ id, inlineObject1 }: UploadProfilePicRequest, opts?: OperationOpts): Observable<AjaxResponse<FileDTO>>
    uploadProfilePic({ id, inlineObject1 }: UploadProfilePicRequest, opts?: OperationOpts): Observable<FileDTO | AjaxResponse<FileDTO>> {
        throwIfNullOrUndefined(id, 'id', 'uploadProfilePic');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<FileDTO>({
            url: '/api/employees/{id}/profile-pic'.replace('{id}', encodeURI(id)),
            method: 'POST',
            headers,
            body: inlineObject1,
        }, opts?.responseOpts);
    };

    /**
     */
    updateProjectsHistory({ id, projectsHistory }: UpdateProjectsHistoryRequest): Observable<Array<ProjectHistoryDTO>>
    updateProjectsHistory({ id, projectsHistory }: UpdateProjectsHistoryRequest, opts?: OperationOpts): Observable<AjaxResponse<Array<ProjectHistoryDTO>>>
    updateProjectsHistory({ id, projectsHistory }: UpdateProjectsHistoryRequest, opts?: OperationOpts): Observable<Array<ProjectHistoryDTO> | AjaxResponse<Array<ProjectHistoryDTO>>> {
        throwIfNullOrUndefined(id, 'id', 'updateProjectsHistory');
        throwIfNullOrUndefined(projectsHistory, 'projectsHistoryDTO', 'updateProjectsHistory');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };
        return this.request<Array<ProjectHistoryDTO>>({
            url: `/api/employees/${encodeURI(id)}/projectsHistory`,
            method: 'PATCH',
            headers,
            body: projectsHistory,
        }, opts?.responseOpts);
    }

}

/**
 * @export
 * @enum {string}
 */
export enum SearchEmployeesSortByEnum {
    LastNameDesc = 'LAST_NAME_DESC',
    LastNameAsc = 'LAST_NAME_ASC',
    CapacityDesc = 'CAPACITY_DESC',
    CapacityAsc = 'CAPACITY_ASC'
}
