import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission, User, UserCredentials, UserRole } from '../models';
import {
  AuthControllerApi,
  EmployeeDTOPermissionsEnum,
  LoginControllerApi,
  ProjectDTOPermissionsEnum,
  ProjectPositionDTOPermissionsEnum,
  RoleDTO,
  RoleDTONameEnum,
  UserLoginResponse,
} from '../proxy';
import { throwIfNotInMap } from '../utils';
import { AuthInterceptor } from './auth.interceptor';
import { EmployeeService } from './employee.service';

export class AuthService {
  private api = new AuthControllerApi(AuthInterceptor.Instance);
  private loginApi = new LoginControllerApi(AuthInterceptor.Instance); // TODO: make backeend move this into AuthController

  static toUser = (data: UserLoginResponse): User => ({
    email: data.email,
    token: data.accessToken,
    employee: EmployeeService.toEmployee(data.employee),
    hasToChangePassword: data.changePassword,
    hasAcceptedPrivacyPolicy: data.acceptPrivatePolicy,
    isValid: data.employee.userValid,
    roles: data.roles.map(AuthService.toUserRole),
    showAppFeedback: data.showAppFeedback,
  });

  static toUserRole = (data: RoleDTO): UserRole => {
    // TODO: make use type literal instead of enum
    const enumToTypeMap: { [key in RoleDTONameEnum]: UserRole } = {
      [RoleDTONameEnum.Employee]: UserRole.Employee,
      [RoleDTONameEnum.ProjectLead]: UserRole.ProjectLead,
      [RoleDTONameEnum.TeamLead]: UserRole.TeamLead,
      [RoleDTONameEnum.ProjectUnit]: UserRole.ProjectUnit,
      [RoleDTONameEnum.ResourceManager]: UserRole.ResourceManager,
      [RoleDTONameEnum.PeopleDevelopment]: UserRole.PeopleDevelopment,
      [RoleDTONameEnum.SuperAdmin]: UserRole.SuperAdmin,
      [RoleDTONameEnum.Admin]: UserRole.Admin,
      [RoleDTONameEnum.PeopleCounselor]: UserRole.PeopleCounselor,
      [RoleDTONameEnum.VwTaskForce]: UserRole.VWTaskForce,
      [RoleDTONameEnum.Qa]: UserRole.QA,
    };
    throwIfNotInMap(enumToTypeMap, data.name, 'RoleDTONameEnum');
    return enumToTypeMap[data.name];
  };

  static toPermission = (
    data: ProjectDTOPermissionsEnum | ProjectPositionDTOPermissionsEnum | EmployeeDTOPermissionsEnum
  ): Permission => {
    const enumToTypeMap: { [key in ProjectDTOPermissionsEnum]: Permission } = {
      [ProjectDTOPermissionsEnum.Create]: 'CREATE',
      [ProjectDTOPermissionsEnum.Read]: 'READ',
      [ProjectDTOPermissionsEnum.Update]: 'UPDATE',
      [ProjectDTOPermissionsEnum.Delete]: 'DELETE',
    };
    throwIfNotInMap(enumToTypeMap, data, 'ProjectDTOPermissionsEnum');
    return enumToTypeMap[data];
  };

  login = (userLoginRequest: UserCredentials): Observable<User> =>
    this.api.login({ userLoginRequest }).pipe(map(AuthService.toUser));

  getMe = (): Observable<User> => this.loginApi.getCurrentUser().pipe(map(AuthService.toUser));

  signUpOrResetPassword = (email: string): Observable<void> =>
    this.api.postResetPassword({ resetPasswordRequest: { email } }).pipe(map(() => undefined));

  changePassword = (params: { oldPassword: string; newPassword: string }): Observable<User> =>
    this.api.changePassword({ employeeChangePasswordRequest: params }).pipe(map(AuthService.toUser));

  logout = (): Observable<void> => this.api.logout().pipe(map(() => undefined));
}
