import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PositionContainer } from '../components';
import { fetchPosition, getPosition } from '../store/position';
import { getUser } from '../store/app';

type Props = {
  positionId: string;
  backButtonLink?: string;
};

export const PositionProvider = ({ positionId, ...props }: Props): JSX.Element => {
  const position = useSelector(getPosition);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPosition.request(positionId));
  }, [positionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PositionContainer
        positionId={positionId}
        position={position}
        user={user}
        {...props}
        isDeleted={position.isDeleted}
      />
    </>
  );
};
