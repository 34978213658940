// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts } from '../runtime';
import type {
    FAQDTO,
} from '../models';

/**
 * no description
 */
export class FaqControllerApi extends BaseAPI {

    /**
     */
    getFAQs(): Observable<Array<FAQDTO>>
    getFAQs(opts?: OperationOpts): Observable<AjaxResponse<Array<FAQDTO>>>
    getFAQs(opts?: OperationOpts): Observable<Array<FAQDTO> | AjaxResponse<Array<FAQDTO>>> {
        return this.request<Array<FAQDTO>>({
            url: '/api/faq',
            method: 'GET',
        }, opts?.responseOpts);
    };

}
