import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { getTruncateStyles } from '../layout';
import { OverviewItem, OverviewItemType } from '../models';
import { sitemap } from '../routes';
import { formatDate, throwIfNotInMap } from '../utils';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      card: {
        position: 'relative',
        height: '100%',
        padding: theme.spacing(2.5, 0, 6.25),
        background: theme.palette.common.white,
        boxShadow: theme.customShadows.card,
        borderRadius: theme.spacing(0.5),
        color: theme.palette.text.secondary,
      },
      title: {
        paddingLeft: theme.spacing(2),
        fontWeight: theme.typography.fontWeightRegular,
      },
      amount: {
        position: 'relative',
        paddingRight: theme.spacing(2),
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightExtraBold,
        fontSize: 80,
        textAlign: 'right',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        justifyContent: 'flex-start',
        borderBottom: `1px solid ${theme.palette.background.default}`,
        fontSize: 12,
        lineHeight: '24px',
      },
      col: getTruncateStyles().truncate,
      colNewLabel: {
        minWidth: '8%',
        color: theme.palette.primary.main,
        fontSize: 8,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
      },
      colName: {
        flex: 1,
        width: '60%',
        fontWeight: theme.typography.fontWeightBold,
      },
      colLongName: {
        flex: 1,
        width: '90%',
        fontWeight: theme.typography.fontWeightBold,
      },
      colPosition: {
        flex: 1,
        fontWeight: theme.typography.fontWeightBold,
      },
      colDate: {
        width: '25%',
        textAlign: 'right',
      },
      colPositionCounter: {
        width: '40%',
        textAlign: 'right',
      },
      colShortCounter: {
        width: '10%',
        textAlign: 'right',
      },
      colCareerLevel: {
        width: '30%',
      },
      colDepartment: {
        width: '25%',
        fontSize: 11,
      },
      iconPerson: {
        verticalAlign: 'bottom',
      },
      bold: {
        fontWeight: theme.typography.fontWeightBold,
      },
      link: {
        position: 'absolute',
        bottom: 0,
        padding: theme.spacing(2),
        width: '100%',
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 14,
        textAlign: 'center',
      },
      trendWrapper: {
        position: 'relative',
        paddingBottom: 20,
      },
      trend: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing(7.25),
        background: theme.palette.background.default,
        width: '88%',
        height: 45,
        borderTopLeftRadius: 14,
        borderBottomLeftRadius: 14,
      },
      trendIcon: {
        marginLeft: theme.spacing(),
        marginTop: theme.spacing(0.25),
        color: theme.palette.primary.main,
        fontSize: 40,
        verticalAlign: 'bottom',
      },
      trendIconUp: {
        transform: 'rotate(-45deg)',
      },
      trendIconDown: {
        transform: 'rotate(45deg)',
      },
    }),
  { name: 'Overview' }
);

type Props = { department?: string } & OverviewItem;

export const OverviewCard = ({ type, amount, entries, department }: Props): JSX.Element => {
  const c = useStyles({});
  const getTitleAndLinkAndBody = () => {
    const typeToDataMap: {
      [key in OverviewItemType]: { title: string; link?: string; body: JSX.Element[] };
    } = {
      project: {
        title: 'My Projects',
        link: sitemap.projects.root.path,
        body: entries.map((entry, index) => (
          <div className={c.row} key={index}>
            <span className={cx(c.col, c.colName)}>{entry.name}</span>
            <span className={cx(c.col, c.colPositionCounter)}>
              <span className={c.bold}>{entry.amount}</span>
              &nbsp;/&nbsp;{entry.total}
            </span>
          </div>
        )),
      },
      employee: {
        title: `Team (${department})`,
        body: entries.map((entry, index) => (
          <div className={c.row} key={index}>
            <span className={cx(c.col, c.colName)}>{entry.name}</span>
            <span className={cx(c.col, c.colPositionCounter)}>
              <span className={c.bold}>{entry.amount}</span>
              &nbsp;/&nbsp;{entry.total}
            </span>
          </div>
        )),
      },
      openPosition: {
        title: 'Open Positions',
        link: sitemap.positions.root.path,
        body: entries.map((entry, i) => {
          return (
            <div className={c.row} key={i}>
              <span className={cx(c.col, c.colPosition)}>{entry.position}</span>
              <span className={cx(c.col, c.colDate)}>{entry.date && formatDate(entry.date)}</span>
            </div>
          );
        }),
      },
      match: {
        title: 'Matches',
        link: sitemap.positions.root.path,
        body: entries.map((entry, i) => {
          return (
            <div className={c.row} key={i}>
              <span className={cx(c.col, c.colPosition)}>{entry.position}</span>
              <span className={cx(c.col, c.colDate)}>{entry.date && formatDate(entry.date)}</span>
            </div>
          );
        }),
      },
      freelancer: {
        title: 'Active Freelancers',
        link: sitemap.projectLead.freelancers.root.path,
        body: entries.map((entry, i) => (
          <div className={c.row} key={i}>
            <span className={cx(c.col, c.colName)}>{entry.name}</span>
            <span className={cx(c.col, c.colPositionCounter)}>
              <span className={c.bold}>{entry.amount}</span>
              &nbsp;/&nbsp;{entry.total}
            </span>
          </div>
        )),
      },
      appRating: {
        title: 'App Rating',
        body: entries.map((entry, i) => (
          <div className={c.row} key={i}>
            <span className={cx(c.col, c.colNewLabel)}>{entry.prefix}</span>
            <span className={cx(c.col, c.colName)}>{entry.name}</span>
            <span className={cx(c.col, c.colPositionCounter)}>
              <span className={c.bold}>{entry.amount}</span>
            </span>
          </div>
        )),
      },
      deletePosition: {
        title: 'Delete Open Position',
        body: entries.map((entry, i) => (
          <div className={c.row} key={i}>
            <span className={cx(c.col, c.colLongName)}>{entry.name}</span>
            <span className={cx(c.col, c.colShortCounter)}>
              <span className={c.bold}>{entry.amount}</span>
            </span>
          </div>
        )),
      },
      createPosition: {
        title: 'Create Open Position',
        body: entries.map((entry, i) => (
          <div className={c.row} key={i}>
            <span className={cx(c.col, c.colLongName)}>{entry.name}</span>
            <span className={cx(c.col, c.colShortCounter)}>
              <span className={c.bold}>{entry.amount}</span>
            </span>
          </div>
        )),
      },
      deactivatePosition: {
        title: 'Deactivate Open Position',
        body: entries.map((entry, i) => (
          <div className={c.row} key={i}>
            <span className={cx(c.col, c.colLongName)}>{entry.name}</span>
            <span className={cx(c.col, c.colShortCounter)}>
              <span className={c.bold}>{entry.amount}</span>
            </span>
          </div>
        )),
      },
    };
    throwIfNotInMap(typeToDataMap, type, 'OverviewItem.type');
    return typeToDataMap[type];
  };

  const { title, link, body } = getTitleAndLinkAndBody();

  return (
    <div className={c.card}>
      <h2 className={c.title}>{title}</h2>
      <div className={c.trendWrapper}>
        <div className={c.amount}>{amount}</div>
      </div>

      <div>{body}</div>
      {link && (
        <Link to={link} className={c.link}>
          To {title}
        </Link>
      )}
    </div>
  );
};
