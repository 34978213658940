import React from 'react';
import { match } from 'react-router-dom';
import { EmployeeProvider } from '../components';
import { sitemap } from '../routes';

type Props = {
  match: match<{ id: string }>;
};

export const EmployeePage = ({
  match: {
    path,
    params: { id: employeeId },
  },
}: Props): JSX.Element => {
  const {
    teamLead: {
      employees: {
        root: { path: pathToEmployees },
      },
    },
    projectLead: {
      freelancers: {
        root: { path: pathToFreelancers },
      },
    },
  } = sitemap;

  return (
    <EmployeeProvider
      employeeId={employeeId}
      backButtonLink={path.includes(pathToEmployees) ? pathToEmployees : pathToFreelancers}
    />
  );
};
