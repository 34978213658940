// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    DashboardItemEntryDTO,
} from './';

/**
 * @export
 * @interface DashboardItemDTO
 */
export interface DashboardItemDTO {
    /**
     * @type {number}
     * @memberof DashboardItemDTO
     */
    amount: number;
    /**
     * @type {string}
     * @memberof DashboardItemDTO
     */
    type: DashboardItemDTOTypeEnum;
    /**
     * @type {Array<DashboardItemEntryDTO>}
     * @memberof DashboardItemDTO
     */
    entries?: Array<DashboardItemEntryDTO>;
}

/**
 * @export
 * @enum {string}
 */
export enum DashboardItemDTOTypeEnum {
    Project = 'PROJECT',
    Employee = 'EMPLOYEE',
    OpenPosition = 'OPEN_POSITION',
    Match = 'MATCH',
    External = 'EXTERNAL',
    DeleteOpenPosition = 'DELETE_OPEN_POSITION',
    CreateOpenPosition = 'CREATE_OPEN_POSITION',
    DeactivateOpenPosition = 'DEACTIVATE_OPEN_POSITION'
}

