import { Checkbox, createStyles, FormControlLabel, IconButton, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { Icon } from '.';
import { EmployeeFilter, Model, Skill } from '../models';
import { getFieldProps, withId } from '../utils';
import { OptionType, toOptionType } from './AutoCompleteField';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
      },
      input: {
        width: 400,
        margin: 0,
      },
      inputField: {
        height: 36,
      },
      check: {
        marginLeft: theme.spacing(1),
      },
    }),
  { name: 'EmployeeSearch' }
);

type Props = {
  type: 'employee' | 'freelancer';
  filter: EmployeeFilter;
  skills: Model[];
  vwSkills: Skill[];
  isVWTaskForce: boolean;
  onSubmit: (params: EmployeeFilter) => void;
  onReset: () => void;
  className?: string;
};

type SearchFormModel = Omit<EmployeeFilter, 'skillIds' | 'searchNames'> & { searchOptions: OptionType[] };

export const EmployeeSearch = ({
  type,
  filter: { searchNames, skillIds, isReplaceable, isVWSkill },
  skills,
  vwSkills,
  isVWTaskForce,
  onSubmit,
  onReset,
  className,
}: Props): JSX.Element => {
  const c = useStyles({});
  const isFreelancer = type === 'freelancer';
  const [isVWSkills, setIsVWSkills] = useState(isVWSkill);
  const [getCurrentSkillIds, setCurrentSkillIds] = useState(skillIds);

  const shownSkills = isVWSkills ? vwSkills : skills;

  const initialValues: SearchFormModel = {
    searchOptions: (getCurrentSkillIds?.map((id) => toOptionType(shownSkills.find(withId(id)))) ?? []).concat(
      searchNames?.map((name) => toOptionType({ name })) ?? []
    ),
    ...(isFreelancer && { isReplaceable }),
    ...(isVWTaskForce && { isVWSkills }),
  };

  const fieldProps = getFieldProps(false);
  const validateName = (key: keyof SearchFormModel) => key;

  const changeToVWSkills = () => {
    setCurrentSkillIds([]);
    setIsVWSkills(true);
  };
  const changeToMHPSkills = () => {
    setCurrentSkillIds([]);
    setIsVWSkills(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ searchOptions, isReplaceable }, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit({
          skillIds: searchOptions?.filter((x) => !x.__isNew__)?.map(({ value }) => value) ?? [],
          searchNames: searchOptions?.filter((x) => x.__isNew__)?.map(({ label }) => label) ?? [],
          isReplaceable,
          isVWSkill: isVWSkills,
        });
      }}
    >
      {({ handleSubmit, setFieldValue, resetForm, values, submitForm }) => (
        <form className={cx(c.root, className)} onSubmit={handleSubmit}>
          <Field
            {...fieldProps.select}
            inputClassName={c.inputField} // styles for field
            className={cx(fieldProps.select.className, c.input)}
            name={validateName('searchOptions')}
            label={'Search'}
            placeholder={`Search for skill or ${isFreelancer ? 'freelancer' : 'employee'}`}
            options={shownSkills.map(toOptionType)}
            isMulti
            isCreatable
            isClearable={false}
            onChange={(tokens) => !!(tokens || values.searchOptions) && setTimeout(submitForm, 0)}
          />

          {isFreelancer && (
            <FormControlLabel
              className={c.check}
              control={
                <Field
                  name={validateName('isReplaceable')}
                  checked={values.isReplaceable} // manual rewiring
                  onChange={(ev, checked) => {
                    setFieldValue(validateName('isReplaceable'), checked);
                    setTimeout(submitForm, 0); // 🙈
                  }}
                  component={Checkbox}
                />
              }
              label={'is replaceable'}
            />
          )}

          {(values.searchOptions?.length > 0 || (isFreelancer && values.isReplaceable)) && (
            <IconButton
              size="small"
              onClick={() => {
                onReset();
                setTimeout(resetForm, 0); // need next cycle to update formik's state
              }}
              children={<Icon name="close" />}
            />
          )}

          {!isFreelancer && isVWTaskForce && (
            <FormControlLabel
              className={c.check}
              control={
                <Field
                  name={validateName('isVWSkill')}
                  checked={isVWSkills} // manual rewiring
                  onChange={(ev, checked) => {
                    checked ? changeToVWSkills() : changeToMHPSkills();
                    setFieldValue(validateName('isVWSkill'), checked);
                    onReset();
                    setTimeout(resetForm, 0); // need next cycle to update formik's state
                  }}
                  component={Checkbox}
                />
              }
              label={'VW Skill'}
            />
          )}
        </form>
      )}
    </Formik>
  );
};
