import {makeStyles, Snackbar, SnackbarContent} from '@material-ui/core';
import {Field} from 'formik';
import React, {useEffect, useState} from 'react';
import {ButtonMailTo, CriteriaForm, Modal, SkillList} from '.';
import {Model, Skill, UpdateStatus} from '../models';
import {getFieldProps, getFormSectionStyles, notWithId, replaceIn, withId, withName} from '../utils';
import {fromOptionType, OptionType, toOptionType} from './AutoCompleteField';
import {FormikProps} from 'formik/dist/types';
import {useDispatch} from "react-redux";
import {CriteriaFormModel} from "./CriteriaList";
import {createModel, editModel} from "../store/mixed";


const useStyles = makeStyles(
    (theme) => ({
        skills: {
            marginTop: 0,
        },
        error: {
            color: theme.palette.error.main,
            fontSize: 14,
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        requestButton: {
            marginRight: theme.spacing(0),
            marginLeft: theme.spacing(2),
            whiteSpace: 'nowrap',
            minWidth: 'auto',
        },
        ...getFormSectionStyles(theme),
    }),
    {name: 'SkillManager'}
);

type Props = {
    name: string;
    targetName: string;
    skills: Skill[];
    onChange?: (skill: Skill) => void;
    onSetCurrentSkills?: (skill: Skill[]) => void;
    className?: string;
    showRequestButton?: boolean;
    isCreatable?: boolean;
    updateStatus: UpdateStatus;
    form: FormikProps<any>;
};

export const SkillManager = ({name, targetName, skills, onSetCurrentSkills, onChange, isCreatable, showRequestButton, className, updateStatus, form,}: Props): JSX.Element => {
    const fieldProps = getFieldProps(updateStatus && updateStatus.isPending);

    const c = useStyles({});
    const [isValid, setIsValid] = useState(true);
    const {setFieldValue, values} = form;
    const currentSkills: Skill[] = values[targetName];

    // merge selected skills with the stats from all skills
    const skillsWithStats =
        currentSkills?.map((item) =>
            item.id
                ? {
                    ...item,
                    status: skills.find(withId(item.id))?.status,
                    stats: skills.find(withId(item.id))?.stats,
                }
                : item
        ) ?? [];


    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState<Model>(undefined);
    const dispatch = useDispatch();
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const createOrEditCriteria = (request: CriteriaFormModel) => {
        selectedCriteria ? dispatch(editModel.request(request)) : dispatch(createModel.request(request));
        setIsCreateModalOpen(false);
        setSelectedCriteria(undefined);
        setIsRequestSubmitted(true);
    };
    const onCloseModel = () => {
        setIsCreateModalOpen(false);
        setSelectedCriteria(undefined);
    };

    useEffect(() => {
        // dispatch fetchSkill for each skill without stats
        if (onChange && skillsWithStats.length) {
            skillsWithStats.filter((item) => !item.stats?.length).forEach(onChange);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const skillRequestContent = `
  - Skill name:
  
  - Skill description:
  `;

    return (
        <div className={className}>
            <div className={c.wrapper}>
                <Field
                    className={c.section}
                    field={{name: targetName, value: values[name]}} // rewiring happens here
                    form={{
                        ...form,
                        setFieldValue: undefined
                    }} // override setFieldValue since we call it manually in belows onChange
                    isCreatable={isCreatable}
                    formatCreateLabel={'Request'}
                    label={'Skills'}
                    placeholder={'Choose a Skill'}
                    {...fieldProps.select}
                    onChange={(value: OptionType) => {
                        if (value) {
                            if (!currentSkills.some(withName(value.label))) {
                                const newSkill = fromOptionType(value) as Skill;
                                setFieldValue(targetName, currentSkills.concat({...newSkill, level: 1}), true);
                                setFieldValue(name, ''); // clear this field
                                setIsValid(true);
                                if (onSetCurrentSkills) {
                                    onSetCurrentSkills([...currentSkills, newSkill]);
                                }
                                if (onChange) {
                                    // callback to fetch skill details in PositionForm
                                    onChange(newSkill);
                                }
                            } else {
                                setFieldValue(name, ''); // clear this field
                                setIsValid(false);
                            }
                        }
                    }}
                    // filter out skills that are already selected
                    options={skills.filter(({id}) => !currentSkills.find(withId(id))).map(toOptionType)}
                />
                {showRequestButton && (

                    <ButtonMailTo
                        className={c.requestButton}
                        text={'Request Skill'}
                        onClick={() => setIsCreateModalOpen(true)}
                    />
                )}
            </div>

            {!onChange && !isValid && (
                <div className={c.section}>
                    <h2 className={c.error}>{'Looks like you already have this skill on your profile.'}</h2>
                </div>
            )}

            <SkillList
                className={onChange && c.skills}
                sectionTitle={'Your Skills'}
                skills={skillsWithStats}
                columns={2}
                showStats={!!onChange}
                onChange={(skill) => {
                    const newCurrentSkills = replaceIn(currentSkills, withName(skill.name), () => skill);
                    setFieldValue(targetName, newCurrentSkills, true);
                    if (onSetCurrentSkills) {
                        onSetCurrentSkills(newCurrentSkills);
                    }
                }}
                onRemove={(skillId) => {
                    const newCurrentSkills = currentSkills.filter(notWithId(skillId));
                    setFieldValue(targetName, newCurrentSkills, true);
                    if (onSetCurrentSkills) {
                        onSetCurrentSkills(newCurrentSkills);
                    }
                }}
            />


            <Modal isOpen={isCreateModalOpen} onClose={onCloseModel} headline={"Request Skill"}>
                {isCreateModalOpen && (
                    <CriteriaForm
                        isUserRequest={true}
                        onCancel={onCloseModel}
                        onSubmit={createOrEditCriteria}
                        type={"skills"}
                        title={"Skill"}
                        criteria={selectedCriteria}
                    />
                )}
            </Modal>


            <Snackbar
            open={isRequestSubmitted}
            autoHideDuration={2000}
            onClose={() => setIsRequestSubmitted(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            color={"primary"}
            >
                <SnackbarContent
                    message={"Your request has been submitted"}
                    style={{backgroundColor:"#749cde"}}
                />
            </Snackbar>



        </div>


    );
};
