import {Epic} from 'redux-observable';
import {concatMap, map, mergeMap, switchMap} from 'rxjs/operators';
import {
    sendEmail,
    createModel,
    editModel,
    fetchFAQ,
    fetchModel,
    fetchModels,
    fetchOrganizationTreeRoot,
    filterModels,
    removeModel,
} from '.';
import {Services} from '../../services';
import {catchErrorAndHandleWithAction, filterAction} from '../../utils';
import {RootAction, RootState} from '../rootReducer';

const fetchModelsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, {mixedService}) =>
    action$.pipe(
        filterAction(fetchModels.request),
        // don't cancel pending requests, order is irrelevant
        mergeMap(({payload: type}) =>
            mixedService.getModels(type).pipe(
                concatMap((data) => [fetchModels.success({type, data}), filterModels({type})]),
                catchErrorAndHandleWithAction(fetchModels.failure, {optData: {type}})
            )
        )
    );

const fetchModelEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, {mixedService}) =>
    action$.pipe(
        filterAction(fetchModel.request),
        // don't cancel pending requests, order is irrelevant
        mergeMap(({payload: {type, id}}) =>
            mixedService.getModel(id).pipe(
                map((data) => fetchModel.success({type, data})),
                catchErrorAndHandleWithAction(fetchModel.failure, {optData: {type}})
            )
        )
    );

const editModelEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, {mixedService}) =>
    action$.pipe(
        filterAction(editModel.request),
        // don't cancel pending requests, order is irrelevant
        mergeMap(({payload: {id, status, type, name}}) =>
            mixedService.editModel({id, status, type, name}).pipe(
                concatMap((data) => [editModel.success({type, data, status}), filterModels({type})]),
                catchErrorAndHandleWithAction(editModel.failure, {optData: {type}})
            )
        )
    );

const removeModelEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, {mixedService}) =>
    action$.pipe(
        filterAction(removeModel.request),
        mergeMap(({payload: {id, type}}) =>
            mixedService.removeModel(id).pipe(
                concatMap(() => [removeModel.success({id, type}), filterModels({type})]),
                catchErrorAndHandleWithAction(removeModel.failure, {optData: {type}})
            )
        )
    );

const createModelEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, {mixedService}) =>
    action$.pipe(
        filterAction(createModel.request),
        // don't cancel pending requests, order is irrelevant
        mergeMap(({payload: {status, type, name, requestMessage}}) =>
            mixedService.createModel({status, type, name, requestMessage}).pipe(
                concatMap((data) => [createModel.success({type, data}), filterModels({type})]),
                catchErrorAndHandleWithAction(createModel.failure, {optData: {type}})
            )
        )
    );

const fetchOrganizationTreeRootEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    {mixedService}
) =>
    action$.pipe(
        filterAction(fetchOrganizationTreeRoot.request),
        switchMap(() =>
            mixedService
                .getOrganizationTreeRoot()
                .pipe(map(fetchOrganizationTreeRoot.success), catchErrorAndHandleWithAction(fetchOrganizationTreeRoot.failure))
        )
    );

const fetchFAQsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, {faqService}) =>
    action$.pipe(
        filterAction(fetchFAQ.request),
        switchMap(() => faqService.getFAQ().pipe(map(fetchFAQ.success), catchErrorAndHandleWithAction(fetchFAQ.failure)))
    );

const sendEmailEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, {mixedService}) =>
    action$.pipe(
        filterAction(sendEmail.request),
        switchMap(({payload}) =>
            mixedService.sendEmail(payload).pipe(map(sendEmail.success), catchErrorAndHandleWithAction(sendEmail.failure))
        )
    );

export const mixedEpics = [
    createModelEpic,
    editModelEpic,
    fetchFAQsEpic,
    fetchModelsEpic,
    fetchModelEpic,
    fetchOrganizationTreeRootEpic,
    removeModelEpic,
    sendEmailEpic,
];
