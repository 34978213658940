// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RoleDTO
 */
export interface RoleDTO {
    /**
     * @type {string}
     * @memberof RoleDTO
     */
    name: RoleDTONameEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RoleDTONameEnum {
    TeamLead = 'TeamLead',
    ProjectLead = 'ProjectLead',
    ProjectUnit = 'ProjectUnit',
    ResourceManager = 'ResourceManager',
    PeopleDevelopment = 'PeopleDevelopment',
    SuperAdmin = 'SuperAdmin',
    Admin = 'Admin',
    VwTaskForce = 'VwTaskForce',
    Employee = 'Employee',
    PeopleCounselor = 'PeopleCounselor',
    Qa = 'QA'
}

