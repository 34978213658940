import React from 'react';
import { PrivacyPolicyText } from './PrivacyPolicyText';
import { Modal } from './Modal';
import { Button, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import { Logo } from './Logo';

const useStyles = makeStyles(
  (theme) => ({
    submit: {
      textAlign: 'center',
    },
    logo: {
      fill: theme.palette.text.primary,
    },
    logoWrapper: {
      backgroundColor: theme.palette.common.white,
      position: 'fixed',
      textAlign: 'center',
      padding: theme.spacing(2),
      borderRadius: 4,
      top: 0,
      left: 0,
      right: 0,
      zIndex: 5000,
    },
    textWrapper: {
      marginTop: 50,
    },
  }),
  { name: 'PrivacyPolicyOverlay' }
);

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const PrivacyPolicyOverlay = ({ isOpen, onConfirm, onClose }: Props): JSX.Element => {
  const c = useStyles({});

  return (
    <Modal isOpen={isOpen} onClose={onClose} hasNoActions>
      <div className={cx(c.logoWrapper)}>
        <Logo className={cx(c.logo)} />
      </div>
      <PrivacyPolicyText className={cx(c.textWrapper)} />
      <div className={cx(c.submit)}>
        <Button onClick={onConfirm}>Continue</Button>
      </div>
    </Modal>
  );
};
