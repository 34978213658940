import { Environment } from './environment.model';

/* eslint-disable no-template-curly-in-string */
export const environment: Environment = {
  version: '{{MHP_RELEASE_VERSION}}',
  build: '7651829759',
  release: '7651829759-release',
  environment: '{{MHP_ENVIRONMENT}}',
  api: '{{MHP_API}}',
  features: {
    showRoleButtons: '{{MHP_SHOW_ROLE_BUTTONS}}',
    useRedirectLogin: '{{MHP_USE_REDIRECT_LOGIN}}',
  },
  redirectLogout: '{{MHP_LOGOUT_LINK}}',
  matomoUrl: '{{MHP_MATOMO_URL}}',
  matomoId: '{{MHP_MATOMO_ID}}',
};
