import { getType } from 'typesafe-actions';
import { LOCAL_STORAGE_USER_KEY, MAX_SKILL_LEVEL_FALLBACK } from '../../constants';
import { Config, UpdateStatus, User } from '../../models';
import { initialUpdateStatus } from '../../utils';
import * as fromActions from './actions';

type State = {
  readonly config: Config;
  readonly user: User; // TODO: change to Entity<User>
  readonly userStatus: UpdateStatus;
  readonly userUpdateStatus: UpdateStatus;
};

export const DEFAULT_CONFIG: Config = {
  maxSkillLevel: MAX_SKILL_LEVEL_FALLBACK,
  backendVersion: undefined,
  activeFeatures: [],
};

const initialState: State = {
  config: DEFAULT_CONFIG,
  user: JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY)),
  userStatus: initialUpdateStatus,
  userUpdateStatus: initialUpdateStatus,
};

const storeUser = (user: User) => localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
const clearUser = () => localStorage.removeItem(LOCAL_STORAGE_USER_KEY);

export const appReducer = (state = initialState, action: fromActions.AppAction): State => {
  switch (action.type) {
    case getType(fromActions.login.request):
    case getType(fromActions.fetchMe.request):
    case getType(fromActions.signUpOrResetPassword.request):
    case getType(fromActions.logout.request):
    case getType(fromActions.acceptPrivacyPolicy.request):
    case getType(fromActions.changePassword.request):
      return {
        ...state,
        userStatus: { isPending: true, isSuccess: undefined },
      };

    case getType(fromActions.acceptPrivacyPolicy.success):
      const newUser = { ...state.user, hasAcceptedPrivacyPolicy: true };
      storeUser(newUser);

      return {
        ...state,
        user: newUser,
      };

    case getType(fromActions.login.success):
    case getType(fromActions.fetchMe.success):
    case getType(fromActions.changePassword.success):
      storeUser(action.payload);

      return {
        ...state,
        user: action.payload,
        userStatus: { isPending: false, isSuccess: !action.payload.hasToChangePassword },
      };

    case getType(fromActions.login.failure):
    case getType(fromActions.fetchMe.failure):
    case getType(fromActions.acceptPrivacyPolicy.failure):
    case getType(fromActions.signUpOrResetPassword.failure):
    case getType(fromActions.changePassword.failure):
      return {
        ...state,
        userStatus: { isPending: false, error: action.payload },
      };

    case getType(fromActions.signUpOrResetPassword.success):
      return {
        ...state,
        userStatus: { isPending: false, isSuccess: true },
      };

    // -------------------------

    case getType(fromActions.logout.success):
    case getType(fromActions.logout.failure): // NOTE: perhaps treat differently
      clearUser();

      return {
        ...initialState,
        user: undefined,
      };

    // -------------------------

    case getType(fromActions.updateUserEmployee):
      const { payload: employee } = action;
      const user: User = {
        ...state.user,
        employee,
        isValid: employee.isValid,
      };
      storeUser(user);

      return {
        ...state,
        user,
      };

    // -------------------------

    case getType(fromActions.fetchConfig.success):
      // TODO: maybe persist in local storage
      return {
        ...state,
        config: action.payload,
      };

    // -------------------------

    case getType(fromActions.resetSuccess):
      return {
        ...state,
        userStatus: initialUpdateStatus,
        userUpdateStatus: initialUpdateStatus,
      };

    // -------------------------

    case getType(fromActions.hideAppFeedback):
      const newUser1 = {
        ...state.user,
        showAppFeedback: false,
      };
      storeUser(newUser1);
      return {
        ...state,
        user: newUser1,
      };

    default:
      return state;
  }
};
