import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  differenceInDays,
  endOfISOWeek,
  endOfMonth,
  endOfWeek,
  format,
  getISODay,
  getISOWeek,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  parseJSON,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMinutes,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  CALENDAR_WEEK_FORMAT,
  DEFAULT_DATE_FORMAT,
  DETAILED_DATE_FORMAT,
  MAX_WORKING_HOURS_PER_WEEK,
} from '../constants';
import { Employee, JobTitle } from '../models';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formatCurrency = (money: number) =>
  Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(money);

export const formatDate = (date: Date) => format(parseJSON(date), DEFAULT_DATE_FORMAT);
export const formatMonth = (date: Date) => months[date.getMonth()] + ' ' + date.getFullYear();
export const formatDetailedDate = (date: Date) => format(date, DETAILED_DATE_FORMAT);
export const formatCalendarWeek = (date: Date) => format(date, CALENDAR_WEEK_FORMAT);

export const formatDateForApi = (date: Date) => {
  const offset = date.getTimezoneOffset();
  return subMinutes(startOfDay(date), offset).toISOString();
};

export const formatName = ({ firstName, lastName }: Pick<Employee, 'firstName' | 'lastName'>) =>
  `${firstName} ${lastName}`;

export const formatJobTitleFilter = (minTitle: JobTitle, maxTitle: JobTitle) =>
  minTitle
    ? maxTitle
      ? `${minTitle.name} - ${maxTitle.name}`
      : `${minTitle.name} +`
    : maxTitle
    ? `${maxTitle.name} -`
    : 'All Levels';

export const isPastDate = (date: Date) => isBefore(startOfDay(date), startOfDay(new Date()));

export const isFutureDate = (date: Date) => isAfter(startOfDay(date), startOfDay(new Date()));

export const isBetweenDates = (date1: Date, date2: Date) =>
  differenceInDays(startOfDay(date1), startOfDay(date2)) === 0;

export const getFirstDateInMonth = (start: Date): Date => zonedTimeToUtc(startOfMonth(start), 'UTC');

export const getLastDateInMonth = (start: Date): Date => zonedTimeToUtc(endOfMonth(start), 'UTC');

export const getDays = (start: Date, end: Date) => differenceInDays(end, start) + 1;

export const getWorkDays = (start: Date, end: Date) => {
  let startDate = startOfDay(start);
  const endDate = startOfDay(end);

  let count = 0;
  while (startDate <= endDate) {
    if (getISODay(startDate) < 6) {
      count++;
    }
    startDate = addDays(startDate, 1);
  }

  return count;
};

export const getWorkDaysWithHours = (start: Date, end: Date, hoursPerWeek: number) =>
  Math.round(((getWorkDays(start, end) * hoursPerWeek) / MAX_WORKING_HOURS_PER_WEEK) * 100) / 100;

export const getMonths = (start: Date, end: Date): { date: Date; isCurrent: boolean }[] => {
  const months: { date: Date; isCurrent: boolean }[] = [];
  const currentMonth = startOfMonth(new Date());

  let m = startOfMonth(start);
  while (!isAfter(startOfMonth(m), startOfMonth(end))) {
    months.push({ date: m, isCurrent: isSameMonth(m, currentMonth) });
    m = addMonths(m, 1);
  }

  // if we don't have a isCurrent month yet, we use the first or last
  if (!months.filter((item) => item.isCurrent).length) {
    months[isBefore(currentMonth, start) ? 0 : months.length - 1].isCurrent = true;
  }

  return months;
};

export type Week = {
  week: number;
  isCurrentWeek?: boolean;
  days: {
    date: Date;
    isPlanned?: boolean;
    isInMonth?: 'current' | 'next' | 'previous';
    isInWeek?: boolean;
    isToday?: boolean;
  }[];
};

export const getWeeksPerMonth = (month: Date): Week[] => {
  const weeks: Week[] = [];

  const monthStart = startOfMonth(month);
  const monthEnd = endOfMonth(month);

  let start = startOfISOWeek(monthStart);
  let end = endOfISOWeek(monthEnd);

  // add some buffer before and after to skip weekends and get a total of 6 weeks
  if (differenceInCalendarDays(monthStart, start) < 2) {
    start = subDays(start, 7);
  }
  if (differenceInCalendarDays(end, monthEnd) < 4) {
    end = addDays(end, 7);
  }

  const today = getToday();
  const weekStart = startOfWeek(today);
  const weekEnd = endOfWeek(today);

  let calendarWeek: number;
  let tmpWeek: Week;

  let isInMonth: boolean;
  let isInPreviousMonth: boolean;
  let isInNextMonth: boolean;
  let isInWeek: boolean;
  let isToday: boolean;

  while (!isAfter(start, end)) {
    calendarWeek = getISOWeek(start);

    if (!tmpWeek || tmpWeek.week !== calendarWeek) {
      if (tmpWeek && tmpWeek.days.length) {
        weeks.push(tmpWeek);
      }

      tmpWeek = {
        week: calendarWeek,
        isCurrentWeek: calendarWeek === getISOWeek(today) && isBetween(today, monthStart, monthEnd),
        days: [],
      };
    }

    // ignore weekends
    if (getISODay(start) < 6) {
      isInMonth = isBetween(start, monthStart, monthEnd);
      isInPreviousMonth = isBefore(startOfDay(start), startOfDay(monthStart));
      isInNextMonth = isAfter(startOfDay(start), startOfDay(monthEnd));
      isInWeek = isBetween(start, weekStart, weekEnd);
      isToday = isSameDay(start, today);

      tmpWeek.days.push({
        date: start,
        ...(isInMonth && { isInMonth: 'current' }),
        ...(isInPreviousMonth && { isInMonth: 'previous' }),
        ...(isInNextMonth && { isInMonth: 'next' }),
        ...(isInMonth && isInWeek && { isInWeek }),
        ...(isInMonth && isToday && { isToday }),
      });
    }

    start = addDays(start, 1);
  }
  weeks.push(tmpWeek); // last week

  return weeks;
};

const isBetween = (dateToCompare, start, end) =>
  isWithinInterval(startOfDay(dateToCompare), {
    start: startOfDay(start),
    end: startOfDay(end),
  });

export const getPlannedDaysPerWeekInMonth = (start: Date, end: Date, month: Date): Week[] =>
  getWeeksPerMonth(month).map((week) => ({
    ...week,
    days: week.days.map((day) => ({
      ...day,
      ...(isBetween(day.date, start, end) && { isPlanned: true }),
    })),
  }));

export const capitalizeFirstLetter = (input: string) => input.charAt(0).toUpperCase() + input.slice(1);

export const getToday = (): Date => startOfDay(new Date());
