import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

const employeesState = (state: RootState) => state.employees;

export const getEmployees = createSelector([employeesState], (employees) => employees.employees);

export const getPagination = createSelector([employeesState], (employees) => employees.pagination);

export const getProjectLeads = createSelector([employeesState], (employees) => employees.projectLeads);

export const getInternalEmployees = createSelector([employeesState], (employees) => employees.internalEmployees);

export const getEmployeesSorting = createSelector([employeesState], (employees) => employees.sorting);

export const getActiveMonth = createSelector([employeesState], (employees) => employees.activeMonth);

export const getEmployeesFilter = createSelector([employeesState], (employees) => employees.filter);

export const getDownloadUrl = createSelector([employeesState], (employees) => employees.downloadUrl);
