import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Position } from '../models';
import { Icon } from './Icon';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    current: {
      fontWeight: theme.typography.fontWeightBold,
    },
    icon: {
      marginLeft: 5,
      color: theme.palette.icon,
    },
  }),
  { name: 'PositionCounter' }
);

type Props = {
  positions?: Position[];
  staffedPosition?: number;
  totalPosition?: number;
  hideIcon?: boolean;
  className?: string;
};

export const PositionCounter = ({
  positions,
  staffedPosition,
  totalPosition,
  hideIcon,
  className,
}: Props): JSX.Element => {
  const c = useStyles({});
  return (
    <div className={cx(c.root, className)}>
      <span className={c.current}>
        {positions ? positions.filter(({ employee }) => employee).length : staffedPosition}
      </span>
      &nbsp;/&nbsp;
      {positions ? positions.length : totalPosition}
      {!hideIcon && <Icon className={c.icon} name="person" />}
    </div>
  );
};
