export const LOCAL_STORAGE_USER_KEY = 'user';
export const AUTH_HEADER_KEY = 'Authorization';

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
export const DETAILED_DATE_FORMAT = 'eeee, MMMM dd yyyy';
export const CALENDAR_WEEK_FORMAT = 'w';
export const MIN_WORKING_HOURS_PER_WEEK = 0;
export const MAX_WORKING_HOURS_PER_WEEK = 40;
export const MIN_SKILLS = 1;
export const MAX_SKILLS = 30;
export const MAX_SKILL_LEVEL_FALLBACK = 3;
export const MAX_DESCRIPTION_LENGTH = 255;

export const MATOMO_EVENT_CATEGORY_POSITIONS = 'Positions';
export const MATOMO_EVENT_ACTION_OPEN_POS_CREATED = 'Create open position';
export const MATOMO_EVENT_ACTION_DELETE_POSITION_LESS_THAN_2_HOURS = 'Delete open position less than 2 hours';
export const MATOMO_EVENT_ACTION_FETCH_SUGGESTIONS = 'Fetch suggestions';

export const MATOMO_EVENT_ENTERPRISE_SEARCH = 'Enterprise Search';
export const MATOMO_EVENT_ACTION_SEND_EMAIL = 'Send email to PC';
export const MATOMO_EVENT_ACTION_DOWNLOAD = 'Download open position attachment';


