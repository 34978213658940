import {createStyles, IconButton, makeStyles, Paper, Tooltip} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import cx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AvatarListItem, FormattedName, Icon, PositionCounter, SkillList, TitleValueItem } from '.';
import { getTruncateStyles } from '../layout';
import {Employee, Position, Project, ProjectHistory, Skill} from '../models';
import {canRead, formatDate, formatJobTitleFilter, getToday} from '../utils';
import { useSelector } from 'react-redux';
import { getConfig, getUser } from '../store/app';
import { Star, StarBorder } from '@material-ui/icons';
import { IconType } from './Icon';
import { environment as env } from '../environments';
import {MATOMO_EVENT_ACTION_SEND_EMAIL} from "../constants";
import {useToasts} from "react-toast-notifications";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        boxShadow: theme.customShadows.card,
        marginTop: theme.spacing(2.5),
        '&:first-child': { margin: 0 },
      },
      rootLink: {
        cursor: 'pointer',
        '&:hover': {
          boxShadow: theme.customShadows.cardHover,
          '& $editBtn': { display: 'block' },
        },
      },
      rootCompact: {
        marginTop: theme.spacing(1),
        '& $main': { padding: theme.spacing(0, 0, 0, 2.5) },
        '& $body': { height: 64 },
        '& $colCta': { margin: theme.spacing(1, 0) },
      },
      rootCompactPaper: {
        marginBottom: theme.spacing(1),
        marginTop: 0,
      },
      rootNoPadding: {
        '& $main': { padding: theme.spacing(0, 0, 0, 0) },
      },
      rootActive: {
        background: theme.palette.divider,
      },
      rootWithBorder: {
        borderLeft: `5px solid ${theme.palette.divider}`,
      },
      rootWithBorderRed: {
        borderColor: theme.palette.error.main,
      },
      rootWithBorderYellow: {
        borderColor: theme.palette.accent.yellow,
      },
      rootWithBorderGreen: {
        borderColor: theme.palette.accent.green,
      },
      main: {
        flex: 1,
        minWidth: 0,
        padding: theme.spacing(0, 2),
      },
      attachmentDiv: {
        display: 'flex',
        flexDirection: 'row',
      },
      attachmentField: {
        display: 'flex',
        fontSize: 15,
        margin: theme.spacing(2),
        border: `1px solid ${theme.palette.accent.skill}`,
        borderRadius: 10,
        width: 330,
      },
      head: {
        height: 50,
        fontSize: 14,
        borderBottom: `1px solid ${theme.palette.divider}`,
        position: 'relative',
      },
      body: {
        height: 75,
        position: 'relative',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      col: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(2),
        width: '25%',
      },
      colEmployeeTitle: {
        width: '40%',
      },
      colPositionTitle: {
        width: '30%',
      },
      colPosition: {
        width: '20%',
      },
      colNarrow: {
        width: '10%',
      },
      colTitle: {
        width: '50%',
      },
      colCta: {}, // ref for override
      ...getTruncateStyles(),
      title: {
        fontSize: 14,
      },
      avatarName: { marginBottom: 5 },
      editBtn: {
        display: 'none',
        position: 'absolute',
        right: 0,
      },
      positionTitle: {
        fontSize: 16,
        justifyContent: 'space-between',
      },
      colScore: {
        backgroundColor: theme.palette.accent.decent,
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      applyText: {
        padding: theme.spacing(5),
        textAlign: 'right',
        color: theme.palette.text.secondary,
        fontSize: 15,
        width: '60%',
      },
      innerPadding: {
        padding: theme.spacing(0, 2.5),
      },
      score: {
        color: theme.palette.common.white,
        padding: '8px 16px',
      },
      scoreGreen: {
        color: theme.palette.accent.green,
      },
      tile: {
        height: '5px',
        backgroundColor: theme.palette.accent.green,
      },
      scoreYellow: {
        color: theme.palette.accent.yellow,
      },
      scoreGray: {
        color: theme.palette.text.secondary,
      },
      like: {
        backgroundColor: 'yellow',
      },
      matchIndicator: {
        fontSize: 24,
      },
      skills: {
        fontSize: 11,
        width: '100%',
        height: 'auto',
        padding: '16px 32px',
      },
      backgroundWhite: {
        backgroundColor: theme.palette.common.white,
      },
      positionsTabs: {
        color: theme.palette.text.secondary,
        fontSize: 14,
        display: 'flex',
        height: 'inherit',
        alignItems: 'center',
        justifyContent: 'center',
      },
      detailsRows: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
      },
      positionTabsContainer: {
        display: 'grid',
        height: '64px',
        borderTop: `0px`,
        gridTemplateColumns: 'repeat(4, 3fr)',
        gridGap: '3px',
        width: '100%',
      },
      rootWrap: {
        width: '200px',
      },
      link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: theme.palette.text.secondary,
      },
      likeButton: {
        width: '30px',
      },
      shortViewStyles: {
        display: 'flex',
        gap: '15px',
        justifyContent: 'right',
        padding: '5px 20px 20px 20px',
      },
      shortViewIcon: {
        padding: '5px',
      },
      shortViewLink: {
        fontSize: 13,
        color: theme.palette.text.secondary,
      },
        iconText: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            fontSize: 12,
            marginRight: 15,
            padding: 3,
        },
        contactShareIcons: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        multiLine: {
            fontWeight: theme.typography.fontWeightRegular,
        },
        rowProjectHistory: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        colProjectHistory: {
            display: 'flex',
            gap: 5,
        },
        description: {
            display: 'block',
            fontSize: 12,
            width: '50%',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        rootNotClickable: {
            cursor: 'default',
        },
        projectHistoryEmptyButton: {
            marginRight: 52,
        },
    }),
  { name: 'ListItem' }
);

type Props<T> = {
  data: T;
  type: 'employee' | 'open-position' | 'my-position' | 'project' | 'criteria' | 'projectHistory';
  isEmployeeSuggestion?: boolean;
  displayProjectLabel?: boolean;
  isActive?: boolean;
  url?: string;
  projectUrl?: string;
  onClick?: (params: T) => void;
  onEdit?: (params: T) => void;
  onConfirm?: (params: T) => void;
  onDecline?: (params: T) => void;
  onRemove?: (params: T) => void;
};

type SharedProps = {
  c: ReturnType<typeof useStyles>;
  onEditClick?: (e: React.MouseEvent) => void;
  rootProps: {
    component: React.ElementType<React.HTMLAttributes<HTMLDivElement>>;
    to?: string;
    onClick?: (e: React.MouseEvent) => void;
  };
  projectLink: {
    component: React.ElementType<React.HTMLAttributes<HTMLDivElement>>;
    to?: string;
  };
  className?: string;
  type: 'employee' | 'open-position' | 'my-position' | 'project' | 'criteria' |'projectHistory';
};

const useCtaStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 50,
        margin: theme.spacing(2, 0),
        color: theme.palette.text.secondary,
      },
      rootBorder: {
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
    }),
  { name: 'ListItemButton' }
);

const ListItemButton = ({
  showIcon,
  className,
  onClick,
  onEditClick,
  iconName,
}: {
  iconName: IconType;
  showIcon?: boolean;
  onClick?: () => void;
  onEditClick?: (e: React.MouseEvent) => void;
  className?: string;
}): JSX.Element => {
  const c = useCtaStyles({});
  return (
    <>
      {onClick ? (
      <Tooltip title={iconName === 'add' ? "Add" : iconName === 'delete' ? "Delete" : "Decline"}>
        <IconButton
          onClick={onClick}
          className={cx(c.root, { [c.rootBorder]: showIcon }, className)}
          children={showIcon && <Icon name={iconName} fontSize="small" />}
        />
      </Tooltip>
      ) : onEditClick ? (
      <Tooltip title={"Edit"}>
        <IconButton
          onClick={onEditClick}
          className={cx(c.root, { [c.rootBorder]: showIcon }, className)}
          children={showIcon && <Icon name={iconName} fontSize="small" />}
        />
      </Tooltip>
      ) : (
        <div
          onClick={onClick}
          className={cx(c.root, { [c.rootBorder]: showIcon }, className)}
          children={showIcon && <Icon name={iconName} fontSize="large" />}
        />
      )}
    </>
  );
};

const EmployeeListItem = ({
  c,
  skillScore,
  capacityScore,
  capacityScoreNextMonth,
  organization,
  isExternal,
  location,
  isEmployeeSuggestion,
  withAvailability,
  like,
  rootProps,
  className,
  ...employee
}: Employee & SharedProps & { isEmployeeSuggestion?: boolean; withAvailability?: boolean }): JSX.Element => {
  const { activeFeatures } = useSelector(getConfig);
    const currentMonth = getToday();
    const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);

    const months = [
        currentMonth.toLocaleString('en-US', { month: 'long' }),
        nextMonth.toLocaleString('en-US', { month: 'long' })
    ];



  const metas: { title: string; value: React.ReactNode }[] = isEmployeeSuggestion
    ? [
        { title: 'Skill', value: skillScore >= 0 && `${skillScore} %` },
        ...(withAvailability ? [{ title: 'Availability', value: capacityScore >= 0 && `${capacityScore} %` }] : []),
        { title: 'Residence', value: employee.placeOfResidence },
        {
          title: 'People Counselor',
          value:
            employee.peopleCounselor &&
            `${employee.peopleCounselor.firstName?.charAt(0)}. ${employee.peopleCounselor.lastName}`,
        },
      ]
    : [
        {
          title: 'Department',
          value: organization?.name,
        },
        { title: 'Office Location', value: location?.name },
        { title: 'Free Capacity: ' + months[0], value: capacityScore >= 0 && `${capacityScore} %` },
        { title: 'Free Capacity: ' + months[1], value: capacityScoreNextMonth >= 0 && `${capacityScoreNextMonth} %` },
      ];
  const borderColor = isEmployeeSuggestion
    ? {
        [c.rootWithBorderRed]: skillScore >= 0,
        [c.rootWithBorderYellow]: skillScore >= 50,
        [c.rootWithBorderGreen]: skillScore >= 80,
      }
    : {
        [c.rootWithBorderRed]: capacityScore <= 20,
        [c.rootWithBorderYellow]: capacityScore > 20 && capacityScore <= 60,
        [c.rootWithBorderGreen]: capacityScore > 60,
      };

  return (
    <Paper
      className={cx(c.root, c.rootWithBorder, capacityScore != null && !isExternal && borderColor, className)}
      {...rootProps}
      style={{ pointerEvents: 'none' }}
    >
      <div className={c.main} style={{ pointerEvents: 'auto' }}>
        <div className={cx(c.row, c.body)}>
          <div
            className={cx(c.col, { [c.colEmployeeTitle]: isEmployeeSuggestion })}
            children={<AvatarListItem {...employee} size="medium" withJobTitle withAvatar/>}
          />
          {metas.map((item, index) => (
            <TitleValueItem key={index} {...item} className={cx(c.col, { [c.colPosition]: isEmployeeSuggestion })} />
          ))}
          <div className={cx(c.likeButton)}>
            {like && activeFeatures.includes('TINDER') ? <Star style={{ fill: '#ffc000' }} /> : ''}
          </div>
        </div>
      </div>
      {!isEmployeeSuggestion && (
        <span style={{ pointerEvents: 'auto' }}><ListItemButton iconName={'chevronRight'} className={c.colCta} showIcon={!!rootProps.to} /></span>
      )}
      {isEmployeeSuggestion && (
          <span onClick={() => window.open("/employees/"+employee.id, "_blank")} style={{ pointerEvents: 'auto' }}>
              <ListItemButton
                  iconName={'chevronRight'}
                  className={c.colCta}
                  showIcon={!rootProps.to}
              />
          </span>
      )}
    </Paper>
  );
};

const CriteriaListItem = ({
  c,
  rootProps,
  className,
  name,
  status,
  createdAt,
  requestMessage,
  createdBy,
  updatedBy,
  updatedAt,
  onConfirm,
  onDecline,
  onRemove,
  onEditClick,
}: Skill & SharedProps & { onConfirm: () => void; onDecline: () => void; onRemove: () => void }): JSX.Element => {
  const metas = [
    { title: '', value: name, fullValueOnHover: true },
    { title: 'Requested By', value: createdBy, isNarrow: false },
    ...(status === 'pending'
      ? [
          { title: 'Requested At', value: formatDate(createdAt), isNarrow: false },
          { title: 'Requested Message', value: requestMessage, isNarrow: false, fullValueOnHover: true },
        ]
      : [
          { title: 'Evaluated By', value: updatedBy, isNarrow: false },
          { title: 'Evaluated At', value: formatDate(updatedAt), isNarrow: false },
        ]),
  ];
  return (
    <Paper className={cx(c.root, className)} {...rootProps}>
      <div className={c.main}>
        <div className={cx(c.row, c.body)}>
          {metas.map((item, index) => (
            <TitleValueItem key={index} {...item} className={cx(c.col, { [c.colNarrow]: item.isNarrow })} />
          ))}
        </div>
      </div>
      {status === 'pending' && (
        <ListItemButton onClick={onConfirm} iconName={'add'} className={c.colCta} showIcon={true} />
      )}
      {status === 'pending' && (
        <ListItemButton onClick={onDecline} iconName={'close'} className={c.colCta} showIcon={true} />
      )}
      {onEditClick && (
        <ListItemButton onEditClick={onEditClick} iconName={'edit'} className={c.colCta} showIcon={true} />
      )}
      {status === 'decline' && (
        <ListItemButton onClick={onRemove} iconName={'delete'} className={c.colCta} showIcon={true} />
      )}
    </Paper>
  );
};

const PositionListItem = ({
                              id,
  c,
  project,
  role,
  startDate,
  endDate,
  minJobTitle,
  maxJobTitle,
  skillScore,
  skills,
  like,
  created,
  description,
  languages,
  workingHoursPerWeek,
  attachments,
  onEditClick,
  visibility,
  type,
  rootProps,
  projectLink,
  className,
}: Position & SharedProps): JSX.Element => {
  const { activeFeatures } = useSelector(getConfig);
  const [expandedSkills, setExpandedSkills] = useState(false);
  const [expandedDetails, setExpandedDetails] = useState(false);
  const [expandedDescription, setExpandedDescription] = useState(false);
  const [expandedAttachments, setExpandedAttachments] = useState(false);
  //TODO: move to root component
  const currentUsersSkills = useSelector(getUser)?.employee?.skills ?? [];
  const daysPerWeek = Math.floor(workingHoursPerWeek / 8);
  const metas: { title: string; value: React.ReactNode; isNarrow?: boolean }[] = [
    { title: 'Client', value: project && `${project.client?.name ?? '-'}` },
    { title: 'Duration', value: `${formatDate(startDate)} – ${formatDate(endDate)}` },
    { title: 'Project', value: project && `${project.name}` },
    {
      title: 'Project Lead',
      value: project && `${project.projectLead.firstName} ${project.projectLead.lastName}`,
      isNarrow: true,
    },
  ];

  const scoreColor = {
    [c.scoreGray]: skillScore >= 50 && skillScore < 75,
    [c.scoreGreen]: skillScore >= 75,
  };
    const { addToast } = useToasts();

  const formattedJobTitleWithLocation = () =>
    project?.location
      ? `${formatJobTitleFilter(minJobTitle, maxJobTitle)}  |  ${project?.location} `
      : formatJobTitleFilter(minJobTitle, maxJobTitle);

  const getFullUrl = (url: string) => `${env.api.replace(/\/+$/, '')}${url}`;
    async function copyToClip(event) {
        event.stopPropagation();
        event.preventDefault()

        await navigator.clipboard.writeText(window.location.href+"/" + id);
        addToast('Link Copied!', {
            appearance: 'success',
            autoDismiss: true,
        });
    }
  return <>
    <Paper
      className={cx(c.root, c.rootCompact, c.rootNoPadding, className)}
      {...(activeFeatures.includes('POSITION_DETAIL') ? '' : { ...projectLink })}
    >
      <div className={c.main}>
        {skillScore >= 75 ? <div className={cx(c.tile)}></div> : ' '}
        <div className={cx(c.row, c.body, c.positionTitle)}>
          <AvatarListItem
            className={cx(c.col, c.colTitle, c.innerPadding)}
            firstName={role}
            jobTitle={{ id: '0', name: formattedJobTitleWithLocation() }} // abusing this field
            size="medium"
            withJobTitle
          />

            <div className={c.contactShareIcons}>
                {type === 'open-position' &&
                    <div>
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (process.env.NODE_ENV === 'production') {
                                    window._paq.push(['trackEvent', MATOMO_EVENT_ACTION_SEND_EMAIL]);
                                }
                            }}
                            href={`mailto:${project?.projectLead.email}?subject=${encodeURIComponent(
                                'Skill Matching | Applicant for your open positions'
                            )}`}
                        >
                            <Icon name={'mail'} />
                            <span className={c.iconText}>contact</span>
                        </IconButton>
                    </div>
                }
                <div>
                    <IconButton size="small" onClick={copyToClip}>
                        <Icon name={'share'} />
                        <span className={c.iconText}>share</span>
                    </IconButton>
                </div>
                {type === 'open-position' && skillScore >= 50 && <div className={cx(c.score, scoreColor)}>
              <span className={cx(c.matchIndicator)}>
                <b>
                  <i>match</i>
                </b>
              </span>
                </div>}
                {type === 'my-position' && <div className={cx(c.score, c.scoreGray)}>
              <span className={cx(c.matchIndicator)}>
                <b>
                  <i>{visibility ? 'public' : 'private'}</i>
                </b>
              </span>
                </div>}

            </div>
        </div>


        {activeFeatures.includes('POSITION_DETAIL') && <div className={cx(c.shortViewStyles)}>
            <IconButton className={cx(c.shortViewIcon)} {...projectLink}>
              <span className={cx(c.shortViewLink)}>View project</span>
              <Icon name="chevronRight" />
            </IconButton>
            <IconButton className={cx(c.shortViewIcon)} {...rootProps}>
              <span className={cx(c.shortViewLink)}>View position</span>
              <Icon name="chevronRight" />
            </IconButton>
          </div>}

        <div className={cx(c.row, c.body, c.innerPadding)}>
          {metas.map((item, index) => <TitleValueItem
              key={index}
              {...item}
              className={cx(c.col, c.colPosition, { [c.colNarrow]: item.isNarrow })}
            />)}
          <div className={cx(c.likeButton)}>
            {skillScore >= 50 && onEditClick && activeFeatures.includes('TINDER') && <IconButton onClick={onEditClick}>
                {like ? <Star style={{ fill: '#ffc000' }} /> : <StarBorder />}
              </IconButton>}
          </div>
        </div>
      </div>
    </Paper>

    <Paper className={cx(c.root, c.rootCompactPaper, c.rootNoPadding)}>
      <div className={c.main}>
        <div className={cx(c.row, c.colScore, c.positionTabsContainer)}>
          <div className={cx(c.positionsTabs, expandedSkills ? c.backgroundWhite : '')}>
            <IconButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setExpandedSkills(!expandedSkills);
                setExpandedDescription(false);
                setExpandedAttachments(false);
                setExpandedDetails(false);
              }}
            >
              <Icon name={expandedSkills ? 'chevronUp' : 'chevronDown'} />
            </IconButton>
            <span>
              <b>Skills</b>&nbsp;({skills.length})
            </span>
          </div>
          <div className={cx(c.positionsTabs, expandedDetails ? c.backgroundWhite : '')}>
            <IconButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setExpandedDetails(!expandedDetails);
                setExpandedDescription(false);
                setExpandedAttachments(false);
                setExpandedSkills(false);
              }}
            >
              <Icon name={expandedDetails ? 'chevronUp' : 'chevronDown'} />
            </IconButton>
            <span>
              <b>Details</b>
            </span>
          </div>
          <div className={cx(c.positionsTabs, expandedDescription ? c.backgroundWhite : '')}>
            <IconButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setExpandedDescription(!expandedDescription);
                setExpandedDetails(false);
                setExpandedAttachments(false);
                setExpandedSkills(false);
              }}
            >
              <Icon name={expandedDescription ? 'chevronUp' : 'chevronDown'} />
            </IconButton>
            <span>
              <b>Description</b>
            </span>
          </div>
          <div className={cx(c.positionsTabs, expandedAttachments ? c.backgroundWhite : '')}>
            <IconButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setExpandedAttachments(!expandedAttachments);
                setExpandedDescription(false);
                setExpandedDetails(false);
                setExpandedSkills(false);
              }}
            >
              <Icon name={expandedAttachments ? 'chevronUp' : 'chevronDown'} />
            </IconButton>
            <span>
              <b>Attachments</b> &nbsp;({attachments.length})
            </span>
          </div>
        </div>
        {expandedSkills && <div className={cx(c.row, c.body, c.skills)}>
            <SkillList skills={skills} skillsOverlap={currentUsersSkills} />
          </div>}
        {expandedDetails && <div className={cx(c.detailsRows, c.body, c.innerPadding)}>
            <TitleValueItem
              {...{ hasLineBreaks: true, title: 'Published since', value: `${formatDate(created)}`, isNarrow: true }}
            />
            <TitleValueItem {...{ hasLineBreaks: true, title: 'Days per week', value: daysPerWeek }} />
            <TitleValueItem
              {...{
                hasLineBreaks: true,
                title: 'Languages',
                value: languages.map(({ id, name }, index) => (
                  <span>
                    {index ? ', ' : ''}
                    {name}
                  </span>
                )),
              }}
            />
          </div>}
        {expandedAttachments && <div className={cx(c.attachmentDiv)}>
            {attachments?.map((attachment) => (
              <div className={cx(c.row, c.innerPadding, c.attachmentField)}>
                <TitleValueItem
                  {...{
                    className: c.rootWrap,
                    hasLineBreaks: false,
                    title: '',
                    value: <div key={attachment.id} children={attachment.fileName} />,
                  }}
                />
                <IconButton>
                  <a className={c.link} href={getFullUrl(attachment.link)} download>
                    <GetAppIcon />
                  </a>
                </IconButton>
              </div>
            ))}
            {expandedAttachments && attachments.length === 0 && (
              <div className={cx(c.row, c.body, c.innerPadding)}>No attachments</div>
            )}
          </div>}
        {expandedDescription && <div className={cx(c.row, c.body, c.innerPadding)}>
            <TitleValueItem
              {...{ hasLineBreaks: true, title: 'Project Description', value: description && `${description}` }}
            />
          </div>}
      </div>
    </Paper>
  </>;
};

const ProjectListItem = ({
                             c,
                             name,
                             projectNumber,
                             startDate,
                             endDate,
                             client,
                             location,
                             manDays,
                             projectLead,
                             staffedPositions,
                             totalPositions,
                             label,
                             rootProps,
                             displayProjectLabel,
                             className,
                             status,
                         }: Project & SharedProps & { displayProjectLabel?: boolean }): JSX.Element => {
    const metas: { title: string; value: React.ReactNode }[] =
        status === 'opportunity'
            ? [
                { title: 'Project Lead', value: <FormattedName {...projectLead} /> },
                { title: 'Location', value: location },
                { title: 'Man Days', value: manDays },
            ]
            : [
                { title: 'Client', value: client?.name },
                { title: 'Project Lead', value: <FormattedName {...projectLead} /> },
                { title: 'Location', value: location },
                { title: 'Man Days', value: manDays },
            ];

    return (
        <Paper
            className={cx(
                c.root,
                displayProjectLabel && {
                    [c.rootWithBorder]: true,
                    [c.rootWithBorderRed]: label === 'critical',
                },
                className
            )}
            {...rootProps}
        >
            <div className={c.main}>
                <div className={cx(c.row, c.head)}>
                    <div className={cx(c.col, c.colTitle)}>
                        <h2 className={cx(c.title, c.truncate)}>{name}</h2>
                    </div>
                    {status !== 'opportunity' && <div className={cx(c.col)}>#{projectNumber}</div>}
                    <div className={c.col}>
                        {formatDate(startDate)} - {formatDate(endDate)}
                    </div>
                    {staffedPositions && totalPositions && (
                        <PositionCounter className={c.col} staffedPosition={staffedPositions} totalPosition={totalPositions} />
                    )}
                </div>
                <div className={cx(c.row, c.body)}>
                    {metas.map((item, index) => (
                        <TitleValueItem key={index} {...item} className={c.col} />
                    ))}
                </div>
            </div>
            <ListItemButton iconName={'chevronRight'} className={c.colCta} showIcon={!!rootProps.to} />
        </Paper>
    );
};


const ProjectHistoryListItem = ({
                                    c,
    realProjectId,
                                    name,
                                    startDate,
                                    endDate,
                                    client,
                                    location,
                                    manDays,
                                    projectLead,
                                    rootProps,
                                    displayProjectLabel,
                                    className,
                                    role,
                                    projectHistoryDescription,
                                    isFromRealProject,
                                }: ProjectHistory & SharedProps & { displayProjectLabel?: boolean }): JSX.Element => {
    // todo: check if visitor is employ if not do not show client?
    // const metas: { title: string; value: React.ReactNode }[] =
    //     !isFromRealProject
    //         ? [
    //             { title: 'Project Lead', value: <FormattedName {...projectLead} /> },
    //             { title: 'Location', value: location },
    //             { title: 'Man Days', value: manDays },
    //         ]
    //         : [
    //             { title: 'Client', value: client?.name },
    //             { title: 'Project Lead', value: <FormattedName {...projectLead} /> },
    //             { title: 'Location', value: location },
    //             { title: 'Man Days', value: manDays },
    //         ];
    const metas: { title: string; value: React.ReactNode }[] =
        [
            { title: 'Client', value: client },
            { title: 'Project Lead', value: projectLead },
            { title: 'Location', value: location },
            { title: 'Man Days', value: manDays },
        ];

    async function openProject(event) {
        event.stopPropagation();
        event.preventDefault();
        if (isFromRealProject){
            window.location.href = "/projects/" + realProjectId;
        }
    }
    return (
        <Paper
            className={cx(
                c.root,
                {
                    [c.rootNotClickable]: !isFromRealProject,
                },
                displayProjectLabel && {
                    [c.rootWithBorder]: true,
                    [c.rootWithBorderRed]: false,
                },
                className
            )}
            {...(isFromRealProject ? rootProps : {})}
        >
            <div className={c.main} >
                <div className={cx(c.rowProjectHistory, c.head)}>
                    <div className={cx(c.colProjectHistory)}>
                        <div className={cx(c.col, c.colTitle)}>
                            <h2 className={cx(c.title, c.truncate)}>{name}</h2>
                        </div>
                        <div className={cx(c.col, c.colTitle)}>
                            <h2 className={cx(c.title, c.truncate)}>{role}</h2>
                        </div>
                        <div className={cx(c.colProjectHistory, c.colTitle)}>
                            <div>
                                {startDate? formatDate(startDate): "-"}
                            </div>
                            <div >
                                -
                            </div>
                            <div >
                                {endDate? formatDate(endDate): "-"}
                            </div>
                        </div>

                    </div>
                    <div className={cx(c.colProjectHistory, c.description, c.truncate)}
                         children={projectHistoryDescription}
                         title={projectHistoryDescription}/>

                </div>
                <div className={cx(c.row, c.body)}>
                    {metas.map((item, index) => (
                        <TitleValueItem key={index} {...item} className={c.col} />
                    ))}
                </div>
            </div>
            {isFromRealProject ? (
                <ListItemButton iconName={'chevronRight'} className={c.colCta} showIcon={!!rootProps.to} />
            ):
                <div className={c.projectHistoryEmptyButton}></div>
            }

        </Paper>
    );
};

export const ListItem = <T extends object>({
  data,
  type,
  isActive,
  url,
  projectUrl,
  onClick,
  onEdit,
  onConfirm,
  onDecline,
  onRemove,
  ...other
}: Props<T>): JSX.Element => {
  const c = useStyles({});

  const onItemClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(data);
  };

  const onEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onEdit(data);
  };

  const isLink =
    type === 'project' || type === 'open-position' || type === 'my-position'
      ? canRead((data as Position | Project).permissions)
      : true;

  const props: SharedProps = {
    onEditClick: onEdit ? onEditClick : undefined,
    rootProps: {
      component: isLink && url ? Link : (undefined as any), // eslint-disable-line @typescript-eslint/no-explicit-any
      to: isLink && url ? url : undefined,
      onClick: onClick ? onItemClick : undefined,
    },
    projectLink: {
      component: isLink && projectUrl ? Link : (undefined as any), // eslint-disable-line @typescript-eslint/no-explicit-any
      to: isLink && projectUrl ? projectUrl : undefined,
    },
    className: cx({
      [c.rootLink]: (isLink && url) || onClick,
      [c.rootCompact]: type !== 'project',
      [c.rootActive]: isActive,
    }),
    type,
    ...other,
    c,
  };

  return type === 'employee' ? (
    <EmployeeListItem {...(data as Employee)} {...props} />
  ) : type === 'open-position' || type === 'my-position' ? (
    <PositionListItem {...(data as Position)} {...props} />
  ) : type === 'project' ? (
      <ProjectListItem {...(data as Project)} {...props} />
  ): type === 'projectHistory' ? (
      <ProjectHistoryListItem {...(data as ProjectHistory)} {...props} />
  ) : (
    type === 'criteria' && (
      <CriteriaListItem
        onConfirm={() => onConfirm(data)}
        onDecline={() => onDecline(data)}
        onRemove={() => onRemove(data)}
        {...(data as Skill)}
        {...props}
      />
    )
  );
};
