import { createSelector } from 'reselect';
import { TEMP_POSITION_ID } from '.';
import { RootState } from '../rootReducer';

const projectState = (state: RootState) => state.project;

export const getProject = createSelector([projectState], ({ project }) => ({
  ...project,
  // filter out temporary positions to not have them appear in list
  data: project.data
    ? {
        ...project.data,
        positions: project.data?.positions.filter((item) => item.id !== TEMP_POSITION_ID),
      }
    : undefined,
}));

export const getProjectUpdateStatus = createSelector([projectState], (project) => project.updateStatus);

export const getPotentialEmployees = createSelector([projectState], (project) => project.potentialEmployees);

export const getActivePosition = createSelector([projectState], ({ project: { data: project } }) =>
  project?.positions.find((item) => item.isActive)
);
