import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Certificate, Employee, UpdateStatus } from '../models';
import { fetchModels, getCertificates } from '../store/mixed';
import { EmployeeCertificatesForm } from './EmployeeCertificatesForm';

type Props = {
  employee: Employee;
  updateStatus: UpdateStatus;
  onSubmit: (params: Certificate[]) => void;
  onCancel: () => void;
};

export const EmployeeCertificatesFormContainer = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { confirm: certificates } = useSelector(getCertificates);

  useEffect(() => {
    if (!certificates.length) {
      dispatch(fetchModels.request('certificates'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <EmployeeCertificatesForm {...props} certificates={certificates} showRequestButton />;
};
