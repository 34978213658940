// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    CriteriaDTO,
    DepartmentDTO,
    FreeCapacityDTO,
    TitleDTO,
} from './';

/**
 * @export
 * @interface EmployeeDTO
 */
export interface EmployeeDTO {
    /**
     * @type {string}
     * @memberof EmployeeDTO
     */
    id?: string;
    /**
     * @type {string}
     * @memberof EmployeeDTO
     */
    firstName?: string;
    /**
     * @type {string}
     * @memberof EmployeeDTO
     */
    lastName?: string;
    /**
     * @type {string}
     * @memberof EmployeeDTO
     */
    email?: string;
    /**
     * @type {string}
     * @memberof EmployeeDTO
     */
    profilePic?: string;
    /**
     * @type {TitleDTO}
     * @memberof EmployeeDTO
     */
    title?: TitleDTO;
    /**
     * @type {Array<CriteriaDTO>}
     * @memberof EmployeeDTO
     */
    skills?: Array<CriteriaDTO>;
    /**
     * @type {DepartmentDTO}
     * @memberof EmployeeDTO
     */
    department?: DepartmentDTO;
    /**
     * @type {number}
     * @memberof EmployeeDTO
     */
    workingHoursPerWeek?: number;
    /**
     * @type {string}
     * @memberof EmployeeDTO
     */
    residence?: string;
    /**
     * @type {Array<CriteriaDTO>}
     * @memberof EmployeeDTO
     */
    languages?: Array<CriteriaDTO>;
    /**
     * @type {CriteriaDTO}
     * @memberof EmployeeDTO
     */
    location?: CriteriaDTO;
    /**
     * @type {Array<CriteriaDTO>}
     * @memberof EmployeeDTO
     */
    certificates?: Array<CriteriaDTO>;
    /**
     * @type {boolean}
     * @memberof EmployeeDTO
     */
    external?: boolean;
    /**
     * @type {string}
     * @memberof EmployeeDTO
     */
    status?: EmployeeDTOStatusEnum;
    /**
     * @type {number}
     * @memberof EmployeeDTO
     */
    capacity?: number;
    /**
     * @type {number}
     * @memberof EmployeeDTO
     */
    capacityNextMonth?: number;
    /**
     * @type {Array<string>}
     * @memberof EmployeeDTO
     */
    permissions?: Array<EmployeeDTOPermissionsEnum>;
    /**
     * @type {boolean}
     * @memberof EmployeeDTO
     */
    userValid?: boolean;
    /**
     * @type {boolean}
     * @memberof EmployeeDTO
     */
    replaceable?: boolean;
    /**
     * @type {Array<FreeCapacityDTO>}
     * @memberof EmployeeDTO
     */
    freeCapacities?: Array<FreeCapacityDTO>;
    /**
     * @type {EmployeeDTO}
     * @memberof EmployeeDTO
     */
    peopleCounselor?: EmployeeDTO;
    /**
     * @type {EmployeeDTO}
     * @memberof EmployeeDTO
     */
    teamLead?: EmployeeDTO;
}

/**
 * @export
 * @enum {string}
 */
export enum EmployeeDTOStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}
/**
 * @export
 * @enum {string}
 */
export enum EmployeeDTOPermissionsEnum {
    Create = 'CREATE',
    Read = 'READ',
    Update = 'UPDATE',
    Delete = 'DELETE'
}

