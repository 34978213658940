import { Button } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { object, string } from 'yup';
import { UpdateStatus } from '../models';
import { getFieldProps } from '../utils';
import { Icon } from './Icon';
import { SimpleFormActions, useSimpleFormStyles } from './LoginForm';

type Props = {
  mode: 'signup' | 'reset';
  onSubmit: (pass: string) => void;
  onCancel?: () => void;
  updateStatus: UpdateStatus;
};

type SignUpFormModel = { email: string };
const validateName = (key: keyof SignUpFormModel) => key;

export const SignUpForm = ({ mode, onSubmit, ...props }: Props): JSX.Element => {
  const c = useSimpleFormStyles({});
  const initialValues: SignUpFormModel = {
    email: '',
  };

  const validationSchema = object({
    email: string().email('Must enter a valid email address').required('Email is required'),
  });

  const {
    onCancel,
    updateStatus: { isPending, isSuccess },
  } = props;

  const fieldProps = getFieldProps(isPending).text;
  const isSignUpMode = mode === 'signup';

  return (
    <div className={c.root}>
      {!isSuccess ? (
        <>
          {isSignUpMode ? (
            <>
              <h2 className={c.title}>{"You don't have an account?"}</h2>
              <p className={c.intro}>
                {'Simply enter your company email address below and we will send you a one time password.'}
              </p>
            </>
          ) : (
            <>
              <h2 className={c.title}>{'You forgot your password?'}</h2>
              <p className={c.intro}>
                {'No problem. We will send you a new one time password to your company email account.'}
              </p>
            </>
          )}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={({ email }, { setSubmitting }) => {
              setSubmitting(false);
              onSubmit(email);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field {...fieldProps} name={validateName('email')} label={'Email'} placeholder={'john.doe@mhp.com'} />

                <SimpleFormActions {...props} ctaLabel={isSignUpMode ? 'Sign Up' : 'Reset Password'} c={c} />
              </form>
            )}
          </Formik>
        </>
      ) : (
        <div className={c.success}>
          <Icon className={c.successIcon} name="star" />
          <p className={c.intro}>{"We've sent you an email with a one time password."}</p>
          <Button onClick={onCancel} children={'Back to Login'} />
        </div>
      )}
    </div>
  );
};
