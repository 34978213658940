import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
    HttpError,
    Position,
    ProjectPositionMeta,
    PositionFilter,
    PositionStatus,
} from '../../models';
import { PositionFormModel } from '../../components/StepperModal';

export const fetchOpenPositions = createAsyncAction(
  '@positions/FETCH_OPEN_POSITIONS',
  '@positions/FETCH_OPEN_POSITIONS_SUCCESS',
  '@positions/FETCH_OPEN_POSITIONS_FAILURE',
  '@positions/FETCH_OPEN_POSITIONS_CANCEL'
)<PositionFilter, ProjectPositionMeta, HttpError, void>();

export const fetchMyPositions = createAsyncAction(
  '@positions/FETCH_MY_POSITIONS',
  '@positions/FETCH_MY_POSITIONS_SUCCESS',
  '@positions/FETCH_MY_POSITIONS_FAILURE',
  '@positions/FETCH_MY_POSITIONS_CANCEL'
)<PositionFilter, ProjectPositionMeta, HttpError, void>();

export const fetchPosition = createAsyncAction(
  '@positions/FETCH_POSITION',
  '@positions/FETCH_POSITION_SUCCESS',
  '@positions/FETCH_POSITION_FAILURE',
  '@positions/FETCH_POSITION_CANCEL'
)<string, Position, HttpError, void>();

export const createUpdatePosition = createAsyncAction(
  '@positions/CREATE_UPDATE_POSITION',
  '@positions/CREATE_UPDATE_POSITION_SUCCESS',
  '@positions/CREATE_UPDATE_POSITION_FAILURE',
  '@positions/CREATE_UPDATE_POSITION_CANCEL'
)<{ positionId?: Position['id']; request: PositionFormModel; reroute?: string }, Position, HttpError, void>();

export const updatePositionLike = createAsyncAction(
  '@positions/UPDATE_POSITION_LIKE',
  '@positions/UPDATE_POSITION_LIKE_SUCCESS',
  '@positions/UPDATE_POSITION_LIKE_FAILURE',
  '@positions/UPDATE_POSITION_LIKE_CANCEL'
)<Position, void, HttpError, void>();

export const setPositionsStatus = createAction('@projects/SET_POSITIONS_STATUS')<PositionStatus>();
export const resetOpenPositions = createAction('@positions/RESET_OPEN_POSITIONS')();
export const reinitEditPosition = createAction('@positions/REINIT_EDIT_POSITION')<boolean>();

export type PositionsAction =
  | ActionType<typeof fetchPosition>
  | ActionType<typeof fetchOpenPositions>
  | ActionType<typeof fetchMyPositions>
  | ActionType<typeof updatePositionLike>
  | ActionType<typeof createUpdatePosition>
  | ActionType<typeof setPositionsStatus>
  | ActionType<typeof reinitEditPosition>
  | ActionType<typeof resetOpenPositions>;
