import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import cx from 'clsx';
import React, { useRef, useState } from 'react';
import { EmployeeSearchRadius } from '../models';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    label: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightSemiBold,
      marginRight: 5,
    },
    btn: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  }),
  { name: 'SearchRadiusControl' }
);

type Props = {
  setSearchRadius: (radius: EmployeeSearchRadius) => void;
  searchRadius: EmployeeSearchRadius;
  className?: string;
};

export const SearchRadiusControl = ({ searchRadius, setSearchRadius, className }: Props): JSX.Element => {
  const c = useStyles({});

  const menuRef = useRef<HTMLButtonElement>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  const onSetRadius = (radius: EmployeeSearchRadius) => () => {
    setSearchRadius(radius);
    closeMenu();
  };

  return (
    <div className={cx(c.root, className)}>
      <div className={c.label} children={'Search Radius:'} />
      <Button
        ref={(ref) => (menuRef.current = ref)}
        className={c.btn}
        variant="text"
        size="small"
        onClick={openMenu}
        children={searchRadius}
      />

      <Menu
        anchorEl={menuRef.current}
        getContentAnchorEl={undefined}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {Object.keys(EmployeeSearchRadius).map((item) => (
          <MenuItem
            key={item}
            disabled={item === searchRadius}
            onClick={onSetRadius(EmployeeSearchRadius[item])}
            children={item}
          />
        ))}
      </Menu>
    </div>
  );
};
