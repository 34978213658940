import { createStyles, makeStyles, Tab, Tabs as MuiTabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getWrapStyles, Main } from '../layout';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSearchCriteria,
  autoCompleteSuggestions,
  changeSkillLevel,
  clearSuggestion,
  enterpriseSearchEmployees,
  getCriteriaOptions,
  getEmployees,
  getPagination,
  getSearchFields,
  removeSearchCriteria,
  resetEnterpriseSearchEmployees,
} from '../store/search';
import { ButtonFloating, EmployeeCard, EnterpriseSearchField, Icon, Loading } from '../components';
import cx from 'clsx';
import { Helmet } from 'react-helmet-async';
import {EmployeeEnterpriseSearch} from "../models";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
      main: {
        top: 0,
      },
      head: {
        top: 0,
        padding: theme.spacing(0, 2, 2, 2),
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        boxShadow: theme.customShadows.fixed,
      },
      headInner: {
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      content: {
        paddingBottom: theme.spacing(3),
      },
      cardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        gridGap: '25px',
        paddingTop: theme.spacing(2),
      },
      noResultContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(6),
        fontWeight: 600,
      },
      serverIcon: {
        paddingBottom: theme.spacing(1),
        width: '96px',
        height: '96px',
      },
      showMoreButton: {
        display: 'flex',
        justifyContent: 'center',
      },
      scrollable: {
        height: '100%',
        overflow: 'auto',
      },
      tabs: {
        fontWeight: 'bold',
        backgroundColor: 'white',
      },
      sticky: {
        zIndex: 5,
        position: 'sticky',
        top: 0,
      },
      helpIconWrapper: {
        display: 'flex',
        marginTop: '16px',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
      },
      formWrapper: {
        display: 'flex',
        justifyContent: 'center',
      },
    }),
  { name: 'Search' }
);

export const SearchPage = (): JSX.Element => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const employees = useSelector(getEmployees);
  const pagination = useSelector(getPagination);
  const searchFields = useSelector(getSearchFields);
  const criteriaOptions = useSelector(getCriteriaOptions);
  const { data, isLoading } = employees;

  const [showCriteriaField, setShowCriteriaField] = useState(true);
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const [requiredSkills, setRequiredSkills] = useState(null);


  useEffect(() => {
    loadData();
    let requiredSkills = searchFields
      .filter((item: EmployeeEnterpriseSearch) => item.searchType === 'Skill')
      .length > 0 ? searchFields.filter((item: EmployeeEnterpriseSearch) => item.searchType === 'Skill') : null;
    setRequiredSkills(requiredSkills);
  }, [searchFields]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    if (searchFields.length > 0) {
      dispatch(
        enterpriseSearchEmployees.request({
          page: 1,
          criteria: searchFields,
        })
      );
    } else {
      dispatch(resetEnterpriseSearchEmployees());
    }
  };

  const loadMore = () => {
    dispatch(
      enterpriseSearchEmployees.request({
        page: pagination.currentPage + 1,
        criteria: searchFields,
      })
    );
  };

  const scrollToTop = () => {
    document.getElementById('scrollable').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onScroll = () => {
    const scrollTop = document.getElementById('scrollable')?.scrollTop;
    const offsetHeight = document.getElementById('scrollable')?.offsetHeight;
    const scrollHeight = document.getElementById('scrollable')?.scrollHeight;
    scrollTop > 200 ? setShowFloatingButton(true) : setShowFloatingButton(false);
    if (scrollTop + offsetHeight > scrollHeight * 0.8 && !isLoading && pagination.currentPage < pagination.totalPages) {
      loadMore();
    }
  };

  return (
    <Main className={c.main}>
      <Helmet title={'Search'} />
      <div className={c.scrollable} id={'scrollable'} onScroll={onScroll}>
        <header className={c.head}>
          <div className={cx(c.wrap, c.headInner)}>
            {searchFields.map((field, index) => (
              <EnterpriseSearchField
                useLogic
                showLogic={index > 0}
                searchField={field}
                onDelete={() => {
                  dispatch(removeSearchCriteria(index));
                }}
                onLevelChange={(skill) => {
                  dispatch(changeSkillLevel({ index, level: skill.level }));
                }}
                key={index}
              />
            ))}
            <div className={c.formWrapper}>
              {showCriteriaField && (
                <EnterpriseSearchField
                  useLogic
                  showLogic={searchFields.length > 0}
                  criteriaOptions={criteriaOptions}
                  onInputChange={(keyword) =>
                    keyword
                      ? dispatch(
                          autoCompleteSuggestions.request({
                            keyword,
                            types: ['Level', 'Skill', 'Certificate', 'Location', 'Language', 'Employee', 'Project', 'Capacity'],
                          })
                        )
                      : dispatch(clearSuggestion())
                  }
                  onSubmit={(field) => {
                    dispatch(addSearchCriteria(field));
                    //workaround for recreating component. TODO fix it
                    setShowCriteriaField(false);
                    setShowCriteriaField(true);
                  }}
                />
              )}
              {/*TODO *search field form is to small with entered criteria*/}
              {/*<Tooltip*/}
              {/*  TransitionComponent={Zoom}*/}
              {/*  title="You can search for Skill, Certificate, Language, Office Location, Employee, Career Level"*/}
              {/*  placement="bottom"*/}
              {/*  interactive*/}
              {/*  disableTouchListener*/}
              {/*>*/}
              {/*  <div className={c.helpIconWrapper}>*/}
              {/*    <Icon name="help" />*/}
              {/*  </div>*/}
              {/*</Tooltip>*/}
            </div>
          </div>
        </header>
        <div className={cx(c.tabs, c.sticky)}>
          <MuiTabs
            value={'employees'}
            indicatorColor="primary"
            textColor="primary"
            centered
            children={[
              <Tab label={`Employees (${pagination?.totalElements ?? 0})`} value={'employees'} key={0} />,
              <Tab label={'Positions (coming soon)'} value={1} disabled key={1} />,
              <Tab label={'Projects (coming soon)'} value={2} disabled key={2} />,
            ]}
          />
        </div>
        <div className={cx(c.wrap, c.content)}>
          {searchFields.length === 0 ? (
            <div className={c.noResultContainer}>
              <Icon className={c.serverIcon} name="serverIcon" />
              Add search criteria to find matching employees.
            </div>
          ) : data.length > 0 ? (
            <div className={c.cardContainer}>
              {data.map((employee, index) => (
                <div key={index}>
                  <EmployeeCard employee={employee} requiredSkills={requiredSkills}/>
                </div>
              ))}
            </div>
          ) : (
            <div className={c.noResultContainer}>
              <Icon className={c.serverIcon} name="serverIcon" />
              There are no search result for your current search request.
            </div>
          )}
          {isLoading && <Loading />}
          {showFloatingButton && <ButtonFloating onClick={scrollToTop} icon="chevronUp" />}
        </div>
      </div>
    </Main>
  );
};
