import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { AutoCompleteField } from './AutoCompleteField';
import { createStyles, IconButton, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { getTruncateStyles, getWrapStyles } from '../layout';
import { Icon } from './Icon';
import { AutoCompleteSuggestions, EmployeeEnterpriseSearch, LogicType, Skill } from '../models';
import cx from 'clsx';
import { SkillLevel } from './SkillLevel';
import { AutoCompleteOption } from './AutoCompleteOption';
import {withId} from "../utils";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
      ...getTruncateStyles(),
      form: {
        display: 'flex',
        fontSize: 16,
        marginTop: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
      },
      inputLogic: {
        width: '200px',
        border: 0,
        '& fieldset': {
          border: 0,
          borderRadius: 0,
          borderRight: `1px solid ${theme.palette.divider}`,
        },
      },
      inputCriteria: {
        width: '650px',
        '& fieldset': {
          border: 0,
        },
      },
      inputFullWidth: {
        width: '100%',
      },
      outputLogic: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        height: '50px',
      },
      outputCriteria: {
        display: 'flex',
        alignItems: 'center',
        height: '50px',
        justifyContent: 'space-between',
        '&:first-child': {
          borderRight: `1px solid ${theme.palette.divider}`,
        },
      },
      outputCriteriaFullWidth: {
        '&:first-child': {
          borderRight: 'none',
        },
      },
      criteriaLabel: {
        display: 'flex',
        width: '80%',
        padding: theme.spacing(1),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      skillName: {
        display: 'inline-block',
        position: 'relative',
        padding: theme.spacing(1),
      },
      searchType: {
        marginLeft: 'auto',
        fontSize: '14px',
        borderRadius: '5px',
        backgroundColor: '#ced4de',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
      },
      searchIcon: {
        color: '#279afe',
      },
      deleteButton: {
        margin: theme.spacing(1),
        color: 'red',
      },
      levels: {}, // for reference only
      noOptionsMessage: {
        padding: theme.spacing(1, 2),
      },
    }),
  { name: 'EnterpriseSearchField' }
);

type Props = {
  searchField?: EmployeeEnterpriseSearch;
  criteriaOptions?: AutoCompleteSuggestions[];
  onLevelChange?: (params: Skill) => void;
  onInputChange?: (keyword: string) => void;
  onDelete?: () => void;
  onSubmit?: (value: EmployeeEnterpriseSearch) => void;
  useLogic?: boolean;
  showLogic?: boolean;
  placeholder?: string;
  className?: string;
  skillsOverlap?: Skill[];

};

type SearchFieldRequest = {
  logic: {
    value: LogicType;
    label: string;
  };
  criteria: AutoCompleteSuggestions;
};

export const EnterpriseSearchField = ({
  searchField,
  onInputChange,
  onDelete,
  onSubmit,
  onLevelChange,
  criteriaOptions,
  useLogic,
  showLogic = useLogic,
  // placeholder = 'Search (Skill, Certificate, Language, Office Location, Career Level, Capacity)',
  placeholder = '(Skill, Certificate, Language, Office Location, Employee, Project, Level, Capacity)',
  className,
  skillsOverlap,
  }: Props): JSX.Element => {
  const c = useStyles({});
  const initialValues: SearchFieldRequest = {
    ...(useLogic && {
      logic: {
        value: 'AND',
        label: 'AND',
      },
    }),
    criteria: undefined,
  };

  const [inputValue, setInputValue] = useState<string>(undefined);

  return searchField ? (
    <div className={c.form}>
      {showLogic && (
        <div className={cx(c.outputCriteria, c.inputLogic)}>
          <div className={cx(c.skillName, c.truncate)} children={searchField.logic} />
        </div>
      )}
      <div
        className={cx(
          c.outputCriteria,
          c.inputCriteria,
          !showLogic && useLogic ? [c.inputFullWidth, c.outputCriteriaFullWidth] : [],
          className
        )}
      >
        <div className={c.criteriaLabel}>
          <div className={cx(c.skillName, c.truncate)} children={searchField.criteria.label} />
          {searchField.searchType === 'Skill' && (
            <SkillLevel
              className={c.levels}
              skill={{ name: searchField.criteria.label, level: searchField.level }}
              skillOverlap={(skillsOverlap || []).find(withId(searchField.criteria.value))}
              showOverlap={!!skillsOverlap}
              onLevelChange={onLevelChange}
            />

          )}
        </div>
        <div className={c.searchType}>{searchField.searchType}</div>
        <IconButton size="small" onClick={onDelete} children={<Icon className={c.deleteButton} name={'close'} />} />
      </div>
    </div>
  ) : (
    <Formik
      onSubmit={(values, { setSubmitting }) => {
        const submitValues = {
          ...(useLogic && { logic: values.logic.value }),
          criteria: values.criteria,
          level: values.criteria.type === 'Skill' ? 1 : undefined,
          searchType: values.criteria.type,
        };
        onSubmit(submitValues);
        setSubmitting(false);
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitForm }) => (
        <form onSubmit={handleSubmit}>
          <div className={c.form}>
            {showLogic && (
              <Field
                className={c.inputLogic}
                component={AutoCompleteField}
                name={'logic'}
                isClearable={false}
                isSearchable={false}
                options={[
                  {
                    value: 'AND',
                    label: 'AND',
                  },
                  {
                    value: 'NOT',
                    label: 'NOT',
                  },
                ]}
                dropdownIndicator={() => <Icon className={c.searchIcon} name={'chevronDown'} />}
              />
            )}
            <Field
              className={cx(c.inputCriteria, className)}
              component={AutoCompleteField}
              placeholder={placeholder}
              isClearable={false}
              onInputChange={(value) => {
                setInputValue(value);
                onInputChange(value);
              }}
              disabledFilterOption
              onChange={(value) => {
                value && setTimeout(submitForm, 0);
              }}
              name={`criteria`}
              options={criteriaOptions}
              dropdownIndicator={() => <Icon className={c.searchIcon} name={'search'} />}
              noOptionsMessage={() => (
                <Typography className={c.noOptionsMessage} color="textSecondary">
                  Enter at least 3 letters to see all suggestions
                </Typography>
              )}
              optionStyle={({ innerRef, isFocused, innerProps, data }) => (
                <MenuItem
                  ref={innerRef}
                  selected={isFocused}
                  component="div"
                  {...innerProps}
                  children={<AutoCompleteOption option={data as AutoCompleteSuggestions} inputValue={inputValue} />}
                />
              )}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
