import { alpha, createTheme as createMuiTheme, Theme, ThemeOptions } from '@material-ui/core';
import type * as CSS from 'csstype';
import { colorPalette } from './color-palette';

export const fontFamily = 'Open Sans';

interface ThemeHeader {
  height?: number;
  background?: CSS.Properties['background'];
}

interface ThemeSubNav {
  height?: number;
}

interface ThemeInputs {
  buttonHeight?: number;
  textfieldHeight?: number;
}

interface ThemeCustomShadows {
  card?: string;
  cardHover?: string;
  fixed?: string;
  overlay?: string;
  panel?: string;
}

interface ThemeStepper {
  background: string;
}

// See: https://material-ui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    header: ThemeHeader;
    subnav: ThemeSubNav;
    inputs: ThemeInputs;
    customShadows: ThemeCustomShadows;
    stepper: ThemeStepper;
  }

  // allow configuratiqon using `createMuiTheme`
  interface ThemeOptions {
    header?: ThemeHeader;
    subnav?: ThemeSubNav;
    inputs?: ThemeInputs;
    customShadows?: ThemeCustomShadows;
    stepper?: ThemeStepper;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface FontStyle {
    fontWeightBold?: any;
    fontWeightSemiBold?: any;
    fontWeightExtraBold?: any;
    fontWeightRegular?: any;
  }
}

const createTheme = (options?: ThemeOptions) =>
  createMuiTheme({
    // defaults
    palette: colorPalette,
    typography: {
      fontFamily: `${fontFamily},sans-serif`,
      fontWeightRegular: 400,
      fontWeightSemiBold: 600,
      fontWeightBold: 700,
      fontWeightExtraBold: 800,
    },
    spacing: 8,
    header: {
      height: 56,
      background: colorPalette.header.background,
    },
    subnav: {
      height: 50,
    },
    inputs: {
      buttonHeight: 50,
      textfieldHeight: 50,
    },
    customShadows: {
      card: '1px 3px 6px 2px rgba(0, 0, 0, 0.03)',
      cardHover: '1px 2px 8px 3px rgba(0, 0, 0, 0.08)',
      fixed: '0 2px 4px 0 rgba(0, 0, 0, 0.03)',
      overlay: '0 3px 35px 1px rgba(0, 0, 0, 0.3)',
      panel: '0 1px 1px -1px rgba(0, 0, 0, 0.1)',
    },
    stepper: {
      background: colorPalette.stepper.background,
    },
    // overrides
    ...options,
  });

// NOTE: we have to create a theme first to use its properties when extending it
const defaultTheme = createTheme();

export const theme: Theme = {
  ...defaultTheme,
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiGrid: {
      spacing: 2,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontSize: 12,
        },
        a: {
          textDecoration: 'none',
          color: defaultTheme.palette.text.primary,
        },
        'h1, h2, h3, h4': {
          margin: 0,
          fontWeight: defaultTheme.typography.fontWeightSemiBold,
        },
        'input:-webkit-autofill': {
          '-webkit-box-shadow': `none !important`,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        height: defaultTheme.inputs.buttonHeight,
      },
      containedPrimary: {
        color: defaultTheme.palette.common.white,
      },
      text: {
        height: 'auto',
      },
    },
    MuiIconButton: {
      root: {
        color: defaultTheme.palette.icon,
      },
    },
    MuiInputBase: {
      root: {
        height: defaultTheme.inputs.textfieldHeight,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: `${(defaultTheme.inputs.textfieldHeight - 19) / 2}px 14px`, // 19 is the line height
      },
      multiline: {
        height: '100%',
      },
    },
    MuiFilledInput: {
      root: {
        height: 56,
        backgroundColor: alpha(defaultTheme.palette.common.white, 0.7),
        '&:hover': {
          backgroundColor: alpha(defaultTheme.palette.common.white, 0.85),
        },
        '&$focused': {
          backgroundColor: defaultTheme.palette.common.white,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 17px) scale(1)', // to compensate changes height on field
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: defaultTheme.palette.stepper.background,
      },
    },
    MuiGrid: {
      'spacing-xs-2': {
        // override of the spacing to our needs
        width: `calc(100% + ${defaultTheme.spacing(2)}px)`,
        margin: `0 -${defaultTheme.spacing(1)}px`,
        '& $item': {
          padding: `0 ${defaultTheme.spacing(1)}px`,
        },
        // [defaultTheme.breakpoints.up('sm')]: {
        //     '& $item': {
        //         padding: 15,
        //     },
        // },
      },
    },
  },
};
