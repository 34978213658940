import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CheckboxWithLabel, CheckboxWithLabelProps, TextField, TextFieldProps } from 'formik-material-ui';
import { AutoCompleteField, DatePickerField, FileField } from '../components';
import { FieldArray } from 'formik';

export const getFormSectionStyles = (theme: Theme) =>
  createStyles({
    section: {
      marginTop: theme.spacing(3),
      '&:first-child': { marginTop: 0 },
      width: '100%',
    },
    sectionTitle: {
      fontSize: 14,
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  });

export const getFormStyles = (theme) =>
  createStyles({
    input: {
      width: '100%',
      marginTop: theme.spacing(2),
      '&:first-child': { margin: 0 },
    },
    inputGrid: {
      marginTop: theme.spacing(2),
      '&:first-child': { marginTop: 0 },
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: 0,
      padding: theme.spacing(3),
      borderTop: `1px solid ${theme.palette.divider}`,
      '& button': {
        marginLeft: theme.spacing(2),
        '&:first-child': { margin: 0 },
      },
    },
    hintText: {
      color: '#969ba2',
      margin: 0,
      fontSize: '0.75rem',
      marginTop: '3px',
      fontWeight: theme.typography.fontWeightSemiBold,
      lineHeight: 1.66,
    },
    ...getFormSectionStyles(theme),
  });

const useStyles = makeStyles(getFormStyles, { name: 'Form' });

type FieldVariant = 'standard' | 'filled' | 'outlined';
type FieldPropsType = Partial<Omit<TextFieldProps, 'variant'> & { component: React.ReactNode; variant?: FieldVariant }>;

export const getFieldProps = (
  isLoading: boolean,
  variant: FieldVariant = 'outlined',
  multiline: boolean = false
): {
  text: FieldPropsType;
  textarea: FieldPropsType;
  array: FieldPropsType;
  password: FieldPropsType;
  number: FieldPropsType;
  date: FieldPropsType;
  select: FieldPropsType;
  file: FieldPropsType;
  checkbox: FieldPropsType & DeepPartial<CheckboxWithLabelProps>;
} => {
  const { input: className } = useStyles({}); // eslint-disable-line react-hooks/rules-of-hooks

  const text: FieldPropsType = {
    className,
    fullWidth: true,
    variant,
    type: 'text',
    disabled: isLoading,
    component: TextField,
  };

  const textarea: FieldPropsType = {
    className,
    fullWidth: true,
    variant,
    multiline,
    rows: 4,
    type: 'text',
    disabled: isLoading,
    component: TextField,
  };

  const array: FieldPropsType = {
    className,
    component: FieldArray,
  };

  const password: FieldPropsType = {
    ...text,
    type: 'password',
    placeholder: 'Password',
    inputProps: { autoComplete: 'off' },
  };

  const number: FieldPropsType = {
    ...text,
    type: 'number',
  };

  const date: FieldPropsType & { disableWeekends?: boolean } = {
    ...text,
    disableWeekends: true,
    component: DatePickerField,
  };

  const select: FieldPropsType = {
    ...text,
    component: AutoCompleteField,
  };

  const file: FieldPropsType = {
    ...text,
    component: FileField,
  };

  const checkbox: FieldPropsType & DeepPartial<CheckboxWithLabelProps> = {
    Label: { className },
    disabled: isLoading,
    component: CheckboxWithLabel,
  };

  return {
    text,
    textarea,
    array,
    password,
    number,
    date,
    select,
    file,
    checkbox,
  };
};
