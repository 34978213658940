import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { ListItem, Loading } from '.';
import { EntityArray, Position } from '../models';
import { createUrlWithParams } from '../routes';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(2, 0),
      },
      noResults: {
        padding: theme.spacing(2),
        textAlign: 'center',
      },
    }),
  { name: 'List' }
);

type Props<T> = {
  data: EntityArray<T>;
  type: 'employee' | 'open-position' | 'my-position' | 'project' | 'criteria' | 'projectHistory';
  isEmployeeSuggestion?: boolean;
  withAvailability?: boolean;
  displayProjectLabel?: boolean;
  activeItemId?: string;
  baseUrl?: string;
  projectUrl?: string;
  onClick?: (params: T) => void;
  onEdit?: (params: T) => void;
  handleScroll?: () => void;
  onConfirm?: (params: T) => void;
  onDecline?: (params: T) => void;
  onRemove?: (params: T) => void;
  className?: string;
};

export const List = <T extends { id?: string; realProjectId?: string }>({
  data: dataInput,
  activeItemId,
  baseUrl,
  projectUrl,
  className,
  handleScroll,
  ...props
}: Props<T>) => {
  const c = useStyles({});
  const { data = [], isLoading, error } = dataInput ?? {};

  return (
    <div className={cx(c.root, className)} onScroll={handleScroll} id={handleScroll && 'EmployeeSuggestionList'}>
      {data.map((item) => (
        <ListItem
          key={item.id}
          data={item}
          isActive={activeItemId && activeItemId === item.id}
          projectUrl={
            projectUrl &&
            createUrlWithParams(projectUrl, {
              // position is special since it links to a project
              id:
                props.type === 'open-position' || props.type === 'my-position'
                  ? (item as unknown as Position).project?.id
                  : item.id,
            })
          }
          url={
            baseUrl &&
            createUrlWithParams(baseUrl, {
              // position is special since it links to a project
              id:
                props.type === 'open-position' || props.type === 'my-position'
                  ? (item as unknown as Position).id
                    :
                    props.type === 'projectHistory' ? item?.realProjectId
                  : item.id,
            })
          }
          {...props}
        />
      ))}
      {isLoading ? <Loading /> : !data.length && !error && <div className={c.noResults} children={'-'} />}
    </div>
  );
};
