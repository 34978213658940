import { Button, createStyles, Dialog, makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { ButtonLoading, ErrorBlock } from '.';
import { UpdateStatus } from '../models';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: 480,
        boxShadow: theme.customShadows.overlay,
      },
      head: {
        padding: theme.spacing(3),
        fontSize: 16,
        fontWeight: theme.typography.fontWeightSemiBold,
      },
      body: {
        padding: theme.spacing(0, 3),
        fontSize: 14,
      },
      actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(3),
        '& button': {
          marginLeft: theme.spacing(2),
          '&:first-child': { margin: 0 },
        },
      },
    }),
  { name: 'ConfirmationDialog' }
);

type Props = {
  isOpen: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  onClose: () => void;
  onConfirm: () => void;
  headline?: string;
  updateStatus?: UpdateStatus;
};

export const ConfirmationDialog = ({
  isOpen,
  confirmLabel,
  cancelLabel,
  onClose,
  onConfirm,
  headline,
  children,
  updateStatus,
}: PropsWithChildren<Props>): JSX.Element => {
  const c = useStyles({});
  const isPending = updateStatus?.isPending ?? false;
  const error = updateStatus?.error;

  return (
    <Dialog
      open={isOpen}
      onClose={(e, reason) => {
        if (!isPending && reason !== 'backdropClick') {
          onClose();
        }
      }}
      disableEscapeKeyDown={isPending}
      PaperProps={{
        className: c.root,
      }}
    >
      <header className={c.head} children={headline} />
      <section className={c.body} children={children} />

      <footer className={c.actions}>
        {error && <ErrorBlock inModal {...error} />}
        <Button
          color="default"
          variant="outlined"
          disabled={isPending}
          onClick={onClose}
          children={cancelLabel || 'Cancel'}
        />
        <ButtonLoading color="secondary" disabled={isPending} onClick={onConfirm} children={confirmLabel || 'Delete'} />
      </footer>
    </Dialog>
  );
};
