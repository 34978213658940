import { DeactivatePositionFeedbackDTO, FeedbackControllerApi } from '../proxy';
import { AuthInterceptor } from './auth.interceptor';
import { Observable } from 'rxjs';
import { Feedback, OverviewItem } from '../models';
import { map } from 'rxjs/operators';
import { DashboardService } from './dashboard.service';
import { mapArray } from '../utils';

export type DeactivatePositionFeedback = {
  positionId: string;
  showFeedback: boolean;
};

export class FeedbackService {
  private api = new FeedbackControllerApi(AuthInterceptor.Instance);

  submitFeedback = (data: Feedback): Observable<void> => {
    switch (data.type) {
      case 'delete_position':
        return this.api
          .submitDeletePositionFeedback({ deletePositionFeedbackContent: { user: data.user, content: data.content } })
          .pipe(map(() => undefined));
      case 'deactivate_position':
        return this.api
          .submitDeactivatePositionFeedback({
            deactivatePositionFeedbackContent: { user: data.user, content: data.content, positionId: data.positionId },
          })
          .pipe(map(() => undefined));
      case 'app_rating':
        return this.api
          .submitAppRating({ appRatingFeedbackContent: { user: data.user, rate: data.rate } })
          .pipe(map(() => undefined));
    }
  };

  disableFeedback = (data: Feedback): Observable<void> => {
    switch (data.type) {
      case 'delete_position':
        throw new Error(`unsupported for disable delete_position Feedback`);
      case 'deactivate_position':
        return this.api.disableDeactivatePositionFeedback({ positionId: data.positionId }).pipe(map(() => undefined));
      case 'app_rating':
        return this.api.disableAppRatingFeedback().pipe(map(() => undefined));
    }
  };

  getAppRating = (): Observable<OverviewItem[]> =>
    this.api.getFeedbackOverview().pipe(map(mapArray(DashboardService.toOverview)));

  fetchDeactivatePositionFeedback = (positionIds: string[]): Observable<DeactivatePositionFeedback[]> =>
    this.api.getDeactivatePositionFeedbacks({ positionIds }).pipe(
      map(
        mapArray((data: DeactivatePositionFeedbackDTO) => ({
          positionId: data.positionId,
          showFeedback: data.showFeedback,
        }))
      )
    );
}
