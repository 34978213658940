import {Epic} from "redux-observable";
import {RootAction, RootState} from "../rootReducer";
import {Services} from "../../services";
import {catchErrorAndHandleWithAction, filterAction} from "../../utils";
import {map, switchMap} from "rxjs/operators";
import {uploadFile} from "./actions";

const uploadFileEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    {fileService}
) =>
    action$.pipe(
        filterAction(uploadFile.request),
        switchMap(({payload}) =>
            fileService.uploadFile(payload).pipe(
                map(uploadFile.success),
                catchErrorAndHandleWithAction(uploadFile.failure)
            )
        )
    );

export const fileEpics = [
    uploadFileEpic,
];