import { createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { AutoCompleteSuggestions } from '../models';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
      label: {
        width: '80%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      type: {
        padding: theme.spacing(0, 1),
        marginLeft: 'auto',
        fontSize: '14px',
        borderRadius: '5px',
        backgroundColor: '#ced4de',
      },
      highlight: {
        color: 'blue',
        textDecoration: 'underline',
      },
    }),
  { name: 'AutoCompleteOption' }
);

type Props = {
  option: AutoCompleteSuggestions;
  inputValue?: string;
};

export const AutoCompleteOption = ({ option, inputValue }: Props): JSX.Element => {
  const c = useStyles({});

  useEffect(() => {
    highlight();
  }, [option]); // eslint-disable-line react-hooks/exhaustive-deps

  const [labelValue, setLabelValue] = useState(<span>{option.label}</span>);

  const { label, type } = option;

  const highlight = () => {
    const splitWords = label.split(new RegExp(inputValue, 'ig'));
    const matches = label.match(new RegExp(inputValue, 'ig'));

    setLabelValue(
      <>
        {matches &&
          splitWords.map((value, index) => (
            <span key={index}>
              <span>{value}</span>
              {index < matches.length ? <span className={c.highlight}>{matches[index]}</span> : <></>}
            </span>
          ))}
      </>
    );
  };

  return (
    <div className={c.root}>
      <div className={c.label}>{labelValue}</div>
      <div className={c.type}>{type}</div>
    </div>
  );
};
