import { createStyles, FormControl, makeStyles, Typography } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { Icon } from '../Icon';
import { PositionFormModel } from '../StepperModal';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardGroup: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyItems: 'center',
      gap: 24,
      '& button:nth-child(1)': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        backgroundColor: 'white',
      },
      '& button:nth-child(2)': {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: 'white',
      },
    },
    subTitle: {
      marginTop: 8,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 24,
      '& p': {
        width: 250,
        padding: 5,
      },
    },
    card: {
      width: '250px',
      height: '250px',
      borderRadius: 4,
    },
    icon: {
      width: '64px',
      height: '64px',
    },
    iconActive: {
      '& svg path': {
        fill: '#0C8FFE',
      },
    },
    radioText: {
      position: 'absolute',
      bottom: 5,
      left: 35,
      fontSize: 12,
    },
    radioCircle: {
      position: 'absolute',
      bottom: 7,
      left: 10,
      width: 15,
      height: 15,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '50%',
    },
    description: {
      color: theme.palette.grey[500],
    },
    searchSection: {
      margin: '1rem 0',
    },
    publicAlert: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 48,
      '& p': {
        marginLeft: 24,
        width: 400,
      },
    },
  })
);

export const validationSchemaCreatedPosition = Yup.object().shape(
  {
    contextSelection: Yup.string().required('Please select an option'),
    selectedProject: Yup.object().when('contextSelection', {
      is: 'existing',
      then: Yup.object().required('Please choose a project to continue'),
    }),
  },
  [['contextSelection', 'selectedProject']]
);

export const PositionCreatedStep = () => {
  const { values, setFieldValue } = useFormikContext<PositionFormModel>();
  const validateName = (key: keyof PositionFormModel) => key;
  const c = useStyles(values);

  return (
    <FormControl component="fieldset">
      <ToggleButtonGroup
        className={c.cardGroup}
        value={values.startSelection}
        exclusive
        aria-label="selection"
        onChange={(event, value) => {
          setFieldValue(validateName('positionVisibility'), value);
        }}
      >
        <ToggleButton className={c.card} value={'public'}>
          <Icon
            name="publicPosition"
            className={`${c.icon} ${values.positionVisibility === 'public' ? c.iconActive : ''}`}
          />
          <span
            className={c.radioCircle}
            style={{
              backgroundColor: values.positionVisibility === 'public' ? '#0C8FFE' : '',
              borderColor: values.positionVisibility === 'public' ? '#0C8FFE' : '',
            }}
          />
          <Typography
            className={c.radioText}
            style={{ color: values.positionVisibility === 'public' ? '#0C8FFE' : '' }}
          >
            SAVE AND PUBLISH
          </Typography>
        </ToggleButton>
        <ToggleButton className={c.card} value={'private'}>
          <Icon
            name="privatePosition"
            className={`${c.icon} ${values.positionVisibility === 'private' ? c.iconActive : ''}`}
          />
          <span
            className={c.radioCircle}
            style={{
              backgroundColor: values.positionVisibility === 'private' ? '#0C8FFE' : '',
              borderColor: values.positionVisibility === 'private' ? '#0C8FFE' : '',
            }}
          />
          <Typography
            className={c.radioText}
            style={{ color: values.positionVisibility === 'private' ? '#0C8FFE' : '' }}
          >
            SAVE
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={c.subTitle}>
        <Typography className={c.description} variant="body2">
          Your colleagues can see your position in "Open Positions"
        </Typography>
        <Typography className={c.description} variant="body2">
          Only you can see this position. You can find it e.g. "My Positions"
        </Typography>
      </div>
      {values.positionVisibility === 'public' && (
        <div className={c.publicAlert}>
          <ReportProblemOutlined color="primary" />
          <Typography variant="body2">
            The position will stay public until 3 months after the "Start Date" or until you stuff it
          </Typography>
        </div>
      )}
    </FormControl>
  );
};
