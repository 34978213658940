import { Button, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Icon } from '.';
import cx from 'clsx';
import { sitemap } from '../routes';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        height: 'auto',
        marginRight: theme.spacing(2),
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        transition: 'all ease .3s',
        '&:hover': {
          color: 'white',
          backgroundColor: theme.palette.primary.main,
        },
      },
    }),
  { name: 'ButtonSearch' }
);

const useButtonSearchTextStyles = makeStyles(
  (theme) =>
    createStyles({
      icon: {
        marginRight: theme.spacing(0.5),
      },
    }),
  { name: 'ButtonSearchText' }
);

const useButtonSearchActiveStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        backgroundColor: 'white',
        height: '100%',
        padding: theme.spacing(2),
        cursor: 'default',
      },
    }),
  { name: 'ButtonSearchActive' }
);

type Props = {
  className?: string;
  text: string;
};

const ButtonSearchText = (): JSX.Element => {
  const c = useButtonSearchTextStyles({});
  return (
    <>
      <Icon className={c.icon} name="search" />
      Search
    </>
  );
};

const ButtonSearchActive = (): JSX.Element => {
  const c = useButtonSearchActiveStyles({});
  return (
    <div className={c.root}>
      <ButtonSearchText />
    </div>
  );
};

export const ButtonSearch = ({ className }: Props): JSX.Element => {
  const c = useStyles({});
  const location = useLocation();
  return location.pathname === sitemap.search.path ? (
    <ButtonSearchActive />
  ) : (
    <Button className={cx(c.root, className)}>
      <ButtonSearchText />
    </Button>
  );
};
