import React from 'react';
import { Pagination as Paginating } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import cx from 'clsx';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      paddingTop: theme.spacing(2),
      alignItems: 'center',
    },
    totalResult: {
      flex: '1 0 0',
      textAlign: 'right',
    },
    column: {
      flex: '1 0 0',
      textAlign: 'center',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  { name: 'Pagination' }
);

type Props = {
  currentPage: number;
  totalPage: number;
  totalElements?: number;
  onChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  className?: string;
};

export const Pagination = ({ currentPage, totalPage, totalElements, onChange, className }: Props): JSX.Element => {
  const c = useStyles({});

  return (
    totalPage > 1 && (
      <div className={cx(c.root, className)}>
        <div className={c.column} />
        <Paginating className={c.column} count={totalPage} page={currentPage} onChange={onChange} shape="rounded" />
        <div className={c.totalResult}>{totalElements && `Total results: ${totalElements}`}</div>
      </div>
    )
  );
};
