import React, { useEffect } from 'react';
import { CriteriaList } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModels, getLanguages } from '../store/mixed';

export const LanguagesPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const languages = useSelector(getLanguages);

  useEffect(() => {
    if (!languages.data.length) {
      dispatch(fetchModels.request('languages'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <CriteriaList criteria={languages} type={'languages'} />;
};
