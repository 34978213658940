import { createStyles, Grid, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { object } from 'yup';
import { List, SearchRadiusControl, SkillList, TitleValueItem } from '.';
import { Employee, EmployeeSearchRadius, Position, Project, ProjectPositionEmployee } from '../models';
import { formatDate, formatJobTitleFilter, getFieldProps, getFormStyles, getWorkDaysWithHours } from '../utils';
import { OptionType, toEmployeeOptionType } from './AutoCompleteField';
import { FormActions } from './ProjectForm';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getFormStyles(theme),
      titleHead: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(2) - 5, // compensate 10px higher content
      },
      titleHeadTitle: {
        fontSize: 14,
      },
      form: {
        // nesting to override inner styles
        '& $suggestions': {
          padding: 0,
        },
      },
      suggestions: {
        maxHeight: '30vh',
        overflow: 'auto',
      },
    }),
  { name: 'AssignEmployeeForm' }
);

type Props = {
  project: Project;
  position: Position;
  withAvailability?: boolean;
  onSubmit: (params: ProjectPositionEmployee) => void;
  onCancel?: () => void;
  handleScroll: () => void;
  employees: Employee[];
  searchRadius: EmployeeSearchRadius;
  setSearchRadius: (radius: EmployeeSearchRadius) => void;
};

type AssignEmployeeFormModel = Omit<ProjectPositionEmployee, 'employeeId'> & {
  employee: OptionType;
};
const validateName = (key: keyof AssignEmployeeFormModel) => key;

export const AssignEmployeeForm = ({
  project,
  position: {
    id: positionId,
    employee,
    startDate,
    endDate,
    skills,
    minJobTitle,
    maxJobTitle,
    workingHoursPerWeek,
    suggestions,
    isLoadingSuggestions,
    updateStatus,
    totalResults,
  },
  onSubmit,
  handleScroll,
  employees,
  withAvailability,
  ...props
}: Props): JSX.Element => {
  const c = useStyles({});
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>(undefined);

  const initialValues: AssignEmployeeFormModel = {
    projectId: project.id,
    positionId,
    employee: employee ? toEmployeeOptionType(employee) : undefined,
  };

  const validationSchema = object({});
  const fieldProps = getFieldProps(updateStatus.isPending);

  const metas: { title: string; value: React.ReactNode; isDetail?: boolean }[] = [
    { title: 'Level', value: formatJobTitleFilter(minJobTitle, maxJobTitle) },
    { title: 'Workload', value: `${workingHoursPerWeek} h` },
    { title: 'Man Days', value: `${getWorkDaysWithHours(startDate, endDate, workingHoursPerWeek)} d` },
    { title: 'Duration', value: `${formatDate(startDate)} - ${formatDate(endDate)}` },
  ];

  return (
    <>
      <Grid container>
        {metas.map((item, index) => (
          <Grid key={index} item xs children={<TitleValueItem {...item} isDetail />} />
        ))}
      </Grid>

      <div className={c.section}>
        <h2 className={c.sectionTitle}>{'Required Skills'}</h2>
        {skills.length > 0 ? (
          <SkillList skills={skills} skillsOverlap={selectedEmployee && selectedEmployee.skills} columns={2} />
        ) : (
          'There are no skills defined for this position.'
        )}
      </div>

      <div className={c.section}>
        <div className={cx(c.sectionTitle, c.titleHead)}>
          <h2 className={c.titleHeadTitle}>{`Employee Suggestions (${totalResults || 0})`}</h2>
          <SearchRadiusControl {...props} />
        </div>

        {skills.length === 0 && "It's not possible to provide suggestions without any skills on position."}
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ employee, ...otherValues }, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit({
            ...otherValues,
            employeeId: employee?.value.toString(),
          });
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <form className={c.form} onSubmit={handleSubmit}>
            <List
              className={c.suggestions}
              type="employee"
              isEmployeeSuggestion
              withAvailability={withAvailability}
              activeItemId={selectedEmployee && selectedEmployee.id}
              data={{ data: suggestions, isLoading: isLoadingSuggestions, error: !skills.length && {} }} // empty error to prevent "no results" message
              onClick={(item) => {
                setSelectedEmployee(item);
                setFieldValue(validateName('employee'), toEmployeeOptionType(item));
              }}
              handleScroll={handleScroll}
            />
            <Field
              {...fieldProps.select}
              name={validateName('employee')}
              label={'Employee'}
              placeholder={'Select Employee'}
              options={employees.map(toEmployeeOptionType)}
            />

            <FormActions updateStatus={updateStatus} {...props} ctaLabel={'Assign Employee'} c={c} />
          </form>
        )}
      </Formik>
    </>
  );
};
