import { Grid, makeStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { array, mixed, number, object, string } from 'yup';
import { OrganizationTreeViewForm } from '.';
import { MAX_WORKING_HOURS_PER_WEEK, MIN_WORKING_HOURS_PER_WEEK } from '../constants';
import { Employee, JobTitle, Language, Location, OrganizationTree, UpdateStatus } from '../models';
import { getFieldProps, getFormStyles } from '../utils';
import { fromOptionType, OptionType, toOptionType } from './AutoCompleteField';
import { FormActions } from './ProjectForm';
import { useSelector } from 'react-redux';
import { getConfig } from '../store/app';

const useStyles = makeStyles((theme) => getFormStyles(theme), { name: 'EmployeeProfileForm' });

type Props = {
  employee: Employee;
  updateStatus: UpdateStatus;
  onSubmit: (employee: Employee) => void;
  onCancel?: () => void;
  jobTitles: JobTitle[];
  organizationTreeRoot: OrganizationTree;
  languages: Language[];
  locations: Location[];
};

type EmployeeProfileFormModel = Omit<
  Employee,
  'jobTitle' | 'organization' | 'location' | 'languages' | 'certificates' | 'workingHoursPerWeek' | 'skills'
> & {
  jobTitle?: OptionType;
  organization?: OptionType;
  location?: OptionType;
  languages?: OptionType[];
  workingHoursPerWeek: number | '';
};
const validateName = (key: keyof EmployeeProfileFormModel) => key;

export const EmployeeProfileForm = ({
  employee,
  onSubmit,
  jobTitles,
  organizationTreeRoot,
  languages,
  locations,
  ...props
}: Props): JSX.Element => {
  const c = useStyles({});

  const { activeFeatures } = useSelector(getConfig);

  const {
    id,
    firstName,
    lastName,
    jobTitle,
    placeOfResidence = '',
    location,
    organization,
    languages: employeeLanguages,
    workingHoursPerWeek = '',
    isExternal,
    isReplaceable = false,
  } = employee;

  const initialValues: EmployeeProfileFormModel = {
    id,
    firstName,
    lastName,
    ...(!isExternal && {
      jobTitle: jobTitle && toOptionType(jobTitle),
      organization: organization && toOptionType(organization),
    }),
    placeOfResidence,
    location: location && toOptionType(location),
    languages: employeeLanguages?.map(toOptionType) ?? [],
    workingHoursPerWeek,
    isReplaceable,
    isExternal, // passed through for service to determine which endpoint to call
  };

  const validationSchema = object({
    firstName: string().required('First Name is required'),
    lastName: string().required('Last Name is required'),
    ...(!isExternal && {
      jobTitle: mixed().required('Career Level1 is required'),
      organization: mixed().required('Organization structure is required'),
    }),
    placeOfResidence: string(),
    ...(isExternal
      ? {
          location: mixed(),
          languages: array<OptionType>().nullable(),
        }
      : {
          location: mixed().required('Location is required'),
          languages: array<OptionType>().required('Languages are required'),
        }),
    workingHoursPerWeek: number()
      .min(MIN_WORKING_HOURS_PER_WEEK)
      .max(MAX_WORKING_HOURS_PER_WEEK)
      .required('Working hours per week is required'),
  });

  const fieldProps = getFieldProps(props.updateStatus.isPending);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit({
          ...values,
          ...(!isExternal && {
            jobTitle: fromOptionType(values.jobTitle),
            organization: fromOptionType(values.organization),
          }),
          ...(values.location && { location: fromOptionType(values.location) }),
          ...(values.languages && { languages: values.languages.map(fromOptionType) }),
          ...(values.workingHoursPerWeek && { workingHoursPerWeek: +values.workingHoursPerWeek }),
        });
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid className={c.inputGrid} container>
            <Grid item xs={6}>
              <Field {...fieldProps.text} name={validateName('firstName')} label={'First Name'} disabled />
            </Grid>
            <Grid item xs={6}>
              <Field {...fieldProps.text} name={validateName('lastName')} label={'Last Name'} disabled />
            </Grid>
          </Grid>
          <Grid className={c.inputGrid} container>
            {!isExternal && (
              <Grid item xs={6}>
                <Field
                  {...fieldProps.select}
                  name={validateName('jobTitle')}
                  label={'Career Level'}
                  placeholder={'Select Career Level'}
                  options={jobTitles.map(toOptionType)}
                  disabled={activeFeatures.includes('BW_IMPORT')}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Field
                {...fieldProps.select}
                name={validateName('location')}
                label={'Office Location'}
                placeholder={'Select Office Location'}
                options={locations.map(toOptionType)}
              />
            </Grid>
          </Grid>

          <Grid className={c.inputGrid} container>
            <Grid item xs={6}>
              <Field
                {...fieldProps.number}
                name={validateName('workingHoursPerWeek')}
                label={'Working Hours per Week'}
                inputProps={{ min: MIN_WORKING_HOURS_PER_WEEK, max: MAX_WORKING_HOURS_PER_WEEK }}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Field {...fieldProps.text} name={validateName('placeOfResidence')} label={'Place of Residence'} />
            </Grid>
          </Grid>
          <Field
            {...fieldProps.select}
            name={validateName('languages')}
            label={'Languages'}
            placeholder={'Select Languages'}
            options={languages.map(toOptionType)}
            isMulti
          />
          {isExternal ? (
            <Field
              {...fieldProps.checkbox}
              type="checkbox"
              name={validateName('isReplaceable')}
              Label={{ ...fieldProps.checkbox.Label, label: 'is replaceable' }}
            />
          ) : (
            <OrganizationTreeViewForm
              className={c.inputGrid}
              fieldProps={fieldProps}
              onItemClick={(data) => setFieldValue('organization', toOptionType(data))}
              organizationTreeRoot={organizationTreeRoot}
              currentOrganization={employee?.organization}
            />
          )}

          <FormActions {...props} ctaLabel={'Save Profile'} c={c} />
        </form>
      )}
    </Formik>
  );
};
