import Select, { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { IndicatorComponentType, SelectComponents } from 'react-select/src/components';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuPortalProps, MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueType } from 'react-select/src/types';
import {
  Chip,
  createStyles,
  makeStyles,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles';
import cx from 'clsx';
import { FieldProps } from 'formik';
import { TextFieldProps } from 'formik-material-ui';
import React, { useState } from 'react';
import { AvatarListItem, Icon } from '.';
import { getTruncateStyles } from '../layout';
import { Employee } from '../models';
import { formatName } from '../utils';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      input: {
        display: 'flex',
        alignItems: 'center',
      },
      valueContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        '& input': { marginLeft: -2 },
      },
      chip: {
        margin: theme.spacing(0.5, 0.25),
        '& span': {
          lineHeight: '20px', // fix for alignment of label
        },
      },
      chipFocused: {
        backgroundColor: emphasize(
          theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
          0.08
        ),
      },
      noOptionsMessage: {
        padding: theme.spacing(1, 2),
      },
      singleValue: {
        ...getTruncateStyles().truncate,
      },
      placeholder: {
        position: 'absolute',
        left: 14,
        fontSize: 16,
      },
      paper: {
        position: 'absolute',
        left: 0,
        right: 0,
      },
      popper: {
        zIndex: 1500,
      },
      indicatorSeparator: {
        display: 'none',
      },
    }),
  { name: 'AutoCompleteField' }
);

type ClassesType = ReturnType<typeof useStyles>;

const NoOptionsMessage: React.FC<NoticeProps<OptionType, boolean>> = ({
  selectProps: { classes },
  innerProps,
  children,
}) => (
  <Typography
    color="textSecondary"
    className={(classes as ClassesType).noOptionsMessage}
    {...innerProps}
    children={children}
  />
);

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control: React.FC<ControlProps<OptionType, boolean>> = ({
  selectProps: { classes, textFieldProps },
  innerRef,
  children,
  innerProps,
}) => (
  <TextField
    fullWidth
    variant="outlined"
    {...textFieldProps}
    InputProps={{
      inputComponent,
      inputProps: {
        className: (classes as ClassesType).input,
        inputRef: innerRef,
        children,
        ...innerProps,
      },
      ...textFieldProps.InputProps,
    }}
  />
);

const Option: React.FC<OptionProps<OptionType, boolean>> = ({
  innerRef,
  isFocused,
  isSelected,
  innerProps,
  children,
  data,
}) => (
  <MenuItem
    ref={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 600 : 400,
    }}
    {...innerProps}
    children={(data as OptionType).employee ? <AvatarListItem {...data.employee} isInTextField withAvatar /> : children}
  />
);

const Placeholder: React.FC<PlaceholderProps<OptionType, boolean>> = ({
  selectProps: { classes },
  innerProps,
  children,
}) => (
  <Typography
    color="textSecondary"
    className={(classes as ClassesType).placeholder}
    {...innerProps}
    children={children}
  />
);

const SingleValue: React.FC<SingleValueProps<OptionType>> = ({
  selectProps: { classes },
  innerProps,
  children,
  data,
}) => (
  <Typography
    component="div"
    className={(classes as ClassesType).singleValue}
    {...innerProps}
    children={data.employee ? <AvatarListItem {...data.employee} isInTextField withAvatar /> : children}
  />
);

const ValueContainer: React.FC<ValueContainerProps<OptionType, boolean>> = ({ selectProps: { classes }, children }) => (
  <div className={(classes as ClassesType).valueContainer} children={children} />
);

const MultiValue: React.FC<MultiValueProps<OptionType>> = ({
  selectProps: { classes },
  isFocused,
  removeProps,
  children,
}) => (
  <Chip
    tabIndex={-1}
    label={children}
    className={cx((classes as ClassesType).chip, {
      [(classes as ClassesType).chipFocused]: isFocused,
    })}
    onDelete={removeProps.onClick}
    deleteIcon={<Icon name="close" {...removeProps} />}
  />
);

const Menu: React.FC<MenuProps<OptionType, boolean>> = ({ selectProps: { classes }, children, innerProps }) => (
  <Paper square className={(classes as ClassesType).paper} {...innerProps} children={children} />
);

const MenuPortal: React.FC<MenuPortalProps<OptionType, boolean>> = ({
  selectProps: { classes, menuIsOpen },
  appendTo,
  children,
}) => (
  <Popper
    className={(classes as ClassesType).popper}
    anchorEl={appendTo || document.body}
    open={menuIsOpen}
    style={{ width: appendTo ? appendTo.clientWidth : undefined }}
    children={children}
  />
);

const components: Partial<SelectComponents<OptionType, boolean>> = {
  Control,
  Menu,
  MenuPortal,
  MultiValue,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator: undefined,
};

export type OptionType = { value: string; label: string; __isNew__?: boolean; employee?: Employee };

export const toEmployeeOptionType = ({ id, ...employee }: Employee): OptionType => ({
  value: id,
  label: formatName(employee),
  employee,
});

export const toOptionType = (data: { id?: number | string; name: string }): OptionType => ({
  value: `${data.id}`,
  label: data.name,
});

export const fromOptionType = (data: OptionType): { id: string; name: string } => ({
  id: data.__isNew__ ? null : data.value,
  name: data.label.trim(), // take care of whitespace
});

type Props = FieldProps &
  TextFieldProps & {
    options: OptionType[];
    disabled?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    isMulti?: boolean;
    isCreatable?: boolean;
    formatCreateLabel?: string;
    onChange?: (value: ValueType<OptionType, boolean>) => void; // additional callback
    onInputChange?: (value: string) => void; // additional callback
    disabledFilterOption?: boolean; // additional callback
    inputClassName?: string;
    dropdownIndicator: IndicatorComponentType<OptionType, boolean>;
    optionStyle?: React.FC<OptionProps<OptionType, boolean>>;
    noOptionsMessage?: React.FC<NoticeProps<OptionType, boolean>>;
    error?: string; // workaround for error message on arrays
  };

export const AutoCompleteField: React.FC<Props> = ({
  label,
  field: { name, value },
  placeholder,
  options,
  className,
  disabled,
  isClearable = true,
  isSearchable = true,
  isMulti,
  dropdownIndicator,
  isCreatable,
  formatCreateLabel,
  onChange,
  disabledFilterOption,
  onInputChange,
  form: { errors, setFieldValue, touched },
  inputClassName,
  optionStyle,
  noOptionsMessage,
  error,
}) => {
  const classes = useStyles({});
  const theme = useTheme<Theme>();
  const [ref, setRef] = useState<HTMLElement>(); // state fuck up needed to trigger render, otherwise portal can't be positioned
  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const errorMsg = error ?? (touched[name] && errors[name]);
  const Tag: React.ElementType = isCreatable ? CreatableSelect : Select;
  return (
    <Tag
      className={className}
      classes={classes}
      styles={selectStyles}
      options={options}
      components={{
        ...components,
        ...(dropdownIndicator ? { DropdownIndicator: dropdownIndicator } : {}),
        ...(noOptionsMessage ? { NoOptionsMessage: noOptionsMessage } : { NoOptionsMessage }),
        ...(optionStyle ? { Option: optionStyle } : { Option }),
      }}
      value={value}
      onInputChange={(value) => {
        onInputChange && onInputChange(value);
      }}
      filterOption={
        disabledFilterOption
          ? (option) => option
          : createFilter({
              ignoreCase: true,
              ignoreAccents: true,
              matchFrom: 'any',
              stringify: (option) => `${option.label} ${option.value}`,
              trim: true,
            })
      }
      onChange={(val: ValueType<OptionType, boolean>) => {
        setFieldValue && setFieldValue(name, val, true); // setFieldValue might be undefined with rewiring in SkillManager
        onChange && onChange(val); // optional additional callback or replacement for setFieldValue
      }}
      menuPortalTarget={ref}
      menuPosition="fixed"
      placeholder={placeholder}
      textFieldProps={{
        ref: (node) => setRef(node),
        label,
        InputLabelProps: {
          shrink: true,
        },
        InputProps: {
          className: inputClassName,
        },
        helperText: errorMsg,
        error: !!errorMsg,
      }}
      isDisabled={disabled}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isMulti={isMulti}
      formatCreateLabel={(value) => (formatCreateLabel ? `${formatCreateLabel} '${value}'` : value)}
    />
  );
};
