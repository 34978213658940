import { createStyles, makeStyles, Tab, Tabs as MuiTabs, Theme } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';

export const getSubNavStyles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: theme.header.height,
      left: 0,
      right: 0,
      background: theme.palette.common.white,
      boxShadow: theme.customShadows.fixed,
    },
  });

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      ...getSubNavStyles(theme),
      tab: {
        height: theme.subnav.height,
        fontSize: 14,
        letterSpacing: '1.3px',
      },
      tabActive: {
        cursor: 'default',
        pointerEvents: 'none',
      },
    }),
  { name: 'Tabs' }
);

export type TabValue<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  value: T;
  values: TabValue<T>[];
  onChange?: (param: T) => void;
};

export const Tabs = <T extends {}>({ value, values, onChange }: Props<T>) => {
  const c = useStyles({});
  return (
    <MuiTabs
      className={c.root}
      value={value}
      onChange={(_, value) => onChange && onChange(value)}
      indicatorColor="primary"
      textColor="primary"
      centered
      children={values.map((item) => (
        <Tab key={item.label} className={cx(c.tab, { [c.tabActive]: item.value === value })} {...item} />
      ))}
    />
  );
};
