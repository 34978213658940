import { getType } from 'typesafe-actions';
import { Client, EntityArray } from '../../models';
import { getInitialEntityArrayState } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

type State = {
  readonly clients: EntityArray<Client>;
};

const initialState: State = {
  clients: getInitialEntityArrayState(),
};

export const clientsReducer = (
  state = initialState,
  action: fromActions.ClientsAction | AppActions.AppAction
): State => {
  switch (action.type) {
    case getType(fromActions.fetchClients.request):
      return {
        ...state,
        clients: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchClients.success):
      return {
        ...state,
        clients: {
          ...state.clients,
          data: action.payload,
          isLoading: false,
        },
      };

    case getType(fromActions.fetchClients.failure):
      return {
        ...state,
        clients: {
          ...state.clients,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
