import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { ReactComponent as Add } from '../assets/icons/add.svg';
import { ReactComponent as AddPerson } from '../assets/icons/add-person.svg';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as Certificate } from '../assets/icons/certificate.svg';
import { ReactComponent as BadgeFreelancer } from '../assets/icons/badge-freelancer.svg';
import { ReactComponent as ChevronDown } from '../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../assets/icons/chevron-right.svg';
import { ReactComponent as ServerIcon } from '../assets/icons/server-icon.svg';
import { ReactComponent as Help } from '../assets/icons/help.svg';
import { ReactComponent as UploadFile } from '../assets/icons/upload-file.svg';

// import { ReactComponent as Certificate } from '../assets/icons/certificate.svg';
// import { ReactComponent as Check } from '../assets/icons/check.svg';
import { ReactComponent as ChevronUp } from '../assets/icons/chevron-up.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Delete } from '../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../assets/icons/edit.svg';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { ReactComponent as Mail } from '../assets/icons/mail.svg';
import { ReactComponent as More } from '../assets/icons/more.svg';
import { ReactComponent as Person } from '../assets/icons/person.svg';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
// import { ReactComponent as Phone } from '../assets/icons/phone.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as Star } from '../assets/icons/star.svg';
import { ReactComponent as Share } from '../assets/icons/share.svg';
// import { ReactComponent as StatusDone } from '../assets/icons/status-done.svg';
// import { ReactComponent as StatusPlanned } from '../assets/icons/status-planned.svg';
// import { ReactComponent as StatusRunning } from '../assets/icons/status-running.svg';
import { ReactComponent as CreatePosition } from '../assets/icons/create-position.svg';
import { ReactComponent as CopyPosition } from '../assets/icons/copy-position.svg';
import { ReactComponent as PotentialProject } from '../assets/icons/potential-project.svg';
import { ReactComponent as PrivatePosition } from '../assets/icons/private-position.svg';
import { ReactComponent as PublicPosition } from '../assets/icons/public-position.svg';
import { ReactComponent as SearchProject } from '../assets/icons/search-project.svg';
import { ReactComponent as ConsultantProfile } from '../assets/icons/consultant-profile.svg';


export type IconType =
  | 'arrowRight'
  | 'badgeFreelancer'
  | 'person'
  | 'add'
  | 'addPerson'
  | 'edit'
  | 'delete'
  | 'close'
  | 'certificate'
  | 'error'
  | 'mail'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronDown'
  | 'chevronUp'
  | 'more'
  | 'search'
  | 'visible'
  | 'invisible'
  | 'star'
  | 'share'
  | 'serverIcon'
  | 'help'
  | 'createPosition'
  | 'copyPosition'
  | 'potentialProject'
  | 'privatePosition'
  | 'publicPosition'
  | 'searchProject'
  | 'uploadFile'
  | 'consultantProfile';

const icons: {
  [key in IconType]: React.FC;
} = {
  arrowRight: ArrowRight,
  badgeFreelancer: BadgeFreelancer,
  person: Person,
  add: Add,
  addPerson: AddPerson,
  edit: Edit,
  delete: Delete,
  close: Close,
  certificate: Certificate,
  error: Error,
  mail: Mail,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  more: More,
  search: Search,
  star: Star,
  share: Share,
  serverIcon: ServerIcon,
  help: Help,
  visible: VisibilityOutlinedIcon,
  invisible: VisibilityOffOutlinedIcon,
  createPosition: CreatePosition,
  copyPosition: CopyPosition,
  potentialProject: PotentialProject,
  privatePosition: PrivatePosition,
  publicPosition: PublicPosition,
  searchProject: SearchProject,
  uploadFile: UploadFile,
  consultantProfile: ConsultantProfile
};

type Props = SvgIconProps & {
  name: IconType;
};

export const Icon = ({ name, ...other }: Props): JSX.Element => {
  const Icon = icons[name];
  return <SvgIcon {...other} children={<Icon />} />;
};
