import React, { useState } from 'react';
import { Modal } from './Modal';
import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import { Logo } from './Logo';

const useStyles = makeStyles(
  (theme) => ({
    submit: {
      textAlign: 'center',
    },
    logo: {
      fill: theme.palette.text.primary,
    },
    logoWrapper: {
      backgroundColor: theme.palette.common.white,
      position: 'fixed',
      textAlign: 'center',
      padding: theme.spacing(2),
      borderRadius: 4,
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10000,
    },
    textWrapper: {
      marginTop: 50,
    },
  }),
  { name: 'PrivacyPolicyOverlay' }
);

export const UnsupportedBrowserOverlay = (): JSX.Element => {
  const c = useStyles({});
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} hasNoActions>
      <div className={cx(c.logoWrapper)}>
        <Logo className={cx(c.logo)} />
      </div>
      <div className={cx(c.textWrapper)}>
        <h2 style={{ color: 'red' }}>Attention - Outdated Browser detected!</h2>
        <p>
          <b>
            Your Internet Explorer web browser is out of date and cannot display the web page you are visiting correctly
            because it is not compatible with modern Web standards.
          </b>
        </p>

        <p>
          To view this site and to browse the Internet safely you should consider stop using Internet Explorer.
          Alternatively, you can use one of the several excellent free standards-compliant browsers that are now
          available, like Chrome or Firefox.
        </p>
      </div>
      {/*<div className={cx(c.submit)}>
        <Button onClick={() => setIsOpen(false)}>Continue On Your Own Risk</Button>
      </div>*/}
    </Modal>
  );
};
