import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { ReactComponent as LogoImg } from '../assets/skillmatching-logo.svg';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 140,
      fill: theme.palette.common.white,
    },
  }),
  { name: 'Logo' }
);

type Props = {
  className?: string;
};

export const Logo = ({ className }: Props): JSX.Element => <LogoImg className={cx(useStyles({}).root, className)} />;
