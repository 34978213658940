import { Button, Grid, makeStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { mixed, object } from 'yup';
import { getTruncateStyles } from '../layout';
import { UpdateStatus } from '../models';
import { getFieldProps } from '../utils';
import { Icon } from './Icon';
import { SimpleFormActions, useSimpleFormStyles } from './LoginForm';

const SUPPORTED_FILE_FORMATS = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const useStyles = makeStyles(() => getTruncateStyles());

type Props = {
  onSubmit: (file: File) => void;
  onReset: () => void;
  updateStatus: UpdateStatus;
};

type ImportFormModel = { file: File };
const validateName = (key: keyof ImportFormModel) => key;

export const ImportForm = ({ onSubmit, onReset, ...props }: Props): JSX.Element => {
  const c = { ...useSimpleFormStyles({}), ...useStyles({}) };
  const [fileName, setFileName] = useState('');

  const initialValues: ImportFormModel = {
    file: undefined,
  };

  const validationSchema = object({
    file: mixed()
      .required('File is required')
      .test('fileFormat', 'Unsupported Format', (value) => value && SUPPORTED_FILE_FORMATS.includes(value.type)),
  });

  const {
    updateStatus: { isPending, isSuccess },
  } = props;

  const fieldProps = getFieldProps(isPending);

  return (
    <div className={c.root}>
      {!isSuccess ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            onSubmit(values.file);
          }}
        >
          {({ handleSubmit, setFieldTouched }) => (
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <Field
                    {...fieldProps.file}
                    name={validateName('file')}
                    label={'Select File'}
                    fullWidth
                    // need to manually set the field to touched to control error display
                    onChange={(file: File) => {
                      setFileName(file.name);
                      setFieldTouched(validateName('file'), true);
                    }}
                    accept={SUPPORTED_FILE_FORMATS}
                  />
                </Grid>
                <Grid item zeroMinWidth>
                  <div className={c.truncate} children={`Selected File: ${fileName}`} />
                </Grid>
              </Grid>

              <SimpleFormActions {...props} fullWidth={false} ctaLabel={'Upload'} c={c} />
            </form>
          )}
        </Formik>
      ) : (
        <div className={c.success}>
          <Icon className={c.successIcon} name="star" />
          <p className={c.intro}>Your upload was successful.</p>
          <Button onClick={onReset} children={'Upload another File'} />
        </div>
      )}
    </div>
  );
};
