import { makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { array, number, object, string } from 'yup';
import { SkillManager } from '.';
import { MAX_SKILLS, MIN_SKILLS } from '../constants';
import { Employee, Skill, UpdateStatus } from '../models';
import { getFormStyles } from '../utils';
import { OptionType } from './AutoCompleteField';
import { FormActions } from './ProjectForm';

const useStyles = makeStyles((theme) => getFormStyles(theme), { name: 'EmployeeSkillsForm' });

type Props = {
  employee: Employee;
  updateStatus: UpdateStatus;
  skillRequestToggle?: boolean;
  onSubmit: (params: Skill[]) => void;
  onCancel?: () => void;
  skills: Skill[];
};

type EmployeeSkillsFormModel = {
  skills: Skill[];
  skillAutoComplete: OptionType;
};
const validateName = (key: keyof EmployeeSkillsFormModel) => key;

export const EmployeeSkillsForm = ({
  employee,
  onSubmit,
  skills,
  skillRequestToggle,
  ...props
}: Props): JSX.Element => {
  const c = useStyles({});

  const initialValues: EmployeeSkillsFormModel = {
    skills: employee.skills ?? [],
    skillAutoComplete: undefined,
  };

  const validationSchema = object({
    skills: array()
      .of(
        object({
          name: string(),
          level: number(),
          requestMessage: string().when('id', {
            is: (val) => val,
            then: string(),
            otherwise: string(), //TODO fix this
          }),
        })
      )
      .min(MIN_SKILLS, 'At least one Skill is required')
      .max(MAX_SKILLS, "You can't have more than 30 Skills")
      .required('Skills are required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit(values.skills);
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <SkillManager
            updateStatus={props.updateStatus}
            name={validateName('skillAutoComplete')}
            targetName={validateName('skills')}
            skills={skills}
            form={formikProps}
            isCreatable={skillRequestToggle}
            showRequestButton={!skillRequestToggle}
          />

          <FormActions {...props} ctaLabel={'Update Skills'} c={c} />
        </form>
      )}
    </Formik>
  );
};
