export const releaseVersion = (version: string, backendVersion: string) => {
  if (version === 'local' || backendVersion === 'local') {
    return '1337.42';
  }

  if (!version || !backendVersion) {
    return '';
  }

  const frontendVersion = version.split('.');
  const frontendPatchVersion = +frontendVersion[2];
  const backendPatchVersion = +backendVersion.split('.')[2];
  if (!frontendPatchVersion || !backendPatchVersion) {
    return '';
  }

  const patchVersion = +frontendVersion[2] + +backendVersion.split('.')[2];
  return `${frontendVersion[0]}.${frontendVersion[1]}.${patchVersion}`;
};
