import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

const employeeState = (state: RootState) => state.employee;

export const getEmployee = createSelector(
  [employeeState],
  (employee) => employee.employee
);

export const getEmployeeUpdateStatus = createSelector(
  [employeeState],
  (employee) => employee.updateStatus
);

export const getEmployeeProjects = createSelector(
  [employeeState],
  (employee) => employee.projects
);
