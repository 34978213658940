import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Project, UpdateStatus } from '../models';
import { getUser } from '../store/app';
import { fetchClients, getClients } from '../store/clients';
import { fetchInternalEmployees, fetchProjectLeads, getInternalEmployees, getProjectLeads } from '../store/employees';
import { isUserTeamLead } from '../utils';
import { ProjectForm } from './ProjectForm';

type Props = {
  project?: Project;
  updateStatus: UpdateStatus;
  onSubmit: (project: Project) => void;
  onCancel: () => void;
};

export const ProjectFormContainer = (props: Props): JSX.Element => {
  const { project } = props;
  const dispatch = useDispatch();
  const clients = useSelector(getClients).data;
  const projectLeads = useSelector(getProjectLeads).data;
  const projectSubLeads = useSelector(getInternalEmployees).data;
  const user = useSelector(getUser);
  const formProps = { ...props, clients, projectLeads, projectSubLeads, user };

  useEffect(() => {
    if (!clients.length) {
      dispatch(fetchClients.request());
    }
    if (!projectLeads.length) {
      dispatch(fetchProjectLeads.request());
    }
    if (!projectSubLeads.length) {
      dispatch(fetchInternalEmployees.request());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // when we don't have a project we are in creation mode so we need to prefill the projectLead with the current employee
  return (
    <ProjectForm projectLead={!project && user.employee} canEditProjectLead={isUserTeamLead(user)} {...formProps} />
  );
};
