import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

const overviewState = (state: RootState) => state.overview;

export const getOverview = createSelector([overviewState], (overview) => overview.overview);

export const getReleaseNotes = createSelector([overviewState], (overview) => overview.releaseNotes);

export const getAppRating = createSelector([overviewState], (overview) => overview.feedbackOverview);
