import { getType } from 'typesafe-actions';
import { EntityArray, OverviewItem, ReleaseNote } from '../../models';
import { getInitialEntityArrayState } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

type State = {
  readonly overview: EntityArray<OverviewItem>;

  readonly feedbackOverview: EntityArray<OverviewItem>;

  readonly releaseNotes: EntityArray<ReleaseNote>;
};

const initialState: State = {
  overview: getInitialEntityArrayState(),
  feedbackOverview: getInitialEntityArrayState(),
  releaseNotes: getInitialEntityArrayState(),
};

export const overviewReducer = (
  state = initialState,
  action: fromActions.OverviewAction | AppActions.AppAction
): State => {
  const { overview, releaseNotes, feedbackOverview } = state;

  switch (action.type) {
    case getType(fromActions.fetchOverview.request):
      return {
        ...state,
        overview: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchOverview.success):
      return {
        ...state,
        overview: {
          ...overview,
          data: action.payload,
          isLoading: false,
        },
      };

    case getType(fromActions.fetchOverview.failure):
      return {
        ...state,
        overview: {
          ...overview,
          error: action.payload,
          isLoading: false,
        },
      };

    case getType(fromActions.fetchAppRating.request):
      return {
        ...state,
        feedbackOverview: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchAppRating.success):
      return {
        ...state,
        feedbackOverview: {
          ...feedbackOverview,
          data: action.payload,
          isLoading: false,
        },
      };

    case getType(fromActions.fetchAppRating.failure):
      return {
        ...state,
        feedbackOverview: {
          ...feedbackOverview,
          error: action.payload,
          isLoading: false,
        },
      };

    case getType(fromActions.fetchReleaseNote.request):
      return {
        ...state,
        releaseNotes: {
          ...getInitialEntityArrayState(),
        },
      };

    case getType(fromActions.fetchReleaseNote.success):
      return {
        ...state,
        releaseNotes: {
          ...releaseNotes,
          data: action.payload,
        },
      };

    case getType(fromActions.fetchReleaseNote.failure):
      return {
        ...state,
        releaseNotes: {
          ...releaseNotes,
          error: action.payload,
        },
      };

    // -------------------------

    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
