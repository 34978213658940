import { goBack } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { match, RouteProps } from 'react-router-dom';
import {
  AssignEmployeeFormContainer,
  ConfirmationDialog,
  ErrorBlock,
  Loading,
  Modal,
  PositionFeedback,
  PositionFormContainer,
  ProjectDetail,
  ProjectFormContainer,
} from '../components';
import { Feedback, Position } from '../models';
import { sitemap } from '../routes';
import {
  assignEmployeeToPosition,
  createOrUpdatePosition,
  createOrUpdateProject,
  deletePosition,
  deleteProject,
  fetchPositionFeedback,
  fetchPotentialEmployees,
  fetchProject,
  getActivePosition,
  getPotentialEmployees,
  getProject,
  getProjectUpdateStatus,
  resetActivePosition,
  resetProject,
  setActivePosition,
} from '../store/project';
import { getRouterAction } from '../store/rootReducer';
import { disableFeedback, getConfig, getUser, submitFeedback } from '../store/app';
import { useToasts } from 'react-toast-notifications';
import { PositionFormModel, StepperModal } from '../components/StepperModal';
import { createUpdatePosition } from '../store/positions';

type Props = RouteProps & {
  match: match<{ id: string }>;
};

export const ProjectPage = ({
  match: {
    params: { id: projectId },
  },
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { data: project, isLoading, error } = useSelector(getProject);
  const potentialEmployees = useSelector(getPotentialEmployees);
  const updateStatus = useSelector(getProjectUpdateStatus);
  const activePosition = useSelector(getActivePosition);
  const routerAction = useSelector(getRouterAction);
  const user = useSelector(getUser);
  const { email } = user;
  const { activeFeatures } = useSelector(getConfig);

  type ModalType =
    | 'project'
    | 'deleteProject'
    | 'addEditPosition'
    | 'deletePosition'
    | 'assignEmployee'
    | 'createPositionFeedback';
  const [isModalOpen, setIsModalOpen] = useState<{ [key in ModalType]: boolean }>({
    project: false,
    deleteProject: false,
    addEditPosition: false,
    createPositionFeedback: true,
    deletePosition: false,
    assignEmployee: false,
  });

  const [feedback, setFeedback] = useState<Feedback>(undefined);

  useEffect(() => {
    dispatch(fetchProject.request(projectId));
    return () => {
      dispatch(fetchProject.cancel());
      dispatch(resetProject());
    };
  }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  const openPositions = project?.positions?.filter((p) => !p.employee);

  if (project?.projectLead?.id === user.employee.id && openPositions?.length > 0 && !project?.showFeedback) {
    dispatch(fetchPositionFeedback.request(openPositions.map((position) => position.id)));
  }

  const openModal = (key: ModalType, position?: Position) => {
    setIsModalOpen((state) => ({ ...state, [key]: true }));
    if (position) {
      dispatch(setActivePosition(position.id));
    }
  };

  const closeModal = (key: ModalType) => {
    setIsModalOpen((state) => ({ ...state, [key]: false }));
    dispatch(resetActivePosition());
  };

  useEffect(() => {
    if (updateStatus.isSuccess) {
      setIsModalOpen((state) => ({ ...state, project: false, deleteProject: false, deletePosition: false }));
    }
  }, [updateStatus]);

  const isActivePositionPending = activePosition && activePosition.updateStatus.isPending;
  const isActivePositionSuccess = activePosition && activePosition.updateStatus.isSuccess;

  useEffect(() => {
    if (isActivePositionSuccess) {
      dispatch(resetActivePosition()); // important to reset success
      setIsModalOpen((state) => ({ ...state, addEditPosition: false, assignEmployee: false }));
    }
  }, [isActivePositionSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPositionFormSubmit = (position: Position) =>
    dispatch(createOrUpdatePosition.request({ id: project.id, position }));
  const onAddOrEditPosition = (position: Position) => openModal('addEditPosition', position);
  const onAssignEmployee = (position: Position) => openModal('assignEmployee', position);
  const onDeletePosition = (position: Position) => openModal('deletePosition', position);

  const closeProjectModal = () => closeModal('project');
  const closePositionModal = () => closeModal('addEditPosition');
  const closeAssignEmployeeModal = () => closeModal('assignEmployee');

  const getPositionNameById = (id: string) => project?.positions.find((data) => data.id === id)?.role;

  const handleSubmit = (values: PositionFormModel) => {
    dispatch(createUpdatePosition.request({ positionId: activePosition.id, request: values }));
    closePositionModal();
  };

  return (
    <>
      <Helmet title={(project && project.name) || 'Projects'} />

      {error && <ErrorBlock {...error} />}
      {isLoading && <Loading withMargin />}

      {project && (
        <>
          <ProjectDetail
            project={project}
            onEdit={() => openModal('project')}
            onDelete={() => openModal('deleteProject')}
            onAddOrEditPosition={onAddOrEditPosition}
            onDeletePosition={onDeletePosition}
            onAssignEmployee={onAssignEmployee}
            backButtonProps={{
              // if we arrive from another page we route back via redux, otherwise url is fallback
              to: sitemap.projects.root.path,
              onClick: routerAction === 'PUSH' ? () => dispatch(goBack()) : undefined,
            }}
          />

          <ConfirmationDialog
            isOpen={isModalOpen.deleteProject}
            headline={`Deletion of Project "${project.name}"`}
            children={'Are you sure?'}
            onClose={() => closeModal('deleteProject')}
            onConfirm={() => dispatch(deleteProject.request(project.id))}
            updateStatus={updateStatus}
          />

          {activePosition && (
            <ConfirmationDialog
              isOpen={isModalOpen.deletePosition}
              headline={`Why did you delete this open position "${activePosition.role}"?`}
              children={
                <PositionFeedback
                  initFeedback={() =>
                    setFeedback({
                      type: 'delete_position',
                      content: 'Successfully staffed with the help of skill-matching tool',
                    })
                  }
                  feedbackOptions={[
                    'Successfully staffed with the help of skill-matching tool',
                    'Successfully staffed without the help of skill-matching tool',
                    'Cancelled during change within project situation',
                    'Other reason',
                  ]}
                  onChange={(value, anonymous) =>
                    setFeedback({
                      ...(!anonymous && { user: email }),
                      type: 'delete_position',
                      content: value,
                    })
                  }
                />
              }
              onClose={() => closeModal('deletePosition')}
              onConfirm={() => {
                //TODO merge 2 action in 1 in reducer
                dispatch(deletePosition.request({ id: project.id, position: activePosition }));
                dispatch(submitFeedback.request(feedback));
                addToast('Position deleted. Thank you for your feedback!', {
                  appearance: 'success',
                  autoDismiss: true,
                });
              }}
              updateStatus={activePosition.updateStatus}
            />
          )}
          {project.showFeedback?.length > 0 && (
            <ConfirmationDialog
              isOpen={isModalOpen.createPositionFeedback}
              headline={`Give us a feedback to this open position: "${getPositionNameById(
                project.showFeedback[0].positionId
              )}"?`}
              children={
                <PositionFeedback
                  initFeedback={() =>
                    setFeedback({
                      type: 'deactivate_position',
                      content: 'Yes, Successfully staffed with the help of skill-matching tool',
                      positionId: project.showFeedback[0].positionId,
                    })
                  }
                  feedbackOptions={[
                    'Yes, Successfully staffed with the help of skill-matching tool',
                    'Yes, Successfully staffed without the help of skill-matching tool',
                    'No, I used the "search for fitting employees" function, but there was no fitting candidate available at MHP',
                    'No, I did not use the "search for fitting employees" function, so I do not know if there could be a fitting candidate',
                    'Other reason',
                  ]}
                  onChange={(value, anonymous) =>
                    setFeedback({
                      ...(!anonymous && { user: email }),
                      type: 'deactivate_position',
                      content: value,
                      positionId: project.showFeedback[0].positionId,
                    })
                  }
                />
              }
              confirmLabel={'Submit'}
              onClose={() => {
                closeModal('createPositionFeedback');
                dispatch(disableFeedback.request({ type: 'deactivate_position', positionId: feedback.positionId }));
              }}
              onConfirm={() => {
                closeModal('createPositionFeedback');
                dispatch(submitFeedback.request(feedback));
              }}
            />
          )}

          <Modal
            isOpen={isModalOpen.project}
            onClose={closeProjectModal}
            headline={'Edit Project'}
            disableClose={updateStatus.isPending}
          >
            {isModalOpen.project && (
              <ProjectFormContainer
                project={project}
                updateStatus={updateStatus}
                onSubmit={(data) => dispatch(createOrUpdateProject.request(data))}
                onCancel={closeProjectModal}
              />
            )}
          </Modal>

          {activeFeatures.includes('CREATE_POSITIONS_WIZARD') && activePosition?.employee === null ? (
            <>
              {isModalOpen.addEditPosition && (
                <StepperModal
                  isOpen={isModalOpen.addEditPosition}
                  headline="Edit position"
                  disableClose={isActivePositionPending}
                  onClose={closePositionModal}
                  onSubmit={handleSubmit}
                  positionId={activePosition?.id}
                />
              )}
            </>
          ) : (
            <Modal
              isOpen={isModalOpen.addEditPosition}
              onClose={closePositionModal}
              headline={activePosition ? 'Edit Position' : 'Add Position'}
              disableClose={isActivePositionPending}
            >
              {isModalOpen.addEditPosition && (
                <PositionFormContainer
                  {...project} // startDate, endDate
                  potentialEmployees={potentialEmployees}
                  position={activePosition}
                  onSubmit={onPositionFormSubmit}
                  onCancel={closePositionModal}
                  onPotentialEmployees={(request) => dispatch(fetchPotentialEmployees.request(request))}
                />
              )}
            </Modal>
          )}

          <Modal
            isOpen={isModalOpen.assignEmployee}
            onClose={closeAssignEmployeeModal}
            headline={`Assign Position "${activePosition && activePosition.role}"`}
            disableClose={isActivePositionPending}
            maxWidth={1000}
          >
            {isModalOpen.assignEmployee && (
              <AssignEmployeeFormContainer
                project={project}
                position={activePosition}
                onSubmit={(data) => dispatch(assignEmployeeToPosition.request(data))}
                onCancel={closeAssignEmployeeModal}
              />
            )}
          </Modal>
        </>
      )}
    </>
  );
};
