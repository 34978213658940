import { LOCATION_CHANGE } from 'connected-react-router';
import { Epic } from 'redux-observable';
import { concatMap, filter, first, ignoreElements, map, switchMap, tap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import {
  acceptPrivacyPolicy,
  changePassword,
  disableFeedback,
  fetchConfig,
  fetchMe,
  hideAppFeedback,
  login,
  logout,
  refreshPage,
  signUpOrResetPassword,
  submitFeedback,
} from '.';
import { environment as env, isFeatureEnabled } from '../../environments';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';
import { hideCreatePositionFeedback } from '../project';

const initialRouteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isOfType(LOCATION_CHANGE)), // TODO: refactor to reusable operator
    first(),
    concatMap(() => [fetchConfig.request(), ...(isFeatureEnabled('useRedirectLogin') ? [fetchMe.request()] : [])])
  );

declare const _paq: any[];

let currentUrl = window.location.href;
const locationChangeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filter(isOfType(LOCATION_CHANGE)),
    tap(({ payload }) => {
      if (process.env.NODE_ENV === 'production') {
        // https://developer.matomo.org/guides/spa-tracking#measuring-single-page-apps-complete-example
        _paq.push(['setReferrerUrl', currentUrl]);
        currentUrl = payload.location.pathname;
        _paq.push(['setCustomUrl', currentUrl]);
        // _paq.push(['setDocumentTitle', 'My New Title']);

        // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
        _paq.push(['deleteCustomVariables', 'page']);
        _paq.push(['trackPageView']);
      }
    }),
    ignoreElements()
  );

const fetchConfigEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { configService }) =>
  action$.pipe(
    filterAction(fetchConfig.request),
    switchMap(() =>
      configService.getConfig().pipe(map(fetchConfig.success), catchErrorAndHandleWithAction(fetchConfig.failure))
    )
  );

const loginEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { authService }) =>
  action$.pipe(
    filterAction(login.request),
    switchMap(({ payload }) =>
      authService.login(payload).pipe(
        concatMap((data) => [login.success(data), fetchConfig.request()]),
        catchErrorAndHandleWithAction(login.failure, { skipLogout: true })
      )
    )
  );

const fetchMeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { authService }) =>
  action$.pipe(
    filterAction(fetchMe.request),
    switchMap(() =>
      authService
        .getMe()
        .pipe(map(fetchMe.success), catchErrorAndHandleWithAction(fetchMe.failure, { skipLogout: true }))
    )
  );

const signUpOrResetPasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { authService }
) =>
  action$.pipe(
    filterAction(signUpOrResetPassword.request),
    switchMap(({ payload }) =>
      authService
        .signUpOrResetPassword(payload)
        .pipe(map(signUpOrResetPassword.success), catchErrorAndHandleWithAction(signUpOrResetPassword.failure))
    )
  );

const changePasswordEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { authService }) =>
  action$.pipe(
    filterAction(changePassword.request),
    switchMap(({ payload }) =>
      authService
        .changePassword(payload)
        .pipe(map(changePassword.success), catchErrorAndHandleWithAction(changePassword.failure))
    )
  );

const acceptPrivacyPolicyEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { employeeService }
) =>
  action$.pipe(
    filterAction(acceptPrivacyPolicy.request),
    switchMap(() =>
      employeeService
        .acceptPrivacyPolicy()
        .pipe(map(acceptPrivacyPolicy.success), catchErrorAndHandleWithAction(acceptPrivacyPolicy.success))
    )
  );

const submitFeedbackEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { feedbackService }) =>
  action$.pipe(
    filterAction(submitFeedback.request),
    switchMap(({ payload }) => {
      const newVar = [
        ...(payload.type === 'app_rating' ? [hideAppFeedback()] : []),
        ...(payload.type === 'deactivate_position' ? [hideCreatePositionFeedback(payload.positionId)] : []),
        submitFeedback.success(),
      ];
      return feedbackService.submitFeedback(payload).pipe(
        concatMap(() => newVar),
        catchErrorAndHandleWithAction(submitFeedback.success)
      );
    })
  );

const disableFeedbackEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { feedbackService }) =>
  action$.pipe(
    filterAction(disableFeedback.request),
    switchMap(({ payload }) =>
      feedbackService.disableFeedback(payload).pipe(
        concatMap(() => [
          disableFeedback.success(),
          ...(payload.type === 'app_rating' ? [hideAppFeedback()] : []),
          ...(payload.type === 'deactivate_position' ? [hideCreatePositionFeedback(payload.positionId)] : []),
        ]),
        catchErrorAndHandleWithAction(disableFeedback.success)
      )
    )
  );

const logoutEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { authService }) =>
  action$.pipe(
    filterAction(logout.request),
    tap(() => {
      if (isFeatureEnabled('useRedirectLogin')) {
        document.cookie = 'oauth2=; Max-Age=0'; // TODO: remove this hot fix when TODO of deployment/values.prod.yaml:33 should work
        window.location.href = env.redirectLogout;
      }
    }),
    switchMap(() =>
      authService.logout().pipe(
        map(logout.success),
        catchErrorAndHandleWithAction(logout.success, { skipLogout: true }) // we always want to logout
      )
    )
  );

const refreshPageEpic: Epic<RootAction, RootAction, RootState, Services> = (action$) =>
  action$.pipe(
    filterAction(refreshPage.request),
    tap(() => {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }),
    ignoreElements()
  );

export const appEpics = [
  initialRouteEpic,
  acceptPrivacyPolicyEpic,
  locationChangeEpic,
  fetchConfigEpic,
  submitFeedbackEpic,
  disableFeedbackEpic,
  ...(isFeatureEnabled('useRedirectLogin') // run only epics that make sense
    ? [fetchMeEpic]
    : [loginEpic, signUpOrResetPasswordEpic, changePasswordEpic]),
  logoutEpic, // important for clearing resetting store states
  refreshPageEpic,
];
