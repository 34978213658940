import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FAQs } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFAQ, getFAQs } from '../store/mixed';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '40px 140px',
    },
  }),
  { name: 'PrivacyPolicyPage' }
);

export const FAQsPage = (): JSX.Element => {
  const c = useStyles({});
  const { data } = useSelector(getFAQs);
  const dispatch = useDispatch();

  useEffect(() => {
    !data.length && dispatch(fetchFAQ.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet title={'FAQs'} />
      <FAQs data={data} className={cx(c.root)} />
    </>
  );
};
