import { Client, Employee, Position, withPermissions } from '.';
import { DeactivatePositionFeedback } from '../services/feedback.service';

export type ProjectStatus = 'opportunity' | 'running' | 'done';
export type ProjectLabel = 'normal' | 'critical';

export type ProjectFilter = {
  status: ProjectStatus;
  searchKeyword?: string;
};

export type LinkType = 'jira' | 'confluence' | 'sharepoint';

export const getLinkTypes = (): { [key in LinkType]: string } => ({
  confluence: 'Confluence',
  jira: 'Jira',
  sharepoint: 'Sharepoint',
});

export type Link = {
  type: LinkType;
  value: string;
};

export type ProjectsStats = {
  opportunity: number;
  running: number;
  done: number;
  all: number;
};

export type ProjectsMeta = {
  stats: ProjectsStats;
  data: Project[];
  currentStatus: ProjectStatus;
} & Pagination;

export type Pagination = {
  currentPage?: number;
  totalPages?: number;
  totalElements?: number;
};

export type Project = {
  id?: string;
  name: string;
  projectNumber?: string;
  client?: Client;
  location?: string;
  projectLead: Employee;
  projectLeadWorkingHours?: number;
  projectSubLead?: Employee;
  projectSubLeadWorkingHours?: number;
  description?: string;
  startDate: Date;
  endDate: Date;
  budget?: number;
  manDays?: number;
  positions?: Position[];
  status?: ProjectStatus;
  label?: ProjectLabel;
  links?: Link[];
  isNewlyCreated?: boolean;
  staffedPositions?: number;
  totalPositions?: number;
  showFeedback?: DeactivatePositionFeedback[];
} & withPermissions;

export enum EmployeeSearchRadius {
  BA = 'BA',
  SU = 'SU',
  CLUSTER = 'CLUSTER',
  MHP = 'MHP',
  FREELANCER = 'FREELANCER',
}
