import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

type Props = {
  className?: string;
  feedbackOptions: string[];
  onChange?: (value: string, anonymous: boolean) => void;
  initFeedback: () => void;
};

export const PositionFeedback = ({ onChange, feedbackOptions, initFeedback, className }: Props): JSX.Element => {
  const [value, setValue] = useState<string>(feedbackOptions[0]);
  // const [anonymous, setAnonymous] = useState<boolean>(true);

  useEffect(() => {
    initFeedback();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={cx(className)}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="feedback"
          name="feedback1"
          value={value}
          onChange={(event, value) => {
            setValue(value);
            onChange(value, false);
          }}
        >
          {feedbackOptions.map((value, index) => (
            <FormControlLabel key={index} value={value} control={<Radio />} label={value} />
          ))}
        </RadioGroup>
      </FormControl>
      {/*<div>*/}
      {/*  <FormControlLabel*/}
      {/*    control={*/}
      {/*      <Checkbox*/}
      {/*        checked={anonymous}*/}
      {/*        onChange={(event, checked) => {*/}
      {/*          setAnonymous(checked);*/}
      {/*          onChange(value, anonymous);*/}
      {/*        }}*/}
      {/*        name="anonymous"*/}
      {/*        color="primary"*/}
      {/*      />*/}
      {/*    }*/}
      {/*    label="Please anonymize my Feedback"*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};
