// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    ProjectDTO,
    ProjectsMetadataDTO,
} from './';

/**
 * @export
 * @interface ProjectsDTO
 */
export interface ProjectsDTO {
    /**
     * @type {Array<ProjectDTO>}
     * @memberof ProjectsDTO
     */
    projects: Array<ProjectDTO>;
    /**
     * @type {number}
     * @memberof ProjectsDTO
     */
    currentPage: number;
    /**
     * @type {number}
     * @memberof ProjectsDTO
     */
    totalPages: number;
    /**
     * @type {number}
     * @memberof ProjectsDTO
     */
    totalElements: number;
    /**
     * @type {string}
     * @memberof ProjectsDTO
     */
    currentStatus: ProjectsDTOCurrentStatusEnum;
    /**
     * @type {Array<ProjectsMetadataDTO>}
     * @memberof ProjectsDTO
     */
    metadata: Array<ProjectsMetadataDTO>;
}

/**
 * @export
 * @enum {string}
 */
export enum ProjectsDTOCurrentStatusEnum {
    Done = 'DONE',
    Running = 'RUNNING',
    Opportunity = 'OPPORTUNITY'
}

