import { createSelector } from 'reselect';
import {
  isUserEmployee as isUserEmployeeUtil,
  isUserProjectLead as isUserProjectLeadUtil,
  isUserTeamLead as isUserTeamLeadUtil,
} from '../../utils';
import { RootState } from '../rootReducer';

const appState = (state: RootState) => state.app;

export const getConfig = createSelector([appState], (app) => app.config);
export const getUser = createSelector([appState], (app) => app.user);

export const isUserEmployee = createSelector([getUser], isUserEmployeeUtil);
export const isUserProjectLead = createSelector([getUser], isUserProjectLeadUtil);
export const isUserTeamLead = createSelector([getUser], isUserTeamLeadUtil);
export const getUserStatus = createSelector([appState], (app) => app.userStatus);
export const getAuthToken = createSelector([getUser], (user) => user?.token);
export const getHasToChangePassword = createSelector([getUser], (user) => user?.hasToChangePassword);
export const getUserUpdateStatus = createSelector([appState], (app) => app.userUpdateStatus);
