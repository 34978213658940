import { connectRouter, RouterAction } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { StateType } from 'typesafe-actions';
import { AppAction, appReducer as app } from './app';
import { ClientsAction, clientsReducer as clients } from './clients';
import { EmployeeAction, employeeReducer as employee } from './employee';
import { EmployeesAction, employeesReducer as employees } from './employees';
import { FreelancersAction, freelancersReducer as freelancers } from './freelancers';
import { MixedAction, mixedReducer as mixed } from './mixed';
import { OverviewAction, overviewReducer as overview } from './overview';
import { PositionAction, positionReducer as position } from './position';
import { PositionsAction, positionsReducer as positions } from './positions';
import { ProjectAction, projectReducer as project } from './project';
import { ProjectsAction, projectsReducer as projects } from './projects';
import { SearchAction, searchReducer as search } from './search';
import { FileAction, fileReducer as file } from './file';

export type RootAction =
  | RouterAction
  | AppAction
  | ClientsAction
  | EmployeeAction
  | EmployeesAction
  | FileAction
  | FreelancersAction
  | MixedAction
  | OverviewAction
  | PositionAction
  | PositionsAction
  | ProjectAction
  | SearchAction
  | ProjectsAction;

export const rootReducer = (history: History) =>
  combineReducers({
    app,
    clients,
    employee,
    employees,
    file,
    freelancers,
    mixed,
    overview,
    position,
    positions,
    project,
    projects,
    search,
    router: connectRouter(history),
  });

export type RootState = StateType<ReturnType<typeof rootReducer>>;

const routerState = (state: RootState) => state.router;

export const getRouterAction = createSelector([routerState], (router) => router.action);
