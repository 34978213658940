// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    EmailRequestBody,
} from '../models';

export interface SendEmailRequest {
    emailRequestBody: EmailRequestBody;
}

/**
 * no description
 */
export class EmailControllerApi extends BaseAPI {

    /**
     */
    sendEmail({ emailRequestBody }: SendEmailRequest): Observable<object>
    sendEmail({ emailRequestBody }: SendEmailRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    sendEmail({ emailRequestBody }: SendEmailRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(emailRequestBody, 'emailRequestBody', 'sendEmail');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<object>({
            url: '/api/emails',
            method: 'POST',
            headers,
            body: emailRequestBody,
        }, opts?.responseOpts);
    };

}
