import { RouteProps } from 'react-router-dom';

export type NavItemType = {
  children: React.ReactNode;
  to: string;
  exact?: boolean;
  disabled?: boolean;
};

export type RouteType = RouteProps & {
  path: string;
};

export const sitemap = {
  home: { path: '/', name: 'Home' },
  teamLead: {
    employees: {
      root: { path: '/employees', name: 'Employees' },
      detail: { path: '/employees/:id', name: 'Employee' },
    },
  },
  projectLead: {
    overview: { path: '/overview', name: 'Overview' },
    freelancers: {
      root: { path: '/freelancers', name: 'Freelancers' },
      detail: { path: '/freelancers/:id', name: 'Freelancer' },
    },
  },
  projects: {
    root: { path: '/projects', name: 'Projects' },
    detail: { path: '/projects/:id', name: 'Project' },
  },
  positions: {
    root: { path: '/positions', name: 'Positions' },
    detail: { path: '/positions/:id', name: 'Position'},
  },
  search: { path: '/search', name: 'Search' },
  profile: { path: '/profile', name: 'My Profile' },
  settings: { path: '/settings', name: 'Change Password' },
  imprint: { path: '/imprint', name: 'Imprint' },
  faqs: { path: '/faqs', name: 'FAQs' },
  news: { path: '/news', name: "What's new" },
  userguide: { path: '/userguide', name: "Userguide" },
  privacyPolicy: { path: '/privacy-policy', name: 'Privacy Policy' },
  skills: { path: '/admin/skills', name: 'Skills' },
  certificates: { path: '/admin/certificates', name: 'Certificates' },
  locations: { path: '/admin/locations', name: 'Locations' },
  languages: { path: '/admin/languages', name: 'Languages' },
  feedbacks: { path: '/admin/feedbacks', name: 'Feedbacks' },
};

export const toNavItemType = (data: { path: string; name: string }): NavItemType => ({
  to: data.path,
  children: data.name,
});

export const createUrlWithParams = (url: string, params = {}) => {
  Object.keys(params).forEach((key) => (url = url.replace(new RegExp(`:${key}`), params[key])));
  return url;
};
