// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CriteriaRequestDTO
 */
export interface CriteriaRequestDTO {
    /**
     * @type {string}
     * @memberof CriteriaRequestDTO
     */
    name: string;
    /**
     * @type {string}
     * @memberof CriteriaRequestDTO
     */
    status: CriteriaRequestDTOStatusEnum;
    /**
     * @type {string}
     * @memberof CriteriaRequestDTO
     */
    type: CriteriaRequestDTOTypeEnum;
    /**
     * @type {string}
     * @memberof CriteriaRequestDTO
     */
    requestMessage: string
}

/**
 * @export
 * @enum {string}
 */
export enum CriteriaRequestDTOStatusEnum {
    Pending = 'PENDING',
    Confirm = 'CONFIRM',
    Decline = 'DECLINE'
}
/**
 * @export
 * @enum {string}
 */
export enum CriteriaRequestDTOTypeEnum {
    Skill = 'SKILL',
    Language = 'LANGUAGE',
    OfficeLocation = 'OFFICE_LOCATION',
    Certificate = 'CERTIFICATE'
}

