import { makeStyles, createStyles } from '@material-ui/core';
import cx from 'clsx';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReleaseNote, getReleaseNotes } from '../store/overview';
import { getTruncateStyles, getWrapStyles } from '../layout';
import { formatDate } from '../utils';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
      releaseNotesContainer: {
        width: '60%',
        margin: '0 auto',
        padding: theme.spacing(4, 1),
        color: theme.palette.text.secondary,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
      },
      title: {
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(2),
      },
      row: {
        display: 'flex',
        alignItems: 'top',
        padding: theme.spacing(1, 0),
        justifyContent: 'flex-start',
        borderBottom: `1px solid ${theme.palette.background.default}`,
        fontSize: 14,
        lineHeight: '24px',
      },
      col: getTruncateStyles().truncate,
      releaseDate: {
        minWidth: '9%',
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
      },
      versionNumber: {
        minWidth: '8%',
        paddingLeft: theme.spacing(0.5),
        color: theme.palette.primary.main,
      },
      releaseNote: {
        whiteSpace: 'pre-wrap',
        paddingLeft: theme.spacing(0.5),
      },
    }),
  { name: 'NewsPage' }
);

export const NewsPage = (): JSX.Element => {
  const c = useStyles({});
  const { data: releaseNotes } = useSelector(getReleaseNotes);
  const dispatch = useDispatch();

  useEffect(() => {
    !releaseNotes.length && dispatch(fetchReleaseNote.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet title={'News'} />
      {releaseNotes.length !== 0 && (
        <div className={c.releaseNotesContainer}>
          <h2 className={c.title}>Release Notes/ News</h2>
          {releaseNotes.map((item, index) => (
            <div className={c.row} key={index}>
              <span className={cx(c.col, c.releaseDate)}>{formatDate(item.releaseDate)}</span>
              <span className={cx(c.col, c.versionNumber)}>{item.version}</span>
              <span className={cx(c.col, c.releaseNote)}>
                {item.descriptions.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
