import { PaletteOptions } from '@material-ui/core/styles/createPalette';

type HeaderColors = {
  background: string;
};

type AccentColors = {
  green: string;
  yellow: string;
  darkRed: string;
  skill: string;
  decent: string;
};

type StepperColor = {
  background: string;
};

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    header: HeaderColors;
    accent: AccentColors;
    icon: string;
    stepper: StepperColor;
  }

  interface PaletteOptions {
    header?: HeaderColors;
    accent?: AccentColors;
    icon?: string;
    stepper: StepperColor;
  }

  interface TypeText {
    headline: string;
  }
}

export const colorPalette: PaletteOptions = {
  stepper: {
    background: '#E3E8EF',
  },
  background: {
    default: '#f3f5f6',
  },
  text: {
    primary: '#3a3939',
    secondary: '#969ba2',
    headline: '#020236', // midnight
  },
  primary: {
    // light: will be calculated from palette.primary.main,
    main: '#749cde',
    // dark: will be calculated from palette.primary.main,
    contrastText: '#fff',
  },
  error: { main: '#ed684a' }, // pastel-red
  divider: '#e2e8eb', // borders, etc.
  header: {
    background: '#969ba2',
  },
  accent: {
    green: '#87bc49', // turtle-green
    yellow: '#f5c500', // gold
    darkRed: '#ff0000',
    skill: '#8a8a8a',
    decent: '#f8f9fa',
  },
  icon: '#9badb2',
};
