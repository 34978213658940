import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReleaseNote } from '../models';
import { ReleaseNoteControllerApi } from '../proxy';
import { mapArray } from '../utils';
import { AuthInterceptor } from './auth.interceptor';
import { ReleaseNoteDTO } from '../proxy/models';
import { parseJSON } from 'date-fns';

export class ReleaseNoteService {
  private api = new ReleaseNoteControllerApi(AuthInterceptor.Instance);

  static toReleaseNote = (data: ReleaseNoteDTO): ReleaseNote => ({
    version: data.version,
    descriptions: data.descriptions,
    releaseDate: parseJSON(data.releaseDate),
  });

  getReleaseNotes = (): Observable<ReleaseNote[]> =>
    this.api.getReleaseNotes().pipe(map(mapArray(ReleaseNoteService.toReleaseNote)));
}
