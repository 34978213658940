import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Config, FeatureToggleType } from '../models';
import { AppConfigDTO, AppConfigDTOKeyEnum, AppConfigControllerApi } from '../proxy';
import { AuthInterceptor } from './auth.interceptor';

export class ConfigService {
  private api = new AppConfigControllerApi(AuthInterceptor.Instance);

  static toConfig = (data: AppConfigDTO[]): Config => ({
    maxSkillLevel: +data.find((item) => item.key === AppConfigDTOKeyEnum.MaxSkillLevel).value,
    backendVersion: data.find((item) => item.key === AppConfigDTOKeyEnum.Version).value.toString(),
    activeFeatures: data.find((item) => item.key === AppConfigDTOKeyEnum.ActiveFeatures).value as FeatureToggleType[],
  });

  getConfig = (): Observable<Config> => this.api.getAppConfigs().pipe(map(ConfigService.toConfig));
}
