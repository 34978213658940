// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SuggestionSearchDTO
 */
export interface SuggestionSearchDTO {
    /**
     * @type {string}
     * @memberof SuggestionSearchDTO
     */
    value: string;
    /**
     * @type {string}
     * @memberof SuggestionSearchDTO
     */
    label: string;
    /**
     * @type {string}
     * @memberof SuggestionSearchDTO
     */
    type: SuggestionSearchDTOTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SuggestionSearchDTOTypeEnum {
    BusinessTitle = 'BUSINESS_TITLE',
    Skill = 'SKILL',
    Certificate = 'CERTIFICATE',
    OfficeLocation = 'OFFICE_LOCATION',
    Language = 'LANGUAGE',
    Employee = 'EMPLOYEE',
    Project = 'PROJECT',
    Position = 'POSITION',
    Capacity = 'CAPACITY'
}

