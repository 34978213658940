import { createStyles, Grid, makeStyles, Button } from '@material-ui/core';
import cx from 'clsx';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBlock, Loading, OverviewCard } from '../components';
import { getSubNavStyles } from '../components/Tabs';
import { getWrapStyles, Main } from '../layout';
import { fetchOverview, getOverview } from '../store/overview';
import { formatCalendarWeek, formatDetailedDate } from '../utils';
import { getUser } from '../store/app';
import Image from '../assets/landing.jpg';
import { sitemap } from '../routes';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
      main: {
        top: theme.subnav.height,
      },
      head: {
        ...getSubNavStyles(theme).root,
        height: theme.subnav.height,
      },
      headInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: theme.palette.text.secondary,
      },
      cardContainer: {
        padding: theme.spacing(2, 0),
      },
      bold: {
        fontWeight: theme.typography.fontWeightBold,
      },
      greeting: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(2.5, 0, 6.25),
      },
      link: {
        display: 'block',
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(2),
      },
      ul: {
        marginTop: 0,
      },
      overviewPicture: {
        height: 300,
        backgroundImage: `url(${Image})`,
      },
      subHead: {
        height: 200,
        zIndex: 9,
        margin: '-70px 200px 70px 200px',
        background: theme.palette.background.paper,
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        minWidth: '600px',
        fontStyle: 'bold',
      },
      flexItem: {
        margin: '20px auto',
      },
      titleStyle: {
        fontWeight: 'bold',
      },
    }),
  { name: 'Overview' }
);

export const OverviewPage = (): JSX.Element => {
  const c = useStyles({});
  const { data: dashboards, isLoading, error } = useSelector(getOverview);
  const {
    employee: { organization, firstName, lastName },
  } = useSelector(getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    !dashboards.length && dispatch(fetchOverview.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const today = new Date();

  return (
    <Main className={c.main}>
      <Helmet title={'Overview'} />
      <header className={c.head}>
        <div className={cx(c.wrap, c.headInner)}>
          <span className={c.bold}>{`CW ${formatCalendarWeek(today)}`}</span>
          &nbsp;–&nbsp;{formatDetailedDate(today)}
        </div>
      </header>
      <div className={c.overviewPicture}></div>
      <div className={c.subHead}>
        <div className={c.flexItem}>
          <h1 className={c.titleStyle}>
            Welcome at Skill Matching {firstName} {lastName}!
          </h1>
          <h3>Match the right people to the right role at the right time.</h3>
        </div>
        <div className={c.flexItem}>
          <Button href={sitemap.positions.root.path}>Find your next Match</Button>
        </div>
      </div>
      <div className={c.wrap}>
        {error && <ErrorBlock {...error} />}
        {isLoading && <Loading />}
        <Grid container className={c.cardContainer}>
          {dashboards.map((item) => (
            <Grid key={item.type} item md={3}>
              <OverviewCard department={organization?.name} {...item} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Main>
  );
};
