import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
    Employee,
    HttpError,
    Position,
    PotentialEmployeesRequest,
    Project,
    ProjectPosition,
    ProjectPositionEmployee,
} from '../../models';
import { DeactivatePositionFeedback } from '../../services/feedback.service';

export const fetchProject = createAsyncAction(
  '@project/FETCH_PROJECT',
  '@project/FETCH_PROJECT_SUCCESS',
  '@project/FETCH_PROJECT_FAILURE',
  '@project/FETCH_PROJECT_CANCEL'
)<string, Project, HttpError, void>();

export const fetchPositionFeedback = createAsyncAction(
  '@projects/FETCH_POSITION_FEEDBACK',
  '@projects/FETCH_POSITION_FEEDBACK_SUCCESS',
  '@projects/FETCH_POSITION_FEEDBACK_FAILURE',
  '@projects/FETCH_POSITION_FEEDBACK_CANCEL'
)<string[], DeactivatePositionFeedback[], HttpError, void>();

export const createOrUpdateProject = createAsyncAction(
  '@project/CREATE_OR_UPDATE_PROJECT',
  '@project/CREATE_OR_UPDATE_PROJECT_SUCCESS',
  '@project/CREATE_OR_UPDATE_PROJECT_FAILURE'
)<Project, Project, HttpError>();

export const createOrUpdatePosition = createAsyncAction(
  '@project/CREATE_OR_UPDATE_POSITION',
  '@project/CREATE_OR_UPDATE_POSITION_SUCCESS',
  '@project/CREATE_OR_UPDATE_POSITION_FAILURE'
)<ProjectPosition, Position, HttpError>();

export const fetchPotentialEmployees = createAsyncAction(
  '@project/FETCH_POTENTIAL_EMPLOYEE_COUNT',
  '@project/FETCH_POTENTIAL_EMPLOYEE_COUNT_SUCCESS',
  '@project/FETCH_POTENTIAL_EMPLOYEE_COUNT_FAILURE'
)<PotentialEmployeesRequest,
    {
    employees: Employee[];
    totalPages: number;
    totalResults: number;
}, HttpError>();

export const deletePosition = createAsyncAction(
  '@project/DELETE_POSITION',
  '@project/DELETE_POSITION_SUCCESS',
  '@project/DELETE_POSITION_FAILURE'
)<ProjectPosition, string, HttpError>();

export const fetchSuggestions = createAsyncAction(
  '@project/FETCH_EMPLOYEE_SUGGESTIONS',
  '@project/FETCH_EMPLOYEE_SUGGESTIONS_SUCCESS',
  '@project/FETCH_EMPLOYEE_SUGGESTIONS_FAILURE'
)<
  ProjectPositionEmployee,
  {
    totalPages: number;
    totalResults: number;
    positionId: string;
    suggestions: Employee[];
  },
  HttpError
>();

export const setActivePosition = createAction('@project/SET_ACTIVE_POSITION')<string>();
export const resetActivePosition = createAction('@project/RESET_ACTIVE_POSITION')();

export const assignEmployeeToPosition = createAsyncAction(
  '@project/ASSIGN_EMPLOYEE_TO_POSITION',
  '@project/ASSIGN_EMPLOYEE_TO_POSITION_SUCCESS',
  '@project/ASSIGN_EMPLOYEE_TO_POSITION_FAILURE'
)<ProjectPositionEmployee, Project, HttpError>();

export const deleteProject = createAsyncAction(
  '@project/DELETE_PROJECT',
  '@project/DELETE_PROJECT_SUCCESS',
  '@project/DELETE_PROJECT_FAILURE'
)<string, void, HttpError>();

export const resetProject = createAction('@project/RESET_PROJECT')();

export const hideCreatePositionFeedback = createAction('@project/HIDE_CREATE_POSITION_FEEDBACK')<string>();

export type ProjectAction =
  | ActionType<typeof fetchProject>
  | ActionType<typeof fetchPositionFeedback>
  | ActionType<typeof createOrUpdateProject>
  | ActionType<typeof createOrUpdatePosition>
  | ActionType<typeof deletePosition>
  | ActionType<typeof fetchPotentialEmployees>
  | ActionType<typeof fetchSuggestions>
  | ActionType<typeof setActivePosition>
  | ActionType<typeof resetActivePosition>
  | ActionType<typeof assignEmployeeToPosition>
  | ActionType<typeof deleteProject>
  | ActionType<typeof hideCreatePositionFeedback>
  | ActionType<typeof resetProject>;
