import { Epic } from 'redux-observable';
import {concatMap, map, switchMap} from 'rxjs/operators';
import { fetchPosition, deletePosition } from '.';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';

const fetchPositionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { positionService }) =>
    action$.pipe(
        filterAction(fetchPosition.request),
        switchMap(({ payload }) =>
            positionService
                .getPosition(payload)
                .pipe(
                    map(fetchPosition.success),
                    catchErrorAndHandleWithAction(fetchPosition.failure),
                    takeUntilAction(action$, fetchPosition.cancel)
                )
        )
    );

const deletePositionEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { projectService }) =>
    action$.pipe(
        filterAction(deletePosition.request),
        switchMap(({ payload, payload: { id: projectId, position: { id: positionId } } }) =>
            projectService.deletePosition(payload).pipe(
                concatMap(() => [deletePosition.success(positionId)]),
                catchErrorAndHandleWithAction(deletePosition.failure)
            )
        )
    );

export const positionEpics = [
    fetchPositionEpic,
    deletePositionEpic
];
