import { createStyles, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import { Icon } from '.';
import cx from 'clsx';
import React from 'react';
import { ButtonBack, CertificateList, SkillList, TitleValueItem } from '.';
import { getWrapStyles, Main } from '../layout';
import { Position, User } from '../models';
import { canUpdate, formatDate, formatJobTitleFilter, isUserProjectLead } from '../utils';
import { getSubNavStyles } from './Tabs';
import { MATOMO_EVENT_ACTION_SEND_EMAIL, MATOMO_EVENT_ACTION_DOWNLOAD } from '../constants';
import GetAppIcon from '@material-ui/icons/GetApp';
import { environment as env } from '../environments';
import { useToasts } from 'react-toast-notifications';

const HEADER_HEIGHT = 80;

export const getSectionStyles = (theme: Theme) =>
  createStyles({
    root: {
      top: HEADER_HEIGHT,
    },
    head: {
      ...getSubNavStyles(theme).root,
      '& +$section': { marginTop: 0 },
    },
    headCol: {
      display: 'flex',
      alignItems: 'center',
    },
    body: {
      fontSize: 14,
      position: 'relative',
      padding: '10px 0 10px 0',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    languageRow: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    headColTitle: {
      flexDirection: 'column',
      alignItems: 'initial',
    },
    headTitle: {
      height: HEADER_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    headActions: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    warningText: {
      margin: 'auto',
      color: 'red',
    },
    h1: {
      fontSize: 15,
      fontWeight: theme.typography.fontWeightBold,
    },
    section: {
      background: theme.palette.common.white,
      marginTop: theme.spacing(2),
      boxShadow: theme.customShadows.fixed,
      '&:first-child': { marginTop: 0 },
    },
    attachmentField: {
      display: 'flex',
      fontSize: 15,
      margin: '16px 0',
      border: `1px solid ${theme.palette.accent.skill}`,
      borderRadius: 10,
      width: 330,
    },
    languageField: {
      display: 'flex',
      fontSize: 15,
      margin: '16px 0',
      border: `1px solid ${theme.palette.accent.skill}`,
      borderRadius: 10,
      width: 100,
    },
    sectionRelative: {
      position: 'relative', // to cast shadow on top of staffing
    },
    sectionHead: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': { border: 0 },
    },
    sectionHeadTitle: {
      fontSize: 14,
    },
    innerPadding: {
      padding: theme.spacing(0, 2.5),
    },
    sectionContent: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    titleValue: {
      marginTop: theme.spacing(2),
      '&:first-child': { marginTop: 0 },
    },
    email: {
      textDecoration: 'none',
      fontSize: '14',
      fontWeight: 600,
    },
    attachmentDiv: {
      display: 'flex',
      flexDirection: 'row',
    },
    rootWrap: {
      width: '200px',
    },
    languageWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '200px',
      padding: '3px',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
    iconText: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      fontSize: 12,
      marginRight: 15,
      padding: 3,
    },
    shareIcon: {
      color: '#aab2bc',
    },
    divider: {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  });

const useStyles = makeStyles(
  (theme) => ({
    ...getWrapStyles(theme),
    ...getSectionStyles(theme),
    avatar: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'PositionDetail' }
);

type Props = {
  position: Position;
  onAddOrEditPosition: () => void;
  onDeletePosition: () => void;
  user: User;
  backButtonLink?: string;
};

export const PositionDetail = ({
  position,
  user,
  onAddOrEditPosition,
  onDeletePosition,
  backButtonLink,
}: Props): JSX.Element => {
  const c = useStyles({});
  const {
    created,
    role,
    project,
    description,
    startDate,
    endDate,
    minJobTitle,
    maxJobTitle,
    location,
    workingHoursPerWeek,
    skills,
    certificates,
    languages,
    attachments,
    permissions,
  } = position;
  const { addToast } = useToasts();
  const titleValueProps = {
    className: c.titleValue,
    isDetail: true,
  };
  const daysPerWeek = Math.floor(workingHoursPerWeek / 8);
  const getFullUrl = (url: string) => `${env.api.replace(/\/+$/, '')}${url}`;

  async function copyToClip() {
    await navigator.clipboard.writeText(window.location.href);
    addToast('Link Copied!', {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  return (
    <Main className={c.root}>
      <header className={cx(c.section, c.head)}>
        <div className={c.wrap}>
          <div className={c.headTitle}>
            <div>
              <h1 className={c.h1}>{role} </h1>
              <div>{formatJobTitleFilter(minJobTitle, maxJobTitle)}</div>
            </div>
            <div className={c.headActions}>
              <div>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window._paq.push(['trackEvent', MATOMO_EVENT_ACTION_SEND_EMAIL]);
                    }
                  }}
                  href={`mailto:${project?.projectLead.email}?subject=${encodeURIComponent(
                    'Skill Matching | Applicant for your open positions'
                  )}`}
                >
                  <Icon name={'mail'} />
                  <span className={c.iconText}>contact</span>
                </IconButton>
              </div>
              <div>
                <IconButton size="small" onClick={copyToClip}>
                  <Icon name={'share'} />
                  <span className={c.iconText}>share</span>
                </IconButton>
              </div>
              <>
                {canUpdate(permissions) ||
                  (isUserProjectLead(user) && (
                    <>
                      <span className={c.divider}> </span>
                      <IconButton onClick={onAddOrEditPosition} children={<Icon name="edit" />} />
                    </>
                  ))}
              </>
              <>
                {isUserProjectLead(user) && <IconButton onClick={onDeletePosition} children={<Icon name="delete" />} />}
              </>
            </div>
          </div>
          {backButtonLink && <ButtonBack to={backButtonLink} />}
        </div>
      </header>

      <section className={c.section}>
        <div className={cx(c.sectionContent, c.wrap)}>
          <Grid container>
            <Grid item xs>
              <TitleValueItem
                title={'Client'}
                value={project && `${project.client?.name ?? '-'}`}
                {...titleValueProps}
              />
              <TitleValueItem title={'Days per Week'} value={daysPerWeek} {...titleValueProps} />
            </Grid>

            <Grid item xs>
              <TitleValueItem
                title={'Duration'}
                value={`${formatDate(startDate)} – ${formatDate(endDate)}`}
                {...titleValueProps}
              />
              <TitleValueItem title={'Published Since'} value={`${formatDate(created)}`} {...titleValueProps} />
            </Grid>

            <Grid item xs>
              <TitleValueItem title={'Project'} value={project && `${project.name}`} {...titleValueProps} />
            </Grid>

            <Grid item xs>
              <TitleValueItem
                title={'Project Lead'}
                value={project && `${project.projectLead.firstName} ${project.projectLead.lastName}`}
                {...titleValueProps}
              />
            </Grid>

            <Grid item xs>
              <TitleValueItem title={'Location'} value={location?.name} {...titleValueProps} />
            </Grid>
          </Grid>
        </div>
      </section>

      <section className={c.section}>
        <div className={c.wrap}>
          <div className={c.sectionHead}>
            <h2 className={c.sectionHeadTitle}>{'Description'}</h2>
          </div>
          <div className={cx(c.row, c.body)}>
            <TitleValueItem {...{ hasLineBreaks: true, title: '', value: description && `${description}` }} />
          </div>
        </div>
      </section>

      <section className={c.section}>
        <div className={c.wrap}>
          <div className={c.sectionHead}>
            <h2 className={c.sectionHeadTitle}>{'Languages'}</h2>
          </div>
          <div className={cx(c.languageRow, c.body)}>
            {languages?.map(({ id, name }, index) => (
              <div className={cx(c.languageField)}>
                <div className={c.languageWrap}>{name}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={c.section}>
        <div className={c.wrap}>
          <div className={c.sectionHead}>
            <h2 className={c.sectionHeadTitle}>{'Required Skills'}</h2>
          </div>
          <SkillList className={c.sectionContent} skills={skills} />
        </div>
      </section>

      <section className={c.section}>
        <div className={c.wrap}>
          <div className={c.sectionHead}>
            <h2 className={c.sectionHeadTitle}>{'Required Certificates'}</h2>
          </div>
          <CertificateList className={c.sectionContent} certificates={certificates} />
        </div>
      </section>

      <section className={c.section}>
        <div className={c.wrap}>
          <div className={c.sectionHead}>
            <h2 className={c.sectionHeadTitle}>{'Attachments'}</h2>
          </div>
          <div className={cx(c.attachmentDiv)}>
            {attachments?.map((attachment) => (
              <div className={cx(c.row, c.body, c.innerPadding, c.attachmentField)}>
                <TitleValueItem
                  {...{
                    className: c.rootWrap,
                    hasLineBreaks: false,
                    title: '',
                    value: <div key={attachment.id} children={attachment.fileName} />,
                  }}
                />
                <IconButton
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window._paq.push(['trackEvent', MATOMO_EVENT_ACTION_DOWNLOAD]);
                    }
                  }}
                >
                  <a className={c.link} href={getFullUrl(attachment.link)} download>
                    <GetAppIcon />
                  </a>
                </IconButton>
              </div>
            ))}
            {attachments.length === 0 && <div className={cx(c.row, c.body)}>No attachments</div>}
          </div>
        </div>
      </section>
    </Main>
  );
};
