import React, {useEffect, useState} from 'react';
import {
  ConfirmationDialog,
  EmployeeCertificatesFormContainer,
  EmployeeDetail,
  EmployeeFormContainer,
  EmployeeSkillsFormContainer,
  ErrorBlock,
  Loading,
  Modal,
  OrganizationTreeView,
  ProfileImageForm,
  ProjectsHistoryFormContainer,
} from '../components';
import {
  Employee,
  Entity,
  EntityArray,
  FileUploadRequest,
  ProjectHistory,
  UpdateCertificatesRequest,
  UpdateFreeCapacityRequest,
  UpdateProjectsHistoryRequest,
  UpdateSkillsRequest,
  UpdateStatus,
  User,
} from '../models';
import {useSelector} from 'react-redux';
import {getOrganizationTreeRoot} from '../store/mixed';
import {getEmployeeProjects} from "../store/employee";

type ModalType = 'profile' | 'certificates' | 'skills' | 'image' | 'freeCapacity' | 'delete' | 'orgaTree' | 'projectHistory';
const DEFAULT_MODAL_STATE: { [key in ModalType]: boolean } = {
  profile: false,
  orgaTree: false,
  delete: false,
  certificates: false,
  skills: false,
  freeCapacity: false,
  image: false,
  projectHistory: false,
};

type Props = {
  employee: Partial<Entity<Employee>>; // partial so we don't have to supply isLoading and error props
  user: User;
  updateStatus: UpdateStatus;
  projects?: EntityArray<ProjectHistory>;
  createOrUpdateEmployee: (params: Employee) => void;
  deleteEmployee?: () => void;
  updateCertificates?: (params: UpdateCertificatesRequest) => void;
  updateSkills?: (params: UpdateSkillsRequest) => void;
  updateFreeCapacity?: (params: UpdateFreeCapacityRequest) => void;
  uploadProfileImage: (params: FileUploadRequest) => void;
  resetSuccess?: () => void;
  backButtonLink?: string;
  showWarning?: boolean;
  skillRequestToggle?: boolean;
  withCertificate?: boolean;
  updateProjectsHistory: (params: UpdateProjectsHistoryRequest) => void;

};

export const EmployeeContainer = ({
  employee: { data: employee, isLoading, error },
  user,
  updateStatus,
  projects,
  createOrUpdateEmployee,
  deleteEmployee,
  updateCertificates,
  updateSkills,
  uploadProfileImage,
  resetSuccess,
  backButtonLink,
  skillRequestToggle,
  showWarning,
  updateProjectsHistory,
}: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(DEFAULT_MODAL_STATE);

  const openModal = (key: ModalType) => setIsModalOpen((state) => ({ ...state, [key]: true }));
  const closeModal = (key: ModalType) => setIsModalOpen((state) => ({ ...state, [key]: false }));

  const organizationTreeRoot = useSelector(getOrganizationTreeRoot);

  const closeProfileModal = () => closeModal('profile');
  const closeCertificatesModal = () => closeModal('certificates');
  const closeSkillsModal = () => closeModal('skills');
  const closeImageModal = () => closeModal('image');
  const closeOrgaTreeModal = () => closeModal('orgaTree');
  const closeProjectHistoryModal = () => closeModal('projectHistory');


  const  projectsHistory  = useSelector(getEmployeeProjects);


  useEffect(() => {
    if (updateStatus.isSuccess) {
      resetSuccess && resetSuccess();
      setIsModalOpen(DEFAULT_MODAL_STATE);
    }
  }, [updateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  function updateProjectsHistoryHelper(projectsHistory) {
    updateProjectsHistory( {id: employee.id, projectsHistory})
    closeModal('projectHistory')
  }

  return (
    <>
      {error && <ErrorBlock {...error} />}
      {isLoading && <Loading withMargin />}

      {employee && (
        <>
          <EmployeeDetail
            employee={employee}
            user={user}
            onProfileEdit={() => openModal('profile')}
            onShowOrgaTree={() => openModal('orgaTree')}
            onProfileDelete={() => openModal('delete')}
            onCertificatesEdit={() => openModal('certificates')}
            onSkillsEdit={() => openModal('skills')}
            onFreeCapacityEdit={() => openModal('freeCapacity')}
            onProjectHistoryEdit={() => openModal('projectHistory')}
            //onImageEdit={() => openModal('image')} // NOTE: disabled for now
            backButtonLink={backButtonLink}
            showWarning={showWarning}
            projectsHistory={projectsHistory}
          />

          <Modal
            isOpen={isModalOpen.orgaTree}
            onClose={closeOrgaTreeModal}
            headline={'Organization Tree'}
            disableClose={updateStatus.isPending}
            hasNoActions
          >
            {isModalOpen.orgaTree && (
              <OrganizationTreeView
                organizationTreeRoot={organizationTreeRoot}
                currentOrganization={employee.organization}
              />
            )}
          </Modal>

          <Modal
            isOpen={isModalOpen.profile}
            onClose={closeProfileModal}
            headline={'Edit Profile'}
            disableClose={updateStatus.isPending}
          >
            {isModalOpen.profile && (
              <EmployeeFormContainer
                employee={employee}
                updateStatus={updateStatus}
                onSubmit={createOrUpdateEmployee}
                onCancel={closeProfileModal}
              />
            )}
          </Modal>

          <ConfirmationDialog
            isOpen={isModalOpen.delete}
            headline={`Deactivate Employee "${employee.firstName} ${employee.lastName}"`}
            children={'Are you sure?'}
            confirmLabel={'Deactivate'}
            onClose={() => closeModal('delete')}
            onConfirm={deleteEmployee}
            updateStatus={updateStatus}
          />

          <Modal
            isOpen={isModalOpen.certificates}
            onClose={closeCertificatesModal}
            headline={'Edit Certificates'}
            disableClose={updateStatus.isPending}
          >
            {isModalOpen.certificates && (
              <EmployeeCertificatesFormContainer
                employee={employee}
                updateStatus={updateStatus}
                onSubmit={(certificates) => updateCertificates({ id: employee.id, certificates })}
                onCancel={closeCertificatesModal}
              />
            )}
          </Modal>

          <Modal
            isOpen={isModalOpen.skills}
            onClose={closeSkillsModal}
            headline={'Edit Skills'}
            disableClose={updateStatus.isPending}
          >
            {isModalOpen.skills && (
              <EmployeeSkillsFormContainer
                employee={employee}
                updateStatus={updateStatus}
                onSubmit={(skills) => updateSkills({ id: employee.id, skills })}
                onCancel={closeSkillsModal}
                skillRequestToggle={skillRequestToggle}
              />
            )}
          </Modal>

          <Modal
            isOpen={isModalOpen.image}
            onClose={closeImageModal}
            headline={'Change Profile Image'}
            disableClose={updateStatus.isPending}
          >
            {isModalOpen.image && (
              <ProfileImageForm
                employee={employee}
                updateStatus={updateStatus}
                onSubmit={(file) => uploadProfileImage({ id: employee.id, file })}
                onCancel={closeImageModal}
              />
            )}
          </Modal>

          <Modal
              isOpen={isModalOpen.projectHistory}
              onClose={closeProjectHistoryModal}
              headline={'Projects History'}
              disableClose={updateStatus.isPending}
          >
            {isModalOpen.projectHistory && (
                <ProjectsHistoryFormContainer
                    employee={employee}
                    updateStatus={updateStatus}
                    projectsHistory={projectsHistory}
                    onSubmit={(projectsHistory) => updateProjectsHistoryHelper( projectsHistory)}
                    onCancel={closeProjectHistoryModal}
                />
            )}
          </Modal>
        </>
      )}
    </>
  );
};
