import { Button, Grid, InputAdornment, makeStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { date, mixed, number, object, string, StringSchema } from 'yup';
import { ButtonLoading, ErrorBlock } from '.';
import { MAX_DESCRIPTION_LENGTH, MAX_WORKING_HOURS_PER_WEEK, MIN_WORKING_HOURS_PER_WEEK } from '../constants';
import { Client, Employee, getLinkTypes, LinkType, Project, UpdateStatus } from '../models';
import { getFieldProps, getFormStyles, getToday } from '../utils';
import { fromOptionType, OptionType, toEmployeeOptionType, toOptionType } from './AutoCompleteField';

const useStyles = makeStyles((theme) => getFormStyles(theme), { name: 'ProjectForm' });

export const FormActions = ({
  updateStatus,
  ctaLabel,
  onCancel,
  c,
}: {
  updateStatus: UpdateStatus;
  ctaLabel: string;
  onCancel?: () => void;
  c: ReturnType<typeof useStyles>;
}): JSX.Element => {
  const { isPending, error } = { ...updateStatus };
  return (
    <div className={c.actions}>
      {error && <ErrorBlock inModal {...error} />}
      {onCancel && (
        <Button color="default" variant="outlined" disabled={isPending} onClick={onCancel} children={'Cancel'} />
      )}
      <ButtonLoading type="submit" disabled={isPending} children={ctaLabel} />
    </div>
  );
};

type ProjectFormModel = Omit<
  Project,
  | 'client'
  | 'projectLead'
  | 'projectSubLead'
  | 'projectLeadWorkingHours'
  | 'projectSubLeadWorkingHours'
  | 'budget'
  | 'manDays'
  | 'links'
> & {
  client: OptionType;
  projectLead: OptionType;
  projectSubLead: OptionType;
  projectLeadWorkingHours: number | '';
  projectSubLeadWorkingHours: number | '';
  budget: number | '';
  manDays: number | '';
  links: ReturnType<typeof getLinkTypes>;
};

type Props = {
  project?: Project;
  startDate?: Date;
  endDate?: Date;
  updateStatus: UpdateStatus;
  onSubmit: (project: Project) => void;
  onCancel?: () => void;
  clients: Client[];
  projectLeads: Employee[];
  projectSubLeads: Employee[];
  projectLead?: Employee;
  canEditProjectLead?: boolean;
};

export const ProjectForm = ({
  project,
  startDate,
  endDate,
  onSubmit,
  clients,
  projectLeads,
  projectSubLeads,
  projectLead,
  canEditProjectLead,
  ...props
}: Props): JSX.Element => {
  const c = useStyles({});
  const isEditMode = !!project;

  const isValidProject = project ? project.status === 'running' || project.status === 'done' : false;

  const linkTypes = getLinkTypes();
  const initialValues: ProjectFormModel = isEditMode
    ? {
        id: project.id,
        name: project.name ?? '',
        projectNumber: project.projectNumber ?? '',
        client: project.client && toOptionType(project.client),
        location: project.location ?? '',
        projectLead: toEmployeeOptionType(project.projectLead),
        projectSubLead: project.projectSubLead ? toEmployeeOptionType(project.projectSubLead) : undefined,
        projectLeadWorkingHours: project.projectLeadWorkingHours ?? '',
        projectSubLeadWorkingHours: project.projectSubLeadWorkingHours ?? '',
        description: project.description ?? '',
        startDate: project.startDate,
        endDate: project.endDate,
        budget: project.budget ?? '',
        manDays: project.manDays ?? '',
        status: project.status,
        // array to object mapping
        links: Object.keys(linkTypes).reduce((result, key) => {
          const match = project.links && project.links.find((item) => item.type === key);
          result[key] = match ? match.value : '';
          return result;
        }, {}) as ReturnType<typeof getLinkTypes>,
      }
    : {
        name: '',
        client: undefined,
        projectNumber: '',
        location: '',
        projectLead: projectLead && toEmployeeOptionType(projectLead), // used from props
        projectSubLead: undefined,
        projectLeadWorkingHours: 8,
        projectSubLeadWorkingHours: '',
        description: '',
        startDate: startDate || getToday(),
        endDate: endDate || null,
        budget: '',
        manDays: '',
        status: 'opportunity',
        // array to object mapping
        links: Object.keys(linkTypes).reduce((result, key) => {
          result[key] = '';
          return result;
        }, {}) as ReturnType<typeof getLinkTypes>,
      };

  const validateName = (key: keyof ProjectFormModel) => key;
  const validationSchema = object({
    name: string().required('Name is required'),
    client: mixed().when(validateName('status'), (type, schema) =>
      isValidProject ? schema.required('Client is required') : schema
    ),
    projectNumber: string().when(validateName('status'), (type, schema) =>
      isValidProject ? schema.required('Project Number is required') : schema
    ),
    location: string(),
    projectLead: mixed().required('Project Lead is required'),
    projectSubLead: mixed(),
    projectLeadWorkingHours: number().min(MIN_WORKING_HOURS_PER_WEEK).max(MAX_WORKING_HOURS_PER_WEEK),
    projectSubLeadWorkingHours: number().when(validateName('projectSubLead'), (projectSubLead, schema) =>
      projectSubLead
        ? schema.min(MIN_WORKING_HOURS_PER_WEEK).max(MAX_WORKING_HOURS_PER_WEEK)
        : schema.oneOf([''], 'You have to select a Sub Project Lead')
    ),
    description: string().max(MAX_DESCRIPTION_LENGTH, 'The description is too long'),
    startDate: date().required('Start Date is required'),
    endDate: date()
      .typeError('End Date is required')
      .when(validateName('startDate'), (startDate, schema) => startDate && schema.min(startDate))
      .required('End Date is required'),
    budget: number().min(0, 'Budget must be 0 or more').max(1000000000),
    manDays: number().min(0, 'Man days must be 0 or more'),
    links: object(
      Object.keys(linkTypes).reduce((result, key) => {
        result[key] = string().url('Must be a valid url');
        return result;
      }, {}) as { [key in LinkType]: StringSchema }
    ),
  });

  const fieldProps = getFieldProps(props.updateStatus.isPending);

  const filterProjectLeads = (values: ProjectFormModel): Employee[] => {
    const leads = [...(values.projectLead ? [values.projectLead] : [])];
    return projectLeads.filter((item) => !leads.length || !leads.some((lead) => lead.value === item.id));
  };

  const filterProjectSubLeads = (values: ProjectFormModel): Employee[] => {
    const subLeads = [...(values.projectSubLead ? [values.projectSubLead] : [])];
    return projectSubLeads.filter((item) => !subLeads.length || !subLeads.some((lead) => lead.value === item.id));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit({
          ...values,
          client: values.client && fromOptionType(values.client),
          projectLead: { ...fromOptionType(values.projectLead) },
          projectSubLead: values.projectSubLead && fromOptionType(values.projectSubLead),
          projectLeadWorkingHours: +values.projectLeadWorkingHours,
          projectSubLeadWorkingHours: +values.projectSubLeadWorkingHours,
          budget: +values.budget,
          manDays: +values.manDays,
          // since endDate can be an empty string, we got to take care of it manually
          // startDate is the fallback since the field is required by the api
          endDate: values.endDate || values.startDate,
          // object to array mapping
          links: Object.keys(values.links)
            .map((key) => ({ type: key as LinkType, value: values.links[key] }))
            .filter((item) => item.value),
        });
      }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          {isValidProject ? (
            <>
              <Grid className={c.inputGrid} container>
                <Grid item xs={6}>
                  <Field
                    {...fieldProps.text}
                    name={validateName('name')}
                    label={'Name'}
                    placeholder={'Name of the Project'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    {...fieldProps.text}
                    name={validateName('projectNumber')}
                    label={'Project Number'}
                    placeholder={'Project Number'}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid className={c.inputGrid} container>
                <Grid item xs={6}>
                  <Field
                    isCreatable
                    {...fieldProps.select}
                    name={validateName('client')}
                    label={'Client'}
                    placeholder={'Select Client'}
                    options={clients.map(toOptionType)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    {...fieldProps.text}
                    name={validateName('location')}
                    label={'Location'}
                    placeholder={'Project Location'}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid className={c.inputGrid} container>
              <Grid item xs={6}>
                <Field
                  {...fieldProps.text}
                  name={validateName('name')}
                  label={'Name'}
                  placeholder={'Name of the Project'}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  {...fieldProps.text}
                  name={validateName('location')}
                  label={'Location'}
                  placeholder={'Project Location'}
                />
              </Grid>
            </Grid>
          )}

          <div className={c.section}>
            <h2 className={c.sectionTitle}>{'Duration'}</h2>
            <Grid className={c.inputGrid} container>
              <Grid item xs={6}>
                <Field {...fieldProps.date} name={validateName('startDate')} label={'Start Date'} />
              </Grid>
              <Grid item xs={6}>
                <Field
                  {...fieldProps.date}
                  name={validateName('endDate')}
                  label={'End Date'}
                  minDate={values.startDate}
                />
              </Grid>
            </Grid>
          </div>
          <h2 className={c.sectionTitle}>{'Assets'}</h2>
          <div className={c.section}>
            {isValidProject && (
              <>
                <Grid className={c.inputGrid} container>
                  <Grid item xs={8}>
                    <Field
                      {...fieldProps.select}
                      name={validateName('projectLead')}
                      label={'Project Lead'}
                      placeholder={'Select Project Lead'}
                      options={filterProjectLeads(values).map(toEmployeeOptionType)}
                      disabled={!canEditProjectLead}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      {...fieldProps.number}
                      name={validateName('projectLeadWorkingHours')}
                      label={'Working Hours per Week'}
                      inputProps={{ min: MIN_WORKING_HOURS_PER_WEEK, max: MAX_WORKING_HOURS_PER_WEEK }}
                    />
                  </Grid>
                </Grid>
                <Grid className={c.inputGrid} container>
                  <Grid item xs={8}>
                    <Field
                      {...fieldProps.select}
                      name={validateName('projectSubLead')}
                      label={'Project Sub Lead'}
                      placeholder={'Select Project Sub Lead'}
                      options={filterProjectSubLeads(values).map(toEmployeeOptionType)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      {...fieldProps.number}
                      name={validateName('projectSubLeadWorkingHours')}
                      label={'Working Hours per Week'}
                      inputProps={{ min: MIN_WORKING_HOURS_PER_WEEK, max: MAX_WORKING_HOURS_PER_WEEK }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid className={c.inputGrid} container>
              {isValidProject && (
                <Grid item xs={6}>
                  <Field
                    {...fieldProps.number}
                    name={validateName('budget')}
                    label={'Budget'}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" children="€" />,
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <Field {...fieldProps.number} name={validateName('manDays')} label={'Man Days'} />
              </Grid>
            </Grid>
          </div>

          {isValidProject && (
            <div className={c.section}>
              <h2 className={c.sectionTitle}>{'Links'}</h2>
              {Object.keys(values.links).map((key) => (
                <Field key={key} {...fieldProps.text} name={`${validateName('links')}.${key}`} label={linkTypes[key]} />
              ))}
            </div>
          )}

          <div className={c.section}>
            <h2 className={c.sectionTitle}>{'Description'}</h2>
            <Field
              {...fieldProps.text}
              name={validateName('description')}
              label={'Description'}
              helperText={`${values.description.length}/${MAX_DESCRIPTION_LENGTH} characters`}
            />
          </div>

          <FormActions {...props} ctaLabel={isEditMode ? 'Save Project' : 'Add Opportunity'} c={c} />
        </form>
      )}
    </Formik>
  );
};
