// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts } from '../runtime';
import type {
    ClientDTO,
} from '../models';

export interface GetClientRequest {
    id: string;
}

/**
 * no description
 */
export class ClientControllerApi extends BaseAPI {

    /**
     */
    getClient({ id }: GetClientRequest): Observable<ClientDTO>
    getClient({ id }: GetClientRequest, opts?: OperationOpts): Observable<AjaxResponse<ClientDTO>>
    getClient({ id }: GetClientRequest, opts?: OperationOpts): Observable<ClientDTO | AjaxResponse<ClientDTO>> {
        throwIfNullOrUndefined(id, 'id', 'getClient');

        return this.request<ClientDTO>({
            url: '/api/clients/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    getClients(): Observable<Array<ClientDTO>>
    getClients(opts?: OperationOpts): Observable<AjaxResponse<Array<ClientDTO>>>
    getClients(opts?: OperationOpts): Observable<Array<ClientDTO> | AjaxResponse<Array<ClientDTO>>> {
        return this.request<Array<ClientDTO>>({
            url: '/api/clients',
            method: 'GET',
        }, opts?.responseOpts);
    };

}
