import {makeStyles, Snackbar, SnackbarContent} from '@material-ui/core';
import {Field} from 'formik';
import React, {useState} from 'react';
import {ButtonMailTo, CertificateList, CriteriaForm, Modal} from '.';
import {Certificate, Model, UpdateStatus} from '../models';
import {getFieldProps, getFormSectionStyles, notWithId, replaceIn, withId, withName} from '../utils';
import {fromOptionType, OptionType, toOptionType} from './AutoCompleteField';
import {FormikProps} from 'formik/dist/types';
import {useDispatch} from "react-redux";
import {CriteriaFormModel} from "./CriteriaList";
import {createModel, editModel} from "../store/mixed";

const useStyles = makeStyles(
    (theme) => ({
        certificates: {
            marginTop: 0,
        },
        error: {
            color: theme.palette.error.main,
            fontSize: 14,
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        requestButton: {
            marginRight: theme.spacing(0),
            marginLeft: theme.spacing(2),
            whiteSpace: 'nowrap',
            minWidth: 'auto',
        },
        ...getFormSectionStyles(theme),
    }),
    {name: 'CertificateManager'}
);

type Props = {
    name: string;
    targetName: string;
    certificates: Certificate[];
    onChange?: (certificate: Certificate) => void;
    onSetCurrentCertificates?: (certificate: Certificate[]) => void;
    className?: string;
    showRequestButton?: boolean;
    isCreatable?: boolean;
    updateStatus: UpdateStatus;
    form: FormikProps<any>;
};

export const CertificateManager = ({
                                       name,
                                       targetName,
                                       certificates,
                                       onSetCurrentCertificates,
                                       onChange,
                                       isCreatable,
                                       showRequestButton,
                                       className,
                                       updateStatus,
                                       form,
                                   }: Props): JSX.Element => {
    const fieldProps = getFieldProps(updateStatus && updateStatus.isPending);

    const c = useStyles({});
    const [isValid, setIsValid] = useState(true);

    const {setFieldValue, values} = form;
    const currentCertificates: Certificate[] = values[targetName];

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState<Model>(undefined);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const dispatch = useDispatch();
    const createOrEditCriteria = (request: CriteriaFormModel) => {
        selectedCriteria ? dispatch(editModel.request(request)) : dispatch(createModel.request(request));
        setIsCreateModalOpen(false);
        setSelectedCriteria(undefined);
        setIsRequestSubmitted(true);
    };
    const onCloseModel = () => {
        setIsCreateModalOpen(false);
        setSelectedCriteria(undefined);
    };

    const certificateRequestContent = () => `
  - Certificate name:
  
  - Certificate description:
  `;

    return (
        <div className={className}>
            <div className={c.wrapper}>
                <Field
                    className={c.section}
                    field={{name: targetName, value: values[name]}} // rewiring happens here
                    form={{
                        ...form,
                        setFieldValue: undefined
                    }} // override setFieldValue since we call it manually in belows onChange
                    isCreatable={isCreatable}
                    formatCreateLabel={'Request'}
                    label={'Certificates'}
                    placeholder={'Choose a Certificate'}
                    {...fieldProps.select}
                    onChange={(value: OptionType) => {
                        if (value) {
                            if (!currentCertificates.some(withName(value.label))) {
                                const newCertificate = fromOptionType(value) as Certificate;
                                setFieldValue(targetName, currentCertificates.concat({...newCertificate}), true);
                                setFieldValue(name, ''); // clear this field
                                setIsValid(true);
                                if (onSetCurrentCertificates) {
                                    onSetCurrentCertificates([...currentCertificates, newCertificate]);
                                }
                                if (onChange) {
                                    // callback to fetch skill details in PositionForm
                                    onChange(newCertificate);
                                }
                            } else {
                                setFieldValue(name, ''); // clear this field
                                setIsValid(false);
                            }
                        }
                    }}
                    // filter out certificates that are already selected
                    options={certificates.filter(({id}) => !currentCertificates.find(withId(id))).map(toOptionType)}
                />
                {showRequestButton && (
                    <ButtonMailTo
                        className={c.requestButton}
                        text={'Request Certificate'}
                        onClick={() => setIsCreateModalOpen(true)}
                    />
                )}
            </div>

            {!onChange && !isValid && (
                <div className={c.section}>
                    <h2 className={c.error}>{'Looks like you already have this certificate on your profile.'}</h2>
                </div>
            )}

            <CertificateList
                className={c.certificates}
                sectionTitle={'Your Certificates'}
                certificates={currentCertificates}
                onChange={(certificate) => {
                    const newCurrentCertificates = replaceIn(currentCertificates, withName(certificate.name), () => certificate);
                    setFieldValue(targetName, newCurrentCertificates, true);
                    if (onSetCurrentCertificates) {
                        onSetCurrentCertificates(newCurrentCertificates);
                    }
                }}
                onRemove={(id) => {
                    const newCurrentCertificates = currentCertificates.filter(notWithId(id));
                    setFieldValue(targetName, newCurrentCertificates, true);
                    if (onSetCurrentCertificates) {
                        onSetCurrentCertificates(newCurrentCertificates);
                    }
                }}
            />

            <Modal isOpen={isCreateModalOpen} onClose={onCloseModel} headline={"Request Certificate"}>
                {isCreateModalOpen && (
                    <CriteriaForm
                        isUserRequest={true}
                        onCancel={onCloseModel}
                        onSubmit={createOrEditCriteria}
                        type={"certificates"}
                        title={"Certificate"}
                        criteria={selectedCriteria}
                    />
                )}
            </Modal>


            {/*TODO Create a separate component for this, as it is also used in the SkillManager*/}
            <Snackbar
                open={isRequestSubmitted}
                autoHideDuration={2000}
                onClose={() => setIsRequestSubmitted(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                color={"primary"}
            >
                <SnackbarContent
                    message={"Your request has been submitted"}
                    style={{backgroundColor:"#749cde"}}
                />
            </Snackbar>

        </div>


    );
};
