import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AdminHeader, AppRatingDialog, Header, PrivacyPolicyOverlay } from './components';
import { Main } from './layout';
import * as fromPages from './pages';
import { RouteType, sitemap } from './routes';
import './Sentry';
import { acceptPrivacyPolicy, disableFeedback, getConfig, getUser, logout, submitFeedback } from './store/app';
import {
  isInternetExplorer,
  isUserAdmin,
  isUserOnlyEmployee,
  isUserPeopleCounselor,
  isUserPeopleDeveloper,
  isUserProfileValid,
  isUserProjectLead,
  isUserResourceManager,
  isUserTeamLead,
  releaseVersion,
} from './utils';
import { environment as env } from './environments';
import { Footer } from './components/Footer';
import { UnsupportedBrowserOverlay } from './components/UnsupportedBrowserOverlay';
import { useToasts } from 'react-toast-notifications';

export const App = (): JSX.Element => {
  const history = useHistory();
  const { addToast } = useToasts();
  const user = useSelector(getUser);
  const { backendVersion } = useSelector(getConfig);
  const isLoggedIn = user && !user.hasToChangePassword;
  const dispatch = useDispatch();
  const version = releaseVersion(env.version, backendVersion);

  const isProfileValid = isUserProfileValid(user);
  const [adminArea, setAdminArea] = useState(history.location.pathname.includes('admin'));

  type ModalType = 'privacy' | 'appRating';
  const [isModalOpen, setIsModalOpen] = useState<{ [key in ModalType]: boolean }>({
    privacy: !user?.hasAcceptedPrivacyPolicy ?? false,
    appRating: user?.showAppFeedback ?? false,
  });

  useEffect(() => {
    return history.listen((location) => {
      setAdminArea(location.pathname.includes('admin'));
    });
  }, [history]);

  const routes: RouteType[] = [
    ...(isProfileValid
      ? [
          ...(isUserTeamLead(user)
            ? [
                { path: sitemap.teamLead.employees.root.path, component: fromPages.Employees },
                { path: sitemap.teamLead.employees.detail.path, component: fromPages.Employee },
              ]
            : []),
          ...(isUserResourceManager(user) || isUserPeopleDeveloper(user) || isUserPeopleCounselor(user)
            ? [
                { path: sitemap.teamLead.employees.root.path, component: fromPages.Employees },
                { path: sitemap.teamLead.employees.detail.path, component: fromPages.Employee },
              ]
            : []),
          ...(isUserProjectLead(user)
            ? [
                // { path: sitemap.projectLead.freelancers.root.path, component: fromPages.Freelancers },
                  { path: sitemap.teamLead.employees.detail.path, component: fromPages.Employee },
              ]
            : []),
          { path: sitemap.projectLead.overview.path, component: fromPages.Overview },
          { path: sitemap.projects.root.path, component: fromPages.Projects },
          { path: sitemap.projects.detail.path, component: fromPages.Project },
            { path: sitemap.positions.root.path, component: fromPages.OpenPositions },
          { path: sitemap.positions.detail.path, component: fromPages.OpenPosition },
          { path: sitemap.search.path, component: fromPages.Search },
        ]
      : []),
    // users with incomplete profile have to change this first
    { path: sitemap.profile.path, component: fromPages.UserProfile },
    { path: sitemap.settings.path, component: fromPages.Settings },
    { path: sitemap.imprint.path, component: fromPages.Imprint },
    { path: sitemap.faqs.path, component: fromPages.FAQs },
    { path: sitemap.news.path, component: fromPages.News },
    { path: sitemap.userguide.path, component: fromPages.UserGuide },
    { path: sitemap.privacyPolicy.path, component: fromPages.PrivacyPolicy },
    ...(isUserAdmin(user)
      ? [
          { path: sitemap.skills.path, component: fromPages.Skills },
          { path: sitemap.certificates.path, component: fromPages.Certificates },
          { path: sitemap.locations.path, component: fromPages.Locations },
          { path: sitemap.languages.path, component: fromPages.Languages },
          { path: sitemap.feedbacks.path, component: fromPages.Feedbacks },
        ]
      : []),
  ];

  return (
    <Main
      header={
        isLoggedIn ? (
          adminArea && isUserAdmin(user) ? (
            <AdminHeader user={user} onLogout={() => dispatch(logout.request())} />
          ) : (
            <Header user={user} onLogout={() => dispatch(logout.request())} />
          )
        ) : undefined
      }
      footer={<Footer version={version} />}
      isRoot
    >
      <Helmet titleTemplate="%s | m.hub Match" defaultTitle="m.hub Match" />
      <Switch>
        {isLoggedIn ? (
          routes.map((item, index) => <Route exact key={index} {...item} />)
        ) : (
          <Route exact path={sitemap.home.path} component={fromPages.Landing} />
        )}

        <Redirect
          from="*"
          to={
            isLoggedIn
              ? adminArea
                ? sitemap.skills.path
                : !isProfileValid || isUserOnlyEmployee(user)
                ? sitemap.profile.path
                : sitemap.projectLead.overview.path
              : sitemap.home.path
          }
        />
      </Switch>
      {isLoggedIn && (
        <PrivacyPolicyOverlay
          isOpen={isModalOpen.privacy}
          onConfirm={() => {
            dispatch(acceptPrivacyPolicy.request());
            setIsModalOpen((state) => ({ ...state, privacy: false }));
          }}
          onClose={() => setIsModalOpen((state) => ({ ...state, privacy: false }))}
        />
      )}

      {isLoggedIn && (
        <AppRatingDialog
          isOpen={isModalOpen.appRating}
          onClose={() => {
            setIsModalOpen((state) => ({ ...state, appRating: false }));
            dispatch(disableFeedback.request({ type: 'app_rating' }));
          }}
          onConfirm={(rate, anonymous) => {
            dispatch(
              submitFeedback.request({
                type: 'app_rating',
                rate,
                ...(!anonymous && { user: user.email }),
              })
            );
            setIsModalOpen((state) => ({ ...state, appRating: false }));
            addToast('Thank you for your feedback!', {
              appearance: 'success',
              autoDismiss: true,
            });
          }}
        />
      )}

      {isInternetExplorer() && <UnsupportedBrowserOverlay />}
    </Main>
  );
};
