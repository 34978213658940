import { makeStyles, createStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FAQ } from '../models';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        fontSize: 14,
        '&$expanded': {
          background: 'red',
          margin: 0,
        },
      },
      question: {
        fontSize: 14,
        width: '65%',
      },
      title: {
        marginBottom: 40,
      },
      number: {
        width: 28,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightSemiBold,
        paddingRight: theme.spacing(1),
      },
      panel: {
        border: '1px solid #eaeaea',
        borderRadius: 3,
        boxShadow: theme.customShadows.panel,
        marginBottom: 2,
      },
      expandArea: {
        background: '#efefef',
        transition: 'none',
        transform: 'none',
        borderRadius: 0,
        margin: 2,
        minHeight: 56,
        '&$expanded': {
          minHeight: 56,
        },
      },
      text: {
        paddingLeft: 28,
        width: '90%',
      },
      summary: {
        minHeight: 48,
        margin: 0,
      },
      content: {
        border: '1px solid blue',
        '&$expanded': {
          margin: 0,
        },
      },
      expanded: {},
    }),
  { name: 'FAQs' }
);

type Props = {
  className?: string;
  data: FAQ[];
};

export const FAQs = ({ className, data }: Props): JSX.Element => {
  const c = useStyles({});
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={cx(c.root, className)}>
      <h1 className={c.title}>Frequently Asked Questions</h1>
      {data.map(({ question, answer }, index) => (
        <Accordion className={c.panel} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
          <AccordionSummary
            className={c.summary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <span className={c.number}>{index + 1}</span>
            <h1 className={c.question}>{question}</h1>
          </AccordionSummary>
          <AccordionDetails>
            <p className={c.text}>{answer}</p>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
