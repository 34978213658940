// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts } from '../runtime';
import type {
    ReleaseNoteDTO,
} from '../models';

/**
 * no description
 */
export class ReleaseNoteControllerApi extends BaseAPI {

    /**
     */
    getReleaseNotes(): Observable<Array<ReleaseNoteDTO>>
    getReleaseNotes(opts?: OperationOpts): Observable<AjaxResponse<Array<ReleaseNoteDTO>>>
    getReleaseNotes(opts?: OperationOpts): Observable<Array<ReleaseNoteDTO> | AjaxResponse<Array<ReleaseNoteDTO>>> {
        return this.request<Array<ReleaseNoteDTO>>({
            url: '/api/release-notes',
            method: 'GET',
        }, opts?.responseOpts);
    };

}
