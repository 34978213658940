import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobTitles, getLocations } from '../../store/mixed';
import { getEditPosition, reinitEditPosition } from '../../store/positions';
import { getFieldProps, getFormStyles } from '../../utils';
import { toOptionType } from '../AutoCompleteField';
import { PositionFormModel } from '../StepperModal';
import * as Yup from 'yup';
import { SearchType } from '../../models';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getFormStyles(theme),
      checkBox: {
        margin: 0,
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
    }),
  { name: 'PositionForm' }
);

const validateName = (key: keyof PositionFormModel) => key;

export const validationSchemaDetail = Yup.object().shape({
  role: Yup.string().max(50).required('Enter a role'),
  minJobTitle: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable(),
  maxJobTitle: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable(),
  positionDescription: Yup.string().max(255).nullable().notRequired(),
  location: Yup.object().nullable().notRequired(),
});

export const PositionDetailStep = () => {
  const { values, setValues } = useFormikContext<PositionFormModel>();
  const positionTemplate = useSelector(getEditPosition);
  const fieldProps = getFieldProps(false, undefined, true);
  const c = useStyles({});
  const jobTitles = useSelector(getJobTitles).data;
  const locations = useSelector(getLocations).data;
  const dispatch = useDispatch();
  const [renderKey, setRenderKey] = useState(Math.random());

  useEffect(() => {
    if (positionTemplate.reinit && positionTemplate.template) {
      setValues({
        ...values,
        role: positionTemplate.template.role,
        minJobTitle: positionTemplate.template.minJobTitle
          ? toOptionType(positionTemplate.template.minJobTitle)
          : undefined,
        maxJobTitle: positionTemplate.template.maxJobTitle
          ? toOptionType(positionTemplate.template.maxJobTitle)
          : undefined,
        startDate: positionTemplate.template.startDate,
        endDate: positionTemplate.template.endDate,
        location: positionTemplate.template.location ? toOptionType(positionTemplate.template.location) : undefined,
        skills: positionTemplate.template.skills.map((skill) => ({
          criteria: {
            value: skill.id,
            label: skill.name,
          },
          level: skill.level,
          searchType: 'Skill',
        })),
        certificate: positionTemplate.template.certificates.map((certificate) => ({
          criteria: {
            value: certificate.id,
            label: certificate.name,
          },
          searchType: 'Certificate' as SearchType,
        })),
        language: positionTemplate.template.languages.map((language) => ({
          criteria: {
            value: language.id,
            label: language.name,
          },
          searchType: 'Language' as SearchType,
        })),
        daysPerWeek: Math.round(positionTemplate.template.workingHoursPerWeek / 8),
        positionDescription: positionTemplate.template.description,
        contextSelection: positionTemplate.template.project ? 'existing' : 'planned',
        selectedProject: positionTemplate.template.project
          ? {
              criteria: {
                value: positionTemplate.template.project.id,
                label: positionTemplate.template.project.name,
              },
              searchType: 'Project',
            }
          : undefined,
        fileIds: positionTemplate.template.attachments.map(attachment => attachment.id),
        attachments: positionTemplate.template.attachments,
        positionVisibility: positionTemplate.template.visibility?.isActive ? 'public' : 'private',
      });
      setRenderKey(Math.random());
      dispatch(reinitEditPosition(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionTemplate]);

  return (
    <div className={c.wrapper} key={renderKey}>
      <Field {...fieldProps.text} name={validateName('role')} label={'Role'} />
      <Grid className={c.inputGrid} container>
        <Grid item xs={6}>
          <Field
            {...fieldProps.select}
            name={validateName('minJobTitle')}
            label={'Minimum Career Level'}
            placeholder={'Junior Consultant'}
            options={jobTitles.map(toOptionType)}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            {...fieldProps.select}
            name={validateName('maxJobTitle')}
            label={'Maximum Career Level (Optional)'}
            placeholder={'Manager'}
            options={jobTitles.map(toOptionType)}
          />
        </Grid>
      </Grid>
      <Grid className={c.inputGrid} container>
        <Grid item xs={9}>
          <Field
            {...fieldProps.select}
            name={validateName('location')}
            label={'Location (Optional)'}
            placeholder={'Berlin'}
            options={locations.map(toOptionType)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <Field
            {...fieldProps.select}
            name={validateName('mode')}
            label={'Mode'}
            placeholder={'Remote'}
            options={jobTitles.map(toOptionType)}
          />
        </Grid> */}
      </Grid>
      <Grid className={c.inputGrid} container>
        <Grid item xs={12}>
          <Field
            {...fieldProps.textarea}
            name={validateName('positionDescription')}
            label={'Description (Optional)'}
            placeholder={'Description'}
          />
        </Grid>
      </Grid>
    </div>
  );
};
