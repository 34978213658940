import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService, EmployeeService, PositionService } from '.';
import {
  Employee,
  EmployeeSearchRadius,
  Link,
  LinkType,
  Position,
  PotentialEmployeesRequest,
  Project,
  ProjectLabel,
  ProjectPosition,
  ProjectPositionEmployee,
  ProjectsMeta,
  ProjectsStats,
  ProjectStatus,
} from '../models';
import {
  GetProjectsStatusEnum,
  GetSuggestedEmployeesPoolEnum,
  HyperlinkDTO,
  HyperlinkDTOHyperlinkTypeEnum,
  ProjectControllerApi,
  ProjectDTO,
  ProjectDTOLabelEnum,
  ProjectDTOStatusEnum,
  ProjectPositionRequest,
  ProjectRequest,
  ProjectRequestStatusEnum,
  ProjectsMetadataDTO,
  ProjectsMetadataDTOStatusEnum,
} from '../proxy';
import { formatDateForApi, throwIfNotInMap } from '../utils';
import { AuthInterceptor } from './auth.interceptor';
import { ClientService } from './client.service';
import { ProjectsDTOCurrentStatusEnum } from '../proxy/models';
import { parseJSON } from 'date-fns';

export class ProjectService {
  private api = new ProjectControllerApi(AuthInterceptor.Instance);

  static toLinkType = (data: HyperlinkDTOHyperlinkTypeEnum): LinkType => {
    const enumToTypeMap: { [key in HyperlinkDTOHyperlinkTypeEnum]: LinkType } = {
      [HyperlinkDTOHyperlinkTypeEnum.Confluence]: 'confluence',
      [HyperlinkDTOHyperlinkTypeEnum.Jira]: 'jira',
      [HyperlinkDTOHyperlinkTypeEnum.Sharepoint]: 'sharepoint',
    };
    throwIfNotInMap(enumToTypeMap, data, 'HyperlinkDTOHyperlinkTypeEnum');
    return enumToTypeMap[data];
  };

  static toHyperlinkDTOHyperlinkTypeEnum = (type: LinkType): HyperlinkDTOHyperlinkTypeEnum => {
    const typeToEnumMap: { [key in LinkType]: HyperlinkDTOHyperlinkTypeEnum } = {
      confluence: HyperlinkDTOHyperlinkTypeEnum.Confluence,
      jira: HyperlinkDTOHyperlinkTypeEnum.Jira,
      sharepoint: HyperlinkDTOHyperlinkTypeEnum.Sharepoint,
    };
    throwIfNotInMap(typeToEnumMap, type, 'HyperlinkDTOHyperlinkTypeEnum');
    return typeToEnumMap[type];
  };

  static toProjectStatusEnum = (type: ProjectDTOStatusEnum): ProjectStatus => {
    const typeToEnumMap: { [key in ProjectDTOStatusEnum]: ProjectStatus } = {
      [ProjectDTOStatusEnum.Running]: 'running',
      [ProjectDTOStatusEnum.Done]: 'done',
      [ProjectDTOStatusEnum.Opportunity]: 'opportunity',
    };
    throwIfNotInMap(typeToEnumMap, type, 'toProjectStatusEnum');
    return typeToEnumMap[type];
  };

  static toLink = (data: HyperlinkDTO): Link => ({
    type: ProjectService.toLinkType(data.hyperlinkType),
    value: data.url,
  });

  static toHyperlinkDTO = (data: Link): HyperlinkDTO => ({
    hyperlinkType: ProjectService.toHyperlinkDTOHyperlinkTypeEnum(data.type),
    url: data.value,
  });

  static toProject = (data: ProjectDTO): Project => {
    const startDate = parseJSON(data.startDate);
    const endDate = parseJSON(data.endDate);
    return {
      id: data.id,
      name: data.name,
      projectNumber: data.projectNumber,
      client: data.client && ClientService.toClient(data.client),
      location: data.location,
      projectLead: data.projectLead && EmployeeService.toEmployee(data.projectLead), // check is needed for history when there is no project lead
      projectSubLead: data.projectSubLead && EmployeeService.toEmployee(data.projectSubLead),
      projectLeadWorkingHours: data.projectLeadWorkingHours,
      projectSubLeadWorkingHours: data.projectSubLeadWorkingHours,
      description: data.description,
      startDate: startDate,
      endDate: endDate,
      budget: data.budget,
      manDays: data.manDays,
      positions: data.positions?.map(PositionService.toPosition) ?? [], // positions might be undefined for employee project history
      status: data.status ? ProjectService.toProjectStatusEnum(data.status) : undefined,
      label: data.label ? ProjectService.toProjectLabel(data.label) : undefined, // 'normal' | 'critical'
      links: data.hyperlinks?.map(ProjectService.toLink) ?? [],
      permissions: data.permissions?.map(AuthService.toPermission) ?? [],
      staffedPositions: data.staffedPositions,
      totalPositions: data.totalPositions,
    };
  };

  static toProjectStatus = (data: ProjectsMetadataDTOStatusEnum): keyof ProjectsStats => {
    const enumToTypeMap: { [key in ProjectsMetadataDTOStatusEnum]: keyof ProjectsStats } = {
      [ProjectsMetadataDTOStatusEnum.Opportunity]: 'opportunity',
      [ProjectsMetadataDTOStatusEnum.Running]: 'running',
      [ProjectsMetadataDTOStatusEnum.Done]: 'done',
    };
    throwIfNotInMap(enumToTypeMap, data, 'ProjectsMetadataDTOStatusEnum');
    return enumToTypeMap[data];
  };

  static toProjectResponseStatus = (data: ProjectsDTOCurrentStatusEnum): ProjectStatus => {
    switch (data) {
      case ProjectsDTOCurrentStatusEnum.Running:
        return 'running';
      case ProjectsDTOCurrentStatusEnum.Opportunity:
        return 'opportunity';
      case ProjectsDTOCurrentStatusEnum.Done:
        return 'done';
    }
  };

  static toProjectsStats = (data: ProjectsMetadataDTO[]): ProjectsStats =>
    data.reduce((result, { status, count }) => {
      const key = ProjectService.toProjectStatus(status);
      if (key) {
        result[key] = count;
      }
      return result;
    }, {} as ProjectsStats);

  static toProjectRequest = (data: Project): ProjectRequest => ({
    name: data.name,
    projectNumber: data.projectNumber,
    client: data.client,
    location: data.location,
    projectLeadId: data.projectLead.id,
    projectSubLeadId: data.projectSubLead?.id,
    projectLeadWorkingHours: data.projectLeadWorkingHours,
    projectSubLeadWorkingHours: data.projectSubLeadWorkingHours,
    description: data.description,
    startDate: formatDateForApi(data.startDate),
    endDate: formatDateForApi(data.endDate),
    budget: data.budget,
    manDays: data.manDays,
    hyperlinks: data.links?.map(ProjectService.toHyperlinkDTO) ?? [],
    status: ProjectRequestStatusEnum.Running,
  });

  static toProjectPositionRequest = (data: Position): ProjectPositionRequest => ({
    positionRole: data.role,
    description: data.description,
    employeeId: data.employee?.id,
    startDate: formatDateForApi(data.startDate),
    endDate: formatDateForApi(data.endDate),
    minBusinessTitleId: data.minJobTitle?.id,
    maxBusinessTitleId: data.maxJobTitle?.id,
    workingHoursPerWeek: data.workingHoursPerWeek,
    visibility: data.visibility
      ? {
          isActive: data.visibility.isActive,
          startDate: data.visibility.isActive ? formatDateForApi(data.visibility.startDate) : undefined,
          endDate: data.visibility.isActive ? formatDateForApi(data.visibility.endDate) : undefined,
        }
      : undefined,
    skills: data.skills.map(({ id, level, name }) => ({ id, level, name })),
  });

  static toGetSuggestedEmployeesPoolEnum = (type: EmployeeSearchRadius): GetSuggestedEmployeesPoolEnum => {
    const typeToEnumMap: { [key in EmployeeSearchRadius]: GetSuggestedEmployeesPoolEnum } = {
      [EmployeeSearchRadius.BA]: GetSuggestedEmployeesPoolEnum.Ba,
      [EmployeeSearchRadius.SU]: GetSuggestedEmployeesPoolEnum.Su,
      [EmployeeSearchRadius.CLUSTER]: GetSuggestedEmployeesPoolEnum.Cluster,
      [EmployeeSearchRadius.MHP]: GetSuggestedEmployeesPoolEnum.MhpLocal,
      [EmployeeSearchRadius.FREELANCER]: GetSuggestedEmployeesPoolEnum.Freelancer,
    };
    throwIfNotInMap(typeToEnumMap, type, 'EmployeeSearchRadius');
    return typeToEnumMap[type];
  };

  static toProjectLabel = (data: ProjectDTOLabelEnum): ProjectLabel => {
    const enumToTypeMap: { [key in ProjectDTOLabelEnum]: ProjectLabel } = {
      [ProjectDTOLabelEnum.Normal]: 'normal',
      [ProjectDTOLabelEnum.Critical]: 'critical',
    };
    throwIfNotInMap(enumToTypeMap, data, 'ProjectDTOLabelEnum');
    return enumToTypeMap[data];
  };

  static toGetProjectsUsingGETStatusEnum = (type: ProjectStatus): GetProjectsStatusEnum => {
    const typeToEnumMap: { [key in ProjectStatus]: GetProjectsStatusEnum } = {
      opportunity: GetProjectsStatusEnum.Opportunity,
      running: GetProjectsStatusEnum.Running,
      done: GetProjectsStatusEnum.Done,
    };
    throwIfNotInMap(typeToEnumMap, type, 'ProjectStatus');
    return typeToEnumMap[type];
  };

  getProjects = (status: ProjectStatus, page: number, searchKeyword: string): Observable<ProjectsMeta> =>
    this.api
      .getProjects({
        page: page,
        searchKeyword: searchKeyword,
        status: ProjectService.toGetProjectsUsingGETStatusEnum(status),
      })
      .pipe(
        map((result) => ({
          stats: ProjectService.toProjectsStats(result.metadata),
          currentPage: result.currentPage,
          totalPages: result.totalPages,
          totalElements: result.totalElements,
          currentStatus: ProjectService.toProjectResponseStatus(result.currentStatus),
          data: result.projects.map(ProjectService.toProject),
        }))
      );

  getProject = (id: string): Observable<Project> => this.api.getProject({ id }).pipe(map(ProjectService.toProject));

  deleteProject = (id: string): Observable<void> => this.api.deleteProject({ id }).pipe(map(() => undefined));

  createOrUpdateProject = (project: Project): Observable<Project> =>
    of(project).pipe(
      map(({ id, ...data }) => ({ id, projectRequest: ProjectService.toProjectRequest(data) })),
      switchMap(({ id, projectRequest }) =>
        id
          ? this.api.updateProject({ id, projectRequest })
          : this.api.createOpportunity({ opportunityRequest: projectRequest })
      ),
      map((data) => ProjectService.toProject(data)),
      map((data) => (project.id ? data : { ...data, isNewlyCreated: true }))
    );

  createOrUpdatePosition = (position: ProjectPosition): Observable<Position> =>
    of(position).pipe(
      map(({ id, position }) => ({
        id,
        positionId: position.id,
        projectPosition: ProjectService.toProjectPositionRequest(position),
      })),
      switchMap(({ id, positionId, projectPosition }) =>
        positionId
          ? this.api.updateProjectPosition({ projectId: id, positionId, projectPositionRequest: projectPosition })
          : this.api.createProjectPosition({ id, projectPositionRequest: projectPosition })
      ),
      map(PositionService.toPosition)
    );

  fetchPotentialEmployees = ({
    minJobTitle,
    skills,
    maxJobTitle,
    page,
  }: PotentialEmployeesRequest): Observable<{
    totalResults: number;
    totalPages: number;
    employees: Employee[]
  }> =>
    this.api.countPotentialEmployees({
        countPotentialEmployeeRequest: {
          skills: skills.map((data) => ({ id: data.id, level: data.level })),
          minTitleId: minJobTitle?.id,
          maxTitleId: maxJobTitle?.id,
          page: page
        },
      })
        .pipe(
            map(( {employees, totalResults, totalPages }  ) => ({
              employees: employees.map(
                  ({ employee, skillProbability: skillScore, capacityProbability: capacityScore, like }) => ({
                    ...EmployeeService.toEmployee(employee),
                    skillScore,
                    capacityScore,
                    like,
                  })
              ),
                totalResults,
                totalPages,
            }))
        );

  deletePosition = ({ id: projectId, position: { id: positionId } }: ProjectPosition): Observable<void> =>
    this.api.deleteProjectPosition({ projectId, positionId }).pipe(map(() => undefined));

  assignEmployeeToPosition = ({
    projectId: id,
    ...assignEmployeeRequest
  }: ProjectPositionEmployee): Observable<Project> =>
    this.api
      .patchAssignEmployee({ id, assignEmployeeRequest: assignEmployeeRequest })
      .pipe(map(ProjectService.toProject));

  getEmployeeSuggestions = ({
    projectId,
    positionId,
    searchRadius,
    page,
  }: ProjectPositionEmployee): Observable<{
    positionId: string;
    suggestions: Employee[];
    totalResults: number;
    totalPages: number;
  }> =>
    this.api
      .getSuggestedEmployees({
        projectId,
        positionId,
        page,
        pool: ProjectService.toGetSuggestedEmployeesPoolEnum(searchRadius),
      })
      .pipe(
        map(({ employees, totalPages, totalResults }) => ({
          positionId,
          suggestions: employees.map(
            ({ employee, skillProbability: skillScore, capacityProbability: capacityScore, like }) => ({
              ...EmployeeService.toEmployee(employee),
              skillScore,
              capacityScore,
              like,
            })
          ),
          totalPages,
          totalResults,
        }))
      );
}
