import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        padding: theme.spacing(0, 0.25),
        fontFamily: '"Courier New", Courier, monospace',
        color: theme.palette.text.disabled,
      },
    }),
  { name: 'Footer' }
);

type Props = {
  version: string;
};

export const Footer = ({ version }: Props): JSX.Element => <div className={useStyles({}).root}>{`v${version}`}</div>;
