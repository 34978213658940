import { AUTH_HEADER_KEY } from '../constants';
import { environment as env, isFeatureEnabled } from '../environments';
import { Configuration, Middleware, RequestOpts } from '../proxy';
import { store } from '../store';
import { getAuthToken } from '../store/app';

export class AuthInterceptor extends Configuration {
  private static config: AuthInterceptor;

  private constructor() {
    const middleware: Middleware[] = isFeatureEnabled('useRedirectLogin')
      ? [] // no need to set our own auth header
      : [
          {
            pre: (request: RequestOpts): RequestOpts => ({
              ...request,
              headers: {
                ...request.headers,
                [AUTH_HEADER_KEY]: `Bearer ${getAuthToken(store.getState())}`,
              },
            }),
          },
        ];

    super({ middleware, basePath: env.api });
  }

  public static get Instance() {
    return AuthInterceptor.config ?? (AuthInterceptor.config = new this());
  }
}
