import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Icon } from '.';
import { getTruncateStyles } from '../layout';
import { Employee } from '../models';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: theme.typography.fontWeightSemiBold,
      },
      rootTextField: {
        fontWeight: theme.typography.fontWeightRegular,
        '& $bold': {
          fontWeight: theme.typography.fontWeightSemiBold,
        },
      },
      name: getTruncateStyles().truncate,
      bold: {
        fontWeight: theme.typography.fontWeightBold,
      },
      icon: {
        display: 'inline-flex',
        marginLeft: theme.spacing(0.5),
        color: theme.palette.error.main,
      },
      iconGreen: {
        color: theme.palette.accent.green,
      },
      iconBadge: {
        height: theme.spacing(2),
        width: theme.spacing(2),
      },
    }),
  { name: 'FormattedName' }
);

type Props = Pick<Employee, 'firstName' | 'lastName' | 'isExternal' | 'isReplaceable'> & {
  isInTextField?: boolean;
  isShort?: boolean;
  className?: string;
  isEmployeePage?: boolean;
};

export const FormattedName = ({
  isInTextField,
  isShort,
  firstName,
  lastName,
  isExternal,
  isReplaceable,
  isEmployeePage,
  className,
}: Props): JSX.Element => {
  const c = useStyles({});
  return (
    <div className={cx(c.root, { [c.rootTextField]: isInTextField }, className)}>
      <span className={c.name}>
        <span className={c.bold} children={firstName} />
          {isEmployeePage && firstName!= null && lastName!= null && firstName.length+lastName.length > 20 && <br/>}
        {!isShort && lastName && ` ${lastName}`}
      </span>
      {isExternal && (
        <span
          className={cx(c.icon, { [c.iconGreen]: isReplaceable })}
          title={`${isReplaceable ? 'Replaceable ' : ''}Freelancer`}
        >
          <Icon className={c.iconBadge} name="badgeFreelancer" />
        </span>
      )}
    </div>
  );
};
