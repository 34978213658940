import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, EmployeeService, MixedService, ProjectService } from '.';
import {
  Certificate,
  Language,
  Position,
  PositionAttachment,
  PositionCapacity,
  PositionFilter,
  ProjectPositionMeta,
  Skill,
} from '../models';
import { AttachmentDTO, PositionCapacityDTO, PositionControllerApi, ProjectPositionDTO } from '../proxy';
import { AuthInterceptor } from './auth.interceptor';
import { addMonths, parseJSON } from 'date-fns';
import { GetOpenPositionSortByEnum } from '../proxy';
import { PositionFormModel } from '../components/StepperModal';
import { formatDateForApi } from '../utils';

export class PositionService {
  private api = new PositionControllerApi(AuthInterceptor.Instance);

  static toPositionCapacities = (data: PositionCapacityDTO): PositionCapacity => ({
    entryDate: parseJSON(data.entryDate),
    capacity: data.capacity,
  });

  static toAttachments = (data: AttachmentDTO): PositionAttachment => ({
    id: data.id,
    link: data.link,
    fileName: data.fileName,
  });

  static toPosition = (data: ProjectPositionDTO): Position => ({
    id: data.id,
    created: data.created && parseJSON(data.created),
    project: data.project && ProjectService.toProject(data.project),
    role: data.positionRole,
    description: data.description,
    employee: data.employee && EmployeeService.toEmployee(data.employee),
    startDate: parseJSON(data.startDate),
    endDate: parseJSON(data.endDate),
    minJobTitle: data.minBusinessTitle && MixedService.toModel(data.minBusinessTitle),
    maxJobTitle: data.maxBusinessTitle && MixedService.toModel(data.maxBusinessTitle),
    location: data.location && MixedService.toModel(data.location),
    workingHoursPerWeek: data.workingHoursPerWeek,
    manDays: data.manDays,
    skillScore: data.probability,
    skills: data.skills?.map<Skill>(MixedService.toModel) ?? [],
    certificates: data.certificates?.map<Certificate>(MixedService.toModel) ?? [],
    languages: data.languages?.map<Language>(MixedService.toModel) ?? [],
    updateStatus: { isPending: false },
    isReadOnly: data.readOnly,
    capacities: data.capacities?.map(PositionService.toPositionCapacities) ?? [],
    permissions: data.permissions?.map(AuthService.toPermission) ?? [],
    like: data.like,
    attachments: data.attachments?.map(PositionService.toAttachments) ?? [],
    visibility: data.visibility
      ? {
          isActive: data.visibility.isActive,
          startDate: data.visibility.startDate ? parseJSON(data.visibility.startDate) : undefined,
          endDate: data.visibility.endDate ? parseJSON(data.visibility.endDate) : undefined,
        }
      : undefined,
  });

  getOpenPositions = ({ page, sortBy }: PositionFilter): Observable<ProjectPositionMeta> => {
    const sort = sortBy === 'match' ? GetOpenPositionSortByEnum.Match : GetOpenPositionSortByEnum.Latest;
    return this.api.getOpenPosition({ page, sortBy: sort }).pipe(
      map(({ positions, currentPage, totalPages, metaData }) => ({
        data: positions.map(PositionService.toPosition),
        totalPages,
        totalElements: metaData.find((i) => i.status === 'OPEN_POSITIONS').count,
        currentPage,
      }))
    );
  };

  getMyPositions = ({ page }: PositionFilter): Observable<ProjectPositionMeta> => {
    return this.api.getMyPositions({ page }).pipe(
      map(({ positions, currentPage, totalPages, metaData }) => ({
        data: positions.map(PositionService.toPosition),
        totalPages,
        totalElements: metaData.find((i) => i.status === 'MY_POSITIONS').count,
        currentPage,
      }))
    );
  };

  createUpdatePosition = (request: PositionFormModel, positionId?: Position['id']): Observable<Position> => {
    const combinedCriteria = [...request.certificate, ...request.language];
    return this.api
      .putCreateOrUpdatePosition({
        createOrUpdatePositionRequest: {
          id: positionId,
          projectId: request.selectedProject?.criteria?.value,
          description: request.positionDescription,
          positionName: request.role,
          projectName: request.projectName,
          startDate: request.startDate ? formatDateForApi(request.startDate) : undefined,
          endDate: request.endDate ? formatDateForApi(request.endDate) : undefined,
          visibility:
            request.positionVisibility === 'private'
              ? undefined
              : {
                  isActive: true,
                  startDate: formatDateForApi(new Date()),
                  endDate: formatDateForApi(addMonths(new Date(), 3)),
                },
          skills: request.skills.map((skill) => ({
            id: skill.criteria.value,
            name: skill.criteria.label,
            level: skill.level,
          })),
          criteria: combinedCriteria
            ? combinedCriteria.map((criteria) => ({
                id: criteria.criteria.value,
                name: criteria.criteria.label,
              }))
            : undefined,
          location: request.location ? request.location.value : undefined,
          minBusinessTitleId: request.minJobTitle ? request.minJobTitle.value : undefined,
          maxBusinessTitleId: request.maxJobTitle ? request.maxJobTitle.value : undefined,
          workingHoursPerWeek: request.daysPerWeek * 8,
          fileIds: request.fileIds,
        },
      })
      .pipe(map(PositionService.toPosition));
  };

  getPosition = (id: string): Observable<Position> => {
    return this.api.getPosition({ id }).pipe(map(PositionService.toPosition));
  };

  updatePositionLike = ({ id, like }: Position): Observable<void> => {
    return this.api.updatePositionLike({ id, like }).pipe(map(() => undefined));
  };
}
