import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Employee, Skill, UpdateStatus } from '../models';
import { fetchModels, getSkills } from '../store/mixed';
import { EmployeeSkillsForm } from './EmployeeSkillsForm';

type Props = {
  employee: Employee;
  updateStatus: UpdateStatus;
  skillRequestToggle?: boolean;
  onSubmit: (params: Skill[]) => void;
  onCancel: () => void;
};

export const EmployeeSkillsFormContainer = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { confirm: skills } = useSelector(getSkills);

  useEffect(() => {
    if (!skills.length) {
      dispatch(fetchModels.request('skills'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <EmployeeSkillsForm {...props} skills={skills} />;
};
