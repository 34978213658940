import { createStyles, Grid, makeStyles, Paper, Popper } from '@material-ui/core';
import React from 'react';
import { SkillList, TitleValueItem } from '.';
import { Position } from '../models';
import cx from 'clsx';
import { formatDate, formatMonth, getFirstDateInMonth, getLastDateInMonth, getWorkDays } from '../utils';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        zIndex: 1,
      },
      paper: {
        position: 'relative',
        width: 340,
        padding: theme.spacing(3),
        marginLeft: theme.spacing(0.5),
        boxShadow:
          '0 1px 15px 1px rgba(0, 0, 0, 0.15), 0 1px 10px 0 rgba(0, 0, 0, 0.15), 0 1px 4px 0 rgba(0, 0, 0, 0.1)',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: -10,
          marginTop: -5,
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '10px 10px 10px 0',
          borderColor: `transparent ${theme.palette.divider} transparent transparent`,
        },
        '&::after': {
          left: -8,
          borderRightColor: theme.palette.common.white,
        },
      },
      head: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightSemiBold,
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      body: {
        paddingTop: theme.spacing(2),
      },
      section: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
        '& $skills': {
          // nested to beat default fontSize
          fontSize: 12,
        },
      },
      capacities: {
        display: 'inline-block',
        marginLeft: theme.spacing(0.5),
      },
      title: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightSemiBold,
        color: theme.palette.text.secondary,
        marginBottom: 5,
      },
      month: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightSemiBold,
        color: theme.palette.text.primary,
      },
      skills: {},
      row: {
        display: 'flex',
        alignItems: 'center',
      },
      col: { display: 'flex', alignItems: 'center', paddingRight: theme.spacing(2), width: '25%' },
    }),
  { name: 'PositionItemTooltip' }
);

type Props = {
  isOpen: boolean;
  anchorEl: Element;
  position: Position;
};

export const PositionItemTooltip = ({ isOpen, position, anchorEl }: Props): JSX.Element => {
  const c = useStyles({});
  const { workingHoursPerWeek, startDate, endDate, manDays, skills, capacities } = position;

  const metas: { title: string; value: React.ReactNode }[] = [
    { title: 'Workload', value: `${workingHoursPerWeek} h` },
    { title: 'Man Days', value: `${manDays} d` },
  ];

  const calculateManDays = (capacity: number, entryDate: Date) => {
    const workDays = getWorkDays(getFirstDateInMonth(entryDate), getLastDateInMonth(entryDate));
    const days = Math.round((workDays * capacity) / 100);
    return days < 1 ? '<1 d' : `${days} d`;
  };

  return (
    <Popper className={c.root} open={isOpen} anchorEl={anchorEl} placement="right">
      <Paper className={c.paper}>
        <header className={c.head}>
          {formatDate(startDate)} – {formatDate(endDate)}
        </header>
        <div className={c.body}>
          {capacities.length > 0 ? (
            <Grid container className={c.capacities}>
              <h3 className={c.title}>Activity Reports</h3>
              {capacities
                .sort((n1, n2) => n1.entryDate.getTime() - n2.entryDate.getTime())
                .map(({ entryDate, capacity }, index) => (
                  <div className={c.row} key={index}>
                    <div className={cx(c.col, c.month)}>{formatMonth(entryDate)}</div>
                    <div className={c.col}>{calculateManDays(capacity, entryDate)}</div>
                  </div>
                ))}
            </Grid>
          ) : (
            <Grid container>
              {metas.map((item, index) => (
                <Grid key={index} item xs children={<TitleValueItem {...item} />} />
              ))}
            </Grid>
          )}

          <section className={c.section}>
            {skills.length > 0 ? (
              <SkillList className={c.skills} skills={skills} columns={1} isFullWidth />
            ) : (
              'There are no skills defined for this position'
            )}
          </section>
        </div>
      </Paper>
    </Popper>
  );
};
