import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Employee, ModelType, UpdateStatus } from '../models';
import {
  fetchModels,
  getCertificates,
  getJobTitles,
  getLanguages,
  getLocations,
  getOrganizationTreeRoot,
  getSkills,
} from '../store/mixed';
import { pick } from '../utils';
import { EmployeeCreateForm } from './EmployeeCreateForm';
import { EmployeeProfileForm } from './EmployeeProfileForm';

type Props = {
  employee?: Employee;
  isExternal?: boolean;
  updateStatus: UpdateStatus;
  onSubmit: (employee: Employee) => void;
  onCancel: () => void;
};

export const EmployeeFormContainer = ({ employee, ...props }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const formProps = {
    ...props,
    certificates: useSelector(getCertificates).confirm,
    languages: useSelector(getLanguages).confirm,
    skills: useSelector(getSkills).confirm,
    organizationTreeRoot: useSelector(getOrganizationTreeRoot),
    jobTitles: useSelector(getJobTitles).data,
    locations: useSelector(getLocations).data,
  };

  const profileProps: Partial<keyof typeof formProps>[] = ['jobTitles', 'languages', 'locations'];
  const otherProps: Partial<keyof typeof formProps>[] = ['certificates', 'skills'];

  useEffect(() => {
    // fetch missing models
    const models = pick(formProps, employee ? profileProps : profileProps.concat(otherProps));
    Object.keys(models).forEach((key) => !models[key].length && dispatch(fetchModels.request(key as ModelType)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return employee ? <EmployeeProfileForm employee={employee} {...formProps} /> : <EmployeeCreateForm {...formProps} />;
};
