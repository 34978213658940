import {
  Box,
  Button,
  createStyles,
  Fade,
  IconButton,
  makeStyles,
  Modal as MuiModal,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import cx from 'clsx';
import { Formik, FormikHelpers } from 'formik';
import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Modal } from '.';
import { EmployeeEnterpriseSearch, Position } from '../models';
import { getActivePosition, getProjectUpdateStatus } from '../store/project';
import { getToday } from '../utils';
import { OptionType } from './AutoCompleteField';
import { PositionCreatedStep, validationSchemaCreatedPosition } from './stepper/PositionCreatedStep';
import { PositionDetailStep, validationSchemaDetail } from './stepper/PositionDetailStep';
import { ProjectContextStep, validationSchemaProject } from './stepper/ProjectContextStep';
import { SkillsStep, validationSchemaSkills } from './stepper/SkillsStep';
import { StartStep, validationSchemaStart } from './stepper/StartStep';
import { TimeframeStep, validationSchemaTimeframe } from './stepper/TimeframeStep';
import { fetchModels } from '../store/mixed';
import { CertificatesLanguagesStep, validationSchemaCertificatesLanguages } from './stepper/CertificatesLanguagesStep';
import { UploadStep } from './stepper/UploadStep';
import { fetchPosition } from '../store/positions';
import { sitemap } from '../routes';

const BORDER_RADIUS = 13;

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      stepper: {
        backgroundColor: '#E3E8EF',
        borderTopLeftRadius: BORDER_RADIUS,
      },
      stepperContent: {
        backgroundColor: '#E3E8EF',
        padding: theme.spacing(3),
        overflow: 'auto',
        borderBottomLeftRadius: BORDER_RADIUS,
      },
      paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        maxHeight: 700,
        width: '90%',
        maxWidth: 1000,
        background: theme.palette.background.paper,
        boxShadow: theme.customShadows.overlay,
        borderRadius: BORDER_RADIUS,
        outline: 'none',
        display: 'grid',
        gridTemplateColumns: '30% 70%',
        gridTemplateRows: 'auto 1fr',
      },
      stepperHeader: {
        padding: theme.spacing(3),
        borderBottom: `1px solid #CED4DE`,
      },
      header: {
        padding: theme.spacing(3),
        borderBottom: `1px solid #CED4DE`,
      },
      content: {
        position: 'relative',
        padding: theme.spacing(3),
        overflow: 'auto',
      },
      contentWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      },
      contentHasNoActions: {
        margin: 0,
      },
      title: {
        fontSize: 16,
      },
      closeButton: {
        position: 'absolute',
        top: 11, // to be aligned with headline
        right: 11,
      },
      helpButton: {
        color: 'green',
        position: 'absolute',
        top: 11, // to be aligned with headline
        right: 51,
      },
      footer: {
        position: 'absolute',
        bottom: 20,
        maxWidth: 800,
        width: '90%',
        display: 'flex',
        justifyContent: 'space-between',
      },
      nextButton: {
        marginLeft: 12,
      },
    }),
  { name: 'Modal' }
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (request: PositionFormModel, reroute: string) => void;
  headline: string;
  maxWidth?: number;
  disableClose?: boolean;
  hasNoActions?: boolean;
  positionId?: Position['id'];
};

export type PositionFormModel = Omit<
  Position,
  | 'minJobTitle'
  | 'maxJobTitle'
  | 'role'
  | 'description'
  | 'workingHoursPerWeek'
  | 'skills'
  | 'location'
  | 'certificate'
  | 'language'
  | 'fileIds'
> & {
  // Step 1
  startSelection: 'new' | 'existing';
  selectedPosition?: EmployeeEnterpriseSearch;
  // Step 2
  role: string;
  minJobTitle: OptionType;
  maxJobTitle?: OptionType;
  location?: OptionType;
  // mode: string;
  positionDescription?: string;
  // Step 3
  startDate: Date;
  endDate?: Date;
  daysPerWeek?: number;
  // Step 4
  skills: EmployeeEnterpriseSearch[];
  certificate?: EmployeeEnterpriseSearch[];
  language?: EmployeeEnterpriseSearch[];
  // Step 5
  contextSelection: 'existing' | 'planned';
  selectedProject?: EmployeeEnterpriseSearch;
  projectName?: string;
  positionVisibility: 'public' | 'private';
  fileIds: string[];
};

export const initialValuesPositionForm: PositionFormModel = {
  startSelection: 'new',
  selectedPosition: undefined,
  role: undefined,
  positionDescription: undefined,
  minJobTitle: undefined,
  maxJobTitle: undefined,
  startDate: getToday(),
  endDate: new Date(getToday().getFullYear(), getToday().getMonth() + 6),
  daysPerWeek: 5,
  skills: [],
  location: undefined,
  certificate: [],
  language: [],
  contextSelection: 'existing',
  selectedProject: undefined,
  projectName: undefined,
  positionVisibility: 'public',
  fileIds: [],
};

export const StepperModal = ({
  isOpen,
  onClose,
  onSubmit,
  headline,
  maxWidth,
  disableClose,
  positionId,
}: PropsWithChildren<Props>): JSX.Element => {
  const dispatch = useDispatch();
  const updateStatus = useSelector(getProjectUpdateStatus);
  const activePosition = useSelector(getActivePosition);
  const [activeStep, setActiveStep] = useState(0);
  const [isConfirmOpen, setConfirmOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchModels.request('jobTitles'));
    dispatch(fetchModels.request('skills'));
    dispatch(fetchModels.request('locations'));
    if (positionId) {
      dispatch(fetchPosition.request(positionId));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const c = useStyles({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (actions: FormikHelpers<PositionFormModel>) => {
    setActiveStep(0);
    actions.resetForm({ values: initialValuesPositionForm });
  };

  const handleOnClose = () => {
    setConfirmOpen(true);
  };

  const onFinish = (
    values,
    actions: FormikHelpers<PositionFormModel>,
    reroute: string = sitemap.positions.root.path
  ) => {
    if (isLastStep) {
      onSubmit(values, reroute);
      handleReset(actions);
    } else {
      actions.setTouched({});
      actions.setSubmitting(false);
      handleNext();
    }
  };

  const stepper = [
    ...(positionId
      ? []
      : [
          {
            step: 'Start',
            stepHeader: 'How do you want to start?',
            schema: validationSchemaStart,
            component: <StartStep />,
          },
        ]),
    {
      step: 'Position Detail',
      stepHeader: 'What are you looking for?',
      schema: validationSchemaDetail,
      component: <PositionDetailStep />,
    },
    {
      step: 'Timeframe',
      stepHeader: "What's the time frame?",
      schema: validationSchemaTimeframe,
      component: <TimeframeStep />,
    },
    {
      step: 'Skills',
      stepHeader: 'Which Skills are required?',
      schema: validationSchemaSkills,
      component: <SkillsStep position={activePosition} updateStatus={updateStatus} />,
    },
    {
      step: 'Certificates',
      stepHeader: 'Certificates',
      schema: validationSchemaCertificatesLanguages,
      component: (
        <CertificatesLanguagesStep position={activePosition} updateStatus={updateStatus} type={'Certificate'} />
      ),
    },
    {
      step: 'Languages',
      stepHeader: 'Languages',
      schema: validationSchemaCertificatesLanguages,
      component: <CertificatesLanguagesStep position={activePosition} updateStatus={updateStatus} type={'Language'} />,
    },
    ...(positionId
      ? []
      : [
          {
            step: 'Project Context',
            stepHeader: "What's the project context?",
            schema: validationSchemaProject,
            component: <ProjectContextStep />,
          },
        ]),
    {
      step: 'Attachment',
      stepHeader: 'Upload',
      component: <UploadStep />,
    },
    {
      step: 'Position visibility',
      stepHeader: 'Position visibility',
      schema: validationSchemaCreatedPosition,
      component: <PositionCreatedStep />,
    },
  ];

  const isLastStep = activeStep === stepper.length - 1;

  return (
    <Fragment>
      <Formik
        initialValues={initialValuesPositionForm}
        validationSchema={stepper[activeStep].schema}
        onSubmit={onFinish}
      >
        {(props) => (
          <>
            <Modal
              isOpen={isConfirmOpen}
              onClose={() => setConfirmOpen(false)}
              headline='Cancel "New Position"'
              maxWidth={400}
            >
              <Typography variant="body2">
                You're about to cancel the creation of a new position. If you continue you'll loose all your progress.
              </Typography>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                position={'absolute'}
                bottom={0}
                left={0}
                padding={2}
                width={'100%'}
              >
                <Button variant="outlined" onClick={() => setConfirmOpen(false)}>
                  Back
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setConfirmOpen(false);
                    handleReset(props);
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Modal>
            <MuiModal
              open={isOpen}
              onClose={(e, reason) => {
                if (reason !== 'backdropClick') {
                  handleOnClose();
                }
              }}
              disableEscapeKeyDown={disableClose}
            >
              <Fade in={isOpen}>
                <div className={c.paper} style={maxWidth && { maxWidth: maxWidth }}>
                  <div className={c.stepper}>
                    {headline && (
                      <header className={c.stepperHeader}>
                        <h2 className={c.title}>{headline}</h2>
                      </header>
                    )}
                  </div>
                  <div>
                    {headline && (
                      <header className={c.header}>
                        <h2 className={c.title}>{stepper[activeStep].stepHeader}</h2>
                        <IconButton
                          className={c.closeButton}
                          onClick={handleOnClose}
                          disabled={disableClose}
                          children={<Icon name="close" />}
                        />
                      </header>
                    )}
                  </div>
                  <aside className={cx(c.stepperContent)}>
                    <div>
                      <Stepper activeStep={activeStep} orientation="vertical">
                        {stepper.map((step, index) => (
                          <Step key={index}>
                            <StepLabel>{step.step}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  </aside>
                  <section className={cx(c.content)}>
                    <form onSubmit={props.handleSubmit}>
                      <div className={cx(c.contentWrapper)}>{stepper[activeStep].component}</div>
                      <footer className={cx(c.footer)}>
                        <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0}>
                          Back
                        </Button>
                        <div>
                          <Button className={c.nextButton} type="submit">
                            {!isLastStep ? 'Next' : 'Finish'}
                          </Button>
                          {/*versteckt go to project when selectedProject = null*/}
                        </div>
                      </footer>
                    </form>
                  </section>
                </div>
              </Fade>
            </MuiModal>
          </>
        )}
      </Formik>
    </Fragment>
  );
};
