import { makeStyles, createStyles, FormHelperText } from '@material-ui/core';
import {useFormikContext} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {EnterpriseSearchField, List} from '..';
import {
    Employee,
    Position,
    UpdateStatus
} from '../../models';
import {fetchPotentialEmployees, getPotentialEmployees} from '../../store/project';
import { autoCompleteSuggestions, clearSuggestion, getCriteriaOptions } from '../../store/search';
import {getFormStyles} from '../../utils';
import {fromOptionType, toEmployeeOptionType} from '../AutoCompleteField';
import { PositionFormModel } from '../StepperModal';
import * as Yup from 'yup';
import cx from "clsx";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getFormStyles(theme),
      checkBox: {
        margin: 0,
      },
      skills: {
        height: 400,
        overflowY: 'scroll',
      },
      divider: {
        margin: '14px 0px',
      },
      caption: {
        width: '100%',
      },
        titleHead: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: theme.spacing(2) - 5, // compensate 10px higher content
        },
        titleHeadTitle: {
            fontSize: 14,
        },
        form: {
            // nesting to override inner styles
            '& $suggestions': {
                padding: 0,
            },
        },
        suggestions: {
            maxHeight: '30vh',
            overflow: 'auto',
        },
    }),
  { name: 'PositionForm' }
);

const validateName = (key: keyof PositionFormModel) => key;

type Props = {
  position?: Position;
  updateStatus?: UpdateStatus;
};

export const validationSchemaSkills = Yup.object().shape({
  skills: Yup.array().min(1, 'Select at least one skill').max(10),
});

export const SkillsStep = ({ position, updateStatus }: Props) => {
  const { values, setFieldValue, errors } = useFormikContext<PositionFormModel>();
  const [rerender, setRerender] = useState(true);
  const dispatch = useDispatch();
  const c = useStyles({});
  const potentialEmployees = useSelector(getPotentialEmployees);
  const [employees, setEmployees] = useState(potentialEmployees?.employees);
  const criteriaOptions = useSelector(getCriteriaOptions);
  const [page, setPage] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>(undefined);
  const scrollPositionRef = useRef(0);


    useEffect(() => {
        handleSkillChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.skills]);

    const handleSkillChange = () => {
        const request = createRequest(1);
        dispatch(fetchPotentialEmployees.request(request));
        setPage(1);
        setEmployees([])
    };

    const createRequest = (page) => ({
        skills: values.skills.map(skill => ({
            id: skill.criteria.value,
            level: skill.level,
            name: skill.criteria.label,
        })),
        minJobTitle: values.minJobTitle ? fromOptionType(values.minJobTitle) : null,
        maxJobTitle: values.maxJobTitle ? fromOptionType(values.maxJobTitle) : null,
        page: page
    });

    const loadData = (page) => {
        const request = createRequest(page);
        const element = document.getElementById('EmployeeSuggestionList');
        if (element) {
            scrollPositionRef.current = element.scrollTop;
        }
        dispatch(fetchPotentialEmployees.request(request));
        setPage(page);
    };

    const handleScroll = () => {
        const element = document.getElementById('EmployeeSuggestionList');
        const scrollTop = element?.scrollTop;
        const offsetHeight = element?.offsetHeight;
        const scrollHeight = element?.scrollHeight;
        if (scrollTop + offsetHeight > scrollHeight * 0.7 &&
            !potentialEmployees?.isLoadingPotentialEmployees &&
            page < potentialEmployees?.totalPages) {
            loadData(page + 1);
        }
    };

    useEffect(() => {
        if (potentialEmployees?.employees?.length>0){
            setEmployees(prevEmployees => [...prevEmployees, ...potentialEmployees?.employees]);
        }
    }, [potentialEmployees]);
    useEffect(() => {
        const element = document.getElementById('EmployeeSuggestionList');
        if (element) {
            element.scrollTop = scrollPositionRef.current;
        }
    }, [employees]);
  return (
    <div className={c.section}>
      {rerender && (
        <div>
          <EnterpriseSearchField
            useLogic={false}
            criteriaOptions={criteriaOptions}
            onInputChange={(keyword) =>
              keyword
                ? dispatch(
                    autoCompleteSuggestions.request({
                      keyword,
                      types: ['Skill'],
                    })
                  )
                : dispatch(clearSuggestion())
            }
            onSubmit={(field) => {
              setFieldValue(validateName('skills'), [...values.skills, field]);
              setRerender(false);
              setRerender(true);
            }}
            placeholder="Add skill"
          />
          {errors.skills && <FormHelperText error>{errors.skills}</FormHelperText>}
        </div>
      )}
      <div className={c.skills}>

          {(values.skills ?? []).map((field, index) => (
                  <EnterpriseSearchField
                      skillsOverlap = {selectedEmployee && selectedEmployee.skills}
                      useLogic={false}
                      searchField={field}
                      onDelete={() => {
                          setFieldValue(validateName('skills'), [
                              ...values.skills.filter((value) => value.criteria.value !== field.criteria.value),
                          ]);
                      }}
                      onLevelChange={({ level }) => {
                          const findSkill = values.skills.find((value, i) => i === index);
                          const newSkillLevel = {
                              ...findSkill,
                              level,
                          };
                          setFieldValue(validateName('skills'), [
                              ...values.skills.map((field, i) => (i === index ? newSkillLevel : field)),
                          ]);
                      }}
                      key={index}
                  />
              ))
          }
          <div className={c.section}>
              <div className={cx(c.sectionTitle, c.titleHead)}>
                  {values.skills?.length !== 0 && <h2 className={c.titleHeadTitle}>{`Employee Suggestions (${potentialEmployees?.totalResults || 0})`}</h2>}
              </div>

              {values.skills?.length === 0 && "It's not possible to provide suggestions without any skills on position."}
          </div>
          <form className={c.form}>
              {potentialEmployees?.employees?.length > 0 &&
                  <List
                      className={c.suggestions}
                      type="employee"
                      isEmployeeSuggestion={true}
                      withAvailability={true}
                      activeItemId={selectedEmployee && selectedEmployee.id}
                      data={{ data: employees, isLoading: potentialEmployees?.isLoadingPotentialEmployees, error: !values.skills?.length && {} }} // empty error to prevent "no results" message
                      onClick={(item) => {
                          setSelectedEmployee(item);
                          setFieldValue(validateName('employee'), toEmployeeOptionType(item));
                      }}
                      handleScroll={handleScroll}
                  />}
          </form>
      </div>

    </div>
  );
};
