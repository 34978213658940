import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService, MixedService } from '.';
import { environment as env } from '../environments';
import {
  Employee,
  EmployeeFilter,
  EmployeeSearchRequest,
  EmployeesMeta,
  FileUploadRequest,
  FreeCapacity,
  ProjectHistory,
  SearchEmployeeDownloadFile,
  Skill,
  UpdateCertificatesRequest,
  UpdateProjectsHistoryRequest,
  UpdateSkillsRequest,
} from '../models';
import {
  CreateFreelancerRequest,
  CreateInternalEmployeeRequest,
  EmployeeControllerApi,
  EmployeeDTO,
  EmployeeListViewDTO,
  EmployeesDTO,
  FileDTO,
  FreeCapacityDTO, ProjectDTO,
  SearchEmployeesSortByEnum,
  UpdateExternalEmployeeRequest,
  UpdateInternalEmployeeRequest,
} from '../proxy';
import { formatDateForApi, mapArray } from '../utils';
import { AuthInterceptor } from './auth.interceptor';
import { parseJSON } from 'date-fns';
import {ProjectHistoryDTO} from "../proxy/models/ProjectHistoryDTO";

export class EmployeeService {
  private api = new EmployeeControllerApi(AuthInterceptor.Instance);

  static getFullUrl = (url: string) => `${env.api.replace(/\/+$/, '')}${url}`;

  static toEmployee = (data: EmployeeDTO): Employee => ({
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    jobTitle: data.title && MixedService.toModel(data.title),
    location: data.location && MixedService.toModel(data.location),
    organization: data.department && MixedService.toOrganization(data.department),
    placeOfResidence: data.residence,
    languages: data.languages?.map(MixedService.toModel) ?? [],
    certificates: data.certificates?.map(MixedService.toModel) ?? [],
    workingHoursPerWeek: data.workingHoursPerWeek,
    skills: data.skills?.map<Skill>(MixedService.toModel) ?? [],
    profileImage: data.profilePic && EmployeeService.getFullUrl(data.profilePic),
    capacityScore: data.capacity ?? null,
    capacityScoreNextMonth: data.capacityNextMonth ?? null,
    freeCapacities: data.freeCapacities?.map(EmployeeService.toFreeCapacity) ?? [],
    isExternal: data.external,
    isReplaceable: data.replaceable,
    isValid: data.userValid,
    peopleCounselor: data.peopleCounselor && EmployeeService.toEmployee(data.peopleCounselor),
    teamLead: data.teamLead && EmployeeService.toEmployee(data.teamLead),
    permissions: data.permissions?.map(AuthService.toPermission) ?? [],
  });

  // TODO: mhh rename or let backend change this
  static toEmployeeForList = (data: EmployeeListViewDTO): Employee => ({
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    jobTitle: data.title && MixedService.toModel(data.title),
    location: data.location && MixedService.toModel(data.location),
    organization: data.department && MixedService.toOrganization(data.department),
    profileImage: data.profilePic && EmployeeService.getFullUrl(data.profilePic),
    capacityScore: data.capacity >= 0 ? data.capacity : undefined,
    capacityScoreNextMonth: data.capacityNextMonth >= 0 ? data.capacityNextMonth : undefined,
    isExternal: data.external,
    isReplaceable: data.replaceable,
  });

  static toEmployeesMeta = (data: EmployeesDTO): EmployeesMeta => ({
    data: data.employees.map(EmployeeService.toEmployeeForList),
    currentPage: data.currentPage,
    totalPages: data.totalPages,
    totalElements: data.totalElements,
  });

  static toFreeCapacity = (data: FreeCapacityDTO): FreeCapacity => ({
    isEnabled: true,
    freeDaysPerWeek: data.daysPerWeek,
    startDate: data.startDate && parseJSON(data.startDate),
    endDate: data.endDate && parseJSON(data.endDate),
  });

  // -------------------------

  static toCreateInternalEmployeeRequest = (data: Employee): CreateInternalEmployeeRequest => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    titleId: data.jobTitle.id,
    locationId: data.location?.id,
    departmentId: data.organization.id,
    residence: data.placeOfResidence,
    languages: data.languages,
    certificates: data.certificates,
    workingHoursPerWeek: data.workingHoursPerWeek,
    skills: data.skills.map(MixedService.toSkillRequest),
    projectLead: false, // TODO: map this
  });

  static toCreateFreelancerRequest = (data: Employee): CreateFreelancerRequest => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    workingHoursPerWeek: data.workingHoursPerWeek,
  });

  static toUpdateInternalEmployeeRequest = (data: Employee): UpdateInternalEmployeeRequest => ({
    departmentId: data.organization.id,
    residence: data.placeOfResidence,
    locationId: data.location.id,
    languages: data.languages,
    titleId: data.jobTitle.id,
    // certificates: toIdArray(data.certificates),
    workingHoursPerWeek: data.workingHoursPerWeek,
    // skills: data.skills.map(MixedService.toSkillRequest),
  });

  static toUpdateExternalEmployeeRequest = (data: Employee): UpdateExternalEmployeeRequest => ({
    residence: data.placeOfResidence,
    locationId: data.location?.id,
    languages: data.languages,
    workingHoursPerWeek: data.workingHoursPerWeek,
    replaceable: data.isReplaceable,
  });

  static toFile = (data: FileDTO): SearchEmployeeDownloadFile => ({
    url: EmployeeService.getFullUrl(data.uri),
    fileName: data.fileName,
  });
  // -------------------------

  getProjectLeads = (): Observable<Employee[]> =>
    this.api.getProjectLeads().pipe(map(mapArray(EmployeeService.toEmployee)));

  getInternalEmployees = (): Observable<Employee[]> =>
    this.api.getInternalEmployees().pipe(map(mapArray(EmployeeService.toEmployee)));

  acceptPrivacyPolicy = (): Observable<void> => this.api.acceptPrivacyPolicy();

  searchEmployees = ({
    searchNames,
    skillIds,
    startDate,
    endDate,
    isVWSkill,
    page,
    orderBy,
    orderAsc,
  }: EmployeeSearchRequest): Observable<EmployeesMeta> => {
    const sortBy =
      orderBy === 'lastName'
        ? orderAsc
          ? SearchEmployeesSortByEnum.LastNameAsc
          : SearchEmployeesSortByEnum.LastNameDesc
        : orderAsc
        ? SearchEmployeesSortByEnum.CapacityAsc
        : SearchEmployeesSortByEnum.CapacityDesc;
    return this.api
      .searchEmployees({
        ...(searchNames?.length && { employeeNames: searchNames }), // filter out empty array
        ...(skillIds?.length && { skillIds }), // filter out empty array
        ...(isVWSkill && { isVWSkill }),
        startDate: formatDateForApi(startDate),
        endDate: formatDateForApi(endDate),
        sortBy: sortBy,
        page,
      })
      .pipe(map(EmployeeService.toEmployeesMeta));
  };

  downloadSearchEmployees = ({
    searchNames,
    skillIds,
    startDate,
    endDate,
  }: EmployeeSearchRequest): Observable<SearchEmployeeDownloadFile> => {
    return this.api
      .downloadEmployees({
        ...(searchNames?.length && { employeeNames: searchNames }), // filter out empty array
        ...(skillIds?.length && { skillIds }), // filter out empty array
        startDate: formatDateForApi(startDate),
        endDate: formatDateForApi(endDate),
      })
      .pipe(map((data) => EmployeeService.toFile(data)));
  };

  searchFreelancers = ({ searchNames, skillIds, isReplaceable, page }: EmployeeFilter): Observable<EmployeesMeta> =>
    this.api
      .searchFreelancers({
        ...(searchNames?.length && { employeeNames: searchNames }), // filter out empty array
        ...(skillIds?.length && { skillIds }), // filter out empty array
        ...(isReplaceable && { replaceable: isReplaceable }),
        page,
      })
      .pipe(map(EmployeeService.toEmployeesMeta));

  getEmployee = (id: string): Observable<Employee> =>
    this.api.getEmployee({ id }).pipe(map(EmployeeService.toEmployee));

  deleteEmployee = (id: string): Observable<void> => this.api.deleteEmployee({ id });

  createOrUpdateEmployee = (employee: Employee): Observable<Employee> =>
    of(employee).pipe(
      switchMap(({ id, isExternal, ...data }) =>
        id
          ? isExternal
            ? this.api.putUpdateExternalEmployee({
                id,
                updateExternalEmployeeRequest: EmployeeService.toUpdateExternalEmployeeRequest(data),
              })
            : this.api.putUpdateInternalEmployee({
                id,
                updateInternalEmployeeRequest: EmployeeService.toUpdateInternalEmployeeRequest(data),
              })
          : isExternal
          ? this.api.postCreateFreelancer({ createFreelancerRequest: EmployeeService.toCreateFreelancerRequest(data) })
          : this.api.postCreateInternalEmployee({
              createInternalEmployeeRequest: EmployeeService.toCreateInternalEmployeeRequest(data),
            })
      ),
      map(EmployeeService.toEmployee)
    );

  updateCertificates = ({ id, certificates }: UpdateCertificatesRequest): Observable<Employee> =>
    this.api.updateCertificates({ id, criteriaRequest: certificates }).pipe(map(EmployeeService.toEmployee));

  updateSkills = ({ id, skills }: UpdateSkillsRequest): Observable<Employee> =>
    this.api
      .updateSkills({ id, skillRequest: skills.map(MixedService.toSkillRequest) })
      .pipe(map(EmployeeService.toEmployee));
  uploadProfileImage = (params: FileUploadRequest): Observable<string> =>
    this.api.uploadProfilePic(params).pipe(map((data) => EmployeeService.getFullUrl(data.uri)));

  // getProjects = (id: string): Observable<ProjectHistory[]> =>
  //     this.api.getHistoryProjects({ id }).pipe(map(mapArray(EmployeeService.toProjectHistory)));
  updateProjectsHistory = ({ id, projectsHistory }: UpdateProjectsHistoryRequest): Observable<ProjectHistory[]> =>
      this.api.updateProjectsHistory({ id, projectsHistory}).pipe(map(mapArray(EmployeeService.toProjectHistory(id))));
  getProjects = (id: string): Observable<ProjectHistory[]> =>
      this.api.getHistoryProjects({ id }).pipe(map(mapArray(EmployeeService.toProjectHistory(id))));

  static toProjectHistory = (employeeId: string) => (data: ProjectHistoryDTO): ProjectHistory => {
    const startDate = data.startDate? parseJSON(data.startDate) : null;
    const endDate = data.startDate?  parseJSON(data.endDate): null;
    return {
      realProjectId: data.realProjectId,
      name: data.name,
      employeeId: employeeId,
      projectNumber: data.projectNumber,
      client: data.client,
      location: data.location,
      projectLead: data.projectLead,
      role: data.role,
      projectHistoryDescription: data.projectHistoryDescription,
      startDate: startDate,
      endDate: endDate,
      manDays: data.manDays,
      isFromRealProject: data.isFromRealProject,
      id: data.id
    };
  };


}



