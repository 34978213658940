import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonFloating,
  EmployeeFormContainer,
  EmployeeSearchContainer,
  EmployeeSorting,
  ErrorBlock,
  Icon,
  List,
  Modal,
} from '../components';
import { getSubNavStyles } from '../components/Tabs';
import { getWrapStyles, Main } from '../layout';
import { EmployeeFilter } from '../models';
import { sitemap } from '../routes';
import { createOrUpdateEmployee, getEmployeeUpdateStatus } from '../store/employee';
import {
  downloadSearchEmployees,
  getActiveMonth,
  getDownloadUrl,
  getEmployees,
  getEmployeesFilter,
  getEmployeesSorting,
  getPagination,
  searchEmployees,
  setActiveMonth,
  setEmployeesSorting,
} from '../store/employees';
import { formatDateForApi, getMonths, getToday, isUserSuperAdmin, isVWTaskForce } from '../utils';
import { getConfig, getUser } from '../store/app';
import { environment as env } from '../environments';
import { Pagination } from '../components/Pagination';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
      main: {
        top: theme.subnav.height,
      },
      head: {
        ...getSubNavStyles(theme).root,
        height: theme.subnav.height,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center',
      },
      headInner: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      headGroup: {
        display: 'flex',
        alignItems: 'center',
      },
      headItem: {
        width: 350,
      },
      link: {
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }),
  { name: 'Employees' }
);

export const EmployeesPage = (): JSX.Element => {
  const c = useStyles({});
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const dispatch = useDispatch();

  const employees = useSelector(getEmployees);
  const user = useSelector(getUser);
  const sorting = useSelector(getEmployeesSorting);
  const updateStatus = useSelector(getEmployeeUpdateStatus);
  const activeMonth = useSelector(getActiveMonth);
  const filter = useSelector(getEmployeesFilter);
  const { currentPage, totalPages, totalElements } = useSelector(getPagination);
  const downloadUrl = useSelector(getDownloadUrl);
  const { activeFeatures } = useSelector(getConfig);

  const closeEmployeeModal = () => setIsEmployeeModalOpen(false);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      closeEmployeeModal();
    }
  }, [updateStatus]);

  const today = getToday();
  const startDate = new Date(today.getFullYear(), today.getMonth() - 5);
  const endDate = new Date(today.getFullYear(), today.getMonth() + 5);

  const months = getMonths(startDate, endDate);

  const loadData = (filter: EmployeeFilter) => {
    dispatch(searchEmployees.request({ ...filter, page: 1 }));
  };
  const { data, error } = employees;

  const onPaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(searchEmployees.request({ ...filter, page: value }));
  };

  const onSortingChange = (data) => {
    dispatch(setEmployeesSorting(data));
    dispatch(searchEmployees.request(filter));
  };

  useEffect(() => {
    // NOTE: need to check for capacityScore of employees since they may have been requested in assign to position already
    if (!data.length || data[0].capacityScore === undefined) {
      loadData(filter);
    }
    return () => {
      dispatch(searchEmployees.cancel());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onActiveMonthChange = (month: Date) => {
    dispatch(setActiveMonth(month));
    loadData(filter);
  };

  return (
    <Main className={c.main}>
      <Helmet title={'Employees'} />

      <header className={c.head}>
        <div className={cx(c.wrap, c.headInner)}>
          <EmployeeSearchContainer
            type="employee"
            filter={filter}
            isVWTaskForce={isVWTaskForce(user)}
            className={c.headItem}
            onSubmit={loadData}
            onReset={() => loadData({ searchNames: [], skillIds: [] })}
          />
          <div className={c.headGroup}>
            {isVWTaskForce(user) && (
              <IconButton
                href={`${env.api}/api/vw-task-force/statistics/employees?activeMonth=${formatDateForApi(activeMonth)}`}
                target="_blank"
                children={<Icon name="star" />}
              />
            )}
            {activeFeatures.includes('DOWNLOAD_EMPLOYEES') &&
              (downloadUrl ? (
                <a className={c.link} href={downloadUrl.url}>
                  {downloadUrl.fileName}
                </a>
              ) : (
                <IconButton onClick={() => dispatch(downloadSearchEmployees.request(filter))}>
                  <GetAppIcon />
                </IconButton>
              ))}
            <EmployeeSorting className={c.headItem} sorting={sorting} onChange={onSortingChange} />
          </div>
        </div>
      </header>

      <div className={c.wrap}>
        {error && <ErrorBlock {...error} />}
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          totalElements={totalElements}
          onChange={onPaginationChange}
        />
        <List type="employee" data={employees} baseUrl={sitemap.teamLead.employees.detail.path} />
        <Pagination currentPage={currentPage} totalPage={totalPages} onChange={onPaginationChange} />
      </div>

      {isUserSuperAdmin(user) && !activeFeatures.includes('BW_IMPORT') && (
        <ButtonFloating onClick={() => setIsEmployeeModalOpen(true)} icon="add" />
      )}

      <Modal isOpen={isEmployeeModalOpen} onClose={closeEmployeeModal} headline={'Add Employee'}>
        {isEmployeeModalOpen && (
          <EmployeeFormContainer
            updateStatus={updateStatus}
            onSubmit={(data) => dispatch(createOrUpdateEmployee.request(data))}
            onCancel={closeEmployeeModal}
          />
        )}
      </Modal>
    </Main>
  );
};
