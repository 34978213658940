import { Epic } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { fetchClients } from '.';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction, takeUntilAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';

const fetchClientsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { clientService }) =>
  action$.pipe(
    filterAction(fetchClients.request),
    switchMap(() =>
      clientService.getClients().pipe(
        map(fetchClients.success),
        catchErrorAndHandleWithAction(fetchClients.failure),
        takeUntilAction(action$, fetchClients.cancel)
      )
    )
  );

export const clientsEpics = [fetchClientsEpic];
