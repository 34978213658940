// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CriteriaSearch
 */
export interface CriteriaSearch {
    /**
     * @type {string}
     * @memberof CriteriaSearch
     */
    criteria: string;
    /**
     * @type {number}
     * @memberof CriteriaSearch
     */
    level?: number;
    /**
     * @type {string}
     * @memberof CriteriaSearch
     */
    searchType: CriteriaSearchSearchTypeEnum;
    /**
     * @type {string}
     * @memberof CriteriaSearch
     */
    logic: CriteriaSearchLogicEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CriteriaSearchSearchTypeEnum {
    BusinessTitle = 'BUSINESS_TITLE',
    Skill = 'SKILL',
    Certificate = 'CERTIFICATE',
    OfficeLocation = 'OFFICE_LOCATION',
    Language = 'LANGUAGE',
    Employee = 'EMPLOYEE',
    Project = 'PROJECT',
    Position = 'POSITION',
    Capacity = 'CAPACITY'
}
/**
 * @export
 * @enum {string}
 */
export enum CriteriaSearchLogicEnum {
    And = 'AND',
    Not = 'NOT'
}

