import { Button, FormHelperText } from '@material-ui/core';
import { FieldProps } from 'formik';
import { TextFieldProps } from 'formik-material-ui';
import React from 'react';

type Props = Omit<FieldProps & TextFieldProps, 'onError' | 'onClick'> & {
  accept: string[];
  onChange?: (file: File) => void;
};

export const FileField = ({
  label,
  field: { name },
  form: { errors, setFieldValue, touched },
  disabled,
  accept,
  onChange,
  fullWidth,
}: Props): JSX.Element => {
  const error = touched[name] && errors[name];

  return (
    <>
      <label>
        <input
          hidden
          type="file"
          accept={accept.join(', ')}
          onChange={(e) => {
            const file = e.target.files[0];
            setFieldValue(name, file, true);
            if (onChange) {
              onChange(file);
            }
          }}
          disabled={disabled}
        />
        <Button
          component="span"
          color="default"
          fullWidth={fullWidth}
          disabled={disabled}
          style={{ whiteSpace: 'nowrap' }}
          children={label}
        />
      </label>
      {error && <FormHelperText variant="outlined" error children={error} />}
    </>
  );
};
