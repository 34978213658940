// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts } from '../runtime';
import type {
    TitleDTO,
} from '../models';

/**
 * no description
 */
export class BusinessTitleControllerApi extends BaseAPI {

    /**
     */
    getTitles(): Observable<Array<TitleDTO>>
    getTitles(opts?: OperationOpts): Observable<AjaxResponse<Array<TitleDTO>>>
    getTitles(opts?: OperationOpts): Observable<Array<TitleDTO> | AjaxResponse<Array<TitleDTO>>> {
        return this.request<Array<TitleDTO>>({
            url: '/api/titles',
            method: 'GET',
        }, opts?.responseOpts);
    };

}
