import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { Config, Employee, Feedback, HttpError, User, UserCredentials } from '../../models';

export const fetchConfig = createAsyncAction(
  '@app/FETCH_CONFIG',
  '@app/FETCH_CONFIG_SUCCESS',
  '@app/FETCH_CONFIG_FAILURE'
)<void, Config, HttpError>();

export const login = createAsyncAction('@app/LOGIN', '@app/LOGIN_SUCCESS', '@app/LOGIN_FAILURE')<
  UserCredentials,
  User,
  HttpError
>();

export const fetchMe = createAsyncAction('@app/FETCH_ME', '@app/FETCH_ME_SUCCESS', '@app/FETCH_ME_FAILURE')<
  void,
  User,
  HttpError
>();

export const signUpOrResetPassword = createAsyncAction(
  '@app/SIGN_UP_OR_RESET_PASSWORD',
  '@app/SIGN_UP_OR_RESET_PASSWORD_SUCCESS',
  '@app/SIGN_UP_OR_RESET_PASSWORD_FAILURE'
)<string, void, HttpError>();

export const changePassword = createAsyncAction(
  '@app/CHANGE_PASSWORD',
  '@app/CHANGE_PASSWORD_SUCCESS',
  '@app/CHANGE_PASSWORD_FAILURE'
)<{ oldPassword: string; newPassword: string }, User, HttpError>();

export const acceptPrivacyPolicy = createAsyncAction(
  '@app/ACCEPT_PRIVACY_POLICY',
  '@app/ACCEPT_PRIVACY_POLICY_SUCCESS',
  '@app/ACCEPT_PRIVACY_POLICY_FAILURE'
)<void, void, HttpError>();

export const submitFeedback = createAsyncAction(
  '@app/SUBMIT_FEEDBACK',
  '@app/SUBMIT_FEEDBACK_SUCCESS',
  '@app/SUBMIT_FEEDBACK_FAILURE'
)<Feedback, void, HttpError>();

export const disableFeedback = createAsyncAction(
  '@app/DISABLE_FEEDBACK',
  '@app/DISABLE_FEEDBACK_SUCCESS',
  '@app/DISABLE_FEEDBACK_FAILURE'
)<Feedback, void, HttpError>();

export const logout = createAsyncAction('@app/LOGOUT', '@app/LOGOUT_SUCCESS', '@app/LOGOUT_FAILURE')<
  void,
  void,
  HttpError
>();

export const refreshPage = createAsyncAction('@app/REFRESH', '@app/REFRESH_SUCCESS', '@app/REFRESH_FAILURE')<
  void,
  void,
  void
>();

export const updateUserEmployee = createAction('@app/UPDATE_USER_EMPLOYEE')<Employee>();

export const hideAppFeedback = createAction('@app/HIDE_APP_FEEDBACK')();

export const resetSuccess = createAction('@app/RESET_SUCCESS_STATE')();

export type AppAction =
  | ActionType<typeof fetchConfig>
  | ActionType<typeof login>
  | ActionType<typeof fetchMe>
  | ActionType<typeof signUpOrResetPassword>
  | ActionType<typeof changePassword>
  | ActionType<typeof acceptPrivacyPolicy>
  | ActionType<typeof logout>
  | ActionType<typeof updateUserEmployee>
  | ActionType<typeof refreshPage>
  | ActionType<typeof submitFeedback>
  | ActionType<typeof disableFeedback>
  | ActionType<typeof hideAppFeedback>
  | ActionType<typeof resetSuccess>;
