import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { getWeeksPerMonth } from '../utils';
import { getTimeLineStyles } from './PositionItemTimeline';
import { format } from 'date-fns';

const useStyles = makeStyles(
  (theme) => ({
    ...getTimeLineStyles(theme),
    root: {
      flex: 1,
      background: theme.palette.common.white,
    },
  }),
  { name: 'PositionItemCalendar' }
);

type Props = {
  activeMonth: Date;
};

export const PositionItemCalendar = ({ activeMonth }: Props): JSX.Element => {
  const c = useStyles({});
  const weeks = getWeeksPerMonth(activeMonth);

  return (
    <div className={cx(c.root, c.timeline)}>
      {weeks.map(({ week, isCurrentWeek, days }) => (
        <div key={week} className={cx(c.week, c.weekCalendar, { [c.weekCurrent]: isCurrentWeek })}>
          <div className={c.weekLabel}>{`CW ${week}`}</div>
          {days.map(({ date, isInMonth, isInWeek, isToday }) => (
            <div
              key={date.toString()}
              className={cx(c.day, {
                [c.dayInMonth]: isInMonth,
                [c.dayInWeek]: isInMonth && isInWeek,
                [c.dayToday]: isInMonth && isToday,
              })}
              children={<div className={c.dayInner} children={format(date, 'd')} />}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
