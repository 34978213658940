import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeeContainer } from '../components';
import {
  createOrUpdateEmployee,
  deleteEmployee,
  fetchEmployee,
  fetchEmployeeProjects,
  getEmployee,
  getEmployeeProjects,
  getEmployeeUpdateStatus,
  resetEmployee,
  resetSuccess,
  updateCertificates, updateProjectsHistory,
  updateSkills,
  uploadProfileImage,
} from '../store/employee';
import { formatName } from '../utils';
import { getConfig, getUser } from '../store/app';
import { fetchOrganizationTreeRoot } from '../store/mixed';

type Props = {
  employeeId: string;
  backButtonLink?: string;
};

export const EmployeeProvider = ({ employeeId, ...props }: Props): JSX.Element => {
  const employee = useSelector(getEmployee);
  const user = useSelector(getUser);
  const { activeFeatures } = useSelector(getConfig);
  const dispatch = useDispatch();
  const skillRequestToggle = activeFeatures.includes('SKILL_REQUEST');

  useEffect(() => {
    dispatch(fetchEmployee.request(employeeId));
    dispatch(fetchEmployeeProjects.request(employeeId));
    dispatch(fetchOrganizationTreeRoot.request());
    return () => {
      dispatch(fetchEmployee.cancel());
      employee && dispatch(resetEmployee());
    };
  }, [employeeId]); // eslint-disable-line react-hooks/exhaustive-deps
  {/*TODO:ProjectHistory*/}

  return (
    <>
      <Helmet title={employee.data && formatName(employee.data)} />
      <EmployeeContainer
        employee={employee}
        user={user}
        updateStatus={useSelector(getEmployeeUpdateStatus)}
        projects={useSelector(getEmployeeProjects)}
        createOrUpdateEmployee={(data) => dispatch(createOrUpdateEmployee.request(data))}
        deleteEmployee={() => dispatch(deleteEmployee.request(employee.data.id))}
        updateCertificates={(data) => dispatch(updateCertificates.request(data))}
        updateSkills={(data) => dispatch(updateSkills.request(data))}
        uploadProfileImage={(data) => dispatch(uploadProfileImage.request(data))}
        resetSuccess={() => dispatch(resetSuccess())}
        skillRequestToggle={skillRequestToggle}
        updateProjectsHistory={(data) => dispatch(updateProjectsHistory.request(data))}
        {...props}
      />
    </>
  );
};
