import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { Employee, EmployeeFilter, EmployeesMeta, HttpError, SearchEmployeeDownloadFile, Sorting } from '../../models';

export const searchEmployees = createAsyncAction(
  '@employees/SEARCH_EMPLOYEES',
  '@employees/SEARCH_EMPLOYEES_SUCCESS',
  '@employees/SEARCH_EMPLOYEES_FAILURE',
  '@employees/SEARCH_EMPLOYEES_CANCEL'
)<EmployeeFilter, EmployeesMeta, HttpError, void>();

export const downloadSearchEmployees = createAsyncAction(
  '@employees/DOWNLOAD_SEARCH_EMPLOYEES',
  '@employees/DOWNLOAD_SEARCH_EMPLOYEES_SUCCESS',
  '@employees/DOWNLOAD_SEARCH_EMPLOYEES_FAILURE',
)<EmployeeFilter, SearchEmployeeDownloadFile, HttpError>();

export const fetchProjectLeads = createAsyncAction(
  '@employees/FETCH_PROJECT_LEADS',
  '@employees/FETCH_PROJECT_LEADS_SUCCESS',
  '@employees/FETCH_PROJECT_LEADS_FAILURE'
)<void, Employee[], HttpError>();

export const fetchInternalEmployees = createAsyncAction(
  '@employees/FETCH_INTERNAL_EMPLOYEES',
  '@employees/FETCH_INTERNAL_EMPLOYEES_SUCCESS',
  '@employees/FETCH_INTERNAL_EMPLOYEES_FAILURE'
)<void, Employee[], HttpError>();

export const updateEmployee = createAction('@employees/UPDATE_EMPLOYEE')<Employee>();
export const setEmployeesSorting = createAction('@employees/SET_EMPLOYEES_SORTING')<Partial<Sorting<Employee>>>();
export const setActiveMonth = createAction('@employees/SET_ACTIVE_MONTH')<Date>();

export const resetEmployees = createAction('@employees/RESET_EMPLOYEES')();

export const setEmployeesPage = createAction('@projects/SET_EMPLOYEES_PAGE')<number>();

export const removeEmployee = createAction('@employee/REMOVE_EMPLOYEE')<string>();

export type EmployeesAction =
  | ActionType<typeof searchEmployees>
  | ActionType<typeof downloadSearchEmployees>
  | ActionType<typeof fetchProjectLeads>
  | ActionType<typeof removeEmployee>
  | ActionType<typeof fetchInternalEmployees>
  | ActionType<typeof updateEmployee>
  | ActionType<typeof setEmployeesSorting>
  | ActionType<typeof setActiveMonth>
  | ActionType<typeof resetEmployees>
  | ActionType<typeof setEmployeesPage>;
