import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { bool, date, mixed, number, object, string } from 'yup';
import { SkillManager } from '.';
import { MAX_DESCRIPTION_LENGTH, MAX_WORKING_HOURS_PER_WEEK, MIN_WORKING_HOURS_PER_WEEK } from '../constants';
import {
  JobTitle,
  ModelType,
  Position,
  PositionVisibility,
  PositionAttachment,
  PotentialEmployees,
  PotentialEmployeesRequest,
  Skill,
} from '../models';
import { formatDate, getFieldProps, getFormStyles, getToday } from '../utils';
import { fromOptionType, OptionType, toOptionType } from './AutoCompleteField';
import { FormActions } from './ProjectForm';
import { useSelector } from 'react-redux';
import { getConfig } from '../store/app';
import { addMonths } from 'date-fns';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getFormStyles(theme),
      checkBox: {
        margin: 0,
      },
    }),
  { name: 'PositionForm' }
);

type Props = {
  potentialEmployees: PotentialEmployees;
  startDate: Date;
  endDate: Date;
  position?: Position;
  onCancel?: () => void;
  onSubmit: (position: Position) => void;
  onPotentialEmployees: (request: PotentialEmployeesRequest) => void;
  jobTitles: JobTitle[];
  skills: Skill[];
  fetchModel?: (params: { type: ModelType; id: string }) => void;
};

type PositionFormModel = Omit<
  Position,
  'minJobTitle' | 'maxJobTitle' | 'role' | 'description' | 'workingHoursPerWeek'
> & {
  minJobTitle: OptionType;
  maxJobTitle: OptionType;
  role: string;
  description?: string;
  skillAutoComplete: OptionType;
  workingHoursPerWeek: number | '';
  visibility?: PositionVisibility;
  attachments?: PositionAttachment[];
};
const validateName = (key: keyof PositionFormModel) => key;

export const PositionForm = ({
  potentialEmployees,
  onPotentialEmployees,
  startDate,
  endDate,
  position,
  onSubmit,
  jobTitles,
  skills,
  fetchModel,
  ...props
}: Props): JSX.Element => {
  const c = useStyles({});
  const isEditMode = !!position;

  const { activeFeatures } = useSelector(getConfig);

  const [potentialEmployeesRequest, setPotentialEmployeesRequest] = useState<PotentialEmployeesRequest>({
    skills: position?.skills ?? [],
    minJobTitle: position?.minJobTitle,
    maxJobTitle: position?.maxJobTitle,
    page: 1
  });

  const today = getToday();
  const initialValues: PositionFormModel = isEditMode
    ? {
        id: position.id,
        role: position.role,
        employee: position.employee,
        description: position.description,
        startDate: position.startDate,
        endDate: position.endDate,
        minJobTitle: position.minJobTitle ? toOptionType(position.minJobTitle) : undefined,
        maxJobTitle: position.maxJobTitle ? toOptionType(position.maxJobTitle) : undefined,
        workingHoursPerWeek: position.workingHoursPerWeek ?? '',
        skills: position.skills,
        skillAutoComplete: undefined,
        attachments: position.attachments,
        visibility: position.visibility
          ? {
              isActive: position.visibility.isActive,
              startDate: position.visibility.startDate ?? new Date(),
              endDate: position.visibility.endDate ?? addMonths(new Date(), 3),
            }
          : undefined,
      }
    : {
        role: undefined,
        description: undefined,
        startDate: today.getTime() > (startDate?.getTime() ?? 0) ? today : startDate,
        endDate: endDate ?? today,
        minJobTitle: undefined,
        maxJobTitle: undefined,
        workingHoursPerWeek: MAX_WORKING_HOURS_PER_WEEK,
        skills: [],
        skillAutoComplete: undefined,
        attachments: undefined,
        visibility: {
          isActive: true,
          startDate: new Date(),
          endDate: addMonths(new Date(), 3),
        },
      };

  const validationSchema = object({
    role: mixed().required('Role is required'),
    description: string().max(MAX_DESCRIPTION_LENGTH, 'The description is too long').nullable(),
    startDate: date().required('Start Date is required'),
    endDate: date()
      .when(validateName('startDate'), (startDate, schema) => (startDate ? schema.min(startDate) : schema))
      .required('End Date is required'),
    workingHoursPerWeek: number()
      .min(MIN_WORKING_HOURS_PER_WEEK)
      .max(MAX_WORKING_HOURS_PER_WEEK)
      .required('Working hours per week is required'),
    visibility: object({
      isActive: bool(),
      startDate: date(),
      endDate: date(),
    }),
  });

  const { updateStatus } = { ...position };
  const fieldProps = getFieldProps(updateStatus && updateStatus.isPending);

  useEffect(() => {
    if (potentialEmployeesRequest.skills.length !== 0) {
      onPotentialEmployees(potentialEmployeesRequest);
    }
  }, [potentialEmployeesRequest]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit({
          ...values,
          minJobTitle: values.minJobTitle && fromOptionType(values.minJobTitle),
          maxJobTitle: values.maxJobTitle && fromOptionType(values.maxJobTitle),
          role: values.role,
          description: values.description,
          workingHoursPerWeek: +values.workingHoursPerWeek,
        });
      }}
    >
      {(formikProps) => {
        const countFittingEmployees = potentialEmployees?.totalResults ?? 0;
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Field {...fieldProps.text} name={validateName('role')} label={'Role Name'} />

            <Field
              {...fieldProps.text}
              name={validateName('description')}
              label={'Description'}
              helperText={`${formikProps.values.description?.length ?? 0}/${MAX_DESCRIPTION_LENGTH} characters`}
            />
            {activeFeatures.includes('MAX_BUSINESS_TITLE') ? (
              <>
                <Grid className={c.inputGrid} container>
                  <Grid item xs={6}>
                    <Field
                      {...fieldProps.select}
                      name={validateName('minJobTitle')}
                      label={'Minimum Career Level'}
                      placeholder={'Select Minimum Career Level'}
                      options={jobTitles.map(toOptionType)}
                      onChange={(value) =>
                        setPotentialEmployeesRequest({
                          ...potentialEmployeesRequest,
                          minJobTitle: value ? fromOptionType(value) : undefined,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      {...fieldProps.select}
                      name={validateName('maxJobTitle')}
                      label={'Maximum Career Level'}
                      placeholder={'Select Maximum Career Level'}
                      options={jobTitles.map(toOptionType)}
                      onChange={(value) =>
                        setPotentialEmployeesRequest({
                          ...potentialEmployeesRequest,
                          maxJobTitle: value ? fromOptionType(value) : undefined,
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid className={c.inputGrid} container>
                  <Grid item xs={6}>
                    <Field
                      {...fieldProps.number}
                      name={validateName('workingHoursPerWeek')}
                      label={'Working Hours per Week'}
                      inputProps={{ min: MIN_WORKING_HOURS_PER_WEEK, max: MAX_WORKING_HOURS_PER_WEEK }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid className={c.inputGrid} container>
                <Grid item xs={6}>
                  <Field
                    {...fieldProps.select}
                    name={validateName('minJobTitle')}
                    label={'Minimum Career Level'}
                    placeholder={'Select Minimum Career Level'}
                    options={jobTitles.map(toOptionType)}
                    onChange={(value) =>
                      setPotentialEmployeesRequest({
                        ...potentialEmployeesRequest,
                        minJobTitle: value ? fromOptionType(value) : undefined,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    {...fieldProps.number}
                    name={validateName('workingHoursPerWeek')}
                    label={'Working Hours per Week'}
                    inputProps={{ min: MIN_WORKING_HOURS_PER_WEEK, max: MAX_WORKING_HOURS_PER_WEEK }}
                  />
                </Grid>
              </Grid>
            )}

            <div className={c.section}>
              <h2 className={c.sectionTitle}>{'Duration'}</h2>
              <Grid className={c.inputGrid} container>
                <Grid item xs={6}>
                  <Field
                    {...fieldProps.date}
                    name={validateName('startDate')}
                    label={'Start Date'}
                    maxDate={formikProps.values.endDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    {...fieldProps.date}
                    name={validateName('endDate')}
                    label={'End Date'}
                    minDate={formikProps.values.startDate}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={c.section}>
              <h2 className={c.sectionTitle}>{`${countFittingEmployees} fitting ${
                countFittingEmployees > 1 ? 'Employees' : 'Employee'
              }`}</h2>
              <SkillManager
                updateStatus={updateStatus}
                name={validateName('skillAutoComplete')}
                targetName={validateName('skills')}
                skills={skills}
                form={formikProps}
                onChange={fetchModel ? ({ id }) => fetchModel({ type: 'skills', id }) : undefined}
                onSetCurrentSkills={(currentSkills) =>
                  setPotentialEmployeesRequest({ ...potentialEmployeesRequest, skills: currentSkills })
                }
              />
            </div>

            {formikProps.values.visibility && (
              <div className={c.section}>
                <Field
                  {...fieldProps.checkbox}
                  name={'visibility.isActive'}
                  type="checkbox"
                  Label={{
                    ...fieldProps.checkbox.Label,
                    className: c.checkBox,
                    labelPlacement: 'start',
                    label: 'Publish Position',
                  }}
                />
                <p className={c.hintText}>
                  Activate this function to share it on "Open Position" with your colleagues for 3 months.
                </p>

                {formikProps.values.visibility.isActive && (
                  <p className={c.hintText}>Expiration date: {formatDate(formikProps.values.visibility.endDate)}</p>
                )}
              </div>
            )}

            <FormActions
              updateStatus={updateStatus}
              {...props}
              ctaLabel={isEditMode ? 'Save Position' : 'Add Position'}
              c={c}
            />
          </form>
        );
      }}
    </Formik>
  );
};
