import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from '../models';
import { ClientControllerApi, ClientDTO } from '../proxy';
import { mapArray } from '../utils';
import { AuthInterceptor } from './auth.interceptor';

export class ClientService {
  private api = new ClientControllerApi(AuthInterceptor.Instance);

  static toClient = (data: ClientDTO): Client => ({
    id: data.id,
    name: data.name,
  });

  getClients = (): Observable<Client[]> => this.api.getClients().pipe(map(mapArray(ClientService.toClient)));
}
