import React, { useEffect, useState } from 'react';
import {
  ConfirmationDialog,
  ErrorBlock,
  Loading,
  Modal,
  PositionDetail,
  PositionFeedback,
  PositionFormContainer,
} from '../components';
import { Position, Entity, User, Feedback } from '../models';
import {
  createOrUpdatePosition,
  fetchPotentialEmployees,
  getActivePosition,
  getPotentialEmployees,
  getProjectUpdateStatus,
  resetActivePosition,
} from '../store/project';
import { useHistory } from 'react-router-dom';
import { deletePosition } from '../store/position';
import { useDispatch, useSelector } from 'react-redux';
import { PositionFormModel, StepperModal } from './StepperModal';
import { createUpdatePosition } from '../store/positions';
import { getConfig, submitFeedback } from '../store/app';
import { useToasts } from 'react-toast-notifications';

type Props = {
  position: Partial<Entity<Position>>; // partial so we don't have to supply isLoading and error props
  user: User;
  backButtonLink?: string;
  positionId: string;
  isDeleted: Boolean;
};

export const PositionContainer = ({
  positionId,
  position: { data: position, isLoading, error },
  user,
  backButtonLink,
  isDeleted,
}: Props): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activePosition = useSelector(getActivePosition);
  const { email } = user;
  const { addToast } = useToasts();
  const updateStatus = useSelector(getProjectUpdateStatus);
  const { activeFeatures } = useSelector(getConfig);
  const potentialEmployees = useSelector(getPotentialEmployees);

  type ModalType = 'addEditPosition' | 'deletePosition';
  const [isModalOpen, setIsModalOpen] = useState<{ [key in ModalType]: boolean }>({
    addEditPosition: false,
    deletePosition: false,
  });

  const openModal = (key: ModalType, position?: Position) => {
    setIsModalOpen((state) => ({ ...state, [key]: true }));
  };

  const closeModal = (key: ModalType) => {
    setIsModalOpen((state) => ({ ...state, [key]: false }));
    dispatch(resetActivePosition());
  };

  useEffect(() => {
    if (updateStatus.isSuccess) {
      setIsModalOpen((state) => ({ ...state, project: false, deleteProject: false, deletePosition: false }));
    }
    if (isDeleted === true) {
      addToast('Position deleted. Thank you for your feedback!', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, isDeleted]);

  const isActivePositionPending = activePosition && activePosition.updateStatus.isPending;
  const [feedback, setFeedback] = useState<Feedback>(undefined);

  const onPositionFormSubmit = (position: Position) =>
    dispatch(createOrUpdatePosition.request({ id: position.project.id, position }));
  const onAddOrEditPosition = () => openModal('addEditPosition', position);
  const onDeletePosition = () => openModal('deletePosition', position);

  const closePositionModal = () => closeModal('addEditPosition');

  const handleSubmit = (values: PositionFormModel) => {
    dispatch(createUpdatePosition.request({ positionId: position.id, request: values }));
    window.location.reload();
  };

  return (
    <>
      {error && <ErrorBlock {...error} />}
      {isLoading && <Loading withMargin />}

      {position && (
        <>
          <PositionDetail
            position={position}
            user={user}
            onAddOrEditPosition={onAddOrEditPosition}
            onDeletePosition={onDeletePosition}
            backButtonLink={backButtonLink}
          />
          {isModalOpen.deletePosition && (
            <ConfirmationDialog
              isOpen={isModalOpen.deletePosition}
              headline={`Why did you delete this open position "${position.role}"?`}
              children={
                <PositionFeedback
                  initFeedback={() =>
                    setFeedback({
                      type: 'delete_position',
                      content: 'Successfully staffed with the help of skill-matching tool',
                    })
                  }
                  feedbackOptions={[
                    'Successfully staffed with the help of skill-matching tool',
                    'Successfully staffed without the help of skill-matching tool',
                    'Cancelled during change within project situation',
                    'Other reason',
                  ]}
                  onChange={(value, anonymous) =>
                    setFeedback({
                      ...(!anonymous && { user: email }),
                      type: 'delete_position',
                      content: value,
                    })
                  }
                />
              }
              onClose={() => closeModal('deletePosition')}
              onConfirm={() => {
                dispatch(deletePosition.request({ id: position.project.id, position: position }));
                dispatch(submitFeedback.request(feedback));
              }}
              updateStatus={activePosition?.updateStatus}
            />
          )}

          {activeFeatures.includes('CREATE_POSITIONS_WIZARD') && activePosition?.employee === undefined ? (
            <>
              {isModalOpen.addEditPosition && (
                <StepperModal
                  isOpen={isModalOpen.addEditPosition}
                  headline="Edit position"
                  disableClose={isActivePositionPending}
                  onClose={closePositionModal}
                  onSubmit={handleSubmit}
                  positionId={position?.id}
                />
              )}
            </>
          ) : (
            <Modal
              isOpen={isModalOpen.addEditPosition}
              onClose={closePositionModal}
              headline={activePosition ? 'Add Position' : 'Edit Position'}
              disableClose={isActivePositionPending}
            >
              {isModalOpen.addEditPosition && (
                <PositionFormContainer
                  {...position.project} // startDate, endDate
                  potentialEmployees={potentialEmployees}
                  position={activePosition}
                  onSubmit={onPositionFormSubmit}
                  onCancel={closePositionModal}
                  onPotentialEmployees={(request) => dispatch(fetchPotentialEmployees.request(request))}
                />
              )}
            </Modal>
          )}
        </>
      )}
    </>
  );
};
