import { getType } from 'typesafe-actions';
import {Employee, Entity, EntityArray, ProjectHistory, UpdateStatus} from '../../models';
import { getInitialEntityArrayState, getInitialEntityState, initialUpdateStatus } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

type State = {
  readonly employee: Entity<Employee>;
  readonly updateStatus: UpdateStatus;
  readonly projects: EntityArray<ProjectHistory>;
};

const initialState: State = {
  employee: getInitialEntityState(),
  updateStatus: initialUpdateStatus,
  projects: getInitialEntityArrayState(),
};

export const employeeReducer = (
  state = initialState,
  action: fromActions.EmployeeAction | AppActions.AppAction
): State => {
  const { employee } = state;

  switch (action.type) {
    case getType(fromActions.fetchEmployee.request):
      return {
        ...initialState, // reset data and error
        employee: {
          ...getInitialEntityState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchEmployee.success):
      return {
        ...state,
        employee: {
          ...employee,
          data: action.payload,
          isLoading: false,
        },
      };

    case getType(fromActions.fetchEmployee.failure):
      return {
        ...state,
        employee: {
          ...employee,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.fetchEmployeeProjects.request):
      return {
        ...state,
        projects: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchEmployeeProjects.success):
      return {
        ...state,
        projects: {
          ...state.projects,
          data: action.payload,
          isLoading: false,
        },
      };

    case getType(fromActions.fetchEmployeeProjects.failure):
      return {
        ...state,
        projects: {
          ...state.projects,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.deleteEmployee.request):
      return {
        ...state,
        updateStatus: { isPending: true, isSuccess: undefined },
      };

    case getType(fromActions.deleteEmployee.success):
      return {
        ...state,
        employee: {
          ...getInitialEntityState(),
        },
        updateStatus: { isPending: false, isSuccess: true },
      };

    case getType(fromActions.deleteEmployee.failure):
      return {
        ...state,
        updateStatus: { isPending: false, isSuccess: false, error: action.payload },
      };

    // -------------------------

    case getType(fromActions.createOrUpdateEmployee.request):
    case getType(fromActions.updateCertificates.request):
    case getType(fromActions.updateSkills.request):
    case getType(fromActions.uploadProfileImage.request):
      return {
        ...state,
        updateStatus: { isPending: true, isSuccess: undefined },
      };

    case getType(fromActions.createOrUpdateEmployee.success):
    case getType(fromActions.updateCertificates.success):
    case getType(fromActions.updateSkills.success):
      return {
        ...state,
        employee: {
          ...employee,
          data: action.payload,
        },
        updateStatus: { isPending: false, isSuccess: true },
      };

    case getType(fromActions.uploadProfileImage.success):
      return {
        ...state,
        employee: {
          ...employee,
          data: {
            ...employee.data,
            profileImage: action.payload,
          },
        },
        updateStatus: { isPending: false, isSuccess: true },
      };

    case getType(fromActions.createOrUpdateEmployee.failure):
    case getType(fromActions.updateCertificates.failure):
    case getType(fromActions.updateSkills.failure):
    case getType(fromActions.uploadProfileImage.failure):
      return {
        ...state,
        updateStatus: { isPending: false, isSuccess: false, error: action.payload },
      };

    // -------------------------

    case getType(fromActions.resetSuccess):
      return {
        ...state,
        updateStatus: initialUpdateStatus,
      };

    // -------------------------

    case getType(fromActions.resetEmployee):
    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
