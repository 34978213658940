import { getType } from 'typesafe-actions';
import { EntityArray, Pagination, Position, PositionStatus, SortedBy } from '../../models';
import { getInitialEntityArrayState, replaceIn, sortArrayDeepBy, withId } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

type State = {
  readonly openPositions: EntityArray<Position>;
  readonly myPositions: EntityArray<Position>;
  readonly editPosition: { template: Position; reinit: boolean };
  readonly openPositionPagination: Pagination;
  readonly myPositionPagination: Pagination;
  readonly sortBy: SortedBy;
  readonly status: PositionStatus;
  // readonly sorting: Sorting<Position>;
};

const initialState: State = {
  openPositions: getInitialEntityArrayState(),
  myPositions: getInitialEntityArrayState(),
  editPosition: {
    template: undefined,
    reinit: false,
  },
  status: 'open-position',
  sortBy: 'match',
  openPositionPagination: {
    currentPage: 1,
    totalPages: 1,
    totalElements: 0,
  },
  myPositionPagination: {
    currentPage: 1,
    totalPages: 1,
    totalElements: 0,
  },
};

export const positionsReducer = (
  state = initialState,
  action: fromActions.PositionsAction | AppActions.AppAction
): State => {
  const { openPositions, myPositions } = state;
  const { data } = openPositions;

  switch (action.type) {
    case getType(fromActions.setPositionsStatus):
      return {
        ...state,
        ...openPositions,
        openPositionPagination: {
          ...state.openPositionPagination,
          currentPage: 1,
        },
        myPositionPagination: {
          ...state.myPositionPagination,
          currentPage: 1,
        },
        status: action.payload,
      };

    // ---------------------------

    case getType(fromActions.fetchPosition.request):
      return {
        ...state,
        editPosition: {
          ...state.editPosition,
          reinit: true,
        },
      };

    case getType(fromActions.fetchPosition.success):
      return {
        ...state,
        editPosition: {
          ...state.editPosition,
          template: action.payload,
          reinit: true,
        },
      };

    case getType(fromActions.reinitEditPosition):
      return {
        ...state,
        editPosition: {
          ...state.editPosition,
          reinit: action.payload,
        },
      };

    case getType(fromActions.fetchOpenPositions.request):
      return {
        ...state,
        sortBy: action.payload.sortBy,
        openPositions: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchOpenPositions.success):
      return {
        ...state,
        openPositions: {
          ...openPositions,
          data: sortArrayDeepBy(action.payload.data, { orderBy: 'role', orderAsc: true }),
          isLoading: false,
        },
        openPositionPagination: {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
        },
      };

    case getType(fromActions.fetchOpenPositions.failure):
      return {
        ...state,
        openPositions: {
          ...openPositions,
          error: action.payload,
          isLoading: false,
        },
      };

    // ---------------------------

    case getType(fromActions.fetchMyPositions.request):
      return {
        ...state,
        myPositions: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchMyPositions.success):
      return {
        ...state,
        myPositions: {
          ...myPositions,
          data: sortArrayDeepBy(action.payload.data, { orderBy: 'role', orderAsc: true }),
          isLoading: false,
        },
        myPositionPagination: {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
        },
      };

    case getType(fromActions.fetchMyPositions.failure):
      return {
        ...state,
        myPositions: {
          ...myPositions,
          error: action.payload,
          isLoading: false,
        },
      };

    // ---------------------------

    case getType(fromActions.createUpdatePosition.request):
      return {
        ...state,
        editPosition: {
          template: undefined,
          reinit: false,
        },
      };

    case getType(fromActions.updatePositionLike.request):
      const newPosition = action.payload;
      return {
        ...state,
        openPositions: {
          ...openPositions,
          data: data.some(withId(newPosition.id))
            ? replaceIn(data, withId(newPosition.id), () => newPosition)
            : [newPosition, ...data],
        },
      };

    case getType(fromActions.resetOpenPositions):
      return {
        ...state,
        openPositions: getInitialEntityArrayState(),
      };

    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
