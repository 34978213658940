import { getType } from 'typesafe-actions';
import {
  Certificate,
  FAQ,
  JobTitle,
  Language,
  Location,
  ModelArray,
  ModelType,
  OrganizationTree,
  Skill,
} from '../../models';
import { getInitialModelArrayState, replaceIn, withId } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

export type State = {
  readonly jobTitles: ModelArray<JobTitle>;
  readonly certificates: ModelArray<Certificate>;
  readonly organizationTreeRoot: OrganizationTree;
  readonly languages: ModelArray<Language>;
  readonly locations: ModelArray<Location>;
  readonly vwSkills: ModelArray<Skill>;
  readonly skills: ModelArray<Skill>;
  readonly faqs: ModelArray<FAQ>;
};

const initialState: State = {
  jobTitles: getInitialModelArrayState(),
  certificates: getInitialModelArrayState(),
  organizationTreeRoot: undefined,
  languages: getInitialModelArrayState(),
  locations: getInitialModelArrayState(),
  vwSkills: getInitialModelArrayState(),
  skills: getInitialModelArrayState(),
  faqs: getInitialModelArrayState(),
};

const mapModelTypeToStateKey = (type: ModelType): keyof State => type;

export const mixedReducer = (state = initialState, action: fromActions.MixedAction | AppActions.AppAction): State => {
  let key: string;
  const { faqs } = state;

  switch (action.type) {
    case getType(fromActions.fetchModels.request):
      key = mapModelTypeToStateKey(action.payload);
      return {
        ...state,
        [key]: {
          ...getInitialModelArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchModels.success):
      key = mapModelTypeToStateKey(action.payload.type);
      return {
        ...state,
        [key]: {
          ...state[key],
          data: [...action.payload.data, ...state[key].data],
          isLoading: false,
        },
      };

    case getType(fromActions.fetchModels.failure):
      key = mapModelTypeToStateKey(action.payload.type);
      return {
        ...state,
        [key]: {
          ...state[key],
          error: action.payload.error,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.fetchModel.success):
    case getType(fromActions.editModel.success):
      key = mapModelTypeToStateKey(action.payload.type);
      const newModel = action.payload.data;
      const data = state[key].data;
      return {
        ...state,
        [key]: {
          ...state[key],
          data: data.some(withId(newModel.id)) // initially we might not have all skills, so replace doesn't work
            ? replaceIn(data, withId(newModel.id), () => newModel)
            : [newModel, ...data],
        },
      };

    // -------------------------

    case getType(fromActions.removeModel.success):
      key = mapModelTypeToStateKey(action.payload.type);
      return {
        ...state,
        [key]: {
          ...state[key],
          data: state[key].data.filter((item) => item.id !== action.payload.id),
        },
      };

    // -------------------------

    case getType(fromActions.createModel.success):
      key = mapModelTypeToStateKey(action.payload.type);
      return {
        ...state,
        [key]: {
          ...state[key],
          data: [action.payload.data, ...state[key].data],
        },
      };

    // -------------------------

    case getType(fromActions.fetchOrganizationTreeRoot.success):
      return {
        ...state,
        organizationTreeRoot: action.payload,
      };

    // -------------------------

    case getType(fromActions.addModel):
      key = mapModelTypeToStateKey(action.payload.type);
      return {
        ...state,
        [key]: {
          ...state[key],
          data: state[key].data.concat([action.payload.data]),
        },
      };

    case getType(fromActions.filterModels):
      key = mapModelTypeToStateKey(action.payload.type);
      return {
        ...state,
        [key]: {
          ...state[key],
          confirm: state[key].data.filter((data) => data.status === 'confirm'),
          pending: state[key].data.filter((data) => data.status === 'pending'),
          decline: state[key].data.filter((data) => data.status === 'decline'),
        },
      };

    case getType(fromActions.fetchFAQ.request):
      return {
        ...state,
        faqs: {
          ...getInitialModelArrayState(),
        },
      };

    case getType(fromActions.fetchFAQ.success):
      return {
        ...state,
        faqs: {
          ...faqs,
          data: action.payload,
        },
      };

    case getType(fromActions.fetchFAQ.failure):
      return {
        ...state,
        faqs: {
          ...faqs,
          error: action.payload,
        },
      };

    // -------------------------

    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
