import { Grid, makeStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React from 'react';
import { mixed, number, object, string } from 'yup';
import { OrganizationTreeViewForm } from '.';
import { MAX_WORKING_HOURS_PER_WEEK, MIN_WORKING_HOURS_PER_WEEK } from '../constants';
import { Employee, JobTitle, OrganizationTree, UpdateStatus } from '../models';
import { getFieldProps, getFormStyles } from '../utils';
import { fromOptionType, OptionType, toOptionType } from './AutoCompleteField';
import { FormActions } from './ProjectForm';

const useStyles = makeStyles((theme) => getFormStyles(theme), { name: 'EmployeeCreateForm' });

type Props = {
  updateStatus: UpdateStatus;
  isExternal?: boolean;
  onSubmit: (employee: Employee) => void;
  onCancel?: () => void;
  jobTitles: JobTitle[];
  organizationTreeRoot: OrganizationTree;
};

type EmployeeCreateFormModel = Omit<Employee, 'jobTitle' | 'workingHoursPerWeek' | 'isExternal'> & {
  jobTitle: OptionType;
  organization: OptionType;
  workingHoursPerWeek: number | '';
  skillAutoComplete: OptionType;
};
const validateName = (key: keyof EmployeeCreateFormModel) => key;

export const EmployeeCreateForm = ({
  onSubmit,
  jobTitles,
  organizationTreeRoot,
  isExternal,
  ...props
}: Props): JSX.Element => {
  const c = useStyles({});

  const initialValues: EmployeeCreateFormModel = {
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: undefined,
    organization: undefined,
    placeOfResidence: '',
    location: undefined,
    languages: [],
    certificates: [],
    workingHoursPerWeek: MAX_WORKING_HOURS_PER_WEEK,
    skills: [],
    skillAutoComplete: undefined,
  };

  const validationSchema = object({
    firstName: string().required('First Name is required'),
    lastName: string().required('Last Name is required'),
    email: string().required('Email is required'),
    ...(!isExternal && {
      jobTitle: mixed().required('Career Level is required'),
      organization: mixed().required('Organization structure is required'),
      placeOfResidence: string(),
    }),
    workingHoursPerWeek: number()
      .min(MIN_WORKING_HOURS_PER_WEEK)
      .max(MAX_WORKING_HOURS_PER_WEEK)
      .required('Working hours per week is required'),
  });

  const fieldProps = getFieldProps(props.updateStatus.isPending);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit({
          ...values,
          ...(!isExternal && {
            jobTitle: fromOptionType(values.jobTitle),
            organization: fromOptionType(values.organization),
            workingHoursPerWeek: +values.workingHoursPerWeek,
          }),
          isExternal,
        });
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid className={c.inputGrid} container>
            <Grid item xs={6}>
              <Field {...fieldProps.text} name={validateName('firstName')} label={'First Name'} />
            </Grid>
            <Grid item xs={6}>
              <Field {...fieldProps.text} name={validateName('lastName')} label={'Last Name'} />
            </Grid>
          </Grid>

          <Grid className={c.inputGrid} container>
            <Grid item xs={6}>
              <Field {...fieldProps.text} name={validateName('email')} label={'Email'} />
            </Grid>
            {!isExternal && (
              <Grid item xs={6}>
                <Field
                  {...fieldProps.select}
                  name={validateName('jobTitle')}
                  label={'Career Level'}
                  placeholder={'Select Career Level'}
                  options={jobTitles.map(toOptionType)}
                />
              </Grid>
            )}
          </Grid>

          <Grid className={c.inputGrid} container>
            <Grid item xs={6}>
              <Field
                {...fieldProps.number}
                name={validateName('workingHoursPerWeek')}
                label={'Working Hours per Week'}
                inputProps={{ min: MIN_WORKING_HOURS_PER_WEEK, max: MAX_WORKING_HOURS_PER_WEEK }}
              />
            </Grid>
            {!isExternal && (
              <Grid item xs={6}>
                <Field {...fieldProps.text} name={validateName('placeOfResidence')} label={'Place of Residence'} />
              </Grid>
            )}
          </Grid>

          {!isExternal && (
            <OrganizationTreeViewForm
              className={c.inputGrid}
              fieldProps={fieldProps}
              onItemClick={(data) => setFieldValue('organization', toOptionType(data))}
              organizationTreeRoot={organizationTreeRoot}
            />
          )}

          <FormActions {...props} ctaLabel={'Add Profile'} c={c} />
        </form>
      )}
    </Formik>
  );
};
