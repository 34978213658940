import {ActionType, createAction, createAsyncAction} from 'typesafe-actions';
import {Position, HttpError, ProjectPosition} from '../../models';

export const fetchPosition = createAsyncAction(
    '@position/FETCH_POSITION',
    '@position/FETCH_POSITION_SUCCESS',
    '@position/FETCH_POSITION_FAILURE',
    '@position/FETCH_POSITION_CANCEL'
)<string, Position, HttpError, void>();

export const resetPosition = createAction('@position/RESET_POSITION')();

export const deletePosition = createAsyncAction(
    '@position/DELETE_POSITION',
    '@position/DELETE_POSITION_SUCCESS',
    '@position/DELETE_POSITION_FAILURE'
)<ProjectPosition, string, HttpError>();

export type PositionAction =
    | ActionType<typeof fetchPosition>
    | ActionType<typeof resetPosition>
    | ActionType<typeof deletePosition>;