import { Field, Formik } from 'formik';
import { Grid, makeStyles } from '@material-ui/core';
import { getFieldProps, getFormStyles } from '../utils';
import { FormActions } from './ProjectForm';
import React from 'react';
import { Model } from '../models';
import { EmailFormModel } from './CriteriaList';

type Props = {
  criteria: Model;
  onSubmit: (data: EmailFormModel) => void;
  onCancel: () => void;
};

const validateName = (key: keyof EmailFormModel) => key;
export const EmailForm = ({ criteria, onSubmit, ...props }: Props): JSX.Element => {
  const initialValues: EmailFormModel = { message: '' };
  const useStyles = makeStyles((theme) => getFormStyles(theme), { name: 'EmailForm' });
  const fieldProps = getFieldProps(false);

  const c = useStyles({});
  return (
    <Formik
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit(data);
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid className={c.inputGrid} container>
            <Grid item xs={12}>
              <Field {...fieldProps.text} name={validateName('message')} label={'Reason'} />
            </Grid>
          </Grid>
          <FormActions {...props} updateStatus={{ isPending: false }} ctaLabel={`Send`} c={c} />
        </form>
      )}
    </Formik>
  );
};
