import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { CssBaseline } from '@material-ui/core';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { App } from './App';
import { browserHistory, store } from './store';
import { theme } from './theme';
import { ToastProvider } from 'react-toast-notifications';
import DateFnsUtils from '@date-io/date-fns';

const render = (Component) =>
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={browserHistory}>
        {/* activate minified jss classnames, https://material-ui.com/styles/api/#creategenerateclassname-options-class-name-generator */}
        <StylesProvider generateClassName={createGenerateClassName({ disableGlobal: true, productionPrefix: 'x' })}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <HelmetProvider>
                <ToastProvider autoDismiss autoDismissTimeout={3000} placement="bottom-center">
                  <Component />
                </ToastProvider>
              </HelmetProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </StylesProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );

render(App);

if (module['hot'] && process.env.NODE_ENV !== 'production') {
  module['hot'].accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
