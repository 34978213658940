import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

const fileState = (state: RootState) => state.file;

export const getFileUploadStatus = createSelector(
  [fileState],
  (file) => file.fileUploadStatus
);

export const getUploadResponse = createSelector(
    [fileState],
    (file) => file.uploadResponse
);

