import { concat, EMPTY, Observable, of, OperatorFunction } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ActionCreator } from 'typesafe-actions/dist/is-action-of';
import { HttpError } from '../models';
import { logout } from '../store/app';
import { RootAction } from '../store/rootReducer';

export const errorFromResponse = (apiResponse: AjaxResponse<any>): Observable<HttpError> =>
  of(apiResponse).pipe(
    tap((error) => process.env.NODE_ENV !== 'production' && console.log(error)), // eslint-disable-line no-console
    map(({ status, response }) =>
      response
        ? { status, ...response }
        : {
            // our own 'INVALID_TOKEN' exception is taken care of here
            status: status >= 0 ? status : 401,
            error:
              status === 0 ? 'An error occurred. Please refresh the current page in your browser' : 'Invalid Token',
          }
    )
  );

export const catchErrorAndHandleWithAction = <T, R extends RootAction>(
  action: AC,
  options?: { skipLogout?: boolean; optData?: any },
  other$?: Observable<any> // eslint-disable-line @typescript-eslint/no-explicit-any
): OperatorFunction<T, T | R> =>
  catchError((response) =>
    errorFromResponse(response).pipe(
      switchMap((error) =>
        concat(
          of(action(options?.optData ? { error, ...options?.optData } : error)),
          error.status === 401 && !options?.skipLogout ? of(logout.request()) : EMPTY, // dispatch logout action on 401
          // error.status === 0 ? of(refreshPage.request()) : EMPTY, // dispatch logout action on network error
          other$ || EMPTY
        )
      )
    )
  );

type AC = ActionCreator<{ type: string }>;
export const filterAction = <T extends AC>(actionCreator: T | T[]) => filter(isActionOf(actionCreator));

export const takeUntilAction = <T>(action$: Observable<RootAction>, action: AC | AC[]): OperatorFunction<T, T> =>
  takeUntil(action$.pipe(filterAction(action)));
