import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, getUserStatus, resetSuccess } from '../store/app';
import { ChangePasswordForm } from './ChangePasswordForm';

export const ChangePasswordFormContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSuccess());
    return () => {
      dispatch(resetSuccess());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ChangePasswordForm
      onSubmit={(data) => dispatch(changePassword.request(data))}
      updateStatus={useSelector(getUserStatus)}
    />
  );
};
