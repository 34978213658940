import { KeyboardDatePicker } from '@material-ui/pickers';
import { FieldProps } from 'formik';
import { TextFieldProps } from 'formik-material-ui';
import React from 'react';
import { DEFAULT_DATE_FORMAT } from '../constants';
import { getISODay } from 'date-fns';

type Props = Omit<FieldProps & TextFieldProps, 'onError' | 'onClick'> & {
  minDate?: Date;
  maxDate?: Date;
  disableWeekends?: boolean;
};

export const DatePickerField = ({
  field: { name, value },
  disableWeekends,
  form: { errors, setFieldValue, touched },
  ...other
}: Props): JSX.Element => {
  const error = touched[name] && errors[name];

  return (
    <KeyboardDatePicker
      {...other}
      name={name}
      value={value}
      disableToolbar
      autoOk
      format={DEFAULT_DATE_FORMAT}
      PopoverProps={{
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        transformOrigin: { horizontal: 'left', vertical: 'top' },
      }}
      helperText={error}
      error={!!error}
      onChange={(value) => setFieldValue(name, value, true)}
      shouldDisableDate={disableWeekends ? (date) => getISODay(date) > 5 : undefined}
      KeyboardButtonProps={{ disabled: other.disabled }}
      variant="inline"
      inputVariant="outlined"
    />
  );
};
