import React from 'react';
import cx from 'clsx';
import { Avatar } from './Avatar';
import { createStyles, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { getTruncateStyles, getWrapStyles } from '../layout';
import {Employee, EmployeeEnterpriseSearch, FreeCapacity} from '../models';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { theme } from '../theme';
import { Icon } from './Icon';
import { MATOMO_EVENT_ACTION_SEND_EMAIL, MATOMO_EVENT_ENTERPRISE_SEARCH } from '../constants';
import {getMonthName, withId} from '../utils';
import {SkillLevel} from "./SkillLevel";


const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
        ...getTruncateStyles(),

        card: {
        boxShadow: '0px 1px 5px 1px #bbb',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px',
        borderTop: '1px solid #e3e8ef',
        borderLeft: '10px solid #e3e8ef',
      },
      avatar: {
        padding: theme.spacing(1),
      },
      profileHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      },
      jobTitle: {
        color: '#76808e',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#e3e8ef',
        padding: theme.spacing(2),
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
      },
      cardFooter: {
        borderTop: '1px solid #e3e8ef',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
      },
      cardFooterIcon: {
        color: theme.palette.primary.main,
      },
      hoverCard: {
        display: 'grid',
        gridTemplateColumns: '0.5fr 1fr',
      },
      organization: {
        color: '#434649',
      },
      subText: {
        color: '#aab2bc',
        wordBreak: 'break-all',
      },
      mainText: {
        fontWeight: 'bold',
      },
      email: {
        textDecoration: 'underline',
        fontSize: '11px',
        fontWeight: 600,
      },
      tooltip: {
        backgroundColor: theme.palette.accent.green,
        color: 'black',
        maxWidth: 220,
      },
      green: {
        backgroundColor: theme.palette.accent.green,
      },
      yellow: {
        backgroundColor: theme.palette.accent.yellow,
      },
      darkRed: {
        backgroundColor: theme.palette.accent.darkRed,
      },
      red: {
        backgroundColor: theme.palette.error.main,
      },
        skillLists: {
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            padding: theme.spacing(0.25),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        skillsSubList: {
            display: 'flex',
            padding: theme.spacing(0.25),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        criteriaLabel: {
            display: 'flex',
            width: '80%',
            padding: theme.spacing(0.25),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        skillName: {
            display: 'inline-block',
            position: 'relative',
            padding: theme.spacing(0.25),
        },
        skillLevels: {},

    }),
  { name: 'EmployeeCard' }
);

type Props = {
  employee: Employee;
  requiredSkills: EmployeeEnterpriseSearch[];
};

type AvatarProps = {
  firstName: string;
  lastName: string;
  capacityInPercent?: number;
  freeCapacities?: FreeCapacity[];
};

const calculateColorByCapacity = (capacityInPercent?: number): string =>
  capacityInPercent === null
    ? theme.palette.grey[100]
    : capacityInPercent > 60
    ? theme.palette.accent.green
    : capacityInPercent > 20 && capacityInPercent <= 60
    ? theme.palette.accent.yellow
    : capacityInPercent >= 0
    ? theme.palette.accent.darkRed
    : theme.palette.error.main;

const EmployeeCardAvatar = ({ firstName, lastName, capacityInPercent, freeCapacities }: AvatarProps): JSX.Element => {
  const capacityColor = calculateColorByCapacity(capacityInPercent);
  const c = useStyles({});
  return (
    <Tooltip
      title={
        <div>
          {`${capacityInPercent}% Capacity`}
          <br />
          <div>
            {freeCapacities?.map((i, index) => {
              return (
                <div key={index} className={c.hoverCard}>
                  <div>{`${getMonthName(i.startDate)}`}</div>
                  <div>{`${i.freeDaysPerWeek} ${i.freeDaysPerWeek > 1 ? 'day(s)' : 'day'} per week`}</div>
                </div>
              );
            })}
          </div>
        </div>
      }
      placement="top"
      interactive
      classes={{
        tooltip: cx(
          c.tooltip,
          capacityInPercent > 60
            ? c.green
            : capacityInPercent > 20 && capacityInPercent <= 60
            ? c.yellow
            : capacityInPercent >= 0
            ? c.darkRed
            : c.green
        ),
      }}
    >
      <div style={{ width: 72, height: 72 }}>
        <CircularProgressbarWithChildren
          strokeWidth={5}
          styles={buildStyles({
            pathColor: capacityColor,
            trailColor: capacityInPercent === 0 ? theme.palette.accent.darkRed : theme.palette.grey[300],
          })}
          value={capacityInPercent}
        >
          <Avatar firstName={firstName} lastName={lastName} size="large" />
        </CircularProgressbarWithChildren>
      </div>
    </Tooltip>
  );
};

export const EmployeeCard = ({ employee, requiredSkills }: Props): JSX.Element => {
  const c = useStyles({});
  const { id, jobTitle, firstName, lastName, organization, peopleCounselor, capacityScore, freeCapacities, skills } = employee;
  const employeeProfileLink = "/employees/" + id;

    const splitList = (list, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < list.length; i += chunkSize) {
            chunks.push(list.slice(i, i + chunkSize));
        }
        return chunks;
    };
    const splitSkillsList = requiredSkills? splitList(requiredSkills, 3): [];
  return (
    <div className={c.card}>
      <div className={c.jobTitle}>{jobTitle?.name ?? '-'}</div>
      <div className={cx(c.profileHeader)}>
        <div className={c.avatar}>
          <EmployeeCardAvatar
            firstName={firstName}
            lastName={lastName}
            capacityInPercent={capacityScore}
            freeCapacities={freeCapacities}
          />
        </div>
        <a href={employeeProfileLink} target={"_blank"} className={c.mainText} style={{ fontSize: 16 }}>
          {firstName} {lastName}
        </a>

      </div>
        <div className={c.skillLists}>
            {splitSkillsList &&
                splitSkillsList.map((skillList, index) =>(
                    <div key={index} className={c.skillsSubList}>
                        {skillList.map((singleSkill) => (
                        <div key={singleSkill.criteria.value} className={c.criteriaLabel}>
                            <div
                                className={cx(c.skillName, c.truncate)}
                                children={singleSkill.criteria.label}
                            />
                            {singleSkill.searchType === 'Skill' && (
                                <SkillLevel
                                    className={c.skillLevels}
                                    skill={{
                                        name: singleSkill.criteria.label,
                                        level: singleSkill?.level,
                                    }}
                                    skillOverlap={(skills || []).find(withId(singleSkill.criteria.value))}
                                    showOverlap={true}
                                />
                            )}
                        </div>
                        ))}
                    </div>
                        )
                )}
        </div>
      <div className={c.cardContent}>
        <div className={cx(c.mainText, c.organization)}>{organization.name}</div>
        {organization.parent ? <div className={c.subText}>{organization.parent.name}</div> : <div>&#160;</div>}
      </div>
      <div className={c.cardContent}>
        <div className={c.subText}>People Counselor</div>
        {peopleCounselor ? (
          <>
            <div className={c.mainText}>
              {peopleCounselor.firstName} {peopleCounselor.lastName}
            </div>
            <div className={cx(c.subText, c.email)}>
              <IconButton
                size="small"
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window._paq.push(['trackEvent', MATOMO_EVENT_ENTERPRISE_SEARCH, MATOMO_EVENT_ACTION_SEND_EMAIL]);
                  }
                }}
                href={`mailto:${peopleCounselor.email}?subject=${encodeURIComponent(
                  'Anfrage für einen Projekteinsatz'
                )}`}
                children={<Icon name={'mail'} />}
              />
            </div>
          </>
        ) : (
          <>
            <div>-</div>
            <div>&#160;</div>
          </>
        )}
      </div>
      <div className={c.cardFooter}>&#160;</div>
    </div>
  );
};
