import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
  AutoCompleteSuggestions,
  EmployeeEnterpriseSearch,
  EmployeeEnterpriseSearchRequest,
  EmployeesMeta,
  HttpError,
  SearchType,
} from '../../models';

export const enterpriseSearchEmployees = createAsyncAction(
  '@search/ENTERPRISE_SEARCH_EMPLOYEES',
  '@search/ENTERPRISE_SEARCH_EMPLOYEES_SUCCESS',
  '@search/ENTERPRISE_SEARCH_EMPLOYEES_FAILURE',
  '@search/ENTERPRISE_SEARCH_EMPLOYEES_CANCEL'
)<EmployeeEnterpriseSearchRequest, EmployeesMeta, HttpError, void>();

export const autoCompleteSuggestions = createAsyncAction(
  '@search/AUTO_COMPLETE_SUGGESTIONS',
  '@search/AUTO_COMPLETE_SUGGESTIONS_SUCCESS',
  '@search/AUTO_COMPLETE_SUGGESTIONS_FAILURE',
  '@search/AUTO_COMPLETE_SUGGESTIONS_CANCEL'
)<{ keyword: string; types: SearchType[] }, AutoCompleteSuggestions[], HttpError, void>();

export const removeSearchCriteria = createAction('@search/REMOVE_SEARCH_CRITERIA')<number>();

export const changeSkillLevel = createAction('@search/CHANGE_SKILL_LEVEL')<{ index: number; level: number }>();

export const addSearchCriteria = createAction('@search/ADD_SEARCH_CRITERIA')<EmployeeEnterpriseSearch>();

export const clearSuggestion = createAction('@search/CLEAR_SUGGESTION')();

export const resetEnterpriseSearchEmployees = createAction('@search/RESET_ENTERPRISE_SEARCH_EMPLOYEES')();

export type SearchAction =
  | ActionType<typeof enterpriseSearchEmployees>
  | ActionType<typeof removeSearchCriteria>
  | ActionType<typeof autoCompleteSuggestions>
  | ActionType<typeof clearSuggestion>
  | ActionType<typeof changeSkillLevel>
  | ActionType<typeof resetEnterpriseSearchEmployees>
  | ActionType<typeof addSearchCriteria>;
