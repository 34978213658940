import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ChangePasswordFormContainer } from '../components';
import { getWrapStyles } from '../layout';

const useStyles = makeStyles((theme) => getWrapStyles(theme), { name: 'SettingsPage' });

export const SettingsPage = (): JSX.Element => {
  const c = useStyles({});
  return (
    <div className={cx(c.wrap, c.wrapTopAndBottom)}>
      <Helmet title={'Settings'} />
      <ChangePasswordFormContainer />
    </div>
  );
};
