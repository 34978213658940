import { createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonFloating, EmployeeFormContainer, EmployeeSearchContainer, ErrorBlock, List, Modal } from '../components';
import { getSubNavStyles } from '../components/Tabs';
import { getWrapStyles, Main } from '../layout';
import { EmployeeFilter } from '../models';
import { sitemap } from '../routes';
import {
  getFreelancers,
  getFreelancersFilter,
  getPagination,
  searchFreelancers,
  setFreelancersPage,
} from '../store/freelancers';
import { isUserSuperAdmin, isVWTaskForce } from '../utils';
import { getUser } from '../store/app';
import { Pagination } from '../components/Pagination';
import { createOrUpdateEmployee, getEmployeeUpdateStatus } from '../store/employee';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getWrapStyles(theme),
      main: {
        top: theme.subnav.height,
      },
      head: {
        ...getSubNavStyles(theme).root,
        height: theme.subnav.height,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center',
      },
      headInner: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    }),
  { name: 'Freelancers' }
);

export const FreelancersPage = (): JSX.Element => {
  const c = useStyles({});
  const dispatch = useDispatch();

  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);

  const freelancers = useSelector(getFreelancers);
  const user = useSelector(getUser);
  const filter = useSelector(getFreelancersFilter);
  const { currentPage, totalPages, totalElements } = useSelector(getPagination);
  const updateStatus = useSelector(getEmployeeUpdateStatus);

  const closeEmployeeModal = () => setIsEmployeeModalOpen(false);

  const loadData = (filter: EmployeeFilter) => dispatch(searchFreelancers.request(filter));
  const { data, error } = freelancers;

  const onPaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setFreelancersPage(value));
    loadData(filter);
  };

  useEffect(() => {
    if (!data.length) {
      loadData(filter);
    }
    if (updateStatus.isSuccess) {
      closeEmployeeModal();
    }
    return () => {
      dispatch(searchFreelancers.cancel());
    };
  }, [updateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Main className={c.main}>
      <Helmet title={'Employees'} />

      <header className={c.head}>
        <div className={cx(c.wrap, c.headInner)}>
          <EmployeeSearchContainer
            isVWTaskForce={isVWTaskForce(user)}
            type="freelancer"
            filter={filter}
            onSubmit={loadData}
            onReset={() => loadData({ searchNames: [], skillIds: [], isReplaceable: false })}
          />
        </div>
      </header>

      <div className={c.wrap}>
        {error && <ErrorBlock {...error} />}
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          totalElements={totalElements}
          onChange={onPaginationChange}
        />
        <List type="employee" data={freelancers} baseUrl={sitemap.projectLead.freelancers.detail.path} />
        <Pagination currentPage={currentPage} totalPage={totalPages} onChange={onPaginationChange} />
      </div>

      {isUserSuperAdmin(user) && <ButtonFloating onClick={() => setIsEmployeeModalOpen(true)} icon="add" />}

      <Modal isOpen={isEmployeeModalOpen} onClose={closeEmployeeModal} headline={'Add Freelancer'}>
        {isEmployeeModalOpen && (
          <EmployeeFormContainer
            updateStatus={updateStatus}
            isExternal
            onSubmit={(data) => dispatch(createOrUpdateEmployee.request(data))}
            onCancel={closeEmployeeModal}
          />
        )}
      </Modal>
    </Main>
  );
};
