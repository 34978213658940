import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { Employee, EmployeeFilter, EmployeesMeta, HttpError } from '../../models';

export const searchFreelancers = createAsyncAction(
  '@freelancers/SEARCH_FREELANCERS',
  '@freelancers/SEARCH_FREELANCERS_SUCCESS',
  '@freelancers/SEARCH_FREELANCERS_FAILURE',
  '@freelancers/SEARCH_FREELANCERS_CANCEL'
)<EmployeeFilter, EmployeesMeta, HttpError, void>();

export const updateFreelancer = createAction('@freelancers/UPDATE_FREELANCER')<Employee>();

export const setFreelancersPage = createAction('@freelancers/SET_FREELANCERS_PAGE')<number>();

export type FreelancersAction =
  | ActionType<typeof searchFreelancers>
  | ActionType<typeof updateFreelancer>
  | ActionType<typeof setFreelancersPage>;
