import { Button, createStyles, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import cx from 'clsx';
import React, { useRef, useState } from 'react';
import { Icon } from '.';
import { Employee, Sorting } from '../models';
import {getToday, withId} from '../utils';

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    }),
  { name: 'EmployeeSorting' }
);

type Props = {
  sorting: Sorting<Employee>;
  onChange: (sorting: Partial<Sorting<Employee>>) => void;
  className?: string;
};


export const EmployeeSorting = ({ sorting, onChange, className }: Props): JSX.Element => {
  const c = useStyles({});
    const currentMonth = getToday();
    const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
    const months = [
        currentMonth.toLocaleString('en-US', { month: 'long' }),
        nextMonth.toLocaleString('en-US', { month: 'long' })
    ];

  const sortableFields: { id: keyof Employee; label: string }[] = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'capacityScore', label: `Capacity ${months[0]}` },
    { id: 'capacityScoreNextMonth', label: `Capacity ${months[1]}` },
  ];
  const { orderBy, orderAsc } = sorting;

  const menuRef = useRef<HTMLButtonElement>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  const onSetSorting = (sorting: Partial<Sorting<Employee>>) => () => {
    onChange(sorting);
    closeMenu();
  };

  return (
    <div className={cx(c.root, className)}>
      Sorted by:
      <Button
        ref={(ref) => (menuRef.current = ref)}
        variant="text"
        size="small"
        onClick={openMenu}
        children={sortableFields.find(withId(orderBy)).label}
      />
      <IconButton
        size="small"
        onClick={onSetSorting({ orderAsc: !orderAsc })}
        children={<Icon name={orderAsc ? 'chevronUp' : 'chevronDown'} />}
      />
      <Menu
        anchorEl={menuRef.current}
        getContentAnchorEl={undefined}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {sortableFields.map(({ id, label }) => (
          <MenuItem key={id} disabled={orderBy === id} onClick={onSetSorting({ orderBy: id })} children={label} />
        ))}
      </Menu>
    </div>
  );
};
