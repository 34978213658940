import { Button, makeStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { Loading } from '.';

const useStyles = makeStyles(
  () => ({
    loading: {
      position: 'absolute',
      '& +span': { opacity: 0 },
    },
  }),
  { name: 'ButtonLoading' }
);

export const ButtonLoading = ({ children, ...props }: ButtonProps): JSX.Element => {
  const c = useStyles({});
  return (
    <Button
      {...props}
      children={
        props.disabled ? (
          <>
            {/* loading will appear on top of label with 0 opacity to keep the same button width */}
            <Loading className={c.loading} isIcon />
            <span children={children} />
          </>
        ) : (
          children
        )
      }
    />
  );
};
