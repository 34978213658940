import React from 'react';
import { OrganizationTree } from '../models';
import { TreeView } from '@material-ui/lab';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Grid } from '@material-ui/core';
import cx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flexGrow: 1,
    maxWidth: 400,
    color: 'black',
  },
  active: {
    color: 'red',
  },
  inactive: {
    color: 'black',
  },
  disable: {
    color: theme.palette.text.secondary,
  },
}));

type Props = {
  organizationTreeRoot: OrganizationTree;
  currentOrganization?: OrganizationTree;
  onItemClick?: (organization: OrganizationTree) => void;
  className?: string;
};

export const OrganizationTreeView = ({
  organizationTreeRoot,
  currentOrganization,
  onItemClick,
  className,
}: Props): JSX.Element => {
  const c = useStyles();

  const renderTree = (organization: OrganizationTree) =>
    organization?.children &&
    organization.children.map((item) => (
      <TreeItem
        className={cx(
          { [c.active]: item.id === currentOrganization?.id },
          { [c.inactive]: item.id !== currentOrganization?.id },
          className
        )}
        key={item.id}
        nodeId={item.id}
        label={item.name}
        onLabelClick={() => onItemClick && onItemClick(item)}
      >
        {renderTree(item)}
      </TreeItem>
    ));

  const getParents = (o?: OrganizationTree) => [...(o ? [o, getParents(o.parent).flat()].flat() : [])];

  return (
    <Grid className={className} container>
      <Grid item xs={12}>
        <TreeView
          defaultExpanded={getParents(currentOrganization).map((item) => item.id)}
          className={c.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(organizationTreeRoot)}
        </TreeView>
      </Grid>
    </Grid>
  );
};
