import { createStyles, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { Avatar, ButtonBack, CertificateList, FormattedName, Icon, List, SkillList, TitleValueItem } from '.';
import {getTruncateStyles, getWrapStyles, Main} from '../layout';
import {Employee, EntityArray, Project, ProjectHistory, User} from '../models';
import { sitemap } from '../routes';
import { canUpdate, formatDate, isUserSuperAdmin } from '../utils';
import { getSubNavStyles } from './Tabs';

const HEADER_HEIGHT = 80;

export const getSectionStyles = (theme: Theme) =>
  createStyles({
      ...getTruncateStyles(),
      title: {
          fontSize: 12,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.secondary,
          marginBottom: 5,
      },
      root: {
      top: HEADER_HEIGHT,
    },
    head: {
      ...getSubNavStyles(theme).root,
      '& +$section': { marginTop: 0 },
    },
    headCol: {
      display: 'flex',
      alignItems: 'center',
    },
    headColTitle: {
      flexDirection: 'column',
      alignItems: 'initial',
    },
    headTitle: {
      height: HEADER_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    headActions: {
      marginLeft: 'auto',
    },
    warningText: {
      margin: 'auto',
      color: 'red',
    },
    h1: {
      fontSize: 14,
    },
    section: {
      background: theme.palette.common.white,
      marginTop: theme.spacing(2),
      boxShadow: theme.customShadows.fixed,
      '&:first-child': { marginTop: 0 },
    },
    sectionRelative: {
      position: 'relative', // to cast shadow on top of staffing
    },
    sectionHead: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': { border: 0 },
    },
    sectionHeadTitle: {
      fontSize: 14,
    },
    sectionContent: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    titleValue: {
      marginTop: theme.spacing(2),
      '&:first-child': { marginTop: 0 },
    },
      projectHistory: {
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.divider}`,
          '&:last-child': { border: 0 },
      },
      addNewProject: {
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.divider}`,
          '&:last-child': { border: 0 },
      },
      consultantProfile: {
          display: 'flex',
          gap: 10,
      }

  });

const useStyles = makeStyles(
  (theme) => ({
    ...getWrapStyles(theme),
    ...getSectionStyles(theme),
    avatar: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'EmployeeDetail' }
);


type Props = {
  employee: Employee;
  user: User;
  onProfileEdit?: () => void;
  onShowOrgaTree?: () => void;
  onProfileDelete?: () => void;
  onCertificatesEdit?: () => void;
  onSkillsEdit?: () => void;
  onFreeCapacityEdit?: () => void;
  onImageEdit?: () => void;
  onProjectHistoryEdit?: () => void;
  projects?: EntityArray<Project>;
  backButtonLink?: string;
  showWarning: boolean;
  projectsHistory?: EntityArray<ProjectHistory>;
};

export const EmployeeDetail = ({
  employee,
  user,
  onProfileEdit,
  onShowOrgaTree,
  onProfileDelete,
  onCertificatesEdit,
  onSkillsEdit,
  onImageEdit,
  onProjectHistoryEdit,
  backButtonLink,
  showWarning,
  projectsHistory,
}: Props): JSX.Element => {
  const c = useStyles({});
  const {
    certificates,
    organization,
    jobTitle,
    languages,
    location,
    placeOfResidence,
    skills,
    workingHoursPerWeek,
    freeCapacities = [],
    isExternal,
    peopleCounselor,
    isValid,
    permissions,
    teamLead,
  } = employee;

  const titleValueProps = {
    className: c.titleValue,
    isDetail: true,
  };

  return (
    <Main className={c.root}>
      <header className={cx(c.section, c.head)}>
        <div className={c.wrap}>
          <div className={c.headTitle}>
            <Avatar className={c.avatar} {...employee} size="large" onClick={onImageEdit} />
            <div>
              <h1 className={c.h1} children={<FormattedName {...employee} />} />
              <div>{jobTitle?.name}</div>
            </div>
            {showWarning && !isValid && (
              <h3 className={c.warningText}>
                Please update you profile, especially your organizational structure, in order to use other features
              </h3>
            )}

              <div className={c.headActions}>
                  {isUserSuperAdmin(user) && user.employee.id !== employee.id && (
                <IconButton onClick={onProfileDelete} children={<Icon name="delete" />} />
              )}
              {canUpdate(permissions) && onProfileEdit && (
                <IconButton onClick={onProfileEdit} children={<Icon name="edit" />} />
              )}
            </div>
          </div>
          {backButtonLink && <ButtonBack to={backButtonLink} />}
        </div>
      </header>

      <section className={c.section}>
        <div className={cx(c.sectionContent, c.wrap)}>
          <Grid container>
            <Grid item xs>
              <TitleValueItem
                title={'Organization Unit'}
                value={
                  organization ? (
                    <div
                      key={organization.id}
                      children={
                        <div>
                          {organization.name}
                          {onShowOrgaTree && (
                            <IconButton onClick={onShowOrgaTree} size={'small'} children={<Icon name="help" />} />
                          )}
                        </div>
                      }
                    />
                  ) : (
                    '-'
                  )
                }
                {...titleValueProps}
              />
              <TitleValueItem
                title={isExternal ? 'QA' : teamLead ? 'Team Lead' : 'People Counselor'}
                value={
                  teamLead
                    ? `${teamLead.firstName} ${teamLead.lastName}`
                    : peopleCounselor && `${peopleCounselor.firstName} ${peopleCounselor.lastName}`
                }
                {...titleValueProps}
              />
            </Grid>

            <Grid item xs>
              <TitleValueItem
                title={'Languages'}
                value={languages.map(({ id, name }) => (
                  <div key={id} children={name} />
                ))}
                {...titleValueProps}
              />
            </Grid>

            <Grid item xs>
              <TitleValueItem title={'Level'} value={jobTitle?.name} {...titleValueProps} />
              <TitleValueItem title={'Working Hours'} value={workingHoursPerWeek} {...titleValueProps} />
            </Grid>

            <Grid item xs>
              <TitleValueItem title={'Office Location'} value={location?.name} {...titleValueProps} />
              <TitleValueItem title={'Place of Residence'} value={placeOfResidence} {...titleValueProps} />
            </Grid>
            {/*  ToDo upload/Download Consultant Profile*/}
            {/*<Grid item xs>*/}
            {/*    <h3 className={cx(c.title, c.truncate)}>{'Consultant Profile'}</h3>*/}
            {/*    <div className={c.consultantProfile} >*/}
            {/*        <Icon id={'download-profile'} name={'consultantProfile'} fontSize="large" />*/}
            {/*        <Icon id={'upload-profile'} name={'consultantProfile'} fontSize="large" />*/}
            {/*    </div>*/}
            {/*</Grid>*/}

          </Grid>
        </div>
      </section>

      {!isExternal && (
        <section className={c.section}>
          <div className={c.wrap}>
            <div className={c.sectionHead}>
              <h2 className={c.sectionHeadTitle}>{'Free Capacity'}</h2>
            </div>
            {freeCapacities.map(({ freeDaysPerWeek, startDate, endDate }, index) => (
              <div className={c.sectionContent} key={index}>
                <Grid container>
                  <Grid item xs>
                    <TitleValueItem
                      title={'Free Days per Week'}
                      value={freeDaysPerWeek && `${freeDaysPerWeek} days`}
                      {...titleValueProps}
                    />
                  </Grid>
                  <Grid item xs>
                    <TitleValueItem
                      title={'Starting from'}
                      value={startDate && formatDate(startDate)}
                      {...titleValueProps}
                    />
                  </Grid>
                  <Grid item xs>
                    <TitleValueItem title={'To'} value={endDate && formatDate(endDate)} {...titleValueProps} />
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </section>
      )}

      <section className={c.section}>
        <div className={c.wrap}>
          <div className={c.sectionHead}>
            <h2 className={c.sectionHeadTitle}>{'Skills'}</h2>
            {onSkillsEdit && (
              <div className={c.headActions}>
                {canUpdate(permissions) && <IconButton onClick={onSkillsEdit} children={<Icon name="edit" />} />}
              </div>
            )}
          </div>
          <SkillList className={c.sectionContent} skills={skills} />
        </div>
      </section>

      <section className={c.section}>
        <div className={c.wrap}>
          <div className={c.sectionHead}>
            <h2 className={c.sectionHeadTitle}>{'Certificates'}</h2>
            {onCertificatesEdit && (
              <div className={c.headActions}>
                {canUpdate(permissions) && <IconButton onClick={onCertificatesEdit} children={<Icon name="edit" />} />}
              </div>
            )}
          </div>
          <CertificateList className={c.sectionContent} certificates={certificates} />
        </div>
      </section>

      <section className={c.section}>
        <div className={c.wrap}>
          <div className={c.sectionHead}>
            <h2 className={c.sectionHeadTitle}>{'Project History'}</h2>
              {onProjectHistoryEdit && (
                  <div className={c.headActions}>
                      {canUpdate(permissions) && <IconButton onClick={onProjectHistoryEdit} children={<Icon name="edit" />} />}
                  </div>
              )}
          </div>
            {/*<ProjectHistoryList className={c.sectionContent} projectsHistory={projectsHistory}/>*/}
        </div>
      </section>
      <section className={c.wrap}>
        <List type="projectHistory" data={projectsHistory} baseUrl={sitemap.projects.detail.path} />
          {/*<ProjectHistoryList className={c.sectionContent} projectsHistory={projectsHistory}/>*/}

      </section>
    </Main>
  );
};
