import { ButtonBase, createStyles, makeStyles, Tooltip } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Skill } from '../models';
import { getConfig } from '../store/app';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        '&:active': {
          '& $btnLevel::before': {
            background: theme.palette.common.black,
            borderColor: theme.palette.common.black,
          },
        },
        '&:hover': {
          // make all levels appear active
          '& $btnLevel::before': {
            background: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
        },
      },
      rootDisabled: {
        pointerEvents: 'none',
      },
      btnLevel: {
        display: 'block',
        height: 20,
        width: 20,
        padding: 5,
        '&::before': {
          content: '""',
          display: 'block',
          height: 10,
          width: 10,
          borderRadius: '50%',
          border: `1px solid ${theme.palette.accent.skill}`,
        },
        '&:hover': {
          // override styles of levels after the one currently hovered
          '& ~$btnLevel::before': {
            background: 'none',
            borderColor: theme.palette.accent.skill,
          },
        },
        '&:first-child': {
          margin: 0,
        },
      },
      btnLevelActive: {
        '&::before': {
          background: theme.palette.primary.main,
        },
      },
      btnLevelOverlap: {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 3,
          left: 3,
          height: 14,
          width: 14,
          borderRadius: '50%',
          border: `1px solid ${theme.palette.accent.skill}`,
        },
      },
      btnLevelOverlapGood: {
        '&::after': {
          borderColor: theme.palette.accent.green,
        },
      },
      btnLevelOverlapBad: {
        '&::after': {
          borderColor: theme.palette.error.main,
        },
      },
    }),
  { name: 'SkillLevel' }
);

const useTooltipStyles = makeStyles((theme) => {
  const arrowSize = 6;
  return createStyles({
    tooltip: {
      position: 'relative',
      background: theme.palette.primary.main,
      margin: `0 0 ${theme.spacing(1)}px 1px`, // 1px margin left to fix positioning issue
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: -arrowSize,
        left: '50%',
        marginLeft: -arrowSize,
        height: 0,
        width: 0,
        borderStyle: 'solid',
        borderWidth: `${arrowSize}px ${arrowSize}px 0 ${arrowSize}px`,
        borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
      },
    },
  });
});

type Props = {
  skill: Skill;
  skillOverlap?: Skill;
  showOverlap?: boolean;
  onLevelChange?: (params: Skill) => void;
  className?: string;
};

export const SkillLevel = ({ skill, skillOverlap, showOverlap, onLevelChange, className }: Props): JSX.Element => {
  const c = useStyles({});
  const maxSkillLevel = useSelector(getConfig).maxSkillLevel;

  const cTooltip = useTooltipStyles({});
  const levels = [
    'Beginner: Project Experience < 1 Year',
    '',
    'Advanced: Project Experience 1-3 Years',
    '',
    'Expert: Project Experience > 3 Years',
  ];
  const tooltips = maxSkillLevel === 3 ? levels.filter((x) => x !== '') : levels; // support for 3 and 5 levels

  return (
    <div
      className={cx(c.root, { [c.rootDisabled]: !onLevelChange }, className)}
      onClick={onLevelChange ? (e) => onLevelChange({ ...skill, level: +e.target['value'] }) : undefined}
      children={[...Array(maxSkillLevel)].map((_, i) => (
        <Tooltip key={i} classes={cTooltip} title={tooltips[i]} placement="top">
          <ButtonBase
            value={onLevelChange && i + 1}
            className={cx(c.btnLevel, {
              [c.btnLevelActive]: i + 1 <= skill.level,
              ...(showOverlap && {
                [c.btnLevelOverlap]: i + 1 <= skill.level || (skillOverlap && i + 1 <= skillOverlap.level),
                [c.btnLevelOverlapGood]: skillOverlap && i + 1 <= skillOverlap.level,
                [c.btnLevelOverlapBad]: (!skillOverlap || i + 1 > skillOverlap.level) && i + 1 <= skill.level,
              }),
            })}
            disableRipple
          />
        </Tooltip>
      ))}
    />
  );
};
