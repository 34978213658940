import { getType } from 'typesafe-actions';
import {UpdateStatus} from '../../models';
import {initialUpdateStatus} from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';
import {AttachmentDTO} from "../../proxy";

type State = {
    readonly fileUploadStatus: UpdateStatus;
    readonly uploadResponse: AttachmentDTO
};

const initialState: State = {
    fileUploadStatus: initialUpdateStatus,
    uploadResponse: null,
};

export const fileReducer = (
    state = initialState,
    action: fromActions.FileAction | AppActions.AppAction
): State => {

    switch (action.type) {

        case getType(fromActions.clearUpload):
            return {
                ...state,
                uploadResponse: null,
                fileUploadStatus: { isPending: false, isSuccess: undefined },
            };

        case getType(fromActions.uploadFile.request):
            return {
                ...state,
                fileUploadStatus: { isPending: true, isSuccess: undefined },
            };

        case getType(fromActions.uploadFile.success):
            return {
                ...state,
                uploadResponse: action.payload,
                fileUploadStatus: { isPending: false, isSuccess: true },
            };

        case getType(fromActions.uploadFile.failure):
            return {
                ...state,
                fileUploadStatus: { isPending: false, isSuccess: false, error: action.payload },
            };
        default:
            return state;
    }
};
