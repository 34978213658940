import { makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      '& p strong': {
        fontSize: '16px',
      },
    },
    text: {
      fontSize: '14px',
    },
  }),
  { name: 'PrivacyPolicyText' }
);

type Props = {
  className?: string;
};

export const PrivacyPolicyText = ({ className }: Props): JSX.Element => {
  const c = useStyles({});
  return (
    <div className={cx(c.root, className)}>
      <div className={cx(c.text)}>
        <h1>Information regarding Data Protection</h1>
        <p>
          We, MHP Management- und IT- Beratung GmbH (hereinafter referred to as "we" or "MHP"), take the protection of
          your personal data and its confidentiality very seriously. Your personal data is processed exclusively within
          the framework of the legal provisions of the European Union's data protection law, in particular the General
          Data Protection Regulation (hereinafter referred to as"GDPR") and the German Federal Data Protection Act
          ("BDSG").{' '}
        </p>
        <p>
          This Privacy Policy is intended to inform you about the processing of your personal data in connection with
          your employment relationship and about your data protection rights within the framework of the employment
          relationship.
        </p>
        <br />
        <p>
          <strong>1. Data Controller and Data Protection Officer; Contact</strong>
        </p>
        <p>The data controller within the meaning of the data protection laws is the following:&nbsp;</p>
        <p>
          MHP Management- und IT-Beratung GmbH
          <br /> Film- und Medienzentrum
          <br /> Königsallee 49
          <br /> 71638 Ludwigsburg
          <br /> Germany
          <br /> Email: dataprivacy@mhp.com
        </p>
        <p>
          Some data processing is carried out under joint responsibility in accordance with Article 26 of the GDPR on
          the basis of a written agreement. The cooperation partner is:
        </p>
        <p>
          Porsche AG
          <br /> Porscheplatz 1<br /> 70435 Stuttgart
          <br /> Telephone: +49 711 911 - 0<br /> Email: datenschutz@porsche.de
        </p>
        <p>If you have any questions or suggestions regarding data protection, please do not hesitate to contact us.</p>
        <p>You can reach our data protection officer at the following:&nbsp;</p>
        <p>
          Stephan Moers
          <br /> Datenschutzberatung Moers GmbH
          <br /> Neue Straße 22
          <br /> 34369 Hofgeismar
          <br /> Germany
        </p>
        <p>Email: datenschutz@dsb-moers.de</p>
        <p>
          With regard to processing in the context of internal group administration and division of labor by centralized
          systems, we are jointly responsible with Porsche AG (Dr. Ing. h.c. Porsche AG, Porscheplatz 1, 70435
          Stuttgart, where the data protection officer of Porsche AG can also be reached). The joint processes relate in
          particular to the operation and use of shared databases, platforms and IT systems. For more information about
          the joint processes, see the description below, which explains the purposes of data processing. In relation to
          the joint processes, we determine the purposes and means of processing personal data together with Porsche AG.{' '}
        </p>
        <p>
          In an agreement concerning our joint responsibility in accordance with Article 26 of the GDPR, we have
          established with Porsche AG how each of the tasks and responsibilities for the processing of personal data is
          structured and who fulfills which data protection obligations. In particular, we have defined how an
          appropriate level of security and your rights as a data subject can be ensured, how we can jointly fulfill the
          data protection information obligations and how we can monitor potential data privacy incidents. This also
          includes ensuring that we meet our reporting and notification obligations.{' '}
        </p>
        <p>
          Porsche AG is available to you as a central point of contact with joint responsibility for processing
          operations. However, you can also assert your rights against us as a jointly responsible group company. If you
          contact us, we will coordinate with Porsche AG in accordance with the agreement referred to in Article 26 of
          the GDPR, in order to answer your inquiry and to guarantee your rights as a data subject.
        </p>
        <br />
        <p>
          <strong>2. Subject of Data Protection</strong>
        </p>
        <p>
          The subject of data protection is personal data. This means any information relating to an identified or
          identifiable natural person ("data subject"). This includes, for example, information such as name, postal
          address, email address or telephone number, as well as information that is required during the employment
          relationship, such as information about your qualifications, training or professional background.
        </p>
        <br />
        <p>
          <strong>3. Purposes and Legal Basis of Data Processing</strong>
        </p>
        <p>
          Below you will find an overview of the purposes and legal bases of data processing as part of the employment
          relationship.
        </p>
        <br />
        <p>
          <strong>3.1 Preparation and Implementation of the Employment Relationship</strong>
        </p>
        <p>
          We process personal data if this is necessary for the preparation and implementation of the employment
          relationship with you. The purposes shall include in particular:&nbsp;
        </p>
        <ul>
          <li>
            Carrying out and supporting the tasks assigned to you and other employees (e.g. processing work results)
          </li>
          <li>Handling internal and external communications (e.g. correspondence with clients and customers)</li>
          <li>Administrative tasks as part of the employment relationship (e.g. handling business trips)</li>
          <li>
            Administration of the induction process (e.g. conclusion of employment contract, creation in personnel
            administration, employee introduction/onboarding)
          </li>
          <li>
            Implementation of personal support (e.g. carrying out performance reviews, advice and implementation of
            individual measures, maintenance of personnel administration, handling work contract changes, remuneration,
            implementation of the assessment process and objective agreement process, implementation of event
            management, implementation of the mood barometer)
          </li>
          <li>
            Personal development (e.g. qualification and training, implementation of personal development programs,
            competency and talent management, support and implementation of individual development initiatives)
          </li>
          <li>
            Handling of the employment relationship (e.g. payroll accounting, processing of individual supplementary
            insurance, implementation of travel management, handling of operational service and additional services,
            cooperation with authorities, fulfillment of legal obligations, employment and social law administration,
            time management)
          </li>
          <li>
            Internal administration (e.g. plant catering, occupational safety, corporate security, personnel and staff
            management, MHP ideas management, administration organizational development, coordination with the works
            committee, user management)
          </li>
          <li>
            Processing of IT usage data (e.g. user ID, passwords) in IT systems for the purpose of professional
            use&nbsp;{' '}
          </li>
          <li>Administration of the exit process, termination of employment relationship</li>
          <li>(Pre-)Contractual measures relating to vehicle leasing (VW, Porsche and Sixt)</li>
          <li>Personnel scheduling and controlling, as well as reporting&nbsp;</li>
        </ul>
        <p>
          Further details regarding the purposes of data processing can be obtained from the documents provided to you
          as part of the employment relationship.&nbsp;
        </p>
        <p>
          The data is processed on the basis of Article 6, Paragraph 1, Item (b) of the GDPR in conjunction with Section
          26, Paragraph 1, Sentence 1 of the BDSG. In this respect, we process the personal data required for carrying
          out the employment relationship.&nbsp;&nbsp;
        </p>
        <p>
          We delete the data if it is no longer necessary for carrying out the employment relationship and if no other
          legal basis to retain the data exists. If there is another legal basis to retain the data, we will delete the
          data after the other legal basis has been removed. In particular, a longer duration of storage may be
          necessary to fulfill legal obligations (e.g. in connection with pension rights).&nbsp;&nbsp;
        </p>
        <br />
        <p>
          <strong>3.2 Fulfillment of Legal Obligations</strong>
        </p>
        <p>
          We also process your personal data in order to comply with legal obligations to which we are subject. The
          obligations may arise, for example, from laws related to trade, tax, money laundering or financial law.
          Measures may also be taken in the field of occupational safety, health management and aptitude tests as a
          result of legal obligations. The purposes of processing result from the relevant legal obligation and the
          processing usually serves the purpose of fulfilling state monitoring and information obligations.
        </p>
        <p>
          Data will be processed on the basis of Article 6, Paragraph 1, Item c) of the GDPR. In this respect, we
          process the personal data required for carrying out the legal obligation.&nbsp;&nbsp;
        </p>
        <p>
          We delete the data after the legal obligation has been removed and if there is no other legal basis to retain
          this data. If there is another legal basis to retain the data, we will delete the data after the other legal
          basis has been removed.&nbsp;
        </p>
        <br />
        <p>
          <strong>3.3 Protection of Legitimate Interests</strong>
        </p>
        <p>
          We also process your personal data for the protection of our legitimate interests or those of third parties.
          We pursue the following interests, which are also the respective purposes:&nbsp;
        </p>
        <ul>
          <li>Administration of the induction process</li>
          <li>Implementation of personnel support</li>
          <li>Personal development</li>
          <li>Handling of the employment relationship</li>
          <li>
            Administration and implementation of occupational health management and occupational social counseling
          </li>
          <li>Internal administration</li>
          <li>Administration of the exit process</li>
          <li>Personnel scheduling and controlling, as well as reporting</li>
          <li>Internal communications</li>
          <li>Ensuring operational safety</li>
          <li>Assertion of legal claims</li>
          <li>
            Sending postal mail/greeting cards to private addresses, e.g. in the event of holidays, anniversaries or
            promotions
          </li>
        </ul>
        <p>
          Data will be processed on the basis of Article 6, Paragraph 1, Item f) of the GDPR. In this respect, we
          process personal data that is necessary for the protection of legitimate interests, unless your interest in
          the protection of personal data outweighs this.&nbsp;
        </p>
        <p>
          We anonymize or delete the data if it is no longer necessary for the purposes we are pursuing and if no other
          legal basis to retain the data exists. If another legal basis exists, we will anonymize or delete the data
          after the other legal basis has been removed. A conclusion regarding your identity can no longer be drawn
          after anonymization.&nbsp;<strong>&nbsp;</strong>
        </p>
        <p>
          <strong></strong>&nbsp;
        </p>
        <p>
          <strong>3.4 Consent</strong>
        </p>
        <p>
          If you have given your consent for specific purposes, e.g. the use of the employee photo for MHP internal
          communication, the purposes will be based on the content of that particular declaration of consent.&nbsp;
        </p>
        <p>
          Data will be processed on the basis of Article 6, Paragraph 1, Item a) of the GDPR. You can withdraw your
          consent at any time without providing reasons. The withdrawal of consent will not affect the lawfulness of
          processing based on consent before its withdrawal.&nbsp;
        </p>
        <p>
          We will delete the data if you have withdrawn your consent and no other legal basis exists. If there is
          another legal basis to retain the data, we will delete the data after the other legal basis has been removed.
        </p>
        <br />
        <p>
          <strong>4. Recipients of Personal Data</strong>
        </p>
        <p>
          Internal recipients: Within MHP, access will be granted only to those who need it for the purposes outlined in
          Section 3 above.
        </p>
        <p>
          External recipients: We will only share your personal data with external recipients outside of MHP if this is
          necessary for the purpose of carrying out the employment relationship, if there is another legal basis to do
          so or if we have your consent to do so.&nbsp;
        </p>
        <p>External recipients can be:&nbsp;</p>
        <p>A) Data processors</p>
        <p>
          Subsidiaries of MHP or external service providers, e.g. event service providers, which are used as part of
          operational processes. These data processors are carefully selected and regularly reviewed by us to ensure
          that your privacy is maintained. The service providers are permitted to use the data exclusively for the
          purposes specified by us.&nbsp;
        </p>
        <p>B) Public authorities</p>
        <p>
          Public authorities and state institutions, such as the tax office, police, public prosecutor's office, social
          insurance agencies, courts, universities and other third parties, insofar as we instruct you to pass on data.
        </p>
        <p>c) Group companies of Porsche AG</p>
        <p>
          Group companies of Porsche AG, with whom data is shared on the basis of consent, or – if on the basis of the
          agreement on joint responsibility according to Article 26 of the GDPR – for the purpose of carrying out the
          employment relationship or for protecting legitimate interests. In this case, the data is shared on the basis
          of Article 6, Paragraph 1, items a), b) and/or f) of the GDPR or on the basis of Section 26, Paragraph 1,
          Sentence 1 of the BDSG.&nbsp;
        </p>
        <p>d) Group companies of Volkswagen Group</p>
        <p>
          Group companies of the Volkswagen Group with whom data is shared on the basis of consent for carrying out the
          employment relationship or for the protection of legitimate interests. In this case, the data is shared on the
          basis of Article 6, Paragraph 1, items a), b) and/or f) of the GDPR or on the basis of Section 26, Paragraph
          1, Sentence 1 of the BDSG.&nbsp;
        </p>
        <p>e) Independent service providers </p>
        <p>
          Independent service providers who provide services for MHP within the scope of your role and with whom data is
          shared for carrying out the employment relationship or for the protection of legitimate interests. In this
          case, the data is shared on the basis of Article 6, Paragraph 1, Items b) and/or f) of the GDPR.&nbsp;
        </p>
        <br />
        <p>
          <strong>5. Data Processing in Third Countries</strong>
        </p>
        <p>
          If data is shared with entities whose place of business or place of data processing is not located in a Member
          State of the European Union or in another Contracting State to the Agreement on the European Economic Area, we
          shall ensure that, apart from in exceptional cases permitted by law, the recipient has either an adequate
          level of data protection (e.g. verified by a European Commission adequacy decision, by appropriate guarantees
          such as self-certification of the recipient for the EU-US Privacy Shield or by agreeing EU Standard
          Contractual Clauses with the recipient) or your sufficient consent, before any data is shared.&nbsp;
        </p>
        <p>
          We use Microsoft Office 365 services for data processing. Here there are apps that are only provided
          exclusively via US-American data centers (e.g. SWAY). We have concluded a corresponding data protection
          agreement with Microsoft for this purpose and have laid down terms of use for internal use, so that the data
          protection principles are adhered to.
        </p>
        <br />
        <p>
          <strong>6. Sources and Data Categories for Data Collected from Third Parties</strong>
        </p>
        <p>
          We do not only process personal data that we receive directly from you. We receive some personal data from
          third parties. Below is an overview of the sources and data categories for data collected from third parties:{' '}
        </p>
        <ul>
          <li>We may obtain information from the Group companies of Porsche AG about their collaboration with you</li>
          <li>
            We may obtain information from Group companies of the Volkswagen Group about their collaboration with you
          </li>
          <li>We may obtain information from public bodies, such as courts or financial authorities</li>
        </ul>
        <br />
        <p>
          <strong>7. Duration of Storage</strong>
        </p>
        <p>
          To find out the duration of storage of personal data, please refer to the relevant chapter on data processing
          under Point 3. In addition, we generally store your personal data only as long as this is necessary for the
          fulfillment of the purposes or – in the case of consent – for as long as you have not withdrawn your consent
          (and provided no other legal basis to retain this data exists). In the event of an objection to processing, we
          will delete your personal data, unless further processing is permitted in accordance with the relevant
          statutory provisions. We will also delete your personal data if we are obliged to do so for legal reasons.{' '}
        </p>
        <br />
        <p>
          <strong>8. Rights as a Data Subject</strong>
        </p>
        <p>As a data subject, you have many rights at your disposal. In detail: </p>
        <p>
          <b>Right to be informed</b>: You have the right to receive information about the personal data stored by us
          about you.&nbsp;&nbsp;
        </p>
        <p>
          <b>Right to rectification and erasure</b>: You have the right to request that we rectify incorrect data and –
          provided that the legal requirements are fulfilled – erase your data.&nbsp;&nbsp;
        </p>
        <p>
          <b>Restriction of processing</b>: You have the right to restrict the processing of your data by us, provided
          that legal requirements are fulfilled.&nbsp;&nbsp;
        </p>
        <p>
          <b>Data portability:</b> If you have provided us with data on the basis of a contract or consent, and if the
          legal requirements are met, you may request to receive the personal data provided by you, in a structured,
          commonly used and machine-readable format or request that we share this data with another data
          controller.&nbsp;
        </p>
        <p>
          <b>
            Objection to data processing in the case of "legitimate interest" as a legal basis: You have the right to
            object at any time to the processing of data by us for reasons arising from your particular situation,
            insofar as this is based on the legal basis of "legitimate interest". If you exercise your right to object,
            we will stop processing your data unless we can prove – in accordance with the legal requirements –
            compelling and legitimate reasons for further processing which outweigh your rights.&nbsp;&nbsp;
          </b>
        </p>
        <p>
          <b>
            Withdrawal of consent: If you have given us your consent to the processing of your data, you can withdraw it
            at any time with effect for the future. This does not affect the lawfulness of the processing of your data
            up to the point at which consent was withdrawn.&nbsp;
          </b>
        </p>
        <p>
          <b>Right to lodge a complaint with a supervisory authority:</b> You also have the right to lodge a complaint
          with the relevant supervisory authority if you believe that the processing of your data is in violation of
          applicable law. You can contact the data protection authority responsible for your place of residence or
          country or the data protection authority responsible for us.&nbsp;&nbsp;
        </p>
        <p>
          <b>How to contact us or to exercise your rights</b>: Furthermore, you can contact us free of charge if you
          have any questions about the processing of your personal data, your rights as a data subject and any consent
          given. To exercise any of your above rights, please contact <i>info@mhp.com</i> or send postal mail to the
          address given above under Section 1. When exercising any of your rights, please make sure that you provide us
          with sufficient information to explicitly identify you. If you withdraw your consent, you can also choose the
          contact method that you used when you gave your consent.
        </p>
        <br />
        <p>
          <strong>9.&nbsp;Status</strong>
        </p>
        <p>The latest version of this Privacy Policy applies. </p>
        <p>As of 03/26/2020</p>
      </div>
    </div>
  );
};
