import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { CriteriaStatus, EmailModel, FAQ, HttpError, ModelType, ModelUnion, OrganizationTree } from '../../models';
import { CriteriaFormModel } from '../../components/CriteriaList';

export const fetchModels = createAsyncAction(
  '@mixed/FETCH_MODELS',
  '@mixed/FETCH_MODELS_SUCCESS',
  '@mixed/FETCH_MODELS_FAILURE'
)<ModelType, { type: ModelType; data: ModelUnion[] }, { type: ModelType; error: HttpError }>();

export const fetchModel = createAsyncAction(
  '@mixed/FETCH_MODEL',
  '@mixed/FETCH_MODEL_SUCCESS',
  '@mixed/FETCH_MODEL_FAILURE'
)<{ type: ModelType; id: string }, { type: ModelType; data: ModelUnion }, { type: ModelType; error: HttpError }>();

export const fetchOrganizationTreeRoot = createAsyncAction(
  '@mixed/FETCH_ORGANIZATION_TREE_ROOT',
  '@mixed/FETCH_ORGANIZATION_TREE_ROOT_SUCCESS',
  '@mixed/FETCH_ORGANIZATION_TREE_ROOT_FAILURE'
)<void, OrganizationTree, HttpError>();

export const fetchFAQ = createAsyncAction('@mixed/FETCH_FAQ', '@mixed/FETCH_FAQ_SUCCESS', '@mixed/FETCH_FAQ_FAILURE')<
  void,
  FAQ[],
  HttpError
>();

export const editModel = createAsyncAction(
  '@mixed/EVALUATE_MODEL',
  '@mixed/EVALUATE_MODEL_SUCCESS',
  '@mixed/EVALUATE_MODEL_FAILURE'
)<CriteriaFormModel, { type: ModelType; data: ModelUnion; status: CriteriaStatus }, HttpError>();

export const removeModel = createAsyncAction(
  '@mixed/REMOVE_MODEL',
  '@mixed/REMOVE_MODEL_SUCCESS',
  '@mixed/REMOVE_MODEL_FAILURE'
)<{ id: string; type: ModelType }, { id: string; type: ModelType }, HttpError>();

export const createModel = createAsyncAction(
  '@mixed/CREATE_MODEL',
  '@mixed/CREATE_MODEL_SUCCESS',
  '@mixed/CREATE_MODEL_FAILURE'
)<CriteriaFormModel, { type: ModelType; data: ModelUnion }, HttpError>();

export const sendEmail = createAsyncAction(
  '@mixed/SEND_EMAIL',
  '@mixed/SEND_EMAIL_SUCCESS',
  '@mixed/SEND_EMAIL_FAILURE'
)<EmailModel<any>, void, HttpError>();

// to update list states upon creation
export const addModel = createAction('@mixed/ADD_MODEL')<{ type: ModelType; data: ModelUnion }>();

export const filterModels = createAction('@mixed/FILTER_MODEL')<{ type: ModelType }>();

export type MixedAction =
  | ActionType<typeof fetchModels>
  | ActionType<typeof fetchFAQ>
  | ActionType<typeof fetchModel>
  | ActionType<typeof fetchOrganizationTreeRoot>
  | ActionType<typeof editModel>
  | ActionType<typeof removeModel>
  | ActionType<typeof filterModels>
  | ActionType<typeof createModel>
  | ActionType<typeof addModel>
  | ActionType<typeof sendEmail>;
