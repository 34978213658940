import { AppBar, createStyles, makeStyles, Menu, MenuItem, IconButton } from '@material-ui/core';
import cx from 'clsx';
import React, { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AvatarListItem, Logo } from '.';
import { environment as env, isFeatureEnabled } from '../environments';
import { User } from '../models';
import { NavItemType, sitemap, toNavItemType } from '../routes';
import {
  e2e,
  isUserAdmin,
  isUserPeopleCounselor,
  isUserPeopleDeveloper,
  isUserProfileValid,
  isUserProjectLead,
  isUserResourceManager,
  isUserSuperAdmin,
  isUserTeamLead,
} from '../utils';
import { ButtonSearch } from './ButtonSearch';
import { useDispatch, useSelector } from 'react-redux';
import { getConfig } from '../store/app';
import { Add } from '@material-ui/icons';
import { PositionFormModel, StepperModal } from './StepperModal';
import { createUpdatePosition } from '../store/positions';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        background: theme.header.background,
        height: theme.header.height,
        fontSize: 14,
      },
      navItem: {
        display: 'inline-block',
        height: theme.header.height,
        lineHeight: `${theme.header.height}px`,
        letterSpacing: '1.3px',
        padding: '0 15px',
        borderBottom: '3px solid transparent',
        color: theme.palette.common.white,
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightSemiBold,
        userSelect: 'none',
      },
      navItemActive: {
        borderColor: theme.palette.common.white,
      },
      navItemDisabled: {
        pointerEvents: 'none',
        color: theme.palette.grey[300],
      },
      navItemProfile: {
        display: 'flex',
        alignItems: 'center',
        letterSpacing: 0,
        fontWeight: theme.typography.fontWeightRegular,
      },
      profile: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
      logo: {
        right: 'auto',
        left: 15,
        height: theme.header.height,
      },
      env: {
        position: 'absolute',
        bottom: 5,
        left: 0,
        right: 0,
        height: 16,
        lineHeight: '16px',
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        borderRadius: 5,
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '2px',
      },
      addPositionButton: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[2],
        color: theme.palette.primary.contrastText,
        fontSize: 14,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    }),
  { name: 'Header' }
);

type Props = {
  user: User;
  onLogout: () => void;
};

export const Header = ({ user, onLogout }: Props): JSX.Element => {
  const c = useStyles({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>();
  const { activeFeatures } = useSelector(getConfig);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: PositionFormModel, reroute: string) => {
    dispatch(createUpdatePosition.request({ request: values, reroute }));
    setOpen(false);
  };

  const mainNav: NavItemType[] = [
    toNavItemType(sitemap.projectLead.overview),
    toNavItemType(sitemap.projects.root),
    toNavItemType(sitemap.positions.root),

    ...(isUserTeamLead(user) ||
    isUserPeopleDeveloper(user) ||
    isUserResourceManager(user) ||
    isUserPeopleCounselor(user)
      ? [toNavItemType(sitemap.teamLead.employees.root)]
      : [{ children: sitemap.teamLead.employees.root.name, to: '', disabled: true, exact: true }]),

    // ...(isUserProjectLead(user) ? [toNavItemType(sitemap.projectLead.freelancers.root)] : []),

    { children: 'Analytics', to: '', disabled: true, exact: true }, // NOTE: temporary
  ].map(isUserProfileValid(user) ? (item) => item : (item) => ({ ...item, disabled: true }));

  const userMenuNav: NavItemType[] = [
    toNavItemType(sitemap.profile),
    ...(isFeatureEnabled('useRedirectLogin') ? [] : [toNavItemType(sitemap.settings)]),
    toNavItemType(sitemap.imprint),
    toNavItemType(sitemap.privacyPolicy),
    toNavItemType(sitemap.faqs),
    toNavItemType(sitemap.news),
    toNavItemType(sitemap.userguide),
    ...(isUserAdmin(user) ? [toNavItemType({ path: '/admin', name: 'Admin' })] : []),
  ];

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <AppBar className={c.root} position="fixed" color="inherit" elevation={0}>
      <Link
        to={sitemap.home.path}
        className={cx(c.profile, c.logo)}
        children={
          <>
            <Logo />
            {env.environment !== 'prod' && <span className={c.env}>{env.environment}</span>}
          </>
        }
      />

      <nav>
        {mainNav.map((item, index) => (
          <NavLink
            key={index}
            className={cx(c.navItem, { [c.navItemDisabled]: item.disabled })}
            activeClassName={c.navItemActive}
            {...item}
            {...e2e(`nav-${item.children}`)}
          />
        ))}
      </nav>

      {user && (
        <div className={c.profile}>
          {activeFeatures.includes('ENTERPRISE_SEARCH') && (isUserProjectLead(user) || isUserResourceManager(user) || isUserPeopleCounselor(user)) && (
            <NavLink to={sitemap.search.path}>
              <ButtonSearch text="Search" />
            </NavLink>
          )}
          <div>
            {activeFeatures.includes('CREATE_POSITIONS_WIZARD') &&
              (isUserProjectLead(user) || isUserResourceManager(user)) && (
                <IconButton aria-label="Add position" className={cx(c.addPositionButton)} onClick={handleOpen}>
                  <Add />
                  Add Position
                </IconButton>
              )}
            <StepperModal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              isOpen={open}
              headline="Create new position"
              onClose={handleClose}
              onSubmit={handleSubmit}
              disableClose={false}
            >
              <div>
                <h2 id="transition-modal-title">Transition modal</h2>
                <p id="transition-modal-description">react-transition-group animates me.</p>
              </div>
            </StepperModal>
          </div>
          <div
            ref={(ref) => (menuRef.current = ref)}
            className={cx(c.navItem, c.navItemProfile)}
            onClick={openMenu}
            {...e2e('btn-menu')}
          >
            <AvatarListItem isShort {...user.employee} withAvatar />
          </div>

          <Menu
            anchorEl={menuRef.current}
            getContentAnchorEl={undefined}
            open={isMenuOpen}
            onClose={closeMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            {userMenuNav.map((item, index) => (
              <MenuItem
                key={index}
                component={Link}
                onClick={closeMenu}
                {...item}
                {...e2e(`btn-menu-${item.children}`)}
              />
            ))}
            <a href={`mailto:helpdesk@mhp.com?subject=${encodeURIComponent('Support Skill Matching @ MHP')}`}>
              <MenuItem>Support</MenuItem>
            </a>
            {isUserSuperAdmin(user) && (
              <a href={`${window.location.origin}/setup`} target="_blank" rel="noopener noreferrer">
                <MenuItem>Setup</MenuItem>
              </a>
            )}
            <MenuItem onClick={onLogout} children={'Logout'} />
          </Menu>
        </div>
      )}
    </AppBar>
  );
};
