import { createStyles, makeStyles, Theme } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';
import { getTruncateStyles } from '../layout';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      ...getTruncateStyles(),
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        fontWeight: theme.typography.fontWeightSemiBold,
      },
      rootDetail: {
        fontSize: 14,
      },
      title: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.secondary,
        marginBottom: 5,
      },
      multiLine: {
        fontWeight: theme.typography.fontWeightRegular,
      },
    }),
  { name: 'TitleValueItem' }
);

type Props = {
  title: string;
  value: React.ReactNode;
  isDetail?: boolean;
  hasLineBreaks?: boolean;
  fullValueOnHover?: boolean;
  className?: string;
};

export const TitleValueItem = ({
  title,
  value,
  isDetail,
  hasLineBreaks,
  fullValueOnHover,
  className,
}: Props): JSX.Element => {
  const c = useStyles({});
  return (
    <div className={cx(c.root, { [c.rootDetail]: isDetail }, className)}>
      <h3 className={cx(c.title, c.truncate)}>{title}</h3>
      <div
        title={fullValueOnHover && value && !hasLineBreaks && fullValueOnHover && value.toString()}
        className={cx({ [c.truncate]: !hasLineBreaks, [c.multiLine]: hasLineBreaks })}
      >
        {value || '–'}
      </div>
    </div>
  );
};
