import { getType } from 'typesafe-actions';
import { EntityArray, Pagination, Project, ProjectFilter, ProjectsStats } from '../../models';
import { getInitialEntityArrayState, replaceIn, withId } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

type State = {
  readonly projects: EntityArray<Project>;
  readonly filter: ProjectFilter;
  readonly pagination: Pagination;
  readonly stats: ProjectsStats;
};

const initialState: State = {
  projects: getInitialEntityArrayState(),
  filter: { status: 'running', searchKeyword: undefined },
  pagination: {
    currentPage: 1,
    totalPages: 1,
  },
  stats: undefined,
};

export const projectsReducer = (
  state = initialState,
  action: fromActions.ProjectsAction | AppActions.AppAction
): State => {
  const { projects, stats, filter } = state;
  const { data } = projects;

  switch (action.type) {
    case getType(fromActions.fetchProjects.request):
      return {
        ...state,
        projects: {
          ...getInitialEntityArrayState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchProjects.success):
      return {
        ...state,
        filter: {
          ...filter,
          status: action.payload.currentStatus,
        },
        projects: {
          ...projects,
          data: action.payload.data,
          isLoading: false,
        },
        pagination: {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
        },
        stats: action.payload.stats,
      };

    case getType(fromActions.fetchProjects.failure):
      return {
        ...state,
        projects: {
          ...projects,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.updateProject):
      const newProject = action.payload;
      return {
        ...state,
        projects: {
          ...projects,
          data:
            // check for matching status and if we already have projects, so we don't start a new list
            newProject.status === state.filter.status || newProject.status === 'opportunity'
              ? // replace on update, add on create
                data.some(withId(newProject.id))
                ? replaceIn(data, withId(newProject.id), () => newProject)
                : [newProject, ...data]
              : data,
        },
        stats:
          newProject.status !== 'opportunity' && newProject.isNewlyCreated
            ? {
                ...stats,
                [newProject.status]: stats[newProject.status] + 1,
              }
            : stats,
      };

    // -------------------------

    case getType(fromActions.addOrUpdateProjectPosition):
      const { position: newPosition } = action.payload;
      return {
        ...state,
        projects: {
          ...projects,
          data: replaceIn(data, withId(action.payload.id), ({ positions, ...item }) => ({
            ...item,
            positions: positions.filter(withId(newPosition.id)).length
              ? replaceIn(positions, withId(newPosition.id), () => newPosition)
              : [newPosition, ...positions],
          })),
        },
      };

    case getType(fromActions.removeProjectPosition):
      return {
        ...state,
        projects: {
          ...projects,
          data: data.length
            ? replaceIn(data, withId(action.payload.projectId), ({ positions, ...item }) => ({
                ...item,
                positions: positions.filter((x) => x.id !== action.payload.positionId),
              }))
            : data,
        },
      };

    // -------------------------

    case getType(fromActions.removeProject):
      return {
        ...state,
        projects: {
          ...projects,
          data: data.filter((item) => item.id !== action.payload),
        },
      };

    // -------------------------

    case getType(fromActions.setProjectsStatus):
      return {
        ...state,
        filter: {
          ...filter,
          status: action.payload,
        },
        pagination: {
          ...state.pagination,
          currentPage: 1,
        },
      };

    // -------------------------

    case getType(fromActions.setProjectsKeyword):
      return {
        ...state,
        filter: {
          ...filter,
          searchKeyword: action.payload,
        },
        pagination: {
          ...state.pagination,
          currentPage: 1,
        },
      };

    // -------------------------

    case getType(fromActions.setProjectsPage):
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload,
        },
      };

    // -------------------------

    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
