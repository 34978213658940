import {Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import {Model, ModelType, UpdateStatus} from '../models';
import {Field, Formik} from 'formik';
import {FormActions} from './ProjectForm';
import {mixed, object, string} from 'yup';
import {getFieldProps, getFormStyles} from '../utils';
import {OptionType, toOptionType} from './AutoCompleteField';
import {CriteriaFormModel} from './CriteriaList';

type Props = {
    title: string;
    criteria?: Model;
    updateStatus?: UpdateStatus;
    onSubmit?: (request: CriteriaFormModel) => void;
    type: ModelType;
    onCancel?: () => void;
    isUserRequest: boolean
};

export const CriteriaForm = ({title, type, criteria, onSubmit, isUserRequest, ...props}: Props): JSX.Element => {
    const useStyles = makeStyles((theme) => getFormStyles(theme), {name: 'CriteriaForm'});

    const c = useStyles({});
    const validationSchema = object({
        name: string().required('Name is required'),
        ...(isUserRequest && {
            requestMessage: string().required('Description is required'),
        }),
        ...((isUserRequest && type === 'skills') && {
            skillCategory: mixed().required('Category is required')
        })
    });

    const status: OptionType[] = [
        {value: 'confirm', label: 'Confirm'},
        {value: 'pending', label: 'Pending'},
        {value: 'decline', label: 'Deactivate'},
    ];

    const initialValues = {
        id: criteria ? criteria.id : undefined,
        type,
        name: criteria ? criteria.name : '',
        status: criteria
            ? status.find((item) => item.value === criteria.status)
            : status.find((item) => isUserRequest ? item.value === 'pending' : item.value === 'confirm'),
        deactivate: criteria?.status === 'decline',
        ...(isUserRequest && {requestMessage: criteria ? criteria.requestMessage : ''}),
        ...(isUserRequest && type === 'skills' && { skillCategory: undefined})
    };


    const skillCategories = [
        { id: 0, name: 'Automotive Basics' },
        { id: 1, name: 'Business' },
        { id: 2, name: 'Management Consulting' },
        { id: 3, name: 'Lifecycle Services' },
        { id: 4, name: 'Process & System Integration' },
        { id: 5, name: 'Custom Software' },
        { id: 6, name: 'Standard Software' },
        { id: 7, name: 'Technology Platforms' },
        { id: 8, name: 'Programming Languages' },
        { id: 9, name: 'Development Tools' },
        { id: 10, name: 'Digital Responsibility' },
        { id: 11, name: 'Cyber Security' },
        { id: 12,name: 'Sustainability' },
        { id: 13, name: 'Design' }
    ];

    const fieldProps = getFieldProps(false);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={({name, status,requestMessage, deactivate, skillCategory }, {setSubmitting}) => {
                setSubmitting(false);
                const criteriaName = type === 'skills' ? `${name} | ${skillCategory.label}` : name;
                onSubmit({
                    id: initialValues.id,
                    name: criteriaName,
                    type,
                    status: status.value === 'pending' ? 'pending' : deactivate ? 'decline' : 'confirm',
                    ...(isUserRequest && {requestMessage})
                });
            }}
        >
            {({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Grid className={c.inputGrid} container>
                        <Grid item xs={12}>
                            <Field {...fieldProps.text} name={'name'} label={`${title} Name`}/>
                        </Grid>

                        {isUserRequest &&
                            <Grid item xs={12} style={{marginTop: '1rem'}}>
                                <Field {...fieldProps.text} name={'requestMessage'} label={`${title} Description`}/>
                            </Grid>
                        }

                        { type === 'skills' &&
                            <Grid item xs={12} style={{ marginTop: '1rem' }}>
                                <Field
                                    {...fieldProps.select}
                                    name={'skillCategory'}
                                    label={`${title} Category`}
                                    options={skillCategories.map(toOptionType)}
                                    placeholder={"Select Category"}
                                >
                                </Field>
                            </Grid>
                        }

                    </Grid>
                    {criteria && initialValues.status.value !== 'pending' && (
                        <Grid className={c.inputGrid} container>
                            <Grid item xs={12}>
                                <Field
                                    {...fieldProps.checkbox}
                                    name={'deactivate'}
                                    type="checkbox"
                                    Label={{...fieldProps.checkbox.Label, label: 'Deactivate'}}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <FormActions
                        {...props}
                        updateStatus={{isPending: false}}
                        ctaLabel={isUserRequest ? `Request ${title}` : `Save ${title}`}
                        c={c}
                    />
                </form>
            )}
        </Formik>
    );
};
