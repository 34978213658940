import { Button, createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import BackgroundImg from '../assets/landing.jpg';
import { ChangePasswordFormContainer, Icon, LoginForm, Logo, SignUpForm } from '../components';
import { isFeatureEnabled } from '../environments';
import { UserCredentials } from '../models';
import { getHasToChangePassword, getUserStatus, login, resetSuccess, signUpOrResetPassword } from '../store/app';
import { e2e } from '../utils';

const BOX_WIDTH = 560;

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        minHeight: 710,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${BackgroundImg}) no-repeat 50% 50%`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        padding: '50px 0',
        overflow: 'auto',
      },
      box: {
        position: 'relative',
        width: BOX_WIDTH,
        padding: 100,
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.customShadows.card,
        textAlign: 'center',
      },
      logo: {
        width: 140,
        marginBottom: 50,
        fill: theme.palette.text.primary,
      },
      error: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 70,
        width: BOX_WIDTH,
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        fontSize: 15,
      },
      errorIcon: {
        marginRight: theme.spacing(1),
      },
      // role login buttons for development
      btnHidden: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: 70,
        width: 70,
        opacity: 0,
      },
      buttons: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        width: 'auto',
        whiteSpace: 'nowrap',
      },
    }),
  { name: 'LandingPage' }
);

export const LandingPage = (): JSX.Element => {
  const c = useStyles({});
  const dispatch = useDispatch();
  const updateStatus = useSelector(getUserStatus);
  const hasToChangePassword = useSelector(getHasToChangePassword);
  const { error } = updateStatus;

  const [credentials, setCredentials] = useState<UserCredentials>(undefined);
  const [mode, setMode] = useState<'login' | 'reset' | 'signup' | 'changePassword'>('login');
  const [showLoginButtons, setShowLoginButtons] = useState(false);

  useEffect(() => {
    if (hasToChangePassword) {
      setMode('changePassword');
    }
  }, [hasToChangePassword]);

  const onCancel = () => {
    setMode('login');
    dispatch(resetSuccess());
  };

  const onRoleClick = (email: string) => () => setCredentials({ email, password: 'testtest' });

  return (
    <>
      <Helmet title={'Welcome'} />

      <div className={c.root}>
        {error && (
          <div className={c.error}>
            <Icon className={c.errorIcon} name="error" />
            {error.message || error.error}
          </div>
        )}

        <div className={c.box}>
          <Logo className={c.logo} />

          {isFeatureEnabled('useRedirectLogin') ? (
            <div>Login happens via Azure AD.</div>
          ) : mode === 'login' ? (
            <LoginForm
              onSubmit={(data) => dispatch(login.request(data))}
              onReset={() => setMode('reset')}
              onSignUp={() => setMode('signup')}
              updateStatus={updateStatus}
              credentials={credentials}
            />
          ) : mode === 'changePassword' ? (
            <ChangePasswordFormContainer />
          ) : (
            <SignUpForm
              mode={mode}
              onSubmit={(data) => dispatch(signUpOrResetPassword.request(data))}
              onCancel={onCancel}
              updateStatus={updateStatus}
            />
          )}
        </div>

        {isFeatureEnabled('showRoleButtons') && (
          <>
            <Button
              className={c.btnHidden}
              onClick={() => setShowLoginButtons(true)}
              children=""
              {...e2e('btn-roles')}
            />
            {showLoginButtons && (
              <Grid className={c.buttons} container>
                {[
                  { email: 'paul.eins@mhub.one', role: 'Employee' },
                  { email: 'digital-ventures.pl@mhub.one', role: 'Project Lead' },
                  { email: 'digital-ventures.projectunit@mhub.one', role: 'Project Unit' },
                  { email: 'digital-ventures.ResourceManager@mhub.one', role: 'Resource Manager' },
                  { email: 'digital-ventures.PeopleDevelopment@mhub.one', role: 'People Development' },
                  { email: 'digital-ventures.tl@mhub.one', role: 'BA Lead' },
                ].map(({ email, role }) => (
                  <Grid
                    key={email}
                    item
                    xs
                    children={
                      <Button fullWidth onClick={onRoleClick(email)} children={role} {...e2e(`btn-role-${role}`)} />
                    }
                  />
                ))}
              </Grid>
            )}
          </>
        )}
      </div>
    </>
  );
};
