import { createStyles, Fade, IconButton, makeStyles, Modal as MuiModal } from '@material-ui/core';
import cx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { Icon } from '.';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
        width: '90%',
        maxWidth: 800,
        background: theme.palette.background.paper,
        boxShadow: theme.customShadows.overlay,
        borderRadius: 4,
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
      },
      header: {
        padding: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      content: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(6) + theme.inputs.buttonHeight,
        overflow: 'auto',
      },
      contentHasNoActions: {
        margin: 0,
      },
      title: {
        fontSize: 16,
      },
      closeButton: {
        position: 'absolute',
        top: 11, // to be aligned with headline
        right: 11,
      },
    }),
  { name: 'Modal' }
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  headline?: string;
  maxWidth?: number;
  disableClose?: boolean;
  hasNoActions?: boolean;
};

export const Modal = ({
  isOpen,
  onClose,
  headline,
  children,
  maxWidth,
  disableClose,
  hasNoActions,
}: PropsWithChildren<Props>): JSX.Element => {
  const c = useStyles({});
  return (
    <MuiModal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      disableEscapeKeyDown={disableClose}
    >
      <Fade in={isOpen}>
        <div className={c.paper} style={maxWidth && { maxWidth: maxWidth }}>
          {headline && (
            <header className={c.header}>
              <h2 className={c.title}>{headline}</h2>
              <IconButton
                className={c.closeButton}
                onClick={onClose}
                disabled={disableClose}
                children={<Icon name="close" />}
              />
            </header>
          )}

          <section className={cx(c.content, { [c.contentHasNoActions]: hasNoActions })}>{children}</section>
        </div>
      </Fade>
    </MuiModal>
  );
};
