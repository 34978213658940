import { configureScope, init } from '@sentry/browser';
import { environment as env } from './environments';

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: 'https://e9d5d8044d3c4e56aeeed96d71c5d4eb@o374349.ingest.sentry.io/5192268',
    release: env.release,
    environment: env.environment,
  });

  configureScope((scope) => {
    scope.setTag('version', env.version);
    scope.setTag('build', env.build);
  });
}
