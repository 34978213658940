import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeeFilter } from '../models';
import { fetchModels, getCertificates, getLanguages, getSkills, getVWSkills } from '../store/mixed';
import { EmployeeSearch } from './EmployeeSearch';

type Props = {
  type: 'employee' | 'freelancer';
  filter: EmployeeFilter;
  isVWTaskForce: boolean;
  onSubmit: (params: EmployeeFilter) => void;
  onReset: () => void;
  className?: string;
};

export const EmployeeSearchContainer = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { confirm: skills } = useSelector(getSkills);
  const { confirm: certificates } = useSelector(getCertificates);
  const { confirm: languages } = useSelector(getLanguages);
  const { data: vwSkills } = useSelector(getVWSkills);

  const criteria = [].concat(...[skills, certificates, languages]);

  useEffect(() => {
    if (!skills.length) {
      dispatch(fetchModels.request('skills'));
    }
    if (!certificates.length) {
      dispatch(fetchModels.request('certificates'));
    }
    if (!languages.length) {
      dispatch(fetchModels.request('languages'));
    }
    if (!vwSkills.length) {
      props.isVWTaskForce && dispatch(fetchModels.request('vwSkills'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <EmployeeSearch {...props} skills={criteria} vwSkills={vwSkills} />;
};
