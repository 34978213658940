import { getType } from 'typesafe-actions';
import { AutoCompleteSuggestions, Employee, EmployeeEnterpriseSearch, EntityArray, Pagination } from '../../models';
import { getInitialEntityArrayState } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';

type State = {
  readonly employees: EntityArray<Employee>;
  readonly pagination: Pagination;
  readonly searchFields: EmployeeEnterpriseSearch[];
  readonly criteriaOptions: AutoCompleteSuggestions[];
};

const initialState: State = {
  employees: getInitialEntityArrayState(),
  searchFields: [],
  criteriaOptions: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
  },
};

export const searchReducer = (state = initialState, action: fromActions.SearchAction | AppActions.AppAction): State => {
  const { employees } = state;

  switch (action.type) {
    case getType(fromActions.enterpriseSearchEmployees.request):
      return {
        ...state,
        employees: {
          ...employees,
          isLoading: true,
        },
      };

    case getType(fromActions.enterpriseSearchEmployees.success):
      const newEmployees =
        action.payload.currentPage === 1 ? action.payload.data : state.employees.data.concat(action.payload.data);

      return {
        ...state,
        employees: {
          ...employees,
          data: newEmployees,
          isLoading: false,
        },
        pagination: {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
        },
      };

    case getType(fromActions.autoCompleteSuggestions.success):
      return {
        ...state,
        criteriaOptions: action.payload,
      };

    case getType(fromActions.removeSearchCriteria):
      return {
        ...state,
        searchFields: state.searchFields.filter((d, i) => i !== action.payload),
      };

    case getType(fromActions.addSearchCriteria):
      return {
        ...state,
        searchFields: [...state.searchFields, action.payload],
      };

    case getType(fromActions.clearSuggestion):
      return {
        ...state,
        criteriaOptions: [],
      };

    case getType(fromActions.changeSkillLevel):
      const toBeChange = state.searchFields.find((d, i) => i === action.payload.index);
      const a = {
        ...toBeChange,
        level: action.payload.level,
      };
      const newSearchFields = state.searchFields.map((field, index) => (index === action.payload.index ? a : field));

      return {
        ...state,
        employees: getInitialEntityArrayState(),
        searchFields: newSearchFields,
      };

    case getType(fromActions.resetEnterpriseSearchEmployees):
      return {
        ...state,
        employees: getInitialEntityArrayState(),
        pagination: {
          currentPage: 1,
          totalPages: 1,
        },
      };

    default:
      return state;
  }
};
