import {Button, createStyles, makeStyles} from '@material-ui/core';
import React from 'react';
import {Icon} from '.';
import cx from 'clsx';

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                height: 'auto',
                marginRight: theme.spacing(2),
            },
            icon: {
                marginRight: theme.spacing(0.5),
            },
        }),
    {name: 'ButtonMailTo'}
);

type Props = {
    className?: string;
    text: string;
    onClick?: () => void;
};

export const ButtonMailTo = ({className, text, onClick}: Props): JSX.Element => {
    const c = useStyles({});
    return (
        <Button
            className={cx(c.root, className)}
            onClick={onClick}
            children={
                <>
                    <Icon className={c.icon} name="mail"/>
                    {text}
                </>
            }
        />
    );
};
