import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {HttpError} from "../../models";
import {AttachmentDTO, CreateOrUpdateFileRequest} from "../../proxy";

export const uploadFile = createAsyncAction(
    '@file/UPLOAD_FILE',
    '@file/UPLOAD_FILE_SUCCESS',
    '@file/UPLOAD_FILE_FAILURE'
)<CreateOrUpdateFileRequest, AttachmentDTO, HttpError>();

export const clearUpload = createAction('@file/CLEAR_UPLOAD')();
export const uploadFileRequest = createAction('@file/UPLOAD_FILE_REQUEST')<CreateOrUpdateFileRequest>();

export type FileAction =
    | ActionType<typeof uploadFile>
    | ActionType<typeof uploadFileRequest>
    | ActionType<typeof clearUpload>;