import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeService, MixedService } from '.';
import {
  AutoCompleteSuggestions,
  Employee,
  EmployeeEnterpriseSearch,
  EmployeeEnterpriseSearchRequest,
  EmployeesMeta,
  SearchType, Skill,
} from '../models';
import {
  CriteriaSearchSearchTypeEnum,
  EmployeeSearchListViewDTO,
  SearchControllerApi,
  SearchSuggestionTypesEnum,
} from '../proxy';
import { AuthInterceptor } from './auth.interceptor';
import {
  CriteriaSearch,
  CriteriaSearchLogicEnum,
  EmployeesSearchDTO,
  SuggestionSearchDTO,
  SuggestionSearchDTOTypeEnum,
} from '../proxy';
import { mapArray } from '../utils';

export class SearchService {
  private api = new SearchControllerApi(AuthInterceptor.Instance);

  static toEmployeesMeta = (data: EmployeesSearchDTO): EmployeesMeta => ({
    data: data.employees.map(SearchService.toEmployeeForList),
    currentPage: data.currentPage,
    totalPages: data.totalPages,
    totalElements: data.totalElements,
  });

  static toEmployeeForList = (data: EmployeeSearchListViewDTO): Employee => ({
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    jobTitle: data.title && MixedService.toModel(data.title),
    organization: data.department && MixedService.toOrganization(data.department),
    profileImage: data.profilePic && EmployeeService.getFullUrl(data.profilePic),
    freeCapacities: data.freeCapacities?.map(EmployeeService.toFreeCapacity) ?? [],
    capacityScore: data.capacity >= 0 ? data.capacity : undefined,
    peopleCounselor: data.peopleCounselor && EmployeeService.toEmployee(data.peopleCounselor),
    skills: data.skills?.map<Skill>(MixedService.toModel) ?? [],
  });

  static toSearchType = (data: SearchType): CriteriaSearchSearchTypeEnum => {
    switch (data) {
      case 'Level':
        return CriteriaSearchSearchTypeEnum.BusinessTitle;
      case 'Skill':
        return CriteriaSearchSearchTypeEnum.Skill;
      case 'Certificate':
        return CriteriaSearchSearchTypeEnum.Certificate;
      case 'Location':
        return CriteriaSearchSearchTypeEnum.OfficeLocation;
      case 'Language':
        return CriteriaSearchSearchTypeEnum.Language;
      case 'Employee':
        return CriteriaSearchSearchTypeEnum.Employee;
      case 'Project':
        return CriteriaSearchSearchTypeEnum.Project;
      case 'Capacity':
        return CriteriaSearchSearchTypeEnum.Capacity;
      default:
        throw new Error(`unsupported value for ${data}: ${CriteriaSearchSearchTypeEnum}`);
    }
  };

  static toSearchTypeString = (data: SuggestionSearchDTOTypeEnum): SearchType => {
    switch (data) {
      case SuggestionSearchDTOTypeEnum.Certificate:
        return 'Certificate';
      case SuggestionSearchDTOTypeEnum.BusinessTitle:
        return 'Level';
      case SuggestionSearchDTOTypeEnum.Skill:
        return 'Skill';
      case SuggestionSearchDTOTypeEnum.Language:
        return 'Language';
      case SuggestionSearchDTOTypeEnum.OfficeLocation:
        return 'Location';
      case SuggestionSearchDTOTypeEnum.Employee:
        return 'Employee';
      case SuggestionSearchDTOTypeEnum.Position:
        return 'Position';
      case SuggestionSearchDTOTypeEnum.Project:
        return 'Project';
      case SuggestionSearchDTOTypeEnum.Capacity:
        return 'Capacity';
      default:
        throw new Error(`unsupported value for ${data}: ${CriteriaSearchSearchTypeEnum}`);
    }
  };

  static toCriteriaSearch = (data: EmployeeEnterpriseSearch): CriteriaSearch => ({
    criteria: data.criteria.value,
    level: data.level,
    searchType: SearchService.toSearchType(data.searchType),
    logic: data.logic === 'AND' ? CriteriaSearchLogicEnum.And : CriteriaSearchLogicEnum.Not,
  });

  static toAutoCompleteSuggestions = (data: SuggestionSearchDTO): AutoCompleteSuggestions => ({
    value: data.value,
    label: data.label,
    type: SearchService.toSearchTypeString(data.type),
  });

  enterpriseSearchEmployees = ({ criteria, page }: EmployeeEnterpriseSearchRequest): Observable<EmployeesMeta> => {
    return this.api
      .enterpriseSearch({
        page,
        criteriaSearch: criteria.map(SearchService.toCriteriaSearch),
      })
      .pipe(map(SearchService.toEmployeesMeta));
  };

  autoCompleteSuggestions = (request: {
    keyword: string;
    types: SearchType[];
  }): Observable<AutoCompleteSuggestions[]> => {
    return this.api
      .searchSuggestion({
        keyword: request.keyword,
        types: request.types.map(SearchService.toSearchTypeRequest),
      })
      .pipe(map(mapArray(SearchService.toAutoCompleteSuggestions)));
  };

  static toSearchTypeRequest = (type: SearchType): SearchSuggestionTypesEnum => {
    switch (type) {
      case 'Level':
        return SearchSuggestionTypesEnum.BusinessTitle;
      case 'Skill':
        return SearchSuggestionTypesEnum.Skill;
      case 'Certificate':
        return SearchSuggestionTypesEnum.Certificate;
      case 'Location':
        return SearchSuggestionTypesEnum.OfficeLocation;
      case 'Language':
        return SearchSuggestionTypesEnum.Language;
      case 'Employee':
        return SearchSuggestionTypesEnum.Employee;
      case 'Position':
        return SearchSuggestionTypesEnum.Position;
      case 'Project':
        return SearchSuggestionTypesEnum.Project;
      case 'Capacity':
        return SearchSuggestionTypesEnum.Capacity;
    }
  };
}
