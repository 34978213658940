import { Field, Formik } from 'formik';
import { getFieldProps } from '../utils';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'clsx';

export type SearchFormModel = { searchKeyword?: string };

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: theme.palette.common.white,
      boxShadow: theme.customShadows.fixed,
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      minWidth: 300,
      margin: 0,
    },
  }),
  { name: 'ProjectSearch' }
);

type Props = {
  searchKeyword?: string;
  onSubmit: (searchKeyword: string) => void;
  className?: string;
};

export const ProjectSearch = ({ searchKeyword, onSubmit, className }: Props): JSX.Element => {
  const c = useStyles({});

  const fieldProps = getFieldProps(false);
  const initialValues: SearchFormModel = {
    searchKeyword: searchKeyword ?? '',
  };

  const validateName = (key: keyof SearchFormModel) => key;
  return (
    <div className={cx(c.root, className)}>
      <Formik
        onSubmit={({ searchKeyword }, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit(searchKeyword);
        }}
        initialValues={initialValues}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <div className={cx(fieldProps.select.className, c.input)}>
              <Field {...fieldProps.text} label={'Search for project details'} name={validateName('searchKeyword')} />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
