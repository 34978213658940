import { createStyles, makeStyles, Theme } from '@material-ui/core';
import cx from 'clsx';
import React, { PropsWithChildren } from 'react';

export const getWrapStyles = (theme: Theme) =>
  createStyles({
    wrap: {
      position: 'relative',
      maxWidth: 1400 + theme.spacing(6),
      margin: '0 auto',
      padding: theme.spacing(0, 3),
    },
    wrapTopAndBottom: {
      padding: theme.spacing(3),
    },
  });

export const getTruncateStyles = () =>
  createStyles({
    truncate: {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      main: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflowX: 'hidden',
      },
      mainHasHeader: {
        top: theme.header.height,
      },
    }),
  { name: 'Main' }
);

type Props = {
  isRoot?: boolean;
  className?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

export const Main = ({ children, isRoot, className, header, footer }: PropsWithChildren<Props>): JSX.Element => {
  const c = useStyles({});
  const RootNode = isRoot ? 'main' : 'div';

  return (
    <>
      {header}
      <RootNode className={cx(c.main, { [c.mainHasHeader]: header }, className)} children={children} />
      {footer}
    </>
  );
};
