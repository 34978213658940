import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import cx from 'clsx';
import React from 'react';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
      rootInline: {
        display: 'inline-flex',
        width: 'auto',
        margin: '0 5px',
        [theme.breakpoints.up('sm')]: {
          margin: '0 10px',
        },
      },
      rootIcon: {
        width: 24,
        '& $loader': {
          height: 24,
          width: 24,
          '&::after': {
            borderWidth: 3,
          },
        },
      },
      rootMargin: {
        margin: theme.spacing(2, 0),
      },
    }),
  { name: 'Loading' }
);

type Props = {
  className?: string;
  isInline?: boolean;
  isIcon?: boolean;
  withMargin?: boolean;
};

export const Loading = ({ className, isInline, isIcon, withMargin }: Props): JSX.Element => {
  const c = useStyles({});
  return (
    <div
      className={cx(c.root, { [c.rootInline]: isInline, [c.rootIcon]: isIcon, [c.rootMargin]: withMargin }, className)}
    >
      <CircularProgress />
    </div>
  );
};
