import { Button, createStyles, IconButton, makeStyles, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import { lighten } from '@material-ui/core/styles';
import cx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';
import { AvatarListItem } from '.';
import { getTruncateStyles } from '../layout';
import { Position } from '../models';
import { canUpdate, formatDate, formatJobTitleFilter, getPlannedDaysPerWeekInMonth } from '../utils';
import { Icon } from './Icon';
import { PositionItemTimeline } from './PositionItemTimeline';
import { PositionItemTooltip } from './PositionItemTooltip';
import { getPositionBaseStyles } from './PositionStaffing';
import { addMonths, isSameDay, subMonths } from 'date-fns';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getPositionBaseStyles(theme),
      head: {
        paddingRight: 0,
      },
      slotWrap: {
        position: 'relative',
        height: '100%',
        background: theme.palette.common.white,
        padding: 10,
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        marginBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2), // space for button
        ...getTruncateStyles().truncate,
        cursor: 'default',
        '&:hover': {
          overflow: 'visible',
        },
      },
      slot: {
        height: 50,
        padding: theme.spacing(1),
        background: theme.palette.divider,
        borderRadius: 2,
      },
      slotReadOnly: {
        background: 'none',
        pointerEvents: 'none',
      },
      avatar: {
        width: '100%',
      },
      btnAssign: {
        color: theme.palette.text.primary,
        fontSize: 12,
        justifyContent: 'initial',
        textAlign: 'left',
        lineHeight: 1.4,
        '&:hover': {
          background: lighten(theme.palette.divider, 0.2),
        },
      },
      btnAssignEmpty: {
        background: theme.palette.common.white,
        '&:hover': {
          background: theme.palette.common.white,
        },
      },
      btnAssignIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 34,
        minWidth: 34,
        maxWidth: 34,
        marginRight: 10,
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: '50%',
      },
      btnMenu: {
        position: 'absolute',
        top: 3,
        right: 3,
        height: 24,
        width: 24,
        padding: 0,
      },
      visibleIcon: {
        fontSize: 'inherit',
        color: theme.palette.primary.main,
      },
      invisibleIcon: {
        fontSize: 'inherit',
        color: theme.palette.text.secondary,
      },
      allTitle: {
        zIndex: 2,
        background: theme.palette.common.white,
      },
    }),
  { name: 'PositionItem' }
);

type Props = {
  position: Position;
  activeMonth: Date;
  onEdit?: () => void;
  onDelete?: () => void;
  onAssign?: () => void;
};

export const PositionItem = ({ position, activeMonth, onEdit, onDelete, onAssign }: Props): JSX.Element => {
  const c = useStyles({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>();

  const {
    startDate,
    endDate,
    employee,
    minJobTitle,
    maxJobTitle,
    role,
    isReadOnly,
    capacities,
    permissions,
    visibility,
  } = position;

  const [positionRole, setPositionRole] = useState(role);

  useEffect(() => {
    if (role.length > 20) {
      setPositionRole(role.substring(0, 20).concat('...'));
    } else setPositionRole(role);
  }, [role, positionRole]);

  const weeks = getPlannedDaysPerWeekInMonth(startDate, endDate, activeMonth);
  const currentCapacity = capacities?.find((capacity) => isSameDay(capacity.entryDate, activeMonth))?.capacity || 0;

  const previousCapacity =
    capacities?.find((capacity) => isSameDay(capacity.entryDate, subMonths(activeMonth, 1)))?.capacity || 0;

  const nextCapacity =
    capacities?.find((capacity) => isSameDay(capacity.entryDate, addMonths(activeMonth, 1)))?.capacity || 0;

  const recentCapacities = { currentCapacity, previousCapacity, nextCapacity };

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = (callback?: () => void) => {
    setIsMenuOpen(false);
    if (typeof callback === 'function') {
      callback();
    }
  };

  const openTooltip = () => setIsTooltipOpen(true);
  const closeTooltip = () => setIsTooltipOpen(false);

  const menuActions: Pick<MenuItemProps, 'onClick' | 'children' | 'disabled'>[] = [
    {
      onClick: () => closeMenu(onEdit),
      children: 'Edit Position',
      disabled: !onEdit,
    },
    {
      onClick: () => closeMenu(onAssign),
      children: 'Assign Employee',
      disabled: capacities?.length > 0 || !onAssign,
    },
    {
      onClick: () => closeMenu(onDelete),
      children: 'Delete Position',
      disabled: !!employee || !onDelete,
    },
  ];

  const positionName = employee ? (
    <h2 className={c.title}>{role}</h2>
  ) : visibility?.isActive ? (
    <h2 className={c.title}>
      <Tooltip title={role.length > 20 ? role : 'Publish'}>
        <div>
          <Icon name={'visible'} className={c.visibleIcon} /> &nbsp; {positionRole}
        </div>
      </Tooltip>
    </h2>
  ) : (
    <h2 className={c.title}>
      <Tooltip title={role.length > 20 ? role : 'Unpublished'}>
        <div>
          <Icon name={'invisible'} className={c.invisibleIcon} /> &nbsp; {positionRole}
        </div>
      </Tooltip>
    </h2>
  );

  return (
    <div className={c.position}>
      <div
        className={cx(c.positionHead, c.head)}
        ref={(ref) => (menuRef.current = ref)}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
      >
        <div className={c.slotWrap}>
          {positionName}

          {canUpdate(permissions) && !position.isReadOnly && (
            <IconButton className={c.btnMenu} onClick={openMenu} children={<Icon name="edit" fontSize="small" />} />
          )}
          <PositionItemTooltip isOpen={isTooltipOpen} position={position} anchorEl={menuRef.current} />

          <Button
            className={cx(c.slot, c.btnAssign, {
              [c.slotReadOnly]: isReadOnly || !canUpdate(permissions) || !onAssign || position.capacities.length > 0,
              [c.btnAssignEmpty]: !employee,
            })}
            variant="text"
            fullWidth
            onClick={onAssign}
          >
            {employee ? (
              <AvatarListItem className={c.avatar} {...employee} withJobTitle withAvatar />
            ) : (
              <>
                <div className={c.btnAssignIcon} children={<Icon name="addPerson" />} />
                <div>
                  <div children={`${formatDate(startDate)} – ${formatDate(endDate)}`} />
                  <div children={formatJobTitleFilter(minJobTitle, maxJobTitle)} />
                </div>
              </>
            )}
          </Button>
        </div>

        <Menu
          anchorEl={menuRef.current}
          getContentAnchorEl={undefined}
          open={isMenuOpen}
          onClose={closeMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {menuActions.map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </Menu>
      </div>

      <PositionItemTimeline weeks={weeks} capacities={recentCapacities} isAssigned={!!employee} />
    </div>
  );
};
