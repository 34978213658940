import React, { useState } from 'react';
import { OrganizationTree } from '../models';
import { Grid, IconButton } from '@material-ui/core';
import { Field } from 'formik';
import { getFieldProps } from '../utils';
import { Icon } from './Icon';
import { OrganizationTreeView } from './OrganizationTreeView';

type Props = {
  organizationTreeRoot: OrganizationTree;
  currentOrganization?: OrganizationTree;
  fieldProps: ReturnType<typeof getFieldProps>;
  onItemClick?: (organization: OrganizationTree) => void;
  className?: string;
};

export const OrganizationTreeViewForm = ({ fieldProps, className, ...props }: Props): JSX.Element => {
  const [showTree, setShowTree] = useState(false);

  return (
    <>
      <Grid className={className} container>
        <Grid item xs={11}>
          <Field {...fieldProps.select} name={'organization'} label={'Organization Unit'} disabled={true} />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => setShowTree(true)}
            className={className}
            children={<Icon name={'edit'} fontSize="small" />}
          />
        </Grid>
      </Grid>
      {showTree && <OrganizationTreeView {...props} />}
    </>
  );
};
