import { getType } from 'typesafe-actions';
import {Position, Entity} from '../../models';
import {getInitialEntityState} from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';
import {differenceInHours} from "date-fns";
import {MATOMO_EVENT_ACTION_DELETE_POSITION_LESS_THAN_2_HOURS, MATOMO_EVENT_CATEGORY_POSITIONS} from "../../constants";

type State = {
    readonly position: Entity<Position>;
    readonly isDeleted: Boolean;
};

const initialState: State = {
    position: getInitialEntityState(),
    isDeleted: false
};

export const positionReducer = (
    state = initialState,
    action: fromActions.PositionAction | AppActions.AppAction
): State => {
    const { position } = state;

    switch (action.type) {
        case getType(fromActions.fetchPosition.request):
            return {
                ...initialState, // reset data and error
                position: {
                    ...getInitialEntityState(),
                    isLoading: true,
                },
            };

        case getType(fromActions.fetchPosition.success):
            return {
                ...state,
                position: {
                    ...position,
                    data: action.payload,
                    isLoading: false,
                },
            };

        case getType(fromActions.fetchPosition.failure):
            return {
                ...state,
                position: {
                    ...position,
                    error: action.payload,
                    isLoading: false,
                },
            };

        case getType(fromActions.resetPosition):
        case getType(AppActions.logout.success):
            return initialState;
        case getType(fromActions.deletePosition.request):
            if (process.env.NODE_ENV === 'production') {
                if (differenceInHours(new Date(), action.payload.position.created)) {
                    window._paq.push([
                        'trackEvent',
                        MATOMO_EVENT_CATEGORY_POSITIONS,
                        MATOMO_EVENT_ACTION_DELETE_POSITION_LESS_THAN_2_HOURS,
                    ]);
                }
            }
            return {
                ...state,
                position: {
                    ...position,
                    isLoading: true,
                },
            };

        case getType(fromActions.deletePosition.success):
            return {
                ...state,
                position: {
                    ...position,
                    isLoading: false,
                },
                isDeleted: true,
            };

        case getType(fromActions.deletePosition.failure):
            return {
                ...state,
                position: {
                    ...position,
                    error: action.payload,
                    isLoading: false,
                },
            };
        default:
            return state;
    }
};

