import { createStyles, Divider, Grid, InputAdornment, makeStyles } from '@material-ui/core';
import { useFormikContext, Field } from 'formik';
import { getFieldProps, getFormStyles } from '../../utils';
import { PositionFormModel } from '../StepperModal';
import * as Yup from 'yup';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      ...getFormStyles(theme),
      checkBox: {
        margin: 0,
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  { name: 'PositionForm' }
);

const validateName = (key: keyof PositionFormModel) => key;

export const validationSchemaTimeframe = Yup.object().shape({
  startDate: Yup.date().required('Enter start date').nullable(),
  endDate: Yup.date()
    .when(validateName('startDate'), (startDate, schema) => (startDate ? schema.min(startDate) : schema))
    .required('Enter end date')
    .nullable(),
  daysPerWeek: Yup.number().max(5).nullable().notRequired(),
});

export const TimeframeStep = () => {
  const { values } = useFormikContext<PositionFormModel>();
  const fieldProps = getFieldProps(false, undefined, false);
  const c = useStyles({});

  return (
    <div className={c.wrapper}>
      <div className={c.section}>
        <Grid className={c.inputGrid} container spacing={4}>
          <Grid item xs={6}>
            <Field
              {...fieldProps.date}
              minDate={Date()}
              name={validateName('startDate')}
              label={'Start Date'}
              maxDate={values.endDate}
            />
          </Grid>
          <Grid item xs={6}>
            <Field {...fieldProps.date} name={validateName('endDate')} label={'End Date'} minDate={values.startDate} />
          </Grid>
        </Grid>
      </div>
      <Divider variant="middle" style={{ marginTop: 32 }} />
      <div className={c.section}>
        <Grid className={c.inputGrid} container>
          <Grid item xs={6}>
            <Field
              {...fieldProps.number}
              name={validateName('daysPerWeek')}
              label={'Days'}
              placeholder={'Days / week'}
              InputProps={{
                endAdornment: <InputAdornment position="end">/ week</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
