import { Epic } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { fetchAppRating, fetchOverview, fetchReleaseNote } from '.';
import { Services } from '../../services';
import { catchErrorAndHandleWithAction, filterAction } from '../../utils';
import { RootAction, RootState } from '../rootReducer';

const fetchOverviewEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { dashboardService }) =>
  action$.pipe(
    filterAction(fetchOverview.request),
    switchMap(() =>
      dashboardService
        .getOverview()
        .pipe(map(fetchOverview.success), catchErrorAndHandleWithAction(fetchOverview.failure))
    )
  );

const fetchAppRatingEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { feedbackService }) =>
  action$.pipe(
    filterAction(fetchAppRating.request),
    switchMap(() =>
      feedbackService
        .getAppRating()
        .pipe(map(fetchAppRating.success), catchErrorAndHandleWithAction(fetchAppRating.failure))
    )
  );

const fetchReleaseNotesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { releaseNoteService }
) =>
  action$.pipe(
    filterAction(fetchReleaseNote.request),
    switchMap(() =>
      releaseNoteService
        .getReleaseNotes()
        .pipe(map(fetchReleaseNote.success), catchErrorAndHandleWithAction(fetchReleaseNote.failure))
    )
  );

export const overviewEpics = [fetchOverviewEpic, fetchReleaseNotesEpic, fetchAppRatingEpic];
