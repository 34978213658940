import { BaseEntity, Entity, EntityArray, ModelArray, Permission, UpdateStatus, User, UserRole } from '../models';

export const withId =
  <T extends { id?: string | number }>(targetId: string | number) =>
  (item: T) =>
    targetId === item['id'];

export const notWithId =
  <T extends { id?: string | number }>(targetId: string | number) =>
  (item: T) =>
    targetId !== item['id'];

export const withName =
  <T extends { name: string }>(targetName: string) =>
  (item: T) =>
    targetName.toLowerCase() === item['name'].toLowerCase();

export const replaceIn = <T>(target: T[], f: (item: T, idx: number) => boolean, modification?: (item: T) => T): T[] => {
  if (!target.length) {
    return target;
  }

  const targetClone = [...target];
  const indexToRemove = targetClone.findIndex(f);
  const item = targetClone[indexToRemove];

  // https://github.com/Microsoft/TypeScript/issues/14409
  if (indexToRemove >= 0) {
    if (modification) {
      targetClone.splice(indexToRemove, 1, modification({ ...item }));
    } else {
      targetClone.splice(indexToRemove, 1);
    }
  }
  return targetClone;
};

export const getMonthName = (value: Date) => {
  const monthName = value.toLocaleString('en-US', { month: 'short' });
  return monthName;
};

export const toId = <T extends { id: string | number }>({ id }: T): T['id'] => id;

export const mapArray =
  <T, R>(f: (item: T, index?: number) => R) =>
  (list: T[]) =>
    list.map(f);

export const sumArray = (target: number[]) => target.reduce((pv, cv) => pv + cv, 0);

const initialBaseEntity: BaseEntity = {
  isLoading: false,
  error: undefined,
};

export const getInitialEntityState = <T>(): Entity<T> => ({
  data: undefined,
  ...initialBaseEntity,
});

export const getInitialEntityArrayState = <T>(): EntityArray<T> => ({
  data: [],
  ...initialBaseEntity,
});

export const getInitialModelArrayState = <T>(): ModelArray<T> => ({
  data: [],
  confirm: [],
  decline: [],
  pending: [],
  ...initialBaseEntity,
});

export const initialUpdateStatus: UpdateStatus = {
  isPending: false,
  isSuccess: undefined,
  error: undefined,
};

export const pick = <T, K extends keyof T>(obj: T, paths: K[]): Pick<T, K> =>
  ({ ...paths.reduce((mem, key) => ({ ...mem, [key]: obj[key] }), {}) } as unknown as Pick<T, K>);

const hasRole = (role: UserRole) => (user: User) => user?.roles?.indexOf(role) >= 0;
export const isUserEmployee = hasRole(UserRole.Employee);
export const isUserProjectLead = hasRole(UserRole.ProjectLead);
export const isVWTaskForce = hasRole(UserRole.VWTaskForce);
export const isUserTeamLead = hasRole(UserRole.TeamLead);
export const isUserPeopleDeveloper = hasRole(UserRole.PeopleDevelopment);
export const isUserPeopleCounselor = hasRole(UserRole.PeopleCounselor);
export const isUserResourceManager = hasRole(UserRole.ResourceManager);
export const isUserSuperAdmin = hasRole(UserRole.SuperAdmin);
export const isUserAdmin = hasRole(UserRole.Admin);

export const isUserOnlyEmployee = (user: User) => user?.roles.length === 1 && isUserEmployee(user);
export const isUserProfileValid = (user: User) => user?.isValid;

const hasPermission = (perm: Permission) => (perms: Permission[]) => perms?.indexOf(perm) >= 0;
export const canCreate = hasPermission('CREATE');
export const canRead = hasPermission('READ');
export const canUpdate = hasPermission('UPDATE');
export const canDelete = hasPermission('DELETE');

export const throwIfNotInMap = (map: {}, type: any, name: string) => {
  if (!Object.keys(map).includes(type)) {
    throw new Error(`unsupported value for ${name}: ${type}`);
  }
};

export const getFirstnameFromEmail = (email: string) => {
  let firstname = email.split('.')[0];
  firstname = firstname.charAt(0).toUpperCase() + firstname.slice(1);
  return firstname;
};
