import React from 'react';
import { match, RouteProps } from 'react-router-dom';
import { PositionProvider } from '../components';
import { sitemap } from '../routes';

type Props = RouteProps & {
  match: match<{ id: string }>;
};

export const PositionPage = ({
  match: {
    path,
    params: { id: positionId },
  },
}: Props): JSX.Element => {
  const {
    positions: {
      root: { path: pathToPositions },
    },
  } = sitemap;

  return (
    <PositionProvider positionId={positionId} backButtonLink={path.includes(pathToPositions) ? pathToPositions : ''} />
  );
};
