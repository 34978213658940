import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttachmentDTO, CreateOrUpdateFileRequest, FileControllerApi } from '../proxy';
import { AuthInterceptor } from './auth.interceptor';

export class FileService {
  private api = new FileControllerApi(AuthInterceptor.Instance);
  uploadFile = (params: CreateOrUpdateFileRequest): Observable<AttachmentDTO> =>
    this.api.createOrUpdateFile(params).pipe(map((data) => data));
}
