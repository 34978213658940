import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Client, HttpError } from '../../models';

export const fetchClients = createAsyncAction(
  '@clients/FETCH_CLIENTS',
  '@clients/FETCH_CLIENTS_SUCCESS',
  '@clients/FETCH_CLIENTS_FAILURE',
  '@clients/FETCH_CLIENTS_CANCEL'
)<void, Client[], HttpError, void>();

export type ClientsAction = ActionType<typeof fetchClients>;
