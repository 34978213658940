// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
    CreateOrUpdatePositionRequest,
    OpenPositionsDTO,
    ProjectPositionDTO,
} from '../models';

export interface GetMyPositionsRequest {
    page: number;
}

export interface GetOpenPositionRequest {
    page?: number;
    sortBy?: GetOpenPositionSortByEnum;
}

export interface GetPositionRequest {
    id: string;
}

export interface PutCreateOrUpdatePositionRequest {
    createOrUpdatePositionRequest: CreateOrUpdatePositionRequest;
}

export interface UpdatePositionLikeRequest {
    id: string;
    like: boolean;
}

/**
 * no description
 */
export class PositionControllerApi extends BaseAPI {

    /**
     */
    getMyPositions({ page }: GetMyPositionsRequest): Observable<OpenPositionsDTO>
    getMyPositions({ page }: GetMyPositionsRequest, opts?: OperationOpts): Observable<AjaxResponse<OpenPositionsDTO>>
    getMyPositions({ page }: GetMyPositionsRequest, opts?: OperationOpts): Observable<OpenPositionsDTO | AjaxResponse<OpenPositionsDTO>> {
        throwIfNullOrUndefined(page, 'page', 'getMyPositions');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'page': page,
        };

        return this.request<OpenPositionsDTO>({
            url: '/api/positions/owned',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getOpenPosition({ page, sortBy }: GetOpenPositionRequest): Observable<OpenPositionsDTO>
    getOpenPosition({ page, sortBy }: GetOpenPositionRequest, opts?: OperationOpts): Observable<AjaxResponse<OpenPositionsDTO>>
    getOpenPosition({ page, sortBy }: GetOpenPositionRequest, opts?: OperationOpts): Observable<OpenPositionsDTO | AjaxResponse<OpenPositionsDTO>> {

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (sortBy != null) { query['sortBy'] = sortBy; }

        return this.request<OpenPositionsDTO>({
            url: '/api/positions',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getPosition({ id }: GetPositionRequest): Observable<ProjectPositionDTO>
    getPosition({ id }: GetPositionRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectPositionDTO>>
    getPosition({ id }: GetPositionRequest, opts?: OperationOpts): Observable<ProjectPositionDTO | AjaxResponse<ProjectPositionDTO>> {
        throwIfNullOrUndefined(id, 'id', 'getPosition');

        return this.request<ProjectPositionDTO>({
            url: '/api/positions/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     */
    putCreateOrUpdatePosition({ createOrUpdatePositionRequest }: PutCreateOrUpdatePositionRequest): Observable<ProjectPositionDTO>
    putCreateOrUpdatePosition({ createOrUpdatePositionRequest }: PutCreateOrUpdatePositionRequest, opts?: OperationOpts): Observable<AjaxResponse<ProjectPositionDTO>>
    putCreateOrUpdatePosition({ createOrUpdatePositionRequest }: PutCreateOrUpdatePositionRequest, opts?: OperationOpts): Observable<ProjectPositionDTO | AjaxResponse<ProjectPositionDTO>> {
        throwIfNullOrUndefined(createOrUpdatePositionRequest, 'createOrUpdatePositionRequest', 'putCreateOrUpdatePosition');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ProjectPositionDTO>({
            url: '/api/positions',
            method: 'PUT',
            headers,
            body: createOrUpdatePositionRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    updatePositionLike({ id, like }: UpdatePositionLikeRequest): Observable<object>
    updatePositionLike({ id, like }: UpdatePositionLikeRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>
    updatePositionLike({ id, like }: UpdatePositionLikeRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
        throwIfNullOrUndefined(id, 'id', 'updatePositionLike');
        throwIfNullOrUndefined(like, 'like', 'updatePositionLike');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'like': like,
        };

        return this.request<object>({
            url: '/api/positions/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            query,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetOpenPositionSortByEnum {
    Match = 'MATCH',
    Latest = 'LATEST'
}
