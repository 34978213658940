import { Employee } from './employee.model';

export type UserCredentials = {
  email: string;
  password: string;
};

export type User = {
  email: string;
  token?: string;
  employee: Employee;
  hasToChangePassword: boolean;
  hasAcceptedPrivacyPolicy: boolean;
  isValid: boolean;
  roles: UserRole[];
  showAppFeedback?: boolean;
};

export enum UserRole {
  Employee = 'Employee',
  ProjectLead = 'ProjectLead',
  TeamLead = 'TeamLead',
  ProjectUnit = 'ProjectUnit',
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  ResourceManager = 'ResourceManager',
  PeopleDevelopment = 'PeopleDevelopment',
  PeopleCounselor = 'PeopleCounselor',
  VWTaskForce = 'VWTaskForce',
  QA = 'QA',
  TemplateProject = 'TemplateProject',
}

export type Permission = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';
export type withPermissions = {
  permissions?: Permission[];
};
