// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
    AttachmentDTO,
    CriteriaDTO,
    EmployeeDTO,
    PositionCapacityDTO,
    ProjectDTO,
    TitleDTO,
    VisibilityPositionDTO,
} from './';

/**
 * @export
 * @interface ProjectPositionDTO
 */
export interface ProjectPositionDTO {
    /**
     * @type {string}
     * @memberof ProjectPositionDTO
     */
    id: string;
    /**
     * @type {string}
     * @memberof ProjectPositionDTO
     */
    created?: string;
    /**
     * @type {string}
     * @memberof ProjectPositionDTO
     */
    description?: string;
    /**
     * @type {string}
     * @memberof ProjectPositionDTO
     */
    positionRole?: string;
    /**
     * @type {string}
     * @memberof ProjectPositionDTO
     */
    startDate: string;
    /**
     * @type {string}
     * @memberof ProjectPositionDTO
     */
    endDate: string;
    /**
     * @type {ProjectDTO}
     * @memberof ProjectPositionDTO
     */
    project?: ProjectDTO;
    /**
     * @type {number}
     * @memberof ProjectPositionDTO
     */
    workingHoursPerWeek: number;
    /**
     * @type {number}
     * @memberof ProjectPositionDTO
     */
    manDays: number;
    /**
     * @type {string}
     * @memberof ProjectPositionDTO
     */
    type: ProjectPositionDTOTypeEnum;
    /**
     * @type {EmployeeDTO}
     * @memberof ProjectPositionDTO
     */
    employee?: EmployeeDTO;
    /**
     * @type {boolean}
     * @memberof ProjectPositionDTO
     */
    readOnly: boolean;
    /**
     * @type {Array<CriteriaDTO>}
     * @memberof ProjectPositionDTO
     */
    skills?: Array<CriteriaDTO>;
    /**
     * @type {CriteriaDTO}
     * @memberof ProjectPositionDTO
     */
    location?: CriteriaDTO;
    /**
     * @type {Array<CriteriaDTO>}
     * @memberof ProjectPositionDTO
     */
    languages?: Array<CriteriaDTO>;
    /**
     * @type {Array<CriteriaDTO>}
     * @memberof ProjectPositionDTO
     */
    certificates?: Array<CriteriaDTO>;
    /**
     * @type {TitleDTO}
     * @memberof ProjectPositionDTO
     */
    minBusinessTitle?: TitleDTO;
    /**
     * @type {TitleDTO}
     * @memberof ProjectPositionDTO
     */
    maxBusinessTitle?: TitleDTO;
    /**
     * @type {Array<string>}
     * @memberof ProjectPositionDTO
     */
    permissions: Array<ProjectPositionDTOPermissionsEnum>;
    /**
     * @type {number}
     * @memberof ProjectPositionDTO
     */
    probability?: number;
    /**
     * @type {Array<PositionCapacityDTO>}
     * @memberof ProjectPositionDTO
     */
    capacities: Array<PositionCapacityDTO>;
    /**
     * @type {VisibilityPositionDTO}
     * @memberof ProjectPositionDTO
     */
    visibility?: VisibilityPositionDTO;
    /**
     * @type {boolean}
     * @memberof ProjectPositionDTO
     */
    like?: boolean;
    /**
     * @type {Array<AttachmentDTO>}
     * @memberof ProjectPositionDTO
     */
    attachments?: Array<AttachmentDTO>;
}

/**
 * @export
 * @enum {string}
 */
export enum ProjectPositionDTOTypeEnum {
    Lead = 'POSITION_LEAD',
    SubLead = 'POSITION_SUB_LEAD',
    Freelancer = 'POSITION_FREELANCER',
    Employee = 'POSITION_EMPLOYEE'
}
/**
 * @export
 * @enum {string}
 */
export enum ProjectPositionDTOPermissionsEnum {
    Create = 'CREATE',
    Read = 'READ',
    Update = 'UPDATE',
    Delete = 'DELETE'
}

