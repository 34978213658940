import { createStyles, makeStyles } from '@material-ui/core';
import { Field, Formik } from 'formik';
import React, { PropsWithChildren } from 'react';
import { object, string } from 'yup';
import { ButtonLoading } from '.';
import { UpdateStatus, UserCredentials } from '../models';
import { e2e, getFieldProps } from '../utils';

export const useSimpleFormStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        textAlign: 'left',
        fontSize: 15,
      },
      title: {
        fontSize: 23,
        marginBottom: theme.spacing(2),
      },
      intro: {
        margin: '0 0 30px',
      },
      actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
      },
      actionsBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 25,
        paddingTop: 20,
        borderTop: `1px solid ${theme.palette.divider}`,
        fontSize: 14,
      },
      btnUnderlined: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
      },
      success: {
        textAlign: 'center',
      },
      successIcon: {
        display: 'block',
        margin: `0 auto ${theme.spacing(3)}px`,
        height: 50,
        width: 50,
        color: theme.palette.icon,
        border: `3px solid ${theme.palette.icon}`,
        borderRadius: '50%',
      },
    }),
  { name: 'SimpleForm' }
);

export const SimpleFormActions = ({
  updateStatus: { isPending },
  ctaLabel,
  onCancel,
  fullWidth = true,
  c,
  children,
}: PropsWithChildren<{
  updateStatus: UpdateStatus;
  ctaLabel: string;
  onCancel?: () => void;
  fullWidth?: boolean;
  c: ReturnType<typeof useSimpleFormStyles>;
}>): JSX.Element => (
  <>
    <div className={c.actions}>
      <ButtonLoading
        type="submit"
        fullWidth={fullWidth}
        disabled={isPending}
        children={ctaLabel}
        {...e2e('btn-login')}
      />
    </div>

    {(children || onCancel) && (
      <div className={c.actionsBottom}>
        {onCancel && <div className={c.btnUnderlined} onClick={onCancel} children={'Back to Login'} />}
        {children}
      </div>
    )}
  </>
);

type Props = {
  onSubmit: (credentials: UserCredentials) => void;
  onReset?: () => void;
  onSignUp?: () => void;
  updateStatus: UpdateStatus;
  credentials?: UserCredentials;
};

type LoginFormModel = UserCredentials;
const validateName = (key: keyof LoginFormModel) => key;

export const LoginForm = ({
  onSubmit,
  onReset,
  onSignUp,
  credentials,
  children,
  ...props
}: PropsWithChildren<Props>): JSX.Element => {
  const c = useSimpleFormStyles({});
  const initialValues: LoginFormModel = credentials || {
    email: '',
    password: '',
  };

  const validationSchema = object({
    email: string().email('Must enter a valid email address').required('Email is required'),
    password: string().min(8, 'Password must have at least 8 characters').required('Password is required'),
  });

  const fieldProps = getFieldProps(props.updateStatus.isPending);

  return (
    <div className={c.root}>
      <h2 className={c.title}>Welcome,</h2>
      <p className={c.intro}>please sign in to MHP Capacity Planning.</p>

      <Formik
        initialValues={initialValues}
        enableReinitialize // needed to fill form via buttons
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit(values);
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field {...fieldProps.text} name={validateName('email')} label={'Email'} placeholder={'Email'} />
            <Field
              {...fieldProps.password}
              name={validateName('password')}
              label={'Password'}
              placeholder={'Password'}
            />

            <SimpleFormActions {...props} ctaLabel={'Login'} c={c}>
              {onReset && <div className={c.btnUnderlined} onClick={onReset} children={'Forgot Password?'} />}
              {onSignUp && <div className={c.btnUnderlined} onClick={onSignUp} children={'Create Account'} />}
            </SimpleFormActions>
          </form>
        )}
      </Formik>
      {children}
    </div>
  );
};
