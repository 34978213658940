import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModelType, Position, PotentialEmployees, PotentialEmployeesRequest } from '../models';
import { fetchModel, fetchModels, getJobTitles, getSkills } from '../store/mixed';
import { pick } from '../utils';
import { PositionForm } from './PositionForm';

type Props = {
  potentialEmployees: PotentialEmployees;
  startDate: Date;
  endDate: Date;
  position?: Position;
  onSubmit: (position: Position) => void;
  onPotentialEmployees: (request: PotentialEmployeesRequest) => void;
  onCancel: () => void;
};

export const PositionFormContainer = (props: Props): JSX.Element => {
  const dispatch = useDispatch();

  const formProps = {
    ...props,
    jobTitles: useSelector(getJobTitles).data,
    skills: useSelector(getSkills).confirm,
  };

  useEffect(() => {
    // fetch missing models
    const models = pick(formProps, ['jobTitles', 'skills']);
    Object.keys(models).forEach((key) => !models[key].length && dispatch(fetchModels.request(key as ModelType)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <PositionForm {...formProps} fetchModel={(data) => dispatch(fetchModel.request(data))} />;
};
