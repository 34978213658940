/* eslint-disable @typescript-eslint/no-explicit-any */
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import {
  AuthService,
  ClientService,
  ConfigService,
  DashboardService,
  EmployeeService,
  FAQService,
  FileService,
  FeedbackService,
  MixedService,
  PositionService,
  ProjectService,
  ReleaseNoteService,
  SearchService,
  Services,
} from '../services';
import { rootEpic } from './rootEpic';
import { RootAction, rootReducer, RootState } from './rootReducer';

declare global {
  interface Window {
    browserHistory: History<any>;
    store: Store;
    _paq: any[];
    __PRELOADED_STATE__: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const configureStore = ({ initialState, history }: any = {}) => {
  const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: {
      authService: new AuthService(),
      clientService: new ClientService(),
      configService: new ConfigService(),
      dashboardService: new DashboardService(),
      employeeService: new EmployeeService(),
      faqService: new FAQService(),
      fileService: new FileService(),
      feedbackService: new FeedbackService(),
      mixedService: new MixedService(),
      positionService: new PositionService(),
      projectService: new ProjectService(),
      releaseNoteService: new ReleaseNoteService(),
      searchService: new SearchService(),
    },
  });

  const middlewares = [routerMiddleware(history), epicMiddleware];

  let composeEnhancers = compose;
  if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  const newStore = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...middlewares)));

  epicMiddleware.run(rootEpic);

  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    module['hot'].accept('./rootReducer', () => newStore.replaceReducer(require('./rootReducer').default));
  }

  return newStore;
};

export const browserHistory = window.browserHistory ?? createBrowserHistory();
export const store =
  window.store ??
  configureStore({
    initialState: window.__PRELOADED_STATE__,
    history: browserHistory,
  });
