import {createStyles, makeStyles} from '@material-ui/core';
import cx from 'clsx';
import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useDispatch} from 'react-redux';
import {
    ButtonFloating,
    ConfirmationDialog,
    CriteriaForm,
    CriteriaSearch,
    CriteriaSorting,
    ErrorBlock,
    List,
    Modal,
    Tabs,
} from '../components';
import {getSubNavStyles, TabValue} from './Tabs';
import {getWrapStyles, Main} from '../layout';
import {CriteriaStatus, Model, ModelArray, ModelType, Sorting} from '../models';
import {createModel, editModel, removeModel, sendEmail} from '../store/mixed';
import {getFirstnameFromEmail, sortArrayBy} from '../utils';
import {EmailForm} from './EmailForm';

export type CriteriaFormModel = {
    id?: string;
    name: string;
    requestMessage?: string;
    status: CriteriaStatus;
    type: ModelType;
};

export type EmailFormModel = {
    message: string;
};

const useStyles = makeStyles(
    (theme) =>
        createStyles({
            ...getWrapStyles(theme),
            main: {
                top: theme.subnav.height,
            },
            head: {
                ...getSubNavStyles(theme).root,
                height: theme.subnav.height,
                padding: theme.spacing(0, 2),
                display: 'flex',
                alignItems: 'center',
            },
            headInner: {
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            headItem: {
                width: 350,
            },
        }),
    {name: 'Criteria'}
);

type Props = {
    criteria: ModelArray<Model>;
    type: ModelType;
};

export const CriteriaList = ({criteria, type}: Props): JSX.Element => {
    const c = useStyles({});
    const dispatch = useDispatch();
    const {error} = criteria;
    const initSortBy: Sorting<Model> = {
        orderBy: 'name',
        orderAsc: true,
    };

    const [criteriaStatus, setCriteriaStatus] = useState('confirm');
    const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModelOpen, setIsDeleteModalOpen] = useState(false);
    const [filteredId, setFilteredId] = useState(undefined);
    const [selectedCriteria, setSelectedCriteria] = useState<Model>(undefined);
    const [sortBy, setSortBy] = useState(initSortBy);

    const tabItems: TabValue<CriteriaStatus>[] = [
        {label: `Pending (${criteria['pending'].length})`, value: 'pending'},
        {label: `Confirm (${criteria['confirm'].length})`, value: 'confirm'},
        {label: `Deactivate (${criteria['decline'].length})`, value: 'decline'},
    ];

    const currentCriteria = {
        ...criteria,
        data: sortArrayBy(
            filteredId ? criteria[criteriaStatus].filter(({id}) => filteredId === id) : criteria[criteriaStatus],
            sortBy
        ),
    };

    const title = type.charAt(0).toUpperCase() + type.substring(1);

    const onFiltering = (data?: Model) => {
        setFilteredId(data?.id);
        if (data) {
            setCriteriaStatus(criteria.data.find(({id}) => data.id === id).status);
        }
    };

    const onSorting = (sorting: Partial<Sorting<Model>>) => {
        setSortBy({
            orderBy: sorting.orderBy ? sorting.orderBy : sortBy.orderBy,
            orderAsc: sorting.orderAsc,
        });
    };

    const onCloseModel = () => {
        setIsCreateModalOpen(false);
        setSelectedCriteria(undefined);
    };
    const onCloseDeclineModel = () => {
        setIsDeclineModalOpen(false);
        setSelectedCriteria(undefined);
    };

    const createOrEditCriteria = (request: CriteriaFormModel) => {
        selectedCriteria ? dispatch(editModel.request(request)) : dispatch(createModel.request(request));
        setIsCreateModalOpen(false);
        setSelectedCriteria(undefined);
    };

    const onEditClick = (data: Model) => {
        setSelectedCriteria(data);
        setIsCreateModalOpen(true);
    };
    const onDeclineClick = (data: Model) => {
        setSelectedCriteria(data);
        setIsDeclineModalOpen(true);
    };

    const onRemoveClick = (data: Model) => {
        setIsDeleteModalOpen(true);
        setSelectedCriteria(data);
    };

    const onDeleteCriteria = () => {
        dispatch(removeModel.request({id: selectedCriteria.id, type}));
        setIsDeleteModalOpen(true);
        setSelectedCriteria(undefined);
    };

    const onDeclineSendMail = (data: EmailFormModel) => {
        dispatch(
            sendEmail.request({
                to: selectedCriteria.createdBy,
                template: 'declineCriteria',
                templateProps: {
                    userName: getFirstnameFromEmail(selectedCriteria.createdBy),
                    criteriaName: selectedCriteria.name,
                },
                message: data.message,
            })
        );
        dispatch(
            editModel.request({name: selectedCriteria.name, type: type, id: selectedCriteria.id, status: 'decline'})
        );
        setSelectedCriteria(undefined);
        setIsDeclineModalOpen(!isDeclineModalOpen);
    };

    return (
        <Main className={c.main}>
            <Helmet title={title}/>
            <header className={c.head}>
                <div className={cx(c.wrap, c.headInner)}>
                    <Tabs value={criteriaStatus} values={tabItems} onChange={setCriteriaStatus}/>
                    <CriteriaSearch title={title} criteriaList={criteria.data} onSubmit={onFiltering}/>
                    <CriteriaSorting className={c.headItem} sorting={sortBy} onChange={onSorting}/>
                </div>
            </header>

            <div className={c.wrap}>
                {error && <ErrorBlock {...error} />}
                <List
                    type="criteria"
                    data={currentCriteria}
                    onConfirm={(data) =>
                        dispatch(editModel.request({name: data.name, type: type, id: data.id, status: 'confirm'}))
                    }
                    onDecline={onDeclineClick}
                    onRemove={onRemoveClick}
                    onEdit={onEditClick}
                />
            </div>
            <ButtonFloating onClick={() => setIsCreateModalOpen(true)} icon="add"/>
            <Modal
                isOpen={isDeclineModalOpen}
                onClose={onCloseDeclineModel}
                headline={`Decline "${selectedCriteria ? selectedCriteria.name : ''}"`}
            >
                <EmailForm criteria={selectedCriteria} onSubmit={onDeclineSendMail} onCancel={onCloseDeclineModel}/>
            </Modal>
            <Modal
                isOpen={isCreateModalOpen}
                onClose={onCloseModel}
                headline={`${selectedCriteria ? 'Edit' : 'Add'} ${title}`}
            >
                {isCreateModalOpen && (
                    <CriteriaForm
                        onCancel={onCloseModel}
                        onSubmit={createOrEditCriteria}
                        type={type}
                        title={title}
                        criteria={selectedCriteria}
                        isUserRequest={false}
                    />
                )}
            </Modal>
            {selectedCriteria && (
                <ConfirmationDialog
                    isOpen={isDeleteModelOpen}
                    headline={`Permanently Delete '${selectedCriteria.name}'`}
                    children={'Are you sure?'}
                    confirmLabel={'Delete'}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onConfirm={onDeleteCriteria}
                />
            )}
        </Main>
    );
};
