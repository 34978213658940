// tslint:disable
/**
 * Skill-Match REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: anh.pham@mhp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts } from '../runtime';
import type {
    AttachmentDTO,
} from '../models';

export interface CreateOrUpdateFileRequest {
    file: Blob;
}

/**
 * no description
 */
export class FileControllerApi extends BaseAPI {

    /**
     */
    createOrUpdateFile({ file }: CreateOrUpdateFileRequest): Observable<AttachmentDTO>
    createOrUpdateFile({ file }: CreateOrUpdateFileRequest, opts?: OperationOpts): Observable<AjaxResponse<AttachmentDTO>>
    createOrUpdateFile({ file }: CreateOrUpdateFileRequest, opts?: OperationOpts): Observable<AttachmentDTO | AjaxResponse<AttachmentDTO>> {
        throwIfNullOrUndefined(file, 'file', 'createOrUpdateFile');

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<AttachmentDTO>({
            url: '/api/files',
            method: 'PUT',
            body: formData,
        }, opts?.responseOpts);
    };

}
