import { getType } from 'typesafe-actions';
import { Entity, Position, PotentialEmployees, Project, UpdateStatus } from '../../models';
import { getInitialEntityState, initialUpdateStatus, replaceIn, withId } from '../../utils';
import * as AppActions from '../app/actions';
import * as fromActions from './actions';
import {
  MATOMO_EVENT_ACTION_DELETE_POSITION_LESS_THAN_2_HOURS,
  MATOMO_EVENT_ACTION_FETCH_SUGGESTIONS,
  MATOMO_EVENT_ACTION_OPEN_POS_CREATED,
  MATOMO_EVENT_CATEGORY_POSITIONS,
} from '../../constants';
import { differenceInHours } from 'date-fns';

export const TEMP_POSITION_ID = '-1';

type State = {
  readonly project: Entity<Project>;
  readonly potentialEmployees?: PotentialEmployees;
  readonly updateStatus: UpdateStatus;
};

const initialState: State = {
  project: getInitialEntityState(),
  potentialEmployees: undefined,
  updateStatus: initialUpdateStatus,
};

export const projectReducer = (
  state = initialState,
  action: fromActions.ProjectAction | AppActions.AppAction
): State => {
  const { project } = state;
  const { data } = project;
  const { positions } = data ?? {};
  const activePosition = positions?.find((item) => item.isActive);

  switch (action.type) {
    case getType(fromActions.fetchProject.request):
      return {
        ...initialState, // reset data and error
        project: {
          ...getInitialEntityState(),
          isLoading: true,
        },
      };

    case getType(fromActions.fetchProject.success):
      return {
        ...state,
        project: {
          ...project,
          data: action.payload,
          isLoading: false,
        },
      };

    case getType(fromActions.fetchPositionFeedback.success):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            showFeedback: action.payload,
          },
        },
      };

    case getType(fromActions.fetchProject.failure):
      return {
        ...state,
        project: {
          ...project,
          error: action.payload,
          isLoading: false,
        },
      };

    // -------------------------

    case getType(fromActions.createOrUpdateProject.request):
    case getType(fromActions.deleteProject.request):
      return {
        ...state,
        updateStatus: { isPending: true, isSuccess: undefined },
      };

    case getType(fromActions.createOrUpdateProject.success):
      return {
        ...state,
        project: {
          ...project,
          data: action.payload,
        },
        updateStatus: { isPending: false, isSuccess: true },
      };

    case getType(fromActions.deleteProject.success):
      return {
        ...state,
        project: {
          ...getInitialEntityState(),
        },
        updateStatus: { isPending: false, isSuccess: true },
      };

    case getType(fromActions.createOrUpdateProject.failure):
    case getType(fromActions.deleteProject.failure):
      return {
        ...state,
        updateStatus: { isPending: false, isSuccess: false, error: action.payload },
      };

    // -------------------------

    case getType(fromActions.createOrUpdatePosition.request):
      const newOrEditedPosition = action.payload.position;
      if (!newOrEditedPosition.id && process.env.NODE_ENV === 'production') {
        window._paq.push(['trackEvent', MATOMO_EVENT_CATEGORY_POSITIONS, MATOMO_EVENT_ACTION_OPEN_POS_CREATED]);
      }
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            // replace on update, add on create
            positions: newOrEditedPosition.id
              ? replaceIn(positions, withId(newOrEditedPosition.id), (item) => ({
                  ...item,
                  updateStatus: { isPending: true },
                }))
              : // new position needs some additional props and a temporary id
                [
                  ...positions,
                  {
                    ...newOrEditedPosition,
                    id: TEMP_POSITION_ID,
                    updateStatus: { isPending: true },
                    isActive: true,
                  },
                ],
          },
        },
      };

    case getType(fromActions.createOrUpdatePosition.success):
      const newPosition = action.payload;
      const tempPosition = positions.find(withId(TEMP_POSITION_ID)); // see if we got a temporary one which was freshly created
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: replaceIn(positions, withId((tempPosition ?? newPosition).id), (item) => ({
              ...item, // isActive is forwarded here
              ...newPosition,
              updateStatus: { isPending: false, isSuccess: true },
            })),
          },
        },
      };

    case getType(fromActions.createOrUpdatePosition.failure):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: replaceIn(positions, withId(activePosition.id), (item) => ({
              ...item,
              updateStatus: { isPending: false, isSuccess: false, error: action.payload },
            })),
          },
        },
      };

    // -------------------------

    case getType(fromActions.deletePosition.request):
      if (process.env.NODE_ENV === 'production') {
        if (differenceInHours(new Date(), action.payload.position.created)) {
          window._paq.push([
            'trackEvent',
            MATOMO_EVENT_CATEGORY_POSITIONS,
            MATOMO_EVENT_ACTION_DELETE_POSITION_LESS_THAN_2_HOURS,
          ]);
        }
      }
      if(positions === null || positions === undefined){
        return {
          ...state,
        }
      }else {
        return {
          ...state,
          project: {
            ...project,
            data: {
              ...data,
              positions: replaceIn(positions, withId(action.payload.position.id), (item) => ({
                ...item,
                updateStatus: {isPending: true},
              })),
            },
          },
        }
      }

    case getType(fromActions.deletePosition.success):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: replaceIn(positions, withId(action.payload)),
          },
        },
        updateStatus: { isPending: false, isSuccess: true }, // since the position is gone we refelect the success on the top level
      };

    case getType(fromActions.deletePosition.failure):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: positions.map<Position>((item) => ({
              ...item,
              updateStatus: { isPending: false, isSuccess: false, error: action.payload },
            })),
          },
        },
      };

    // -------------------------

    case getType(fromActions.fetchSuggestions.request):
      if (process.env.NODE_ENV === 'production') {
        window._paq.push(['trackEvent', MATOMO_EVENT_CATEGORY_POSITIONS, MATOMO_EVENT_ACTION_FETCH_SUGGESTIONS]);
      }
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: replaceIn(positions, withId(action.payload.positionId), (item) => ({
              ...item,
              suggestions: action.payload.page > 1 ? item.suggestions : [],
              totalPages: action.payload.page > 1 ? item.totalPages : 0,
              totalResults: action.payload.page > 1 ? item.totalResults : 0,
              isLoadingSuggestions: true,
            })),
          },
        },
      };

    case getType(fromActions.fetchSuggestions.success):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: replaceIn(positions, withId(action.payload.positionId), (item) => {
              const suggestion = item.suggestions.concat(action.payload.suggestions);
              return {
                ...item,
                suggestions: suggestion,
                totalPages: action.payload.totalPages,
                totalResults: action.payload.totalResults,
                isLoadingSuggestions: false,
              };
            }),
          },
        },
      };

    case getType(fromActions.fetchSuggestions.failure):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: positions.map<Position>((item) => ({ ...item, isLoadingSuggestions: false })),
          },
        },
      };

    // -------------------------

    case getType(fromActions.fetchPotentialEmployees.request):
      return {
        ...state,
        potentialEmployees: {
          employees: undefined,
          totalPages: undefined,
          totalResults: undefined,
          isLoadingPotentialEmployees: true,
        },
      };

    case getType(fromActions.fetchPotentialEmployees.success):
      return {
        ...state,
        potentialEmployees: {
          employees: action.payload.employees,
          totalPages: action.payload.totalPages,
          totalResults: action.payload.totalResults,
          isLoadingPotentialEmployees: false,

        },
      };

    case getType(fromActions.fetchPotentialEmployees.failure):
      return {
        ...state,
        potentialEmployees: {
          employees: undefined,
          totalPages: undefined,
          totalResults: undefined,
          isLoadingPotentialEmployees: false,
        },
      };

    // -------------------------

    case getType(fromActions.setActivePosition):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: replaceIn(positions, withId(action.payload), (item) => ({
              ...item,
              isActive: true,
            })),
          },
        },
      };

    case getType(fromActions.resetActivePosition):
      return {
        ...state,
        potentialEmployees: {
          employees: undefined,
          totalPages: undefined,
          totalResults: undefined,
          isLoadingPotentialEmployees: false,
        },
        project: {
          ...project,
          data: {
            ...data,
            positions: (activePosition
              ? replaceIn(positions, withId(activePosition.id), (item) => ({
                  ...item,
                  updateStatus: { isPending: false, isSuccess: undefined },
                  isActive: false,
                }))
              : positions
            ).filter((item) => item.id !== TEMP_POSITION_ID), // get rid of temporary position
          },
        },
      };

    // -------------------------

    case getType(fromActions.assignEmployeeToPosition.request):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: replaceIn(positions, withId(action.payload.positionId), (item) => ({
              ...item,
              updateStatus: { isPending: true },
            })),
          },
        },
      };

    case getType(fromActions.assignEmployeeToPosition.success):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...action.payload, // we get a whole project response
            positions: replaceIn(
              action.payload.positions,
              withId(activePosition.id), // retrieve the old one
              (item) => ({
                ...activePosition, // isActive is forwarded here
                ...item, // set the right updateStatus on the one we worked before
                updateStatus: { isPending: false, isSuccess: true },
              })
            ),
          },
        },
      };

    case getType(fromActions.assignEmployeeToPosition.failure):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            positions: replaceIn(positions, withId(activePosition.id), (item) => ({
              ...item,
              updateStatus: { isPending: false, isSuccess: false, error: action.payload },
            })),
          },
        },
      };

    // -------------------------
    case getType(fromActions.hideCreatePositionFeedback):
      return {
        ...state,
        project: {
          ...project,
          data: {
            ...data,
            showFeedback: data.showFeedback.filter((data) => data.positionId !== action.payload),
          },
        },
      };

    case getType(fromActions.resetProject):
    case getType(AppActions.logout.success):
      return initialState;

    default:
      return state;
  }
};
